const RastreioIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<style type="text/css">{'.st0ras{display:none;}.st1ras{display:inline;}.st3ras{fill:none;stroke:#FFFFFF;stroke-width:9.0937;stroke-miterlimit:10;}'}</style>
		<g>
			<g className="st0ras">
				<title>Asset 1</title>
				<g id="Layer_2" className="st1ras">
					<g id="Layer_1-2">
						<polygon className="st2ras" points="1464.7,2108.4 1464.7,2302.4 1296.7,2205.1 1296.7,2011.1 				" />
						<polygon className="st2ras" points="1641.7,2011.1 1641.7,2205.1 1473.7,2302.4 1473.7,2108.4 				" />
						<polygon className="st2ras" points="1301.4,2002.2 1469,2099.4 1637,2002.2 1469,1905.4 				" />
						<path
							className="st3ras"
							d="M1301.4,2200.9l167.6,96.8c0,0,70.3,127.9,221.8,87.8c0,0-115.6,104.8-292.6,69.9c0,0-94.9-76.9-96.8-157.6
					V2200.9z"
						/>
						<path
							className="st3ras"
							d="M1301.4,2297.6v-290.7v0.9l-0.5-0.5l0.5-0.5l-89.2,92.5c-91.6,88.7-42,202-42,202
					c77.4,129.8,228,153.9,228,153.9C1297.2,2370.3,1301.4,2297.6,1301.4,2297.6z"
						/>
						<path
							className="st3ras"
							d="M1301.4,2006.9l-89.2,92.5c0,0-88.7,75-42,202c0,0-127.9-170.4-9.4-379.9c0,0,44.8-52.4,116.6-45.8
					c6.1,0.9,183.6,33,189.7,34.5l1.9,0.5l0,0l-0.9,0.5C1467.1,1911.1,1418.5,1939.4,1301.4,2006.9z"
						/>
						<path
							className="st3ras"
							d="M1469,1910.1l-191.6-34.5c0,0-61.4-9.4-117.1,45.8c0,0,138.8-253.9,435.2-152.9c0,0,41.5,39.2,41.5,115.6
					v122.7L1469,1910.1z"
						/>
						<path
							className="st3ras"
							d="M1816.8,2018.7c-37.3-186.4-221.8-250.2-221.8-250.2c-51.9-22.2-113.7-23.1-113.7-23.1
					c13.7,0,103.4,0,135.5,54.8c14.6,24.5,17,59.9,19.4,94.9c1.4,22.7,0.9,41.5,0,54.8v6.1c0,0.5,0,1.4,0,1.9c0,0.9,0,1.9,0,1.9
					c0,1.4,0,76.5,0,236.5c-0.9,0.5-1.4,0.5-1.9,0.5c-0.5,0-0.5,0-0.5,0h0.5c0.5,0,0.9,0,1.9-0.5c0.5-0.5,0.5-0.5,0.9-0.9
					c0.5,0,1.4,0,1.9-0.5c77.4-51.4,113.3-76.5,131.2-89.2c1.4-0.9,3.3-2.4,5.2-4.2C1804.1,2079.6,1813.5,2044.7,1816.8,2018.7z"
						/>
						<path
							className="st3ras"
							d="M1469,2297.6c0,0,66.1,127.9,221.8,87.8c0,0,180.3-130.7,126.5-367.2c0,0,6.6,47.2-46.3,87.8
					c-44.4,29.7-88.7,59.9-133.1,89.7C1581.3,2230.1,1525.1,2264.1,1469,2297.6z"
						/>
					</g>
				</g>
			</g>
			<g>
				<g>
					<path
						className="st2ras"
						d="M563.7,579.6c43.4-65.1,95.3-121.8,151.5-174.2c138.3-127.4,296.4-220.9,479.1-269.5
				c189.7-50.5,379.9-44.8,570.2-1.4c62.3,14.2,117.5,40.1,165.2,82.6c53.3,47.7,77.4,110.4,89.7,178.9
				c21.2,118.9,24.5,239.3,20.8,360.1c-1.4,48.6-0.5,97.2-0.5,146.3c0,6.6,0,13.2,0,23.1c-29.3-17-56.2-32.1-83.1-47.7
				c-135.5-77.9-272.8-153.4-405.4-236c-50-31.2-103.8-33-156.2-43c-169.9-33-340.8-62.8-511.6-92.5c-93.5-16-182.2,3.8-266.7,44.8
				C599.1,559.8,581.6,570.2,563.7,579.6z"
					/>
					<path
						className="st2ras"
						d="M2655.5,1093.1c8.5,48.6,12.3,93,13.7,137.8c10.4,335.1-108.1,620.2-335.6,862.3
				c-18.4,19.4-39.2,36.8-57.1,56.6c-29.7,31.6-66.1,47.2-109,54.8c-266.7,48.6-492.3-66.1-635.8-269c-9-12.7-1.4-14.6,7.1-19.8
				c172.7-99.6,345-199.2,518.2-298.3c9.9-5.7,17.9-10.9,15.1-24.1c-1.9-9,6.1-11.3,11.8-15.1c129.8-87.3,258.6-176.1,389.4-262
				C2550.3,1265.9,2617.8,1197.4,2655.5,1093.1z"
					/>
					<path
						className="st2ras"
						d="M1456.2,640c-57.1,33-110,63.2-162.8,93.5c-115.2,66.5-230.3,133.6-345.5,200.6c-6.6,3.8-12.7,8-17.9,13.2
				c-106.2,110-214.8,217.6-318.1,330.4c-120.4,131.2-171.3,288.4-156.2,466.3c1.4,19.8,4.2,39.2,6.6,59.5c-7.1,0.9-7.6-5.2-9.4-9
				c-57.6-118-95.8-241.7-112.8-371.9c-34-259.1,21.7-500.3,144.9-727.8c7.1-13.2,16.5-24.5,27.8-34.9
				C600.5,582,702.5,537.1,820.5,531c39.2-1.9,76.9,8,114.7,14.6C1106.9,576.3,1279.2,607.9,1456.2,640z"
					/>
					<path
						className="st2ras"
						d="M1975.4,203.9c44.8,19.8,86.8,43,128.4,68c192.1,117.5,347.9,270,449.3,473.9
				c36.3,72.7,61.8,149.1,80.2,228.4c6.6,28.3-2.4,54.3-8.5,80.7c-23.1,94.4-72.7,170.4-154.8,225.1
				c-128.9,86.4-256.8,174.2-385.1,261.5c-3.3,2.4-7.1,4.2-13.7,8.5c0-7.6,0-13.7,0-19.4c0-115.6,0-230.8,0-346.4
				c0-184.1,0.5-368.1,0-552.2c0-75.5,1.9-151-15.1-225.1c-15.1-66.1-38.7-128.4-75-186C1978.7,215.7,1974,211.9,1975.4,203.9z"
					/>
					<path
						className="st2ras"
						d="M961.6,1626.9c81.2,46.7,159.1,91.6,237.4,136.4c89.7,51.4,178.9,103.8,269,154.3
				c17.5,9.9,29.7,22.2,40.6,38.2c104.3,151.5,244.5,250.2,428.1,281.3c74.1,12.7,149.1,10.9,223.2-1.4c1.9,9-5.2,9.4-9,12.3
				c-135,89.7-282.7,149.1-442.2,177.9c-138.3,25-276.6,23.6-414.9-0.5c-13.7-2.4-25.5-7.6-36.3-17
				c-100.1-90.6-187.8-191.2-247.8-313.4c-31.6-64.7-50-132.2-48.6-205.3C963,1803.5,961.6,1716.6,961.6,1626.9z"
					/>
					<path
						className="st2ras"
						d="M927.6,994.5c5.2,7.1,2.8,14.2,2.8,20.8c0,93.5,0,187.4,0,280.8c0.5,207.7,0,415.8,1.4,623.5
				c0.5,80.7,30.2,153.4,67.5,223.2c49.1,92,114.2,172.3,190.2,244c5.7,5.7,12.7,9.4,17.5,16.5c-31.2-3.3-59-12.7-86.8-22.7
				c-165.2-57.6-313.4-143-439.4-265.3c-58.1-56.2-109.5-118.5-149.1-188.8c-23.6-41.5-32.1-89.2-39.2-135.5
				c-24.5-156.2,4.2-301.1,93.9-432.8c34-50,78.3-90.6,119.4-134c71.3-75,144-149.1,215.7-223.7
				C922.9,998.7,925.2,996.8,927.6,994.5z"
					/>
					<path
						className="st2ras"
						d="M1486.9,1588.7c0-94.4-0.5-188.8,0.5-282.7c0-13.7-3.8-20.8-15.6-27.4c-165.7-95.3-330.9-191.6-496.5-287
				c-8-4.7-14.6-8-13.7-19.4c1.4-15.6,8.5-20.8,22.7-12.7c63.2,36.3,126,72.7,189.3,109c105.3,60.4,210.5,121.3,315.8,182.2
				c9,5.2,15.6,6.1,25,0.5c165.2-95.8,330.9-191.6,496.5-287c8.5-4.7,17-15.6,27.4-3.3c7.1,9,0.5,24.1-12.3,31.6
				c-164.7,95.3-329.9,191.2-494.6,286.5c-11.8,6.6-16,14.2-16,27.8c0.5,186,0.5,372.4,0.5,558.4c0,4.2-0.5,9,0,13.2
				c1.4,11.3-6.6,17.5-15.1,17.5c-9,0-13.7-6.6-13.7-17.9c0.5-92.5,0.5-185,0.5-277.1C1486.9,1596.3,1486.9,1592.5,1486.9,1588.7z"
					/>
				</g>
				<path
					className="st2ras"
					d="M2246.3,2208.9c-0.9,0.5-1.4,0.9-2.4,1.4c-1.9,1.4-4.7,3.8-8,6.6c-236,192.1-499.4,240.2-604.6,253.9
			c-91.6,11.8-233.6,19.4-405-14.6l0,0c-53.3-10.4-111.9-26-174.6-48.6c-159.5-58.5-278-143.5-356.8-212.4
			c230.3,219.5,460.2,438.9,690.5,658.4c5.2,6.1,38.2,43.9,93.9,46.3c57.1,2.4,93.9-33.5,99.6-39.2
			c242.1-232.7,484.7-465.4,726.9-698.1C2287.4,2179.2,2267.6,2194.7,2246.3,2208.9z"
				/>
			</g>
		</g>
	</svg>
);
export default RastreioIconBranco;
