const WidgetInstagramIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_4" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<path
				className="st0wins"
				d="M2360,333.8c1.2,74.1-19,136.2-61.2,191.4c-45.8,60-93.5,117.7-152.1,166.2c-29,24-57.9,33.2-95.6,22.8
		c-76.2-21-153.2-38.7-232-48.2c-37.5-4.5-49.8-30.9-27.8-58.1c127.4-157.5,254-315.9,391-465.3c4.5-4.9,9-9.7,13.7-14.4
		c46-45.6,101.7-35,126.6,24.8c15.2,36.6,22.9,75.4,30.8,114.2C2358.1,290.9,2359,314.7,2360,333.8z"
			/>
			<g>
				<path
					className="st0wins"
					d="M2424,1929c-22.4-171.5-40.3-319.4-105.4-481.1c-4.2-10.4-11.9-23.9,1.3-31.1c45.9-24.9,74.5-81.2,97.5-126.8
			c17.5-34.6,1.6-56.8-36.8-53.5c-22.6,1.9-40.8-3.9-51.3-25.7c-6.1-12.6-14-24.6-18.6-37.6c-30-84.8-68.5-166-107.3-247.1
			c-39-81.6-102.3-141-188.3-169.5c-44.3-14.7-81.3-21.6-127.1-27.7c-23.7-3.3-80.1-2.8-80.1-2.8s-57.3-50.5-137.3-80.9
			c-53.3-23.7-110-34.1-169.5-30.6c-118.4,8.2-190.5,86.9-193.6,90.3c0,0-70.8-8.8-118.4-5.8c-52,3.4-103.9,28.1-149.5,60
			c10.7-52.4,21.2-99.5,29.9-146.9c16.5-89.9,15.6-177.1-40.2-256.2C993,304.8,955,256.1,891.7,237c-77.2-23.3-126.6,5.9-141.5,84
			c-6,31.6-11.4,63.3-15.1,95.2c-17.7,156.1-26,312.9-36.1,469.6c-1.6,25.1,2.1,51.9-11.2,74.9c-16.3,28-28.8,57.5-38.1,88.5
			c-29.6,99-55.5,198.3-66.7,301.8c-16.3,150.9-25.9,301.7-19.5,453.3c4.4,105.6,5.6,211.4,19.2,316.8
			c11.8,91.7,13.3,184.8,32.2,275.7c9.3,44.9,12.9,91.3,29.6,134.5c49.4,127.1,139.4,213.5,265.5,264
			c114.8,46,231.1,85.5,354.5,100.7c149,18.4,280.6-31.2,405.8-104.9c5.1-3.5,10.4-6.8,15.8-10.6c2.8-2,2.5-6.1-0.2-7.8
			c-36.4-19.5-71.9-40.9-102.9-65.3c-69.6-54.9-148.2-90.7-231-119.5c-134.4-46.9-262-107.1-373.5-197.5
			c-136.4-110.5-242.9-241.7-286.4-416c-9.6-38.2-19-76.5-24.3-119.6c-0.6-4.8,5.9-6.8,8.2-2.5c8.2,16.3,19.1,38.1,24.8,50.4
			C748,2004.2,798,2102,866.1,2191.3c107.3,140.7,247.8,239.6,411.8,301.8c141.2,53.6,278.8,111.2,400.3,203.5
			c45,34.2,99.4,53.8,155.2,65.7c78.5,16.7,158,15.4,236.8,4.5c129.1-17.8,231.7-77.8,292.1-198.1c27.4-54.7,45-113.1,55.4-173.2
			C2444.3,2240.6,2444.2,2085,2424,1929z M1860.9,766.9c132.9,5,252.7,48,313.3,182.4c34.8,77.3,72.2,153.6,100.6,233.7
			c4.7,13.1,11,25.8,17.8,38c21.9,38.3,38.7,50.6,79.3,52.8c5.9,0.3,9.4,6.8,6.4,12c-27.1,47-59.1,92.4-115.6,103.1
			c-46.3,8.8-92,18.2-138.9-8.2c-12.8-7.1-12.9-11.3-9.6-23.2c21-76.2,41.8-152.4,57.6-229.9c5.6-27.4,10.5-54.9,3.5-83.3
			c-8-32.2-26.4-46.2-59.3-40.9c-44.1,7.1-136.6,61.5-142,63.4c-8.3,3.1-12.2,1.6-15.3-6c-2-5.2-14.8-94.6-22.9-130.7
			c-10.3-45.1-61.5-133.8-78.1-157.3C1855.8,770.4,1857.7,766.8,1860.9,766.9z M2120.4,1154.5c3.6-1,7,2.3,6.2,6
			c-4.7,21.2-8.6,39-12.6,56.8c-2.1,9.8-9.3,15.3-17.3,20.1c-99.2,60.1-199.5,48.8-300.4,4.6c-15.7-6.9-10.3-13.9-0.6-20.2
			c38.7-25,77.7-49.6,116.6-74.4c6.8-4.3,13.2-3.8,21.1-1.8C1993.9,1161.1,2054.7,1171.5,2120.4,1154.5z M1976.2,1109.4
			c44-26.6,83.6-50,127.2-65c30.6-10.5,36.1-4.8,37.8,26.7c1.3,23.8-3.6,38.8-29.9,47.1c-43.7,13.9-85.8,12-133.8-0.3
			C1973.4,1116.8,1972.7,1111.5,1976.2,1109.4z M1977.9,1312.4c36.7-2.6,71.9-12.3,108.9-25.7c3.5-1.2,6.9,1.7,6.2,5.4
			c-6.9,33.8-15.7,64.3-26.4,94.3c-4.8,13.4-15.1,19.5-25.5,27.2c-123.5,90-246.3,87.4-368.6,11.1c-9-5.6-9.8-11.5-8.3-20.2
			c7.9-45.8,27.1-85.5,58.3-120.2c16.3-18.2,32.1-22,54.2-11.5C1840.4,1302.8,1907.1,1317.3,1977.9,1312.4z M2079.3,1473
			c6.1-22.3,12-40.2,17.8-57.1c1.1-3,2.7-8.7,8.3-5.9l29.6,15.9c3.4,2.6,1.5,6.4-1.8,9.3c-7.8,6.8-45.9,36.4-51.6,39.4
			C2080.3,1475.2,2079,1474.2,2079.3,1473z M1340.3,733c63.2-58.7,135.3-96.5,224.5-79.7c85.3,16,159.7,56.4,225.8,112.4
			c73.3,62.2,110.1,142.7,122.9,235.7c3.2,22.8,9,59.1,11.3,80.9c1.2,10.9,0.2,13.7-6.6,18.3c-62.7,38.3-137,82.4-193.2,130.6
			c-61.9,53-95.3,119.3-99.5,200.2c-0.8,13.9,0.4,53.8,0.4,53.8c-1.8,1-4.6,0.7-9-1.7c-140.1-77-268.3-165.6-316.4-330.4
			c-35.7-105.8-36.8-205-35.6-254.3C1266.5,825.9,1303.7,766.8,1340.3,733z M915.2,1315c-32.3-118.1-32.1-235.4,18.4-349.4
			c21.1-47.6,51-89.4,84.3-129.7c35.2-42.7,75.1-75.9,129.3-90.2c40.4-10.6,80.6-10.4,126.2-6.4c4.6,0.4,7.2,5.6,4.7,9.5
			c-24.5,38.6-40.6,75.4-47.3,116.3c-13.1,79.9,0.2,157.4,17.5,235.4c36.6,164.9,128,289.5,274.3,371.9
			c27.9,17.8,81.8,46.7,84.1,48.5c3,2.3,2.7,6.9-0.6,8.7c-42.9,25.1-270.3,72.3-394.5,33.9c-52.8-16.4-104.5-34.3-153.3-61.2
			C982.7,1460.3,937.3,1395.9,915.2,1315z M1755.9,1907.6c-14.6-1.7-39.3-4.4-48-5.4c-49.2-5.1-98.4-10.6-147.8-15.2
			c-76.1-7-149.8,3.7-220,34.2c-33.1,14.4-67.2,27.1-97.1,48.1c-16.4,11.5-31.9,7-45.4-4.6c-44.5-38.7-130.6-161.7-142.7-181.2
			c-5.7-9.2,1.5-20.9,12.3-20c21.7,1.8,53.9,10,62.7,13.1c22.4,8.2,54.7,19.3,75.3,30.9c4.5,2.5,7.6,3.5,13.5,1.9
			c121.2-56.2,269.5-55.5,394-21.5c44.9,12.2,80.7,40.6,114.6,71.1c6.2,5.7,23.3,27.7,33,40.5
			C1763.1,1903.1,1760.2,1908.1,1755.9,1907.6z M1860.9,1591.8c-93.9-1.2-169.1-39.1-193.7-121.4c-1.2-4.1,3.2-7.6,6.8-5.4
			c123.1,73.8,243.6,64.6,363.1-7.2c2.1-1.2,4.7,0.8,3.8,3.2C2008.6,1552.6,1948.3,1592.8,1860.9,1591.8z M2140.7,2430.4
			c-4.8,12.4-16.3,15.3-28.5,16.2c-36.9-0.6-68.9-13.1-94.4-40.3c-7.6-8.1-12.2-18.5-8-29.5c4.7-12.2,16.7-16.3,28.3-16.2
			c36,0.4,67.5,12.7,93.1,38.5C2139.5,2407.6,2145.2,2418.3,2140.7,2430.4z M2124,1855.4c-48.1-44.2-65.5-101.7-67.9-165
			c-0.3-38.2,8.1-74.1,28.7-106.4c35.2-55.1,101.3-66,152.6-25.5c75.7,59.8,99.1,193.5,48.2,275.8
			C2248.3,1894.2,2176.7,1904.1,2124,1855.4z M2322.2,2385.3c-7.7,8.6-17.4,16.4-30.2,11.9c-12.2-4.3-16.8-15.2-18-27.2
			c-3.2-28.8,3-55.7,17.9-80.3c9.5-15.5,20.4-33.8,41.8-27.2c19.8,6.2,19,26.5,19.2,46C2350.7,2335.9,2342.1,2363,2322.2,2385.3z"
				/>
			</g>
		</g>
	</svg>
);
export default WidgetInstagramIconBranco;
