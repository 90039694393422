const CompreJuntoIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<style type="text/css">{'.ghhghhhhhhh{fill:none;}'}</style>
		<g>
			<g>
				<g>
					<path
						className="ghhghhhhhhh"
						d="M1310.9,412.1c-26.1-93.6-76.1-170.8-163.1-218.6c-77.4-42.6-157.5-43.6-236.5-3.9
				c-85.1,42.9-136.7,114.7-167.5,202.7c-9.1,26.1-14.5,53.1-17.8,80.4h596C1319.8,452.3,1316.5,432.1,1310.9,412.1z"
					/>
				</g>
			</g>
			<g id="Camada_3">
				<path
					className="odlkfsodpfk"
					d="M1591.8,2193.8c-10.3-16.1-22.7-30.7-35.4-44.7c-19.1-21-46.5-50.2-62.5-67.2c-8-8.6-19.8-12.6-31.3-10.6
			c-20.4,3.4-54.6,8.8-87.1,12.6c-56.6-3.5-84.5-9.8-100.2-14.9c-8.2-2.7-16,4.9-13.8,13.1c6.5,23.8,20.9,58.9,64.9,106.4
			c33.2,35.9,68.2,69.8,103.1,104.1c9.5,9.4,14.3,19.5,16.9,32.2c10.4,52.6,21.7,104.9,32.3,157.4
			c24.4,121.3,67.3,235.3,131.5,341.4c13.1,21.7,30.4,33.8,54.2,39.3c40.7,9.4,81.3,18,122.7,23c69.1,8.4,137.9,4.6,206.4-6.6
			c37.3-6.1,40.7-43.3,25.4-64.2c-5.2-7.1-11.3-13.8-17.2-20.6C1949.8,2735.4,1609.9,2222.1,1591.8,2193.8z"
				/>
				<path
					className="odlkfsodpfk"
					d="M1944.3,868.2c7.9-1.2,14.5-5.3,21-9.4c16.9-10.3,32.3-22.4,47.5-34.7c25.9-21,51.3-42.5,77.4-63.2
			c89.3-71.6,182-138.2,285.4-188.7c15-7.3,29.1-16.2,42.6-26.1c9.2-6.6,17.9-13.8,25.6-22.2c20.1-22,20-48.6-0.5-70.5
			c-18.3-19.6-41.4-27-69.8-26.2c-7.9,0.6-17.9,1.2-27.8,3.6c-39.4,9-75.3,25.7-108.1,49.1c-26.8,19.1-51.5,40.4-74.9,63.2
			c-79.3,77.7-149.3,163.5-214,253.5c-7,9.7-13.1,19.8-19.1,30.2c-3.2,5.6-6,11.8-7.4,18C1918.6,860.6,1928.3,870.6,1944.3,868.2z"
				/>
				<path
					className="odlkfsodpfk"
					d="M2730.7,693.3c-22.4-16.9-46.6-28.8-70.5-26.7c-42,1-77.2,9.7-112,20.7c-118.9,37.7-233.4,85.8-347.2,136.3
			c-24,10.6-47.6,21.7-70.3,35.1c-6.1,3.6-12.4,7.3-16.7,13.1c-8.8,12.1-3.6,25.7,11.3,28.2c8.8,1.3,17.9,0.6,26.7-1
			c13.4-2.6,27-5,40.2-8c96.2-22.4,192.9-43.1,290.9-56.8c51.8-7.3,103.9-13,155.9-19.6c24.9-3.1,49.4-7.6,73.2-15.6
			c24-8,38.6-25.1,43.1-50.2C2760.1,724.7,2749,707,2730.7,693.3z"
				/>
				<g>
					<path
						className="odlkfsodpfk"
						d="M1115.2,1279.4c42.9,52.3,72.9,83.5,126.3,124.5c21.1,16.2,66.9,43.3,66.9,43.3s109.6-110.9,223.1-142
				c10.6-3,36.9-12.9,48.8-14.5c-1.3-29.5,4.5-65.7,9.6-95.1c10.9-61.7,18-85.3,48.1-141.4c9-16.7,42.2-71.5,65.4-89.8
				c26.5-20.8,59.2-41.5,83.4-57.6c-3-43.5-3-36.9-6-80.5c-1.9-27.8-5.2-75.6-6.9-103.6c-1.2-19.6-1.5-20.3-20.4-20.9l-523.8-0.3
				l0,0l-315.4-0.2c-15.3-0.3-16.7,2.6-19.1,18.6c-4.5,31.9-8.4,63.7-12.5,95.4c-6.2,47.3,0.4-3-5.7,44.4
				c28.2,51.7,97.5,186.9,111.1,212.5C1027.7,1147.2,1061.1,1213.6,1115.2,1279.4z"
					/>
					<g>
						<path
							className="odlkfsodpfk"
							d="M404.5,1468.5c61.7-12.2,129.3-25.2,190.5-32.4c45-5.2,143.2,0.5,144.3,0.7c-1-0.1,0.5-5.5,0.5-5.5
					s-16-8.3-34.1-27c-68.4-70.4-116.9-164.4-128.8-259.8c-9.6-77.2,5.2-188.7,45.1-255.1c17.6-29.1,55.6-65.2,85.4-79.4
					c27.9-13.2,54.7-20.6,93.2-8.8c20,6.2,29.4,11.8,31.1,14c2.9-19,10-69.2,16.7-110.9c3.7-22.9,16-38.5,34.9-45.5l395.3-2.3
					l253.4-1.4c20.2,0,43.5,0.1,51.7,0.1c13.1,0,13.7-0.1,12.4-12.5c-4.2-37.6-9-74.4-14.3-112.1c-3.6-26.5-17.6-52.9-44.1-54.8
					h-5.7v-0.4h-58.1h-91.6c-1.5-14.4-3.4-28.8-5.9-43.1c-20.2-113.6-74.7-207.2-170.1-273c-100.1-68.8-207.8-77.8-318.9-27.3
					c-71.8,32.6-123.9,87.1-165.4,153.1c-37.1,59.2-53.5,123.7-61.5,190.5H545.5v0.1c-14.8,0.1-29.5,0.3-44.4,0.2
					c-35.2-0.1-52.4,16-57.6,51c-13.4,89.3-27,178.8-40.4,268.3c-16.4,109-32.8,217.8-49.1,326.7c-16.2,107.7-32.5,215.6-48.7,323.2
					c-4.6,31.2-4.6,26.5-8.6,57.7C296.7,1502.8,371.8,1475,404.5,1468.5z M741,395.3c30.9-88,82.5-159.8,167.5-202.7
					c79-39.7,159.1-38.7,236.5,3.9c87,47.8,137,125,163.1,218.6c5.5,19.9,8.9,40.2,11,60.6h-596C726.6,448.4,731.8,421.5,741,395.3z
					"
						/>
						<g>
							<path
								className="odlkfsodpfk"
								d="M2613,1500.8c-13.7-63.9-40.3-122.9-71-180.2c-31.1-58.3-66.5-113.4-108.9-163.5
						c-10.7-12.6-21.7-24.9-33.2-36.8c-42.8-44-90.8-80.1-142.3-111c-34.9-20.9-71.3-39.3-108.8-56.3
						c-51.3-23.2-105-38.1-161.2-44.1c-1.9-0.2-3.9-0.5-5.8-0.6c-68.5-6.3-135.9,11.7-191.9,48.9c-24.3,16.1-46.4,35.8-65.5,58.7
						c-19,22.7-37.1,46.2-46,62.9c-30.1,56-43.9,116.6-54.8,178.3c-5.1,29.3-8.9,58.9-7.5,88.4c-11.9,1.6-50.2,6.9-60.8,9.8
						c-113.4,31.1-240.5,159.4-240.5,159.4s-71.3-42.5-92.5-58.7c-53.4-41-103.1-85.9-145.9-138.2
						c-54.1-65.8-92.8-140.9-132.1-215.6c-13.5-25.6-49.1-91.1-79.7-144.3c-18.2-31.6-34.5-59-43.5-71.2c-1.6-2.3-3-4-4.2-5.1
						c-16.4-22.8-47.6-31.4-76.6-20.6c-32.3,12.2-57.6,33.8-75.2,63c-39.9,66.4-54.5,138.5-44.9,215.6
						c11.9,95.4,53.4,174.2,121.3,243.9c1.5,1.5,3.1,3.1,5,4.9c13.2,12.4,34.6,28.7,55.6,43.9c30.9,22.4,61.2,42.5,64.1,44.2
						c112.8,67.6,168,92.8,280.6,143c14,6.3,89.6,37,90.7,37.5c0,0-10,21.8-21.6,48.8c-29.5-16.3-171.4-75-180.7-78.5
						c-167-63.7-241.5-130.4-241.5-130.4c-1.4-0.3-2.9-0.6-4.4-0.9c-0.6-0.2-1-0.3-1.6-0.3c-18.5-4.2-144.5-13.3-186.5-8.7
						c-61.2,6.7-120,24.1-179,41c-32,9-63.6,20-93,36.2c-10.4,5.8-20.3,11.8-26.7,21.5c-1.7,2.7-3.1,5.5-4.3,8.9
						c-14.2,23-20.4,48.1-20.4,74.8c0,75.5,14.5,147.7,49.1,215.4c24.4,47.5,56,89.9,91.2,129.5c70.9,79.6,157.3,133.7,261.8,157.4
						c40.1,9,80.7,14.6,121.3,21.1c14.2,2.2,28.6,4.6,28.6,4.6s-1.2,14.6-2.2,29.5c-1,13.7-5.8,24.4-17.9,33
						c-38.9,27.5-77.2,56-115.4,84.3c-61.8,45.7-123.3,91.6-181.1,142.4c-50,44.2-98.8,89.8-137,145.1
						c-29.9,43.1-58.9,86.7-85.2,131.9c-15.6,26.7-30.4,53.9-45,81.2c-5.5,10.3-3.1,16.4,7.6,20.6c14.5,5.5,122.1,32,164.1,33.6
						c57.3,2.2,91.4,1.3,148.5-2.7c9-0.6,31.7-5.3,48.1-32.2c26.4-43.1,46.6-73,74.2-115.4c56.8-87.4,323.6-400,337.7-408.8
						c34.6-21.9,46.8-53.3,42.5-93.2c-3.6-32.8-13-64-21-95.7c-2.2-9-5-17.7-5-17.7s8.8-4.8,17.9-8.7c7.1-3,14.3-6.1,21.3-9
						c48.3-20.3,90.6-50,131.6-81.7c3.7-3,23.9-22,33.7-31.2c22,14,46.4,23.1,72.7,27.8c38.4,6.9,77.2,4.8,115.7,3.1
						c53.4-2.1,107-5.5,159.4-18c110.5-26.4,189.3-91.2,235.5-194.8c11.8-26.4,35.2-122,41.2-151.1c11.7,7.4,22.5,13.9,31.2,18.7
						c128.2,70.1,258.1,126.4,401.8,150.4c48.9,8.2,98,11.6,147.4,1.5c84.5-17.2,152.7-77.7,167-168.9
						C2628.6,1613.7,2624.9,1557.2,2613,1500.8z M2295.3,1387.2c-7.6,31.9-20.1,62.1-31.2,95.3l0,0l0,0
						c-21.1-13.7-40.1-26.2-59-38.4c-55.7-35.9-113.3-67.9-175-92.2c0,0-60.5-24-64.7-25.1c-1.8-0.6-3.4-92,10.1-133.7
						c14.6-45.2,41.3-85.7,81.5-100.5c1-0.4,2.2-0.8,3.3-1.1c0.8-0.3,1.5-0.5,2.3-0.7c7.6-2.3,15.8-3.7,24.4-4c24.9-1,64,0,109.4,22
						c34.4,16.7,57.9,45.2,74.9,79.1C2303.3,1251.2,2311.9,1317.7,2295.3,1387.2z"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);
export default CompreJuntoIconBranco;
