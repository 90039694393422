const SuperFavoritoIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<style type="text/css">{'.st1fav{display:none;fill:#FEFEFE;}'}</style>
		<g>
			<g>
				<path
					className="st0fav"
					d="M2115.8,784.9c-1.5-9-14.2-9.6-16.7-0.8c-11.8,41.9-10.3,110.6-5,152.8c13.8,109.4,23.1,188,59.6,292.5
			c2.7,7.7,4.6,14.1-8.7,32c-6.4,8.5-19.1,9.2-26.2,1.2c-75.8-84.7-171.5-142.4-270.9-194.6c-33.2-17.5-68.5-31.1-102.9-46.3
			c-33.8-14.9-42.3-27.9-34.7-64.2c28.9-139.2,83.4-268.4,153.1-391.8c33.6-59.5,73-113.7,126.6-156.9
			c48.1-38.8,101.4-68.7,159.4-89.1c74.6-26.2,139.2-0.7,196.7,45.8c48.7,39.4,88.1,88.2,121.1,141c70,111.8,131.9,228.2,152,360.4
			c20.7,136.1,24.6,272.9-11.1,407.8c-11.4,43.3-28.3,84.5-51.9,122.9c-27.1,44.2-54.9,54.8-102.9,33.1
			c-112.7-51.2-195.8-132.4-241.7-248.5c-42.9-109-71.5-221.9-85.9-338.4C2123.4,825.1,2119.3,804.8,2115.8,784.9z"
				/>
				<g>
					<path
						className="st0fav"
						d="M2355.7,1899.5c-8.5-56.6-19.4-112.9-40.6-166.4c-61.9-156.1-133.2-306.2-254.2-427.7
				c-77.1-77.4-165-139.4-264.9-180.4c-113.8-46.7-235.8-57.8-358.2-61.5c-101.7-3-200.4,16.6-298.6,39.8
				c-21.1,5-27.5,1.7-25.7-20.6c7.1-93.2-7.1-183.8-38-271.4c-16.5-46.7-23.4-94.7-26.7-143.1c-8.7-129.6,12.4-255.5,51.1-378.9
				c13.2-42,30.7-82.6,39.3-126c9.1-46.3-13.7-69.3-60.3-61.9c-5.4,0.9-10.8,2.4-16,4.1c-107.9,36.3-211.6,83.8-303,150.3
				C575.9,389.3,460.5,573.6,390.6,787.6c-50.4,154.6-25.1,297.4,66.3,431c45.2,66.2,100.9,120.3,165.8,166.3
				c13.9,9.9,20.7,14.9,22,20.9c1.2,6.1-3.4,13.3-12,27.5c-19.5,31.9-47.4,70.9-63.2,107.5c-3.6,8.4-1.6,18.2,4.9,24.5
				c1.5,1.4,3.1,3,4.7,4.6c94.8,91.8,198.1,171.7,316.9,230.9c177.2,88.4,358.7,167.2,545.7,231.9
				c196.4,67.9,398.2,111.9,629.5,111.2c38.2,2.4,98.6,1.6,158.6,0.5c11.4-0.2,26.7-1,40-1.7c18.2-1.1,34.4-12.4,41.4-29.3
				c7.4-17.7,14.5-37.5,19-52.5C2345.7,2008.2,2364,1955.3,2355.7,1899.5z M2187.3,1983.7c-30.7,57.3-75.7,98.6-144,104.2
				c-39.3,3.2-78-7.1-117-11c-11.2-1.2-8.5-9.9-7.9-16.2c18.4-169.9,78.9-324.6,170.4-467.9c15.7-24.5,25-24.5,43.4-4
				c48.1,53.3,90.9,171.1,88,247.8C2222.1,1884.9,2212.5,1936.6,2187.3,1983.7z"
					/>
				</g>
				<g>
					<path
						className="st0fav"
						d="M2255.8,2208.2c-3.2,0.2-6.4,0.4-9.8,0.6c-25.9,1.2-37.6,1.4-63.5,2.8c-84.2,4.4-168.7,6-252.8,0.5
				c-104.4-6.8-206.6-28.3-308.2-52c-15.1-3.5-17.2,1.3-19.7,12.8c-6.3,29.1-12,58.4-19.9,87c-23.7,86.5-77.1,143.3-165.8,165.5
				c-33.1,8.3-66.3,13.3-100.3,11.1c-120.1-7.3-209.6-63.1-258.7-173.7c-42.8-96.6-47.4-199.3-30.9-302.7c2.1-13,0.6-18-11.1-22.9
				c-23.1-9.5-45.6-20.6-68.3-31.1C802.8,1839,668.8,1756.5,557,1641.5c-0.1-0.1-0.2-0.2-0.3-0.3c-8.6-8.9-23.5-6.2-28.5,5.1
				c-9,20.6-17.7,45.8-22.7,61.4c-43.9,135.9-64.4,275.7-61.4,418.4c1.2,59.7,13.7,118.3,39,173.8
				c53.7,117.8,140.9,202.1,252.4,265.3c220.4,125,450.1,226.4,697.6,284.5c102.4,24,205.1,46.6,310.7,49.8
				c24.6,1.4,49.1-0.1,73-5.2c115-24.6,213.3-73.3,267.1-186.4c36.7-77.1,68.4-156.3,100.6-235.3c29.7-72.9,62.1-163.5,89-241.3
				C2277.7,2219.5,2268.3,2207.3,2255.8,2208.2z M1999.7,2419.7c-10,36.6-25.7,67.9-47.2,96.7c-5.2,6.9-13,6.3-19.4,4.1
				c-29.4-9.9-57.8-22-87.3-38.7c-5.9-3.4-5.5-11.8,0.5-14.7c47.2-22,92.9-41.5,142.9-57.4
				C1995.4,2407.7,2001.4,2413.4,1999.7,2419.7z"
					/>
				</g>
			</g>
			<path
				className="st0fav"
				d="M1471.3,2129.2c-26.3-29.4-70.5-33.2-100.7-8.4c-9.9,8.2-17.2,18.3-23,30.4c-0.3,0.5-0.6,0.7-1.1,0.9v-0.1
		c-0.6,0.1-1.1-0.1-1.4-0.5c-14.6-17.9-32.8-28.6-56.4-29.7c-26.1-1.1-48.2,7.6-63.7,28.7c-16.6,22.7-18.2,48.2-9.1,74.4
		c7.4,21.3,20.6,38.9,36.4,54.6c34.5,34.4,72.4,57.3,116.4,77.5c1.5,0.7,2.9,1,4.2,0.9v0.1c1.9-0.1,3.7-1,5.4-2.7
		c28.5-24.8,52.8-48.6,75.4-79.1c16.3-22,30-45.4,35.8-72.5C1495.7,2175.9,1490.7,2150.8,1471.3,2129.2z"
			/>
		</g>
		<path
			className="st1fav"
			d="M2247,1856.1c2,50.4-8,104.3-34.3,153.3c-32,59.7-78.8,102.7-149.9,108.5c-40.9,3.3-81.2-7.4-121.8-11.5
	c-11.7-1.2-8.9-10.3-8.2-16.9c19.2-176.9,82.2-338,177.4-487.2c16.3-25.5,26-25.5,45.2-4.2C2205.5,1653.5,2250,1776.1,2247,1856.1z"
		/>
	</svg>
);
export default SuperFavoritoIconBranco;
