import { PaginateNav, PaginateInfor, Container, Paginate, Filters, BoxLoading, Search, Check, DropDown, Infor, ListCard, ButtonAdd, BoxAlert, BoxInforInitial, BoxInforInitialLeft, BoxInforInitialRight, ContainerInfor, BoxMessage, NavInfor } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { AiOutlineSearch, AiOutlineLoading } from "react-icons/ai";
import { IoIosAdd, IoIosAddCircle } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { RiCheckFill, RiAddCircleFill } from "react-icons/ri";
import { useState } from "react";
import Card from "../../components/card";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalAdd from "../../components/modal-add";
import { isVisibleScript } from "../../../../utils/displayScript";
import AlertInfor from "../../../../components/Alert";
import CardLoading from "../../components/card-loading";
import ModalInforVideo from "../../../../components/modal-infor-video";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import { getTranslation } from "../../../../translations";
import LoadingLogo from "../../../../components/Loading-logo";
import LoadingLogoLily from "../../../../components/Loading-logo-lily";
import HelpClick from "../../../../components/HelpClick";
import Layout from "../../../../components/tailwind/layout";
import { ContainerLoading } from "../../../reviews/pages/home-new/styled";
import LoadingLogoLilyNew from "../../../../components/Loading-logo-lily-new";
import LayoutNew from "../../../../components/tailwind/layout-new";
import TableCupons from "../../components/tailwind/table";
import PageIntegration from "../../../../components/page-clean";
import LayoutUI from "../../../../components/tailwind/layout-ui";



const Home = () => {

    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const cupons = useSelector(state => state.reducerCupons)
    const [visibleModalAdd, setVisibleModalAdd] = useState(false)
    const [visibleModalInforCupom, setVisibleModalInforCupom] = useState(false)
    const dispatch = useDispatch()


    const statusUpdateCupom = useSelector(state => state.reducerUpdateCupom)
    const statusDeleteCupom = useSelector(state => state.reducerDeleteCupom)
    const statusAddCupom = useSelector(state => state.reducerAddCupom)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        if (!statusUpdateCupom.loading && !statusUpdateCupom.error && statusUpdateCupom.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusUpdateCupom])

    useEffect(() => {
        if (!statusDeleteCupom.loading && !statusDeleteCupom.error && statusDeleteCupom.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusDeleteCupom])

    useEffect(() => {
        if (!statusAddCupom.loading && !statusAddCupom.error && statusAddCupom.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusAddCupom])

    // useEffect(() => {
    //     if (typeof search !== "undefined" || typeof statusQuestion !== "undefined") {
    //         dispatch({
    //             type: "SAGA_LIST_CUPONS", payload: {
    //                 search: search,
    //                 perPage: perPage,
    //                 page: page
    //             }
    //         })
    //     }
    // }, [])

    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_CUPONS", payload: {
                search: search,
                perPage: perPage,
                page: page
            }
        })
    }

    useEffect(() => {
        goSearch()
    }, [page])

    // const countItens = () => {
    //     let result = 0
    //     if (page == cupons.data?.meta?.last_page) result = cupons.data?.meta?.total
    //     else result = page * perPage
    //     return result
    // }

    return (
        <>
            <ModalInforVideo visible={visibleModalInforCupom} setVisibleModal={setVisibleModalInforCupom} 
            video={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/DPIPuyDQwck" : "https://www.youtube.com/embed/We3dG7uL454"}
             />
            <ModalAdd visible={visibleModalAdd} setVisibleModal={setVisibleModalAdd} />

            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{translation.sidebar.cupons}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {translation.title.cupom}
                            </p>
                        </div>
                    </div>
                    <PageIntegration />
                </div> :
                <>
                    <TableCupons search={search} setSearch={setSearch} goSearch={goSearch} setVisibleModal={setVisibleModalAdd} />

                    {!cupons.loading &&
                        <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                            <div onClick={() => { setVisibleModalInforCupom(true) }}>
                                <HelpClick text={translation.alert.cupons} />
                            </div>
                        </div>}
                </>
            }
        </ >


    )
}

export default Home
