const App365IconBranco = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 3000 3000"
    {...props}
  >
    <style type="text/css">
      {'.ffsafaf2{font-family:"ArialMT";}.ddfdfee{font-size:11.649px;}'}
    </style>
    <g>
      <g>
        <g id="Layer_2_1_">
          <g id="Layer_1-2_1_">
            <path
              className="st03asdaq2"
              d="M282.6,556.4c-93.7,0-170.5,73.1-176.1,165.5c-6.2,102.9,74.8,187.3,178,187.3h225.6c2.3,0,4.6-0.9,6.3-2.3
					l385.4-333.3c6.8-5.9,2.6-17.1-6.3-17.1L282.6,556.4L282.6,556.4z"
            />
            <path
              className="st03asdaq2"
              d="M1855.3,556.4h815.3c97.4,0,176.4,78.9,176.4,176.4l0,0c0,97.4-78.9,176.4-176.4,176.4h-815.3
					c-97.4,0-176.4-78.9-176.4-176.4l0,0C1679,635.4,1757.9,556.4,1855.3,556.4z"
            />
          </g>
        </g>
      </g>
      <path
        className="st03asdaq2"
        d="M2900,1739.6c0.1,333.6-223.4,610.5-543.6,686.2c-331.9,78.5-680.5-101.7-807.7-417.6
		c-34-84.5,5.1-179.9,88-214.4c84.2-35,178.8,1.8,214.5,85.8c52.9,124.5,143.9,204.3,276.4,230.2c266.6,52,469.4-151.7,458.3-380
		c-8.8-181.3-133.2-320.6-313.6-352c-103.7-18-198.1-0.9-279,71.6c-24.5,21.9-198,147.8-291,1.3c-44.7-70.3-37.8-154.8,14.1-220.1
		c85.8-107.9,204.3-149.8,332.7-174.6c51.2-9.9,103.3-10.3,154.9-14.1c93.6-6.9,204.7,11,289.1,48.2
		C2733.7,1196.7,2899.8,1417.2,2900,1739.6z"
      />
      <path
        className="st03asdaq2"
        d="M1373.4,1478.2c-28.2-66.2-66.5-126.8-112.8-180.1c-73.3-84.4-166.9-150.3-273.5-190.7L1272,867.2
		c74.6-62.5,84.3-173.6,21.7-248c-0.1-0.1-0.2-0.3-0.3-0.4c-62.5-74.6-173.6-84.3-248-21.7c-0.1,0.1-0.3,0.2-0.4,0.3l-749.1,630.8
		c-10.5,8.3-20,17.8-28.2,28.2c-49.9,65.6-47.6,157.2,5.6,220.2c62.8,74.2,173.8,83.8,248.4,21.5l118.9-99.1
		c43.6-13.2,89.2-18,134.5-14.2c103,4.3,194.6,51.3,257.7,123c59.6,67.8,94.3,157.7,90.4,255c-8.3,200.6-177.5,356.3-378,348
		c-97.4,0-188.5-39-255.1-104.9c-32.8-32.4-59.6-71.4-78.4-115.5c-29-83.6-120.3-127.9-203.9-98.9s-127.9,120.3-98.9,203.9
		c2.2,6.5,5,12.9,8.1,19.1c33.9,79.4,81.1,149.1,137.8,207.6l0.1-0.1c191,197.1,490.8,266.8,758.4,152.6
		C1360.2,2226.4,1521.4,1825.1,1373.4,1478.2z"
      />
      <text
        transform="matrix(1 0 0 1 1223.6031 1517.3242)"
        className="st03asdaq2 ffsafaf2 ddfdfee"
      >
        00B086
      </text>
    </g>
  </svg>
);
export default App365IconBranco;
