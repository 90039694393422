import styled from "styled-components";


export const Container = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: blue ; 
    flex: 1;
   
`;

export const BoxControlMobile = styled.div`
    display: flex;
    padding: 0px 20px; 
    height: 100%;
    //flex: 1;
    
    @media(max-width: 768px) {
        flex-direction: ${props => props.active ? "column" : "row"};
    }


   
`;



export const BarLeft = styled.div`
    display: flex;
    min-height: 100%;
    flex-direction: column;
    background-color: #fff ;
    //width: ${props => props.visibleBarConfig ? "340px" : "max-content"} ;
    width: ${props => props.isFull ? "340px": "initial"};
    min-width: ${props => props.isFull ? "340px": "initial"};
    gap: 10px;
    align-items: center;
    padding-top: 0px !important;

    height: max-content;
    //background-color: red;
    padding-bottom: 10px;
    
    //background-color: green;

    @media(max-width: 900px) {
        width: auto;
        //padding: 10px 10px ;
    }
`;

export const Submenu = styled.div`
    display: flex;
    height:40px;
    background-color: ${props => props.active ? "#FFCC00" : "transparent"} ;
    width: 250px ;
    border-radius: 20px ;
    justify-content: space-between ;
    align-items: center ;
    padding: 0px 20px ;
    cursor: pointer;

    @media(max-width: 900px) {
        width: 40px;
        padding: 0px ;
        justify-content: center ;
    }

    :hover{
        background-color: ${props => props.active ? "#FFCC00" : "#FFCC0010"} ; ;
    }

    span{
        gap: 10px;
        display: flex ;
        cursor: pointer;

        img{
            width: 20px ;
            height: 20px ;
            cursor: pointer;
            display: flex;
        }
        label{
            @media(max-width: 900px) {
                display: none;
            }
        }

    }

    img{
        width: 20px ;
        height: 20px ;
        cursor: pointer;
        display: ${props => props.active ? "inicial" : "none"} ;

        @media(max-width: 1200px) {
            margin-right: 0px;
        }

    }

    img:nth-child(2){
        //background-color:  purple;
        @media(max-width: 900px) {
            display: none;
        }
    }

    label{
        font-size: 13px ;
        cursor: pointer;
        color: ${props => props.active && "#000000"} ; 

       
    }
`;

export const Filters = styled.div`
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-radius: 30px;
    flex-wrap: wrap;


    select{
        outline: none;
        border-radius: 5px;
        height: 40px;
        padding: 0px 10px;
        cursor: pointer;
        border: none;
        background-color: ${props => props.theme.colors.layout.secondary};
        color: #A1A5B6;
        min-width: 150px;
   }

   button{
       height: 40px;
       width: 100px;
       outline: none;
       border: none;
       background-color: ${props => props.theme.colors.layout.secondary} !important;
       border-radius: 5px;
       color: gray;
       cursor: pointer;
       border: 1px solid #d3d3d3;
   }
`;

export const SectionLeft = styled.div`
    display: flex;
    margin: 10px 0px;
 
`;

export const SectionRight = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0px;
    
`;

export const Search = styled.div`
    //background-color: #FBF9F6;
    background-color: ${props => props.theme.colors.layout.secondary};
    display: flex;
    height: 40px;
    //border: 1px solid gray;
    border-radius: 20px;
    padding: 10px;
    align-items: center;

    input{
        display: flex;
        height: 100%;
        width: 250px;
        outline: none;
        border: none;
        background-color: transparent;
    }
`;

export const Check = styled.div`
    display: flex;
    margin-right: 20px;
    align-items: center;
    color: gray;
    span{
        display: flex;
        width: 20px;
        height: 20px;
        background-color: #FBF9F6;
        justify-content: center;
        align-items: center;
        border: 2px solid gray;
        margin-right: 5px ;
        border-radius: 4px;
    }
`;

export const DropDown = styled.div`
    display: flex;
    background-color: orange;

    span{
        display: none;
        flex-direction: column;
    }
`;

export const Infor = styled.div`
    min-height: 50px;
    display: flex;
    align-items: center;


    label{
        margin-right: 10px; 
    }
    /* button{
        display: flex;
        height: 35px;
        background-color:  yellow;
        outline: none;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        color: black;
        border: 1px solid gray;
        width: 150px;

        div{
            background-color: #FBF9F6;
            width: 200px;
            position: absolute;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            z-index: 9999;
            padding-left: 5px;
            border: 1px solid gray;
            border-radius: 5px;

            label{
                //background-color: peru;
                text-align: left;
                width: 100%;
                cursor: pointer;
                height: 30px;
                display: flex;
                align-items: center;
                font-size: 14px;
                color: black;
                
                
                :hover{
                    opacity: 0.5;
                }
            }
        } 
    } */

    
`;

export const ListCard = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    flex-direction: column;
`;

export const BoxLoading = styled.div`

    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;

    span{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;

export const BoxLoadingCustom = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    //background-color: violet;
    div{
        animation: spin 1s linear infinite;
    }

    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }
`;

export const ButtonAdd = styled.div`
    background-color: #19B674;
    display: flex;
    height: 35px;
    border-radius: 5px;
    justify-content: space-between;
    color: white;
    padding: 0px 5px;
    cursor: pointer;

    span{
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: tomato;
        margin-left: -5px;
        cursor: pointer;
    }
    label{
        display: flex;
        align-items: center;
        cursor: pointer;
        //background-color: violet;
    }

    //align-items: center;
`;

export const Paginate = styled.div`
    display: flex;
    height: 40px;
    margin-bottom: 5px;
    align-items: center;

    span{
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    label{
        margin: 0px 10px;
    }
`;





export const ButtonSave = styled.button`
    background-color: #19B674;
    width: 75px;
    height: 35px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: white;
    margin-left: 10px;
    
`;

export const ButtonDefault = styled.button`
    background-color: #191C31;
    padding:0px 10px;
    height: 35px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: white;
    margin-left: 10px;
    
`;


export const ErrorMessage = styled.label`
    //background-color: yellow;
    display: flex;
    margin-top: -15px;
    margin-bottom: 5px;
    font-size: 12px;
    color: red;
`;

export const TextAreaCustom = styled.textarea`
    display: flex;
    flex: 1;
    outline: none;
    border: none;
    background-color: #f5f8fa;
    padding-right: 10px;
    border-radius: 5px;
    padding: 10px;
    resize: none;
    color: #5E6278;
    text-align: justify;
`;

export const InputFileCustom = styled.div`
    display: flex;
    background-color: #f5f8fa;
    height: 40px;
    font-weight: bold;
    color: #5E6278;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 5px;
    align-items: center;
    justify-content: space-between;
    

    label{
        background-color: #ffcc00;
        display: flex;
        width: 50px !important;
        outline: none;
        border: none;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        height: 30px;
        border-radius: 5px 5px 5px 5px;
        font-size: 12px;
        color: #5e0a07;
    }

    p{
        display: flex;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        font-size: 8px;
    }

    @media(min-width: 480px) {
        width: 450px;
    }
`;

export const InputTextCustom = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #f5f8fa;

    label{
        width: 110px;
        height: 25px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:  #ffcc00;
        text-align: center;
        vertical-align: center;
        font-size: 13px;
        border-radius: 5px;
        color: #5e0a07;
        margin-right: 5px;
        margin-left: 5px;
        cursor: pointer;
        //padding: 0px 5px;
    }
`;

export const BoxActions = styled.div`
    display: flex;
`;

export const ButtonPreview = styled.button`
    width: 75px;
    height: 35px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    background-color: #ffcc00;
    color: #5e0a07;
`;

export const Tooltip = styled.div`
    position: relative;  
 
    :hover div{
        visibility: visible;
        opacity: 0.9;
        top: 30px;
        z-index: 999;
    }

    div{
        display: flex;
        position: absolute;
        background: black;
        visibility: hidden;
        border-radius: 5px;
        max-width: 300px !important;
        width: max-content;
        padding: 5px 10px;

        label{
            font-size: 10px;
            color: white;
        }
  

        :after{
            content: '';
            position: absolute;
            bottom: 100%;
            left: 10px;
            border-bottom: 8px solid black;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
`;

export const ContainerPreview = styled.div`
    background-color: orange;
    display: flex ;
    margin-left: 20px ;
    margin-bottom: 20px ;
    width: 100% ;
`

export const Box = styled.div`
    //background-color: yellow;
    display: flex;
`

export const NewBoxCustom = styled.div`
    //background-color: yellow;
    display: flex;
`

export const NewBarLeft = styled.div`
    //background-color: yellow;
    display: flex;
    width: 340px;
    //background-color: purple;
    flex-direction: row;
    padding: 20px 20px;
    gap: 20px;
    flex-wrap: wrap;
    //justify-content: center;

`

export const ItemBarLeft = styled.div`
    //background-color: yellow;
    display: flex;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    width: 140px;
    height: 140px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    :hover{
        border: 1px solid #FFC400;
    }


    label{
        display: flex;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
        height: 60px;
        //background-color: red;
    }
`

export const NewBarLeftTitle = styled.label`
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    border-radius: 8px;

    font-size: 18px;
    color: #1E1E1E ;
    font-weight: 400;
    font-family:"poppins";
    

        
`

export const Timer = styled.div`
    display: flex;
    //background-color: red;
    width: 100%;
    border-radius: 15px;
    align-items: center;
    justify-content:end;
    //border: 1px solid #80808099;
    cursor: pointer;
    height: 40px;
    padding-right: 10px;
    //background-color: yellow;

    :hover{

        label{
            display: flex  !important;
        }
    }

    svg{

        :hover{
            color:  #FFC400 !important;
        }
    }

    label{
        background-color: #1E1E1E ;
        color: white ;
        padding: 5px 10px ;
        display: none !important ;
        position: absolute ;
        z-index: 1 ;
        font-size: 9px ;
        left: 140px  ; 
        border-radius:  8px;
        align-items: center ;
        width: max-content ;
        justify-content: center ;
        height: 40px !important;
        align-items: center ;

        :after{
            content: '';
            position: absolute;
            //left: 100%;
            left: -8px;
            border-top: 8px solid #1E1E1E ;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            transform: rotate(90deg);
        }
    }
`

export const ItemBarLeftCustom = styled.div`
    //background-color: yellow;
    display: flex;
    background-color: #f1f1f1;
    //padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    width: 140px;
    height: 140px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
   
    :hover{
        border: 1px solid #FFC400;
    }


    label{
        display: flex;
        cursor: pointer;
        font-size: 12px;
        text-align: center;
        height: 60px;
        
    }
`

export const BoxControlItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    //background-color: #11ff0022;
    width: 140px;
    height: 100px;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
`

export const BoxTimerControl = styled.div`
    display: flex;
    //background-color: red;
    min-height: 40px;
    flex: 1;
`