import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Modal from '@radix-ui/react-dialog';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface ModalProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: ReactNode;
}
const DialogOverlay = forwardRef<React.ElementRef<typeof Modal.Overlay>, React.ComponentPropsWithoutRef<typeof Modal.Overlay>>(({ className, ...props }, ref) => <Modal.Overlay ref={ref} className={twMerge('fixed inset-0 z-[1] z-50 bg-gray-500 bg-opacity-75 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0', className)} {...props} />);
DialogOverlay.displayName = Modal.Overlay.displayName;

type DialogDescriptionProps = React.HTMLAttributes<HTMLParagraphElement>;

const DialogDescription = forwardRef<HTMLParagraphElement, DialogDescriptionProps>(({ className, ...props }, ref) => <Modal.Description ref={ref} className={twMerge('text-sm text-muted-foreground', className)} {...props} />);

DialogDescription.displayName = Modal.Description.displayName;

const ModalContent = ({ children, ...props }: ModalProps) => {
	return (
		<>
			<Modal.Portal>
				<DialogOverlay />
				<DialogDescription />
				<Modal.Content className={twMerge('fixed left-[50%] top-[50%] rounded-md z-50 bg-white grid w-3/6 h-auto translate-x-[-50%] translate-y-[-50%] gap-4 border p-6 pr-1 pt-8 pb-8 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]', props.className)}>
					<Modal.DialogTitle className="hidden" />
					<div className='custom-scroll max-h-[80vh] overflow-auto pr-5'>{children}</div>
					<Modal.Close asChild>
						<button className="absolute right-2.5 top-2.5 inline-flex size-[16px] appearance-none items-center justify-center rounded-full text-gray-900 outline-none" aria-label="Close">
							<XMarkIcon />
						</button>
					</Modal.Close>
				</Modal.Content>
			</Modal.Portal>
		</>
	);
};

export default ModalContent;
