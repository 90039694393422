const OvniIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<path
				className="st0ov"
				d="M2859.1,1658.5c-53.4-128.3-140.5-233.1-242.6-325.5c-199.3-180-431.1-304.5-681.5-396.9
		c-0.1,0.3-0.3,0.5-0.5,0.7c-0.5-0.8-1.2-1.5-2.1-2c-2.3-1.1-4.6-2.3-7.1-3.2c-218.1-78.9-441.8-132.1-673.2-152.7
		c-223.8-20-445.7-14.7-664.1,44.3C466.4,856,352.2,904.4,255.2,987.3c-105.8,90.5-161.9,204.3-154.7,345.2
		c3.7,74.2,28.8,143.2,57.6,210.9v0.1c2,4.7,9.1,3.5,9.5-1.6c0.3-3.6,0.6-7.5,1-11.2c7.9-66.2,35.4-121.9,89.9-161.8
		c59.2-43.3,125-42.9,192.7-26.8c62.1,14.9,124,21.9,182.2-13.2c44.5-26.9,79.6-64.4,113-103.8c76.2-90.2,171.8-131.6,289.6-108
		c63,12.5,114.6,49,162.7,90.4c32.5,28,62.9,58.9,101.3,79.3c0.3,0.2,0.6,0.3,0.9,0.4c0,0-0.1,0-0.1,0.1
		c80.7,61.9,167.3,67.1,259.2,28c50.3-21.4,96.5-51.8,148.8-68.9c59-19.2,118.6-28.6,179.2-8.4c72.5,24.3,113.9,81.1,147.3,145
		c21.8,41.5,44.1,82.8,75.4,118c69.6,78.3,154.9,119.3,261.4,102.7c37.9-6,74.7-9.8,113-3.5c102.6,17,186.2,104,200.7,206.2
		c6.5,45.8-0.2,92,9.3,137.5c12.3,58.7,34.7,110.6,83.2,149c15.6,12.3,24.2,12.8,37.3-4.5
		C2916.8,1953.3,2921.4,1808.4,2859.1,1658.5z M427.1,1091.8l-28.7,71.4c-7.7,19.1-29.6,28.5-48.7,20.8l0,0
		c-19.1-7.7-28.5-29.6-20.8-48.7l28.7-71.4c7.7-19.1,29.6-28.5,48.7-20.8l0,0C425.4,1050.7,434.8,1072.6,427.1,1091.8z
		 M764.7,1043.3c-11.7,13.6-32.5,15.3-46.3,3.4l-51.1-44c-13.6-11.7-15.3-32.5-3.4-46.3c11.7-13.6,32.5-15.3,46.3-3.4l51.1,44
		C774.9,1008.8,776.4,1029.7,764.7,1043.3z M1040.4,903.9l-44,51.1c-11.7,13.6-32.5,15.3-46.3,3.4c-13.6-11.7-15.3-32.5-3.4-46.3
		l44-51.1c11.7-13.6,32.5-15.3,46.3-3.4l0,0C1050.6,869.5,1052.2,890.3,1040.4,903.9z M1317.2,1116.1c-15.7,9-35.8,3.6-44.8-12
		l-33.8-58.4c-9-15.7-3.6-35.8,12-44.8c15.7-9,35.8-3.6,44.8,12l33.8,58.4C1338.3,1087,1332.9,1107.2,1317.2,1116.1z M1591.5,1010
		l-58.4,33.8c-15.7,9-35.8,3.6-44.8-12l0,0c-9-15.7-3.6-35.8,12-44.8l58.4-33.8c15.7-9,35.8-3.6,44.8,12l0,0
		C1612.5,980.8,1607.1,1001.1,1591.5,1010z M2030.3,1203.8L2030.3,1203.8c-15.7,9-35.8,3.6-44.8-12l-33.8-58.4
		c-9-15.7-3.6-35.8,12-44.8l0,0c15.7-9,35.8-3.6,44.8,12l33.8,58.4C2051.3,1174.6,2046,1194.8,2030.3,1203.8z M2220.7,1383.8
		l-58.4,33.8c-15.7,9-35.8,3.6-44.8-12c-9-15.7-3.6-35.8,12-44.8l58.4-33.8c15.7-9,35.8-3.6,44.8,12S2236.4,1374.8,2220.7,1383.8z
		 M2455.6,1388.1L2455.6,1388.1c-15.7,9-35.8,3.6-44.8-12l-33.8-58.4c-9-15.7-3.6-35.8,12-44.8c15.7-9,35.8-3.6,44.8,12l33.8,58.4
		C2476.7,1358.9,2471.3,1379,2455.6,1388.1z M2593.5,1525.8l-58.4,33.8c-15.7,9-35.8,3.6-44.8-12l0,0c-9-15.7-3.6-35.8,12-44.8
		l58.4-33.8c15.7-9,35.8-3.6,44.8,12l0,0C2614.5,1496.7,2609.1,1516.8,2593.5,1525.8z M2811.2,1805.2c-15.7,9-35.8,3.6-44.8-12
		l-33.8-58.4c-9-15.7-3.6-35.8,12-44.8c15.7-9,35.8-3.6,44.8,12l33.8,58.4C2832.2,1776.1,2826.8,1796.2,2811.2,1805.2z"
			/>
			<path
				className="st0ov"
				d="M2416,1106.2c11.5,8.2,22.9,16.7,36.3,26.3c0.1-13.6-2.2-22.8-5.1-32.2c-28-93.2-86.6-165.4-157.2-229
		c-108.1-97.3-234.3-163.9-370.9-210.6c-208.8-71.2-421.7-98-639.8-49.6c-69.2,15.4-134.5,41-197.8,86.7
		c201.6,4.2,392.6,33.1,582.3,73.3C1938.8,829.2,2188.2,943.4,2416,1106.2z"
			/>
			<path
				className="st0ov"
				d="M2740.3,2152c-79.3-60.5-113-144.1-119.2-240.9c-2.2-35.4-0.5-71.4-7.2-105.9
		c-17.3-88.4-104.5-147.8-194.8-137.3c-35.9,4.1-71.1,14.8-107.8,11.2c-103.4-10.1-187.7-55.8-255.8-134
		c-41.6-47.9-66.6-105.6-99.2-158.8c-51.1-83.4-110.2-104.7-204.9-79.1c-49.1,13.2-92.3,39-137.2,61c-122.5,60-240.1,57.6-355.1-21
		c-39.8-27.2-74.8-59.2-111.2-90.1c-46.9-39.9-97.8-74.4-161.8-76.5c-78.8-2.5-139.7,33.9-189.6,93.5
		c-51.9,62.1-108.5,119.1-190.2,142c-64.7,18.1-127.8,5.4-190.9-9.3c-81.8-19-153.5,25.1-172.4,106.6
		c-12.4,53.8-3.4,107.2,1.4,160.9c2.3,25.3,14.6,43.7,30.2,62.1c65.5,77.5,139.5,145.8,220.4,206.8
		c193,145.7,407.3,250.1,635.4,328.2c263.1,90.1,533.3,139.1,811.7,142.1c108.2-1,215.9-7.2,322.6-26.4
		c139.2-25.1,273.1-65.2,393.6-142.1c31.1-19.9,62.9-39.5,84.9-70.8C2750.6,2164.1,2750.6,2159.9,2740.3,2152z M1905.5,1998.9
		c-8.6,12-24,11-38.6,6.6c-52.3-16-105-30.7-157.2-47c-16.8-5.3-30-5.7-47,3.4c-41.4,22.3-87.7,18.9-132.7,15.7
		c-86.6-6.2-169-29.3-244.7-71.9c-35.2-19.8-72-40.4-78.3-87.2c-1.2-8.7-9-7.7-14.2-9.2c-51.6-15.2-103.4-29.8-155.1-44.6
		c-5.1-1.4-10.4-2.5-15.2-4.7c-11.1-5.2-25-9.1-23.8-24.7c1.2-16.5,14.6-20.2,28.4-22.6c65.5-11.8,131.6-18.1,198.2-18
		c246.9,1.3,465.4,76.8,648.1,246.7c7.2,6.7,14.3,13.2,20.7,20.5C1903.5,1972.4,1915.4,1985.1,1905.5,1998.9z"
			/>
		</g>
	</svg>
);
export default OvniIconBranco;
