import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { getTranslation } from '../../../../translations';
import { Container } from '../bar-config-product-ajustes/styled';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BodyItem, Carrossel, Close, ItemActive, ItemReview, List, ListItem, Title, TitleCircle, TitleInfor, TitleList } from './styled';
import { GrFormClose } from 'react-icons/gr';
import IconSlider from "../../../../assets/imgs/icon-slider-home.svg";
import { toast } from 'react-toastify';
import { AiFillStar } from 'react-icons/ai';
import { Sms } from 'iconsax-react';
import { BoxInput, InputCustomText } from '../../../cupons/components/modal-add/styled';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
        maxWidth: "580px",
        maxHeight: "90%"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalConfigGoogle = (props) => {


    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const dispatch = useDispatch()

    const [isActiveReviews, setIsActiveReviews] = useState(JSON.parse(localStorage.getItem("depoimentos@login")).user?.is_reviews_notification == 1 ? true : false)
    const [isActiveQuestions, setIsActiveQuestions] = useState(JSON.parse(localStorage.getItem("depoimentos@login")).user?.is_asks_notification == 1 ? true : false)
    const [email, setEmail] = useState(
        JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.email_notification == null ?
            JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.email :
            JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.email_notification
    )



    const updateReviews = () => {
        dispatch({ type: "SAGA_UPDATE_ACCOUNT", payload: { is_reviews_notification: !isActiveReviews ? 1 : 0 } })
        setIsActiveReviews(!isActiveReviews)
    }

    const updateEmail = () => {
        dispatch({ type: "SAGA_UPDATE_ACCOUNT", payload: { email_notification: email } })
    }

    const updateQuestions = () => {
        dispatch({ type: "SAGA_UPDATE_ACCOUNT", payload: { is_asks_notification: !isActiveQuestions ? 1 : 0 } })
        setIsActiveQuestions(!isActiveQuestions)
    }

    const statusUpdateAccount = useSelector(state => state.reducerUpdateAccount)

    useEffect(() => {
        if (statusUpdateAccount.loading == false && statusUpdateAccount.error == false && statusUpdateAccount.data.length == 0) {

        } else {
            const id = "statusUpdateAccount"
            if (statusUpdateAccount.loading) {
                toast.loading(translation.conta.aguarde, { toastId: id })
            } else {
                if (statusUpdateAccount.error) toast.update(id, { render: translation.conta.errorAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });

                else {
                    toast.update(id, { render: translation.conta.sucessoAtualizar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    let copy = JSON.parse(localStorage.getItem("depoimentos@login"))
                    copy.user = statusUpdateAccount.data.data.user
                    localStorage.setItem("depoimentos@login", JSON.stringify(copy))
                }
            }
        }

    }, [statusUpdateAccount])

    const closeModal = () => {
        dispatch({ type: "CONFIG_CONTROL_VISIBLE_MODAL_NOTIFICATIONS_SET", payload: false })
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() =>
                closeModal()
                //props?.setVisibleModal(false)
            }
        >
            <Container style={{ padding: "40px", gap: "20px" }}>
                <Close onClick={() => {
                    closeModal()
                    //props.setVisibleModal(false)
                }}>
                    <GrFormClose size={25} color={"#5e6278"} />
                </Close>
                <Title>
                    <strong>
                        {translation.thema.modalConfigNotificacao.notificacoes}
                    </strong>
                </Title>

                <Carrossel>
                    <span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Sms size="20" color="#808080" />
                            <label style={{ marginLeft: "10px" }}> {translation.thema.modalConfigNotificacao.notificacoesAvaliacoes}</label>
                        </div>
                        <ItemActive onClick={() => { updateReviews() }} active={isActiveReviews}>
                            <span />
                        </ItemActive>
                    </span>

                </Carrossel>

                <Carrossel>
                    <span >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Sms size="20" color="#808080" />
                            <label style={{ marginLeft: "10px" }} > {translation.thema.modalConfigNotificacao.notificacoesPerguntas}</label>
                        </div>
                        <ItemActive onClick={() => { updateQuestions() }} active={isActiveQuestions}>
                            <span />
                        </ItemActive>
                    </span>
                </Carrossel>

                <div style={{ display: "flex", alignItems: "end", gap: "20px" }}>
                    <BoxInput style={{ marginBottom: "0px" }}>
                        <label>{"E-mail"}</label>
                        <InputCustomText>
                            <input style={{ padding: "0px 10px", textTransform: "lowercase" }} type={"text"} placeholder={"E-mail"} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </InputCustomText>
                    </BoxInput>

                    <label onClick={() => { updateEmail() }}
                        style={{ padding: "0px 20px", cursor: "pointer", fontSize: "14px", height: "40px", display: "flex", alignItems: "center", borderRadius: "4px", backgroundColor: "#19B674", color: "#fff" }}>
                        {translation.thema.modalConfigNotificacao.salvar}
                    </label>
                </div>


                <Carrossel>
                    <label style={{ maxWidth: "500px", borderRadius: "8px", backgroundColor: "#ff500022", fontSize: "12px", padding: "20px", marginTop: "0px" }}>
                        {translation.thema.modalConfigNotificacao.infor}
                    </label>
                </Carrossel>

                {/* <iframe
                    src="https://www.youtube.com/embed/uMIKvvrxZ2I"
                    title="Integração Lily Reviews com Nuvemshop"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="true"
                    height={250}
                    width={500}
                /> */}

            </Container>
        </Modal>
    )
}

export default ModalConfigGoogle
