import Boss from './appsIcons/Boss';
import EmpreenderIcon from './appsIcons/EmpreenderIcon';

const HeaderApps = () => {
	return (
		<a href='https://app.empreender.plus' target='_blank' className='flex flex-row items-center gap-x-3 !py-5 !px-2 !pl-3.5'>
			<Boss />
			<div className='flex flex-col'>
				<EmpreenderIcon />
				<span className='text-gray-400 text-[11px] font-normal select-none'>Assine +20 apps por R$297/mês</span>
			</div>
		</a>
	);
};

export default HeaderApps;
