import React, { ReactNode, cloneElement, isValidElement, ReactElement, HTMLAttributes, Children, useState, useEffect } from 'react';
import { useSubMenu } from '../SubMenuContext';
import { twMerge } from 'tailwind-merge';
import ItemSub from '../ItemSub';
import { makeID } from '../lib/utils';
import { motion } from 'framer-motion';
import Modal from './Modal/Modal';
import { DropdownMenu } from './DropDown/DropdownMenu';

interface ItemMenuProps extends HTMLAttributes<HTMLElement> {
	id?: string;
	children: ReactNode;
	asChild?: boolean;
	isActive?: boolean;
	isSidebarOpen?: boolean;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	setSidebarOpen?: (toggle: boolean) => void;
	className?: string;
	classeAtiva?: string;
}

interface ChevronProps {
	active: boolean;
	isBtnActive: boolean;
}

export const Chevron = ({ active, isBtnActive }: ChevronProps) => {
	return (
		<motion.div initial={{rotate: -90}} animate={{rotate: active ? 0 : -90}} className={`size-3 ${isBtnActive ? 'text-white' : 'text-gray-500'}`}>
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9.75 4.125L6 7.875L2.25 4.125" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</motion.div>
	);
};

const ItemMenu = ({ id, children, asChild = false, isActive = false, setSidebarOpen, classeAtiva = 'bg-gray-900 text-white', ...props }: ItemMenuProps) => {
	const { toggle, toggleSub, state, setMenuId: setMenuActive, closeThirdMenus, clearSub } = useSubMenu();
	const [menuRandomID, setMenuRandomID] = useState('');

	useEffect(() => {
		const id = makeID();
		setMenuRandomID(id);
	}, []);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		const hasItemSub = Children.toArray(children).some((child) => isValidElement(child) && child.type === ItemSub);

		props.onClick?.(event);

		if (hasItemSub) {
			if (toggleSub) {
				if (state?.subID === menuRandomID) {
					toggleSub('');
					setMenuActive?.(menuRandomID);
					return;
				}

				setSidebarOpen?.(true);
				toggleSub(menuRandomID);
				setMenuActive?.(menuRandomID);
			}
			closeThirdMenus?.();
			return;
		}

		clearSub?.();
		toggleSub?.('');

		closeThirdMenus?.();

		if (id) {
			setSidebarOpen?.(false);
			toggle(id!);
		}

		setMenuActive?.(menuRandomID);
	};

	const isSubMenuOpen = state?.subID === menuRandomID;

	let elAtivo = isActive;

	if (state?.menuID) {
		elAtivo = state?.menuID === menuRandomID;
	}

	useEffect(() => {
		if (elAtivo) setMenuActive?.(menuRandomID);
	}, [menuRandomID]);

	const defaultClasses = `group rounded-md text-sm font-semibold items-center justify-between break-normal whitespace-nowrap gap-3 h-9 w-full min-w-9 flex flex-row py-2 px-1.5 transition focus-visible:outline-0 overflow-hidden bg-white text-gray-900  ${isActive ? 'bg-gray-100 active' : ''} ${elAtivo ? `${twMerge('bg-gray-900 text-white', `${classeAtiva}`)} active` : 'hover:bg-gray-100'}`;
	const itemsSubClasses = `overflow-hidden flex flex-col gap-1`;

	const mainChildren: ReactNode[] = [];
	const itemSubs: ReactNode[] = [];

	Children.forEach(children, (child) => {
		if (isValidElement(child) && child.type && ((child as ReactElement).type === ItemSub || (child as ReactElement).type === Modal || (child as ReactElement).type === DropdownMenu)) {
			itemSubs.push(child);
		} else {
			mainChildren.push(child);
		}
	});

	if (asChild && mainChildren.length === 1 && isValidElement(mainChildren[0])) {
		const mainChild = mainChildren[0] as ReactElement;
		const mergedClasses = twMerge(defaultClasses, `${(mainChild as React.ReactElement<ItemMenuProps>).props.className || ''} ${props.className || ''}`);

		const childProps = {
			onClick: (event: React.MouseEvent<Element, MouseEvent>) => {
				if ((mainChild as React.ReactElement<ItemMenuProps>).props.onClick) {
					(mainChild as React.ReactElement<ItemMenuProps>).props?.onClick?.(event as React.MouseEvent<HTMLElement, MouseEvent>);
				}
				handleClick(event as React.MouseEvent<HTMLElement>);
			},
			...props,
			draggable: false,
			className: mergedClasses,
		};

		return (
			<div>
				{cloneElement(mainChild, childProps)}
				{itemSubs.length > 0 ? <Chevron isBtnActive={elAtivo} active={isSubMenuOpen} /> : null}
				<motion.div initial={{ maxHeight: 0 }} animate={{ maxHeight: isSubMenuOpen ? itemSubs.length * 40 : 0 }} className={itemsSubClasses}>
					{itemSubs}
				</motion.div>
			</div>
		);
	}

	return (
		<div>
			<button {...props} onClick={handleClick} className={twMerge(defaultClasses, props.className)}>
				{mainChildren}
				{itemSubs.length > 0 ? <Chevron isBtnActive={elAtivo} active={isSubMenuOpen} /> : null}
			</button>
			<motion.div initial={{ maxHeight: 0, margin: 0, opacity: 0 }} animate={{ maxHeight: isSubMenuOpen ? itemSubs.length * 40 : 0, marginBottom: isSubMenuOpen ? 4 : 0, marginTop: isSubMenuOpen ? 4 : 0, opacity: isSubMenuOpen ? 1 : 0 }} className={itemsSubClasses}>
				{itemSubs}
			</motion.div>
		</div>
	);
};

export default ItemMenu;
