const SuperCheckoutIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Camada_1" x={0} y={0} viewBox="0 0 3000 3000" width="22" height="22" {...props}>
		<style>{'.dd2d2d{fill:#080413 !important}.filllllrrr{fill:#fefefe}.fil33333{fill:#d2122e}.sdkjskf22{fill:#ad0e2d}.fil4442{fill:none}'}</style>
		<path d="M2150.5 2669.1c-111.1-.8-206.1-18.8-300.1-46.1-60-17.4-121.2-21.7-184.8-11.7-74.9 11.7-151.4 4.1-227 .4-61.6-3-123.2-7.7-184.7-12.2-210.3-15.3-412-63-597.6-165.9-122.6-68-245.2-136.8-355.1-224.9-60.4-48.3-119-99-173.5-153.9-25.2-25.4-35.8-53-20.7-90.7 47.1-117.4 111.2-225.5 178.9-331.5 103.1-161.3 208.3-321.3 328.7-470.4 8-9.9 9.2-17.8 5.3-29.4-47.8-142-81.6-287.6-109.9-434.4-10.7-55.6-26.1-133.6-11.5-190.4 11.6-45.1 47.1-45.6 94.1-47.9 74.9-3.5 147.5 4.9 219.9 20.2 101.2 21.4 217.7 60.7 308.7 111.3 53.9 30 53.9 30 107.6 26.1 155.8-11.3 354.4-4.2 508.3 21.5 92.2 15.4 177.2 53 262 90.3 185.7 81.5 347.2 198.9 497.8 333.3 121.8 108.7 248.8 211.5 362 329.9 42.5 44.6 49 89.7 33 143-25.7 85.9-40.4 139.9-78.1 220.9-55.9 119.8-131.4 270.7-211.7 375.7-77 100.7-111.5 214.4-143.7 332.2-27 98.8-85.4 170.6-190.6 193.8-42.7 9.6-86.8 9.3-117.3 10.8zM2439.6 836c-1.9 16.5-9.2 29.9-25 37.1-15.5 7-26.4-1.8-37.8-10.9-112.7-89.5-231.3-169.1-369.1-215.7-39.7-13.4-79.7-26.4-119.5-39.6-5.9-2-12-3.5-17.6-6.2-38.1-17.7-43.1-43.8-9.8-68.7 153.6-114.6 441.6-197.9 490.7-200.9 43.1-2.6 65.9 18.4 74.9 60.5 18.2 86 31.9 285.7 13.2 444.4z" className="dd2d2d" />
		<path d="M2369.1 2121c37.6-.4 42.2 4.7 35.1 33-15.7 63.4-41.1 123.3-67.9 182.7-6.2 13.7-15 20-31.4 15-62.3-19-123.8-39.7-181.4-70.7-23.4-12.6-24.4-23.3-3.5-42.9 57.2-53.5 123.6-90.7 200.5-107.9 18.9-4.1 38-7.2 48.6-9.2z" className="filllllrrr" />
		<path d="M1815.3 1230.5c-21.7 5-45.2 29.4-59 46.3-69.7 86.3-157 280.4-246.1 380.6-5.5 6.2-12.9 10.4-20.8 12l-41.6 87.4c11.5.9 24.5 3.8 34.4 6.4 79.2 20.4 156.5 46.4 232.9 75.2 11.8 4.5 23.1 9.6 32.6 18 16 14.1 22.3 31.8 10.7 50.1-42 66.3-97.6 107.5-178.6 88.9-99.2-22.9-153-82.2-161.2-177.4l-106.9 224.4c1.8.8 3.6 1.6 5.5 2.5 76.8 36.5 150.9 78 226.4 116.7 64.5 33.1 129.3 65.6 201.5 79.2 49.6 9.3 78.9-7.6 94.6-56 14.2-43.9 21.9-89.1 24.9-135.2 23.5-364.9 9.9-651.3-1.7-766.8-3.3-32-16-59.7-47.6-52.3z" className="fil33333" />
		<path d="M1423.8 1761.8c-5.2 5.3-6.2 16.9-6.3 24.4-.2 11 .2 21.7 1 31.9l29.2-61.3c-10.2-.8-19.3.3-23.9 5z" className="sdkjskf22" />
		<path d="M2357.8 1675.6c-8.9 13.7-15 28.9-17.8 45.2-4 22.9 5 44 24.6 55.8 51.2 30.8 119.5 11.8 147.5-40.1l-56.2-177.4c-37.6 34.4-70.1 73.4-98.1 116.5z" className="fil4442" />
		<path d="M2364.6 1776.6c-19.6-11.8-28.6-33-24.6-55.8 2.9-16.4 9-31.5 17.8-45.2 28-43.1 60.5-82 98.1-116.5l-20.8-65.4c-1 1.4-2 2.9-3 4.3-10.9 16.1-21.4 19.8-36.9 4.4-21-21-162.8-202.7-224.2-252.2-22-17.7-43.1-18.6-62.1-4-20.2 15.5-14.4 33.3-7.9 54.5 31.1 101.9 105 332.4 269.6 698.7 9.8 21.9 23.3 30.9 47.8 30.9 24.2 0 32-18.9 39.7-38.1 24.2-60.4 48.5-125.4 75.5-186.8l-21.7-68.7c-27.8 51.7-96.1 70.7-147.3 39.9z" className="sdkjskf22" />
		<path d="M2514.2 1732.3c2.5-5.1 4.9-10.4 7.1-15.5.8-1.6 1.3-3.2 1.9-5 .2 0 .3-.1.5-.1-.1-.1-.1-.3-.2-.5l-.1-.1c.6-1.8 1-3.7 1.3-5.6.8-4.2 1.4-8.4 2.1-12.6 6.2-40.7 17-81.2 8.9-122.8-5-25.7-13.2-36.3-31.1-37.1-6.3-.3-12.5.9-18.2 3-.8-.2-1.5-.4-2.4-.6-.1.6-.3 1.1-.4 1.7-4.1 1.8-7.9 4.3-11.3 7.1-5.6 4.8-11.1 9.6-16.5 14.6l56.2 177.4c.8-1.1 1.6-2.4 2.2-3.9z" className="sdkjskf22" />
		<path d="M2573.5 1774.8c24.3 24.2 44.5 52.5 71.6 73.9 15 11.7 31.8 17.5 49.5 5.4 16-10.9 19.4-25.8 15-45-32.5-140.1-72.4-278.2-115.1-415.6-5.2-17-12.3-32.7-31.7-37-21.3-4.8-36.4 6.6-47.8 22.5-19 26.6-37 53.8-55.4 80.9-8.1 11.1-16.6 22.2-24.4 33.6l20.8 65.4c5.4-5 11-9.8 16.5-14.6 3.4-3 7.2-5.3 11.3-7.1.2-.6.3-1.1.4-1.7.8.2 1.5.4 2.4.6 5.8-2.2 12-3.2 18.2-3 17.8.8 26 11.3 31.1 37.1 8.2 41.5-2.7 82.1-8.9 122.8-.7 4.2-1.3 8.4-2.1 12.6-.3 1.9-.8 3.8-1.3 5.6l.1.1c.1.2.1.4.2.5-.2 0-.3.1-.5.1-.6 1.7-1.1 3.3-1.9 5-2.3 5.1-4.7 10.4-7.1 15.5l-2.1 4.2 21.7 68.7c4.7-10.6 9.3-21.1 14.2-31.3 7.3-16.1 17.3-7.1 25.3.8z" className="fil33333" />
		<path d="M1417.5 1786.1c.1-7.5 1-19 6.3-24.4 4.6-4.7 13.7-5.7 24-5l41.6-87.4c-4.8 1-9.6 1-14.6.2-13.2-2.4-26.2-6.8-39.1-10.7-73-21.7-143-54.2-218.8-65.2-78.7-11.5-96.2 2.2-119.9 65.4-6.5 17.3-12.1 35-16.9 52.9-30.3 114.9-54.4 231.1-78.9 347.3-16.6 78.3-33 156.8-43.6 236.2-2.8 20.8 1.3 37.6 21.8 46.6 21.2 9.3 41.6 6.9 58.2-9.9 14.8-15 28.6-31.1 42.1-47.1 65.9-78.1 125.2-161.5 192.5-238.5 12.9-11.3 25.6-9.8 39.4-4l106.9-224.4c-.8-10.2-1.2-20.9-1-32z" className="sdkjskf22" />
		<path d="M1747.7 1856.4c-9.5-8.4-20.8-13.5-32.6-18-76.4-28.9-153.7-54.9-232.9-75.2-18.9-4.9-48.7-11.3-58.4-1.4-5.2 5.3-6.2 16.9-6.3 24.4-1.8 113.9 52.3 183.9 162.2 209.3 81 18.7 136.6-22.6 178.6-88.9 11.7-18.4 5.4-36.1-10.6-50.2z" className="filllllrrr" />
		<path d="M2484.1 1535.6c-.1.6-.3 1.1-.4 1.7 1-.4 1.8-.8 2.8-1.1l-2.4-.6zM2523.8 1711.7c-.1-.1-.1-.3-.2-.5l-.1-.1c-.1.2-.2.5-.2.7.2 0 .3-.1.5-.1z" className="fil4442" />
		<path d="M2535.8 1570.2c-5-25.7-13.2-36.3-31.1-37.1-6.3-.3-12.5.9-18.2 3-1 .4-1.8.8-2.8 1.1-4.1 1.8-7.9 4.3-11.3 7.1-44.7 37.9-82.6 81.8-114.6 131.1-8.9 13.7-15 28.9-17.8 45.2-4 22.9 5 44 24.6 55.8 52.6 31.6 123.2 10.8 149.6-44.3 2.5-5.1 4.9-10.4 7.1-15.5.8-1.6 1.3-3.2 1.9-5 .1-.2.2-.5.2-.7.6-1.8 1-3.7 1.3-5.6.8-4.2 1.4-8.4 2.1-12.6 6.3-40.4 17.1-81 9-122.5z" className="filllllrrr" />
	</svg>
);
export default SuperCheckoutIcon;
