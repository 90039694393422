const DijitalIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Camada_3"
    x={0}
    y={0}
    width={22}
    height={22}
    viewBox="0 0 3000 3000"
    {...props}
  >
    <style>{".dijital1{fill:#0079fe}.dijital23{fill:#00adfe}"}</style>
    <path
      d="M2212 103.4c53.6 5.6 74.2 29.8 82.8 83.2 18.9 116.8 53 439.5 57.8 483.1 2 18 15.1 26.3 28.1 34.8 66.6 43.2 133.7 85.5 199.7 129.6 54.2 36.2 58.1 65.8 13.6 113.4-65.6 70.2-132.3 139.4-199.9 207.6-16.1 16.2-22.9 32.4-21.3 54.8 4.6 64.7-.5 236.5-6.2 301.1-19.6 224.1-50.7 334.9-179.8 526.1-113.2 167.5-275.5 264.8-465 321.4-152.5 45.5-309.2 50.7-467 44.5-105.6-4.2-209.4-17.3-310.3-50.3-180.7-59-302.1-183.1-389.1-347.5-69.7-131.5-111.2-272.2-134.6-417.8-20.9-129.5-46.6-303.2-45.2-323 2.3-32.8 19.6-41.2 50.9-52.1 54.3-19.1 110.3-27.3 163.4-36.5 67-11.8 134.6-20.4 201.9-30.6 63.6-9.6 86.5 13.1 91.6 78 11.6 147.5 35.4 293 94 430.4 15.4 35.9 36.2 68.7 66.7 94.4 58.8 49.9 132.9 37 171-30.2 21.1-37.2 26-78.5 29.5-120 7-81.5-124.8-1118-128.2-1286.8-.8-41.3 13.8-82.1 63.7-99.8 202.4-72.4 863.5-125.3 1031.9-107.8z"
      style={{
        fill: "#080413",
      }}
    />
    <path
      d="M1272.5 2471.8c-24.5 7.8-33.9 25.1-40.3 40.4-6.6 15.8-13.2 33.4-18.9 49.6-17.1 48.3-58.7 69.9-89 76.8-37.5 8.6-35.6 62.5-3.4 83.5 21.6 14 45.2 25.1 68.1 37.1 39.7 20.8 75.1 46.2 94.5 88.7 3.2 7 5.6 14.5 9.6 21 1 1.7 2.2 3.2 3.2 4.6v-403c-7.2-.6-15.3-.1-23.8 1.3z"
      className="dijital1"
    />
    <path
      d="M1383.1 2888c21.7-15.2 20.9-39.1-2.1-73-5.8-8.5-15.4-15.8-12.2-32.7 13.6 11.3 24.8 20.2 35.7 29.5 25.2 21.4 51.9 40 84.4 48.6 18.9 5.1 34.5-.2 46.2-14.9 12.2-15.2 11.3-32.2 2.4-48.6-9.2-17.1-23.5-29.5-39.2-40.6-7.7-5.5-15.7-10.7-23-16.6-3.8-3.1-8.6-7.2-4.5-12.9 3.4-4.7 7.6-.9 11.5.4 13 4.4 25.9 9.7 39.1 13.2 20.7 5.6 44.1-6.2 50.4-25.6 6.9-21.3-4.2-34.6-21.8-44.2-22.1-12.1-46.1-19-70.3-25.5-27.5-7.3-55-14.2-80-28.7-31.2-18-36.5-27-46.2-59.9-5-16.5-1-40-8.4-55.7-8.9-19.2-26.1-29.4-48.9-30.9v403c21.7 29.3 57.6 35.6 86.9 15.1z"
      className="dijital23"
    />
    <path
      d="M1693.4 2460.6c-13.7 34.7-19.4 58.9-83.6 80.7-39.7 13.4-37.8 56.9-9 87.4 15.2 16 34.4 25.9 53.9 34.9 30.5 13.9 52.8 35.1 64.6 66.8 2.6 6.9 7.7 16.6 12.3 24.6 5.1 9 12.4 16.4 21.1 21.6v-368.1c-24.9-.7-46.6 20-59.3 52.1z"
      className="dijital1"
    />
    <path
      d="M1825.1 2773.5c22.3-22.8 16.2-40.9 0-62.7-3.1-4.1-10.4-14.1-4.7-18.2 4.7-3.4 9.8.2 13.6 3.9 19.4 18.6 39.2 36.8 64 47.9 18.3 8.2 35.4 6.7 49.3-9.5 13.8-16 13.4-32.9 3.2-50.3-8.1-13.6-18.8-24.7-32.6-32.6-5.3-3-11.3-5.3-13.5-10.9-1-2.7.8-5.6 3.8-5.8 6.5-.3 11.9 4.3 18 5.3 14.9 2.2 27.4-1.7 37.1-13.4 10.5-12.6 7.3-26 1.8-39-6.3-14.8-19.5-21.8-34.3-25.2-24.6-5.7-47.8-14.9-70.1-26.2-30.5-15.5-53.4-36.6-60.1-72.8-3.2-17.3-3.4-37.1-25-48.7-7.8-4.3-15.6-6.4-23.1-6.6v368.1c3.6 2.2 7.4 3.9 11.5 5.3 23.4 7.4 48.6 4.3 61.1-8.6z"
      className="dijital23"
    />
    <path
      d="M2065.3 725.9c0 7.7.4 15.6-.1 23.3-7.5 126.9-109.2 199.7-231.3 166.1-34-9.3-60.7-28.6-80.8-57.1-62.4-88.6-63.5-234.1-3-323.8 9.9-14.8 22.4-25.8 38.2-34.1 111-57.6 220.8-12.8 258.8 105.6 12.4 39.1 17.6 79.2 18.2 120z"
      style={{
        fill: "#fefefe",
      }}
    />
  </svg>
);
export default DijitalIcon;
