const SuperCheckoutIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<style type="text/css">{'.st1scheck{fill:#FEFEFE;}.st2scheck{fill:none;}.st3scheck{fill:#D2122E;}.st4scheck{fill:#AD0E2D;}'}</style>
		<g>
			<path
				className="st0scheck"
				d="M2439.6,836c-1.9,16.5-9.2,29.9-25,37.1c-15.5,7-26.4-1.8-37.8-10.9c-112.7-89.5-231.3-169.1-369.1-215.7
		c-39.7-13.4-79.7-26.4-119.5-39.6c-5.9-2-12-3.5-17.6-6.2c-38.1-17.7-43.1-43.8-9.8-68.7c153.6-114.6,441.6-197.9,490.7-200.9
		c43.1-2.6,65.9,18.4,74.9,60.5C2444.6,477.6,2458.3,677.3,2439.6,836z"
			/>
			<g>
				<g>
					<path
						className="st1scheck"
						d="M1747.4,1856.4c-9.5-8.4-20.8-13.5-32.6-18c-76.4-28.9-153.7-54.9-232.9-75.2c-9.9-2.6-23-5.5-34.4-6.4
				c-0.6,0-1-0.1-1.6-0.1c-0.1,0-0.2,0-0.4,0c-0.5,0-1,0-1.4-0.1c-0.2,0-0.4,0-0.5,0c-0.5,0-0.9,0-1.2,0c-0.2,0-0.4,0-0.7,0
				c-0.4,0-0.8,0-1.1,0c-0.2,0-0.5,0-0.7,0c-0.4,0-0.8,0-1,0s-0.5,0-0.8,0c-0.4,0-0.7,0.1-1,0.1c-0.3,0-0.5,0-0.8,0.1
				c-0.3,0-0.7,0.1-1,0.1c-0.3,0-0.5,0.1-0.8,0.1s-0.6,0.1-0.9,0.1s-0.5,0.1-0.8,0.1c-0.3,0.1-0.6,0.1-0.9,0.2
				c-0.3,0.1-0.5,0.1-0.8,0.2s-0.5,0.1-0.8,0.2c-0.3,0.1-0.5,0.2-0.8,0.2c-0.2,0.1-0.5,0.2-0.7,0.2c-0.3,0.1-0.5,0.2-0.7,0.3
				s-0.4,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.7,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.3-0.7,0.4c-0.2,0.1-0.3,0.2-0.5,0.3
				c-0.3,0.2-0.5,0.4-0.7,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c-0.3,0.3-0.7,0.6-1,0.9c-5.2,5.3-6.2,16.9-6.3,24.4c0,2.5,0,5,0,7.4
				c0,0.5,0,1,0,1.5c0,2.5,0.1,5,0.2,7.3v0.1c0.1,2.5,0.2,5,0.4,7.5c0,0.2,0,0.4,0,0.6c0.2,2.6,0.3,5,0.6,7.5
				c8.2,95.2,61.9,154.4,161.2,177.4c81,18.7,136.6-22.6,178.6-88.9C1769.7,1888.2,1763.4,1870.5,1747.4,1856.4z"
					/>
					<path
						className="st1scheck"
						d="M2504.4,1533.2c-6.3-0.3-12.5,0.9-18.2,3c-1,0.4-1.8,0.8-2.8,1.1c-4.1,1.8-7.9,4.3-11.3,7.1
				c-44.7,37.9-82.6,81.8-114.6,131.1c-8.9,13.7-15,28.9-17.8,45.2c-4,22.9,5,44,24.6,55.8c52.6,31.6,123.2,10.8,149.6-44.3
				c2.5-5.1,4.9-10.4,7.1-15.5c0.8-1.6,1.3-3.2,1.9-5c0.1-0.2,0.2-0.5,0.2-0.7c0.6-1.8,1-3.7,1.3-5.6c0.8-4.2,1.4-8.4,2.1-12.6
				c6.2-40.7,17-81.2,8.9-122.8C2530.4,1544.5,2522.2,1533.9,2504.4,1533.2z"
					/>
				</g>
				<g>
					<path
						className="st2scheck"
						d="M2369.1,2121c-10.6,2-29.7,5-48.6,9.1c-76.9,17.2-143.3,54.4-200.5,107.9c-20.9,19.5-19.9,30.3,3.5,42.9
				c57.5,31,119.1,51.7,181.4,70.7c16.5,5,25.2-1.2,31.4-15c26.8-59.3,52.2-119.3,67.9-182.7
				C2411.3,2125.7,2406.7,2120.7,2369.1,2121z"
					/>
					<path
						className="st0scheck"
						d="M2523.8,1711.7c-0.1-0.1-0.1-0.3-0.2-0.5l-0.1-0.1c-0.1,0.2-0.2,0.5-0.2,0.7
				C2523.5,1711.7,2523.6,1711.7,2523.8,1711.7z"
					/>
					<path className="st0scheck" d="M2496.2,1758.3L2496.2,1758.3L2496.2,1758.3z" />
					<path className="st0scheck" d="M2498.1,1756.3C2498,1756.3,2498,1756.4,2498.1,1756.3C2498,1756.4,2498,1756.3,2498.1,1756.3z" />
					<path className="st0scheck" d="M2499.8,1754.3L2499.8,1754.3L2499.8,1754.3z" />
					<path className="st0scheck" d="M2501.6,1752.2C2501.6,1752.3,2501.6,1752.3,2501.6,1752.2C2501.6,1752.3,2501.6,1752.3,2501.6,1752.2z" />
					<path
						className="st0scheck"
						d="M2859,1392.8c-113.3-118.5-240.2-221.3-362-329.9c-150.6-134.4-312-251.7-497.8-333.3
				c-84.9-37.3-169.8-74.9-262-90.3c-153.9-25.7-352.5-32.9-508.3-21.5c-53.7,3.9-53.7,3.9-107.6-26.1
				c-91-50.7-207.5-89.9-308.7-111.3c-72.4-15.3-145.1-23.7-219.9-20.2c-47,2.3-82.5,2.8-94.1,47.9c-14.6,56.8,0.9,134.8,11.5,190.4
				c28.3,146.8,62.1,292.4,109.9,434.3c3.9,11.6,2.7,19.5-5.3,29.4c-120.4,149.2-225.6,309.2-328.7,470.4
				c-67.7,105.9-131.7,214-178.9,331.5c-15.1,37.7-4.6,65.2,20.7,90.7c54.6,55,113.2,105.6,173.5,153.9
				c109.9,88.1,232.5,156.9,355.1,224.9c185.6,103,387.3,150.6,597.6,165.9c61.5,4.5,123.1,9.1,184.7,12.2
				c75.6,3.7,152.1,11.3,227-0.4c63.6-10,124.8-5.7,184.8,11.7c94,27.3,189.1,45.3,300.1,46.1c30.6-1.5,74.6-1.2,117.3-10.8
				c105.2-23.2,163.6-95.1,190.6-193.8c32.2-117.8,66.8-231.6,143.7-332.2c80.3-105,155.8-255.8,211.7-375.7
				c37.7-81,52.4-135,78.1-220.9C2908,1482.5,2901.5,1437.3,2859,1392.8z M1864.5,2049.6c-3,46.1-10.7,91.2-24.9,135.2
				c-15.7,48.4-45,65.3-94.6,56c-72.2-13.5-137-46.1-201.5-79.2c-75.5-38.7-149.6-80.2-226.4-116.7c-1.9-0.9-3.7-1.7-5.5-2.5
				c-13.8-5.8-26.6-7.3-39.4,4c-67.3,77-126.6,160.4-192.5,238.5c-13.5,16.1-27.3,32.2-42.1,47.1c-16.6,16.8-37,19.2-58.2,9.9
				c-20.5-9-24.6-25.8-21.8-46.6c10.7-79.4,27.1-157.9,43.6-236.2c24.5-116.2,48.6-232.4,78.9-347.3c4.8-17.9,10.4-35.5,16.9-52.9
				c23.7-63.2,41.2-77,119.9-65.4c75.8,11,145.8,43.5,218.8,65.2c13,3.9,25.9,8.3,39.1,10.7c5,0.9,9.8,0.8,14.6-0.2
				c7.9-1.6,15.2-5.8,20.8-12c89.2-100.2,176.4-294.3,246.1-380.6c13.7-16.9,37.2-41.2,59-46.3c31.6-7.3,44.3,20.4,47.5,52.3
				C1874.4,1398.3,1888.1,1684.7,1864.5,2049.6z M2404.2,2154c-15.7,63.4-41.1,123.3-67.9,182.7c-6.2,13.7-15,20-31.4,15
				c-62.3-19-123.8-39.7-181.4-70.7c-23.4-12.6-24.4-23.3-3.5-42.9c57.2-53.5,123.6-90.7,200.5-107.9c18.9-4.1,38-7.1,48.6-9.1
				C2406.7,2120.7,2411.3,2125.7,2404.2,2154z M2694.6,1854.1c-17.7,12.1-34.6,6.3-49.5-5.4c-27.1-21.4-47.3-49.7-71.6-73.9
				c-8-7.9-18-16.9-25.4-1c-4.9,10.3-9.5,20.8-14.2,31.3c-27.1,61.3-51.3,126.4-75.5,186.8c-7.7,19.2-15.5,38.1-39.7,38.1
				c-24.5,0-38-9-47.8-30.9c-164.7-366.2-238.5-596.7-269.6-698.7c-6.5-21.1-12.3-39,7.9-54.5c19-14.6,40.1-13.7,62.1,4
				c61.4,49.5,203.3,231.3,224.2,252.2c15.4,15.4,26,11.7,36.9-4.4c1-1.4,1.9-2.9,3-4.3c7.8-11.4,16.3-22.4,24.4-33.6
				c18.5-27.1,36.4-54.3,55.4-80.9c11.4-15.9,26.5-27.2,47.8-22.5c19.4,4.3,26.5,20,31.7,37c42.7,137.3,82.6,275.5,115.1,415.6
				C2714.1,1828.3,2710.6,1843.3,2694.6,1854.1z"
					/>
					<path className="st3scheck" d="M2498,1756.4c-0.6,0.7-1.1,1.3-1.8,1.9C2496.9,1757.7,2497.5,1757,2498,1756.4z" />
					<path className="st3scheck" d="M2501.6,1752.3c-0.6,0.7-1.1,1.3-1.7,2C2500.4,1753.7,2501,1753,2501.6,1752.3z" />
					<path className="st3scheck" d="M2499.8,1754.4c-0.6,0.7-1.1,1.3-1.7,2C2498.7,1755.7,2499.3,1755.1,2499.8,1754.4z" />
					<path className="st4scheck" d="M1452.2,1757.2c5.9,0.7,12,1.8,17.8,3C1464.2,1759,1458.1,1757.8,1452.2,1757.2z" />
				</g>
			</g>
		</g>
	</svg>
);
export default SuperCheckoutIconBranco;
