import { useState } from "react";
import { DropdownMenu } from "./ui/DropDown/DropdownMenu";
import { DropdownMenuTrigger } from "./ui/DropDown/DropdownMenuTrigger";
import { DropdownMenuContent } from "./ui/DropDown/DropdownMenuContent";
import ToggleMenuBox from "./ui/ToggleMenuBox";
import NavigationApps from "./ui/NavigationApps";
import { Button } from "./ui/Button";
import { useSubMenu } from "./SubMenuContext";
import { eplusHub } from "./api/eplus";

interface TopBarProps {
	toggleSidebar?: (toggle?: boolean) => void;
	app: string;
}

interface EplusApps {
	[key: string]: {
		integrations?: Integration;
	};
}

interface Integration {
	[key: string]: {
		show: boolean;
		img: string;
	};
}

const TopBar = ({ app }: TopBarProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const { setSideBarOpen, isSidebarOpen } = useSubMenu();

	const handleOnOpen = (state: boolean) => {
		setIsOpen(state);
	};

	const [eplusApps, setEplusApps] = useState<EplusApps | null>(null);
	const { appEndPoint, headers } = useSubMenu();

	const options = {
		method: "POST",
		credentials: "include" as RequestCredentials,
		headers: headers || {},
		body: JSON.stringify({
			acao: "buscarUsuarioComConfigs",
		}),
	};

	if (!eplusApps) {
		eplusHub(options, appEndPoint).then((res) => {
			try {
				setEplusApps(res.data.apps);
			} catch (error) {
				setEplusApps({});
				return error;
			}
		});
	}

	return (
		<div className="w-full h-[60px]">
			<div className="bg-white w-full h-full border-b border-gray-200 flex items-center !px-4 gap-x-2 cursor-pointer">
				<Button onClick={() => setSideBarOpen(!isSidebarOpen)} className="w-9 h-9 p-0">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
						<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
					</svg>
				</Button>
				<div>
					<DropdownMenu isOpen={isOpen} setIsOpen={handleOnOpen}>
						<DropdownMenuTrigger>
							<ToggleMenuBox app={app} isOpen={isOpen} />
						</DropdownMenuTrigger>

						<DropdownMenuContent className="pr-0.5">
							<NavigationApps eplusApps={eplusApps} />
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</div>
		</div>
	);
};

export default TopBar;
