import { useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { BadgeCheck, BoxContents, BoxStars, Container, Infor, SectionMedia, SectionText } from './styled'
import { HiBadgeCheck } from "react-icons/hi";
import { getTranslation } from '../../../../translations';
import ImgCard from "../../../../assets/imgs/icon-bolsa.svg";

const Card01LilyCarousel = (props) => {

    const [icon, setIcon] = useState({ size: 20, color: "orange" })
    const config = useSelector(state => state.reducerControlConfig)

    useEffect(() => {
        setIcon({ ...icon, colorActive: config?.homereviews_color_star_card, colorInative: "#dcdcdc" })
    }, [config])




    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <div>
            <Container
                background={config.homereviews_background_color_card}
                colorBorder={config?.homereviews_color_border_card}
                sizeBorder={config?.homereviews_size_border_card}
                radiusBorder={config?.homereviews_radius_border_card}
            >
                <BoxStars fontColor={config.homereviews_font_color_card} position={config.position_star_review}>
                    <div>
                        <span>
                            <AiFillStar size={icon.size} color={props.item.qtdStars > 0 ? icon.colorActive : icon.colorInative} />
                            <AiFillStar size={icon.size} color={props.item.qtdStars > 1 ? icon.colorActive : icon.colorInative} />
                            <AiFillStar size={icon.size} color={props.item.qtdStars > 2 ? icon.colorActive : icon.colorInative} />
                            <AiFillStar size={icon.size} color={props.item.qtdStars > 3 ? icon.colorActive : icon.colorInative} />
                            <AiFillStar size={icon.size} color={props.item.qtdStars > 4 ? icon.colorActive : icon.colorInative} />
                        </span>
                    </div>
                    <label style={{ visibility: config.visible_date_review == "1" ? "visible" : "hidden" }}>17/03/2023</label>
                </BoxStars>
                <BoxContents>
                    <SectionText fontColor={config.homereviews_font_color_card} >{props.item.text}</SectionText>
                    <SectionMedia><img src={ImgCard} /></SectionMedia>
                </BoxContents>
                <Infor fontColor={config.homereviews_font_color_card} >
                    {translation.thema.cardReviewLilyPreview.enviadoPor} <strong style={{ margin: "0px 3px" }}>{props.item.name}</strong>
                    {config.homereviews_visible_verified_card == 1 &&
                        <BadgeCheck>
                            {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={20} color={config?.homereviews_color_verified_card} />}
                            <label>{translation.thema.cardReviewLilyPreview.compraVerificada}</label>
                        </BadgeCheck>}

                </Infor>
            </Container>
            <div style={{ width: "98%", borderRadius: `0px 0px ${props => props.radiusBorder}px ${props => props.radiusBorder}px`, alignItems: "center", borderTop: "1px solid gray", padding: "16px", display: "flex", gap: "20px", backgroundColor: `${config?.homereviews_background_color_card}` }}>
                <img style={{ display: "flex", maxWidth: "50px", maxHeight: "50px", objectFit: "cover" }} src={ImgCard} />
                <label style={{ fontSize: "14px" }}>Nome do produto</label>
            </div>
        </div>
    )
}

export default Card01LilyCarousel