const RastreioIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width={22} height={22} viewBox="0 0 3000 3000" {...props}>
		<title>{"Asset 1"}</title>
		<path
			d="m667.3 2156.3 782.3 708c54.7 49.6 138.8 47.2 190.6-5.5l692.9-702.6H667.3z"
			style={{
				fill: "#0098ca",
			}}
		/>
		<linearGradient
			id="a"
			x1={1028.144}
			x2={1503.854}
			y1={4671.595}
			y2={3509.07}
			gradientTransform="translate(0 -2740.01)"
			gradientUnits="userSpaceOnUse"
		>
			<stop
				offset={0}
				style={{
					stopColor: "#fff",
				}}
			/>
			<stop
				offset={1}
				style={{
					stopColor: "#e8e8e8",
				}}
			/>
		</linearGradient>
		<path
			d="M1502 1295.8v646.1l-559.5-323.1V972.7z"
			style={{
				fill: "url(#a)",
			}}
		/>
		<linearGradient
			id="b"
			x1={1501.983}
			x2={2061.499}
			y1={4197.304}
			y2={4197.304}
			gradientTransform="translate(0 -2740.01)"
			gradientUnits="userSpaceOnUse"
		>
			<stop
				offset={0}
				style={{
					stopColor: "#fff",
				}}
			/>
			<stop
				offset={1}
				style={{
					stopColor: "#e8e8e8",
				}}
			/>
		</linearGradient>
		<path
			d="M2061.5 972.7v646.1L1502 1941.9v-646.1z"
			style={{
				fill: "url(#b)",
			}}
		/>
		<linearGradient
			id="c"
			x1={1017.775}
			x2={3741.852}
			y1={3987.464}
			y2={2442.279}
			gradientTransform="translate(0 -2740.01)"
			gradientUnits="userSpaceOnUse"
		>
			<stop
				offset={0}
				style={{
					stopColor: "#fff",
				}}
			/>
			<stop
				offset={1}
				style={{
					stopColor: "#e8e8e8",
				}}
			/>
		</linearGradient>
		<path
			d="m942.5 972.7 559.5 323.1 559.5-323.1L1502 649.8z"
			style={{
				fill: "url(#c)",
			}}
		/>
		<path
			d="m942.2 1619.7 558.9 323.1s234.1 425.9 738.6 292.8c0 0-385.4 348.6-975.8 232.2 0 0-316.6-256.6-322.3-525l.6-323.1z"
			style={{
				fill: "#0083ca",
			}}
		/>
		<path
			d="M942.2 1942.8V973.9c-.2 1.2-.7 2.4-1.5 3.3l1.5-3.6L644.7 1282c-304.8 296.3-140.5 672.9-140.5 672.9 258.7 432.4 760.2 512.8 760.2 512.8-335.1-283.7-322.2-524.9-322.2-524.9z"
			style={{
				fill: "#0075ca",
			}}
		/>
		<path
			d="M942.2 973.6 644.7 1282s-296 250.1-140.5 673.6c0 0-425.9-567.5-31.8-1266.3 0 0 149.4-174.1 389-153.2l639.1 115.3-558.3 322.2z"
			style={{
				fill: "#005eca",
			}}
		/>
		<path
			d="M1501.1 650.7 862 535.4s-203.8-32.1-389.6 153.2c0 0 462.7-845.6 1449.9-509.7 0 0 137.8 130.2 137.8 384.9V973l-559-322.3z"
			style={{
				fill: "#ffc615",
			}}
		/>
		<path
			d="M2661.5 1012.3c-124.4-621.3-738.6-833.5-738.6-833.5-172.6-73.7-378.9-77-378.9-77 175 58.8 358.3 330.4 452.5 487.2 2 3.8 59.7 113.2 64.1 194.6v20.8c-.3 4.4-.3 8.8 0 13.2v802.1l446.5-314.8c175.7-134.7 154.4-292.6 154.4-292.6z"
			style={{
				fill: "#ffad15",
			}}
		/>
		<path
			d="M1501.1 1942.8s220.2 425.9 738.6 292.8c0 0 601-436.2 421.8-1223.3 0 0 21.4 157.9-154.9 292.6l-446 283.5-559.5 354.4z"
			style={{
				fill: "#ffa515",
			}}
		/>
	</svg>
);
export default RastreioIcon;
