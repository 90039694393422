
import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.headers.common['Accept'] = 'application/json' // for all requests
const url = process.env.REACT_APP_API_URL
const axiosConfig = {
    baseURL: url
};

const axiosConfigMain = {
    baseURL: "https://api-lily.herokuapp.com"
};

if(process.env.REACT_APP_HABILITAR_PLUS) {
    axiosConfig.withCredentials = true;
}
//baseURL: "https://api-depoimento.herokuapp.com" 
const api = axios.create(axiosConfig);
const apiMain = axios.create(axiosConfigMain);


export const setupInterceptors = history => {
    api.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error    

        if (error.response?.status === 402) { // USUÁRIO PRECISA RENOVAR O PLANO
            toast.error(error.response.data.description, { toastId: 7634534 })
            history.replace('/pagamentos');
        }
        
        if (error.response?.status === 401) { // USUÁRIO NÃO AUTENTICADO
            localStorage.removeItem("depoimentosAdmin@login");
            localStorage.removeItem("depoimentos@login");
            history.replace("/login")
        }

        return Promise.reject(error);
    });
}

export const POST = (data) => {

    return (
        api.post(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        }).then(res => {

            return res
        }).catch(error => {

            return false
        })
    )
}

export const POST_INTEGRATION = (data) => {

    return (
        api.post(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        }).then(res => {

            return res
        }).catch(error => {
            const response = error.response; // A resposta do erro (se existir)
            if (response) {
              console.log("Resposta do erro:", response);
              return response; // Retorna a resposta de erro
            } else {
              console.log("Erro dados:", error.message);
              return false;
            }
            return false
        })
    )
}


export const POST_ADMIN = (data) => {

    return (
        api.post(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentosAdmin@login'))?.access_token.token
            }
        }).then(res => {

            return res
        }).catch(error => {

            return false
        })
    )
}

export const PUT_ADMIN = (data) => {

    return (
        api.put(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentosAdmin@login'))?.access_token.token
            }
        }).then(res => {

            return res
        }).catch(error => {

            return false
        })
    )
}

export const POST_ADMIN_CUSTOM = (data) => {

    return (
        api.post(data.endpoint, data.data, {
            headers: {
                Authentication: `Bearer ${data.token}`
            }
        }).then(res => {

            return res
        }).catch(error => {

            return false
        })
    )
}

export const POST_ERROR = (data) => {
    return (
        api.post(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login')).access_token.token
            }
        }).then(res => {

            return res
        }).catch(error => {
            return error.response
        })
    )
}

export const GET = (data) => {

    return (
        api.get(data.endpoint, {
            headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token?.token
            }
        }).then(res => {

            return res
        }).catch(error => {
            return false
        })
    )
}

export const GET_SHOPEE = (data) => {

    return (
        api.get(data.endpoint, {
            headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token?.token
            }
        }).then(res => {

            return res
        }).catch(error => {
            return false
        })
    )
}

export const GET_ADMIN = (data) => {


    return (
        api.get(data.endpoint, {
            headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem('depoimentosAdmin@login'))?.access_token.token
            }
        }).then(res => {

            return res
        }).catch(error => {
            return false
        })
    )
}

export const GET_ADMIN_CUSTOM1 = (data) => {


    return (
        api.get(data.endpoint, {
            headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem('depoimentosAdmin@login'))?.access_token.token
            }
        }).then(res => {

            return res
        }).catch(error => {
            return false
        })
    )
}

export const PUT = (data) => {

    return (
        api.put(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        }).then(res => {

            return res
        }).catch(error => {

            return false
        })
    )
}

export const DELETE = (data) => {

    return (
        api.delete(data.endpoint, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        }).then(res => {

            return res
        }).catch(error => {

            return false
        })
    )
}
export const DELETE_CUSTOM = (data) => {

    return (
        api.delete(data.endpoint, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }, data: data.data
        }).then(res => {

            return res
        }).catch(error => {

            return false
        })
    )
}

export const API_LOGAR = (data) => {
    return (
        api.post(process.env.REACT_APP_HABILITAR_PLUS ? "/eplus/auth/logar" : "/login", data,)
            .then(res => {
                if (res.status === 200) return res.data
            }).catch(error => {
                return false
            })
    )
}

export const API_LOGAR_ADMIN = (data) => {
    return (
        api.post("/login", data,)
            .then(res => {
                if (res.status === 200) return res.data
            }).catch(error => {
                return false
            })
    )
}

export const API_CONCLUIR_LOGIN_PLUS = (data) => {
    return (
        api.get("/eplus/auth/link-magico", { params: data })
            .then(res => {
                if (res.status === 200) return res.data
            }).catch(error => {
                return false
            })
    )
}

export const API_REGISTER = (data) => {
    return (
        api.post(process.env.REACT_APP_HABILITAR_PLUS ? "/eplus/auth/cadastrar" : "/register", data,)
            .then(res => {
                if (res.status === 201) return { error: false, data: res.data }
            }).catch(error => {
                return { error: true, data: error.response.data }
            })
    )
}

export const API_PASSWORD = (data) => {
    return (
        api.post(process.env.REACT_APP_HABILITAR_PLUS ? "/eplus/auth/recupera-senha" : "/forgotPassword", data,)
            .then(res => {
                if (res.status === 200) return res.data
            }).catch(error => {
                return false
            })
    )
}




export const PUT_CUSTOM = async (data) => {

    let formData = new FormData();
    for (const name in data.data) {
        formData.append(name, data.data[name]);
    }

    try {


        const response = await fetch(`${process.env.REACT_APP_API_URL}/configurations/` + data.data.id, {
            method: 'PUT',
            body: formData,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        })

        let result = await response.json()

        return result
    } catch (error) {

        return false
    }

}


export const POST_CUSTOM = async (data) => {

    let formData = new FormData();
    for (const name in data.data) {
        formData.append(name, data.data[name]);
    }

    try {


        const response = await fetch(`${process.env.REACT_APP_API_URL}/emailpreview/`, {
            method: 'POST',
            body: formData,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        })

        let result = await response.json()

        return result
    } catch (error) {

        return false
    }

}