const SuperFavoritoIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Camada_1"
		x={0}
		y={0}
		width={22}
        height={22}
		viewBox="0 0 3000 3000"
		{...props}>
		<style>{'.superfav1{fill:#080413}'}</style>
		<path d="M1743.9 2899.5c-105.7-3.3-208.3-25.8-310.7-49.8-247.5-58.1-477.2-159.5-697.6-284.5-111.5-63.2-198.7-147.4-252.4-265.3-25.3-55.5-37.7-114.1-39-173.8-3.1-142.6 17.5-282.5 61.4-418.4 5-15.6 13.6-40.7 22.7-61.4 5-11.3 19.9-14 28.5-5.1l.3.3c111.8 115.1 245.8 197.5 389.8 264.6 22.7 10.6 45.2 21.6 68.3 31.1 11.7 4.9 13.3 9.9 11.1 22.9-16.5 103.4-11.9 206.1 30.9 302.7 49.1 110.6 138.6 166.4 258.7 173.7 34 2.1 67.1-2.9 100.3-11.1 88.7-22.2 142.1-78.9 165.8-165.5 7.9-28.6 13.5-57.9 19.9-87 2.5-11.4 4.6-16.2 19.7-12.8 101.6 23.6 203.8 45.1 308.2 52 84.1 5.5 168.6 3.9 252.8-.5 25.9-1.3 37.6-1.6 63.5-2.8 3.4-.2 6.6-.4 9.8-.6 12.5-1 21.9 11.2 17.8 23.1-26.9 77.8-59.4 168.4-89 241.3-32.2 79-63.9 158.2-100.6 235.3-53.8 113.1-152.1 161.8-267.1 186.4-24 5.1-48.5 6.6-73.1 5.2zM2115.8 784.9c-1.5-9-14.2-9.6-16.7-.8-11.8 41.9-10.3 110.6-5 152.8 13.8 109.4 23.1 188 59.6 292.5 2.7 7.7 4.6 14.1-8.7 32-6.4 8.5-19.1 9.2-26.2 1.2-75.8-84.7-171.5-142.4-270.9-194.6-33.2-17.5-68.5-31.1-102.9-46.3-33.8-14.9-42.3-27.9-34.7-64.2 28.9-139.2 83.4-268.4 153.1-391.8 33.6-59.5 73-113.7 126.6-156.9 48.1-38.8 101.4-68.7 159.4-89.1 74.6-26.2 139.2-.7 196.7 45.8 48.7 39.4 88.1 88.2 121.1 141 70 111.8 131.9 228.2 152 360.4 20.7 136.1 24.6 272.9-11.1 407.8-11.4 43.3-28.3 84.5-51.9 122.9-27.1 44.2-54.9 54.8-102.9 33.1-112.7-51.2-195.8-132.4-241.7-248.5-42.9-109-71.5-221.9-85.9-338.4-2.3-18.7-6.4-39-9.9-58.9z" className="superfav1" />
		<path d="M2355.7 1899.5c-8.5-56.6-19.4-112.9-40.6-166.4-61.9-156.1-133.2-306.2-254.2-427.7-77.1-77.4-165-139.4-264.9-180.4-113.8-46.7-235.8-57.8-358.2-61.5-101.7-3-200.4 16.6-298.6 39.8-21.1 5-27.5 1.7-25.7-20.6 7.1-93.2-7.1-183.8-38-271.4-16.5-46.7-23.4-94.7-26.7-143.1-8.7-129.6 12.4-255.5 51.1-378.9 13.2-42 30.7-82.6 39.3-126 9.1-46.3-13.7-69.3-60.3-61.9-5.4.9-10.8 2.4-16 4.1-107.9 36.3-211.6 83.8-303 150.3-184 133.5-299.4 317.8-369.3 531.8-50.4 154.6-25.1 297.4 66.3 431 45.2 66.2 100.9 120.3 165.8 166.3 13.9 9.9 20.7 14.9 22 20.9 1.2 6.1-3.4 13.3-12 27.5-19.5 31.9-47.4 70.9-63.2 107.5-3.6 8.4-1.6 18.2 4.9 24.5 1.5 1.4 3.1 3 4.7 4.6 94.8 91.8 198.1 171.7 316.9 230.9 177.2 88.4 358.7 167.2 545.7 231.9 196.4 67.9 398.2 111.9 629.5 111.2 38.2 2.4 98.6 1.6 158.6.5 11.4-.2 26.7-1 40-1.7 18.2-1.1 34.4-12.4 41.4-29.3 7.4-17.7 14.5-37.5 19-52.5 15.5-52.7 33.8-105.6 25.5-161.4zm-168.4 84.2c-30.7 57.3-75.7 98.6-144 104.2-39.3 3.2-78-7.1-117-11-11.2-1.2-8.5-9.9-7.9-16.2 18.4-169.9 78.9-324.6 170.4-467.9 15.7-24.5 25-24.5 43.4-4 48.1 53.3 90.9 171.1 88 247.8 1.9 48.3-7.7 100-32.9 147.1z" className="superfav1" />
		<path
			d="M1846.3 2467.2c47.2-22 92.9-41.5 142.9-57.4 6.2-2 12.2 3.6 10.5 10-10 36.6-25.7 67.9-47.2 96.7-5.2 6.9-13 6.3-19.4 4.1-29.4-9.9-57.8-22-87.3-38.7-5.9-3.4-5.5-11.8.5-14.7z"
			style={{
				fill: '#fefefe',
			}}
		/>
		<path
			d="M1471.3 2129.2c-26.3-29.4-70.5-33.2-100.7-8.4-9.9 8.2-17.2 18.3-23 30.4-.3.5-.7.8-1.2.9l26.5 206c1.9-.1 3.7-1 5.4-2.7 28.5-24.8 52.8-48.6 75.4-79.1 16.3-22 30-45.4 35.8-72.5 6.2-27.9 1.2-53-18.2-74.6z"
			style={{
				fill: '#fe54b7',
			}}
		/>
		<path
			d="M1345.2 2151.5c-14.6-17.9-32.8-28.6-56.4-29.7-26.1-1.1-48.2 7.6-63.7 28.7-16.6 22.7-18.2 48.2-9.1 74.4 7.4 21.3 20.6 38.9 36.4 54.6 34.5 34.4 72.4 57.3 116.4 77.5 1.5.7 3 1 4.3.9l-26.5-206c-.5.2-1 0-1.4-.4z"
			style={{
				fill: '#e10485',
			}}
		/>
	</svg>
);
export default SuperFavoritoIcon;
