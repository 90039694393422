const SakIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" width={22} height={22} viewBox="0 0 3000 3000" {...props}>
		<path
			d="M1876.8 2900c-36.4 0-72.5-8.6-105-25l-836.2-418.3C322.2 2145 77.7 1395 389.4 781.6S1451.1-76.2 2064.5 235.4s857.9 1061.6 546.1 1675a1246.236 1246.236 0 0 1-555.8 551l43.6 128.7c24.8 71.3 13.1 150.3-31.2 211.3-44.1 61.7-114.9 98.2-190.4 98.6z"
			style={{
				fill: '#fff',
				stroke: '#fff',
				strokeWidth: 1.4071,
				strokeMiterlimit: 10,
			}}
		/>
		<defs>
			<path id="asfaf" d="M1236.1 294.9C1072.7 335.7 923.7 413.7 799.2 519 565 718 416.1 1014.7 416.1 1346c0 287.3 111.9 548.3 294.1 742.2 85 90.1 184.9 165.6 295.9 222.3l836.5 418.6c58.4 29 122.6-26.9 101.9-88.6l-91.9-271c323.6-111.6 577.7-371.7 680.3-699.5 31.9-102.2 49.2-211.1 49.2-323.9 0-37.9-1.8-75-5.6-111.9-.6-3.8-.8-7.6-1.4-11.3C2514 683 2055.5 263.1 1499 263.1c-90.5-.1-178.7 11.2-262.9 31.8" />
		</defs>
		<clipPath id="gggg">
			<use
				xlinkHref="#asfaf"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<g
			style={{
				clipPath: 'url(#gggg)',
			}}>
			<defs>
				<path id="ccc" d="M1236.4 295.1C656 440.3 303.2 1028.3 448.3 1608.7c75.8 303.5 279.4 559.5 558 701.8l836.4 418.5c36 18 79.9 3.4 97.9-32.6 8.7-17.4 10.1-37.6 3.9-56l-91.7-270.9c475.5-164.6 775.2-634.6 723.9-1135.1 0-3.8-.8-7.6-1.3-11.3-62.6-546.6-525.1-959.3-1075.3-959.8-88.4.1-177.9 10.8-263.7 31.8" />
			</defs>
			<clipPath id="ddddd">
				<use
					xlinkHref="#ccc"
					style={{
						overflow: 'visible',
					}}
				/>
			</clipPath>
			<g
				style={{
					clipPath: 'url(#ddddd)',
				}}>
				<path
					d="M416.4 263.3h2165.9v2494.9H416.4z"
					style={{
						fill: '#00e673',
						stroke: '#fff',
						strokeWidth: 0.3518,
						strokeMiterlimit: 10,
					}}
				/>
			</g>
			<path
				d="M1236.4 295.1C656 440.3 303.2 1028.3 448.3 1608.7c75.8 303.5 279.4 559.5 558 701.8l836.4 418.5c36 18 79.9 3.4 97.9-32.6 8.7-17.4 10.1-37.6 3.9-56l-91.7-270.9c475.5-164.6 775.2-634.6 723.9-1135.1 0-3.8-.8-7.6-1.3-11.3-62.6-546.6-525.1-959.3-1075.3-959.8-88.4.1-177.9 10.8-263.7 31.8"
				style={{
					fill: 'none',
					stroke: '#fff',
					strokeWidth: 1.4071,
					strokeMiterlimit: 10,
				}}
			/>
		</g>
		<defs>
			<path id="eeeee" d="M1236.1 294.9C1072.7 335.7 923.7 413.7 799.2 519 565 718 416.1 1014.7 416.1 1346c0 287.3 111.9 548.3 294.1 742.2 85 90.1 184.9 165.6 295.9 222.3l836.5 418.6c58.4 29 122.6-26.9 101.9-88.6l-91.9-271c323.6-111.6 577.7-371.7 680.3-699.5 31.9-102.2 49.2-211.1 49.2-323.9 0-37.9-1.8-75-5.6-111.9-.6-3.8-.8-7.6-1.4-11.3C2514 683 2055.5 263.1 1499 263.1c-90.5-.1-178.7 11.2-262.9 31.8" />
		</defs>
		<clipPath id="ffff">
			<use
				xlinkHref="#eeeee"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<defs>
			<path id="gg" d="M2575.1 1222.9c-.1-.8-.1-1.7-.3-2.5.2.8.3 1.7.3 2.5m-1339-928c4.6-.6 9.1-.8 13.9-.8 51.9 0 95.4 36.3 106.5 84.7l196.3 521c39 103.3 134.5 174.3 244.7 181.5 248.8 16.3 666.7 43.6 668.6 43.6 55.9 0 101.7 41.6 108.6 95.5C2512.5 681.6 2054.5 263 1499 263c-90.5 0-178.7 11.3-262.9 31.9" />
		</defs>
		<clipPath id="hh">
			<use
				xlinkHref="#gg"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M1236.1 263h1339v959.9h-1339z"
			style={{
				clipPath: 'url(#hh)',
				fill: 'url(#SVGID_6_)',
			}}
		/>
		<defs>
			<path id="iii" d="M2575.7 1234.3h.8c-.6-3.8-.8-7.6-1.4-11.3.3 3.7.6 7.5.6 11.3" />
		</defs>
		<clipPath id="jj">
			<use
				xlinkHref="#iii"
				style={{
					overflow: 'visible',
				}}
			/>
		</clipPath>
		<path
			d="M2575.1 1222.9h1.4v11.3h-1.4z"
			style={{
				clipPath: 'url(#jj)',
				fill: 'url(#SVGID_8_)',
			}}
		/>
		<path
			d="M1499.3 2096.8c-414 0-750.7-336.7-750.7-750.7 0-51.9 42.1-94.1 94.1-94.1 51.9 0 94.1 42.1 94.1 94.1 0 310.1 252.3 562.5 562.5 562.5 51.9 0 94.1 42.1 94.1 94.1.1 52.2-42.2 94.1-94.1 94.1"
			style={{
				fill: '#fff',
			}}
		/>
	</svg>
);
export default SakIcon;
