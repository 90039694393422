import React, { cloneElement, ReactElement, useEffect, useState } from 'react';
import { Button } from './Button';
import PlusContaPreview from './PlusContaPreview';
import SkelletonLoader from './Skelleton';
import { eplusHub } from '../api/eplus';
import { AnimatePresence, motion } from 'framer-motion';
import { useSubMenu } from '../SubMenuContext';

export interface AppProps {
	app: {
		eplus: string;
		icon: React.ReactElement;
		name: string;
	};
}

type Conta = {
	nome: string;
	id_conta: string;
	chave_empreender: string;
	vencimento_segundos: string;
	inclusao_segundos: string;
};

interface iconProps {
	width: number;
	height: number;
}

const Loading = () => {
	return (
		<div className="bg-white border rounded-md !p-4 flex flex-row items-center justify-between">
			<div className="flex flex-col gap-2">
				<div className="flex flex-row items-center gap-1">
					<SkelletonLoader width="200px" />
				</div>
				<div className="w-full flex flex-row justify-between items-center">
					<SkelletonLoader width="150px" />
				</div>
			</div>
			<div>
				<SkelletonLoader width="65px" />
			</div>
		</div>
	);
};

const AppModal = ({ app }: AppProps) => {
	const { appEndPoint, headers } = useSubMenu();

	console.log(headers);

	const optionsBuscarContasApp = {
		method: 'POST',
		credentials: 'include' as RequestCredentials,
		headers,
		body: JSON.stringify({
			acao: 'buscarContasDoApp',
			nome_key: app.eplus,
		}),
	};

	const [showButton, setShowButton] = useState(true);

	const [showActive, setShowActive] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const [isCreatingAcc, setIsCreatingAcc] = useState(false);

	const [contas, setContas] = useState<Conta[]>([]);

	const handleTextClick = () => {
		if (!isCreatingAcc) {
			setShowButton(false);
		}
	};

	const searchAccs = () => {
		setIsLoading(true);
		eplusHub(optionsBuscarContasApp, appEndPoint)
			.then((res) => {
				try {
					if (res.code === 200) {
						setContas(res.data.contas);
					} else {
						setContas([]);
					}
					setIsLoading(false);
				} catch (error) {
					console.log(error);
				}
			})
			.catch(() => {
				setContas([]);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		searchAccs();
	}, [app]);

	const currentTimestamp = Math.floor(Date.now() / 1000);

	const filteredContas = contas
		.filter((conta) => {
			const vencimentoSegundos = parseInt(conta.vencimento_segundos);
			if (showActive) {
				return vencimentoSegundos > currentTimestamp || vencimentoSegundos === 0;
			} else {
				return vencimentoSegundos <= currentTimestamp;
			}
		})
		.sort((a, b) => parseInt(b.inclusao_segundos) - parseInt(a.inclusao_segundos));

	const handleCriarConta = (app: string) => {
		const bodyObj = {
			acao: 'criarConta',
			nome_key: app,
		};
		const options = {
			method: 'POST',
			headers,
			credentials: 'include' as RequestCredentials,
			body: JSON.stringify(bodyObj),
		};

		setIsCreatingAcc(true);

		eplusHub(options, appEndPoint).then((res) => {
			try {
				if (res.code === 200) {
					eplusHub(optionsBuscarContasApp, appEndPoint).then((res) => {
						try {
							if (res.code === 200) {
								const idsBefore = new Set(contas.map((item) => item.id_conta));
								const newAccs = res.data.contas;
								const novaConta = newAccs.find((item: { id_conta: string }) => !idsBefore.has(item.id_conta));
								setContas((prevAcc) => [novaConta, ...prevAcc]);
							}
						} catch (error) {
							console.log(error);
						}
					});
				}
				setIsCreatingAcc(false);
				setShowButton(true);
			} catch (error) {
				console.log(error);
			}
		});
	};

	return (
		<div className="text-gray-900 flex flex-col gap-3">
			<div className="size-12 bg-gray-100 rounded-lg flex items-center justify-center">
				<span>{cloneElement(app.icon as ReactElement<iconProps>, { width: 35, height: 35 })}</span>
			</div>

			<div>
				<p className="text-2xl font-semibold">Escolha uma conta</p>
				<span className="text-xs">para acessar {app.name}</span>
			</div>
			<div className="flex flex-col gap-3">
				<div className="flex flex-row">
					<Button className={`w-auto rounded-none border-b-2 ${showActive ? 'border-gray-900' : ''}`} onClick={() => setShowActive(true)}>
						Ativa
					</Button>
					<Button className={`w-auto rounded-none ${!showActive ? 'border-b-2 border-gray-900' : ''}`} onClick={() => setShowActive(false)}>
						Vencida
					</Button>
				</div>
				<div className="custom-scroll w-full max-h-[40vh] overflow-auto flex-col items-start">
					{isLoading ? (
						<Loading />
					) : (
						<>
							<>
								{contas && contas.length <= 0 ? (
									<p className="text-gray-900/80 !p-2 w-full text-center">Você não tem nenhuma conta ativa.</p>
								) : (
									<div className="w-full flex flex-col gap-2 px-1 items-start">
										<AnimatePresence mode="wait">
											{filteredContas.map((conta, index) => (
												<motion.div className="w-full" layout key={conta.id_conta} initial={{ opacity: 0, y: 0 }} animate={{ opacity: 1, y: 0, transition: { delay: index * 0.1 } }} exit={{ opacity: 0, y: 0 }}>
													<PlusContaPreview conta={conta} />
												</motion.div>
											))}
										</AnimatePresence>
									</div>
								)}
							</>
						</>
					)}
				</div>
			</div>

			{!isLoading && (
				<div>
					{!showButton ? (
						<Button onClick={() => !isCreatingAcc && handleCriarConta(app.eplus)} className={`w-full bg-gray-900 text-white hover:bg-gray-900/90 hover:text-white text-center justify-center ${!isCreatingAcc ? 'cursor-pointer' : 'cursor-not-allowed'}`}>
							{isCreatingAcc ? (
								<span className="flex flex-row gap-1">
									<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
										<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
									</svg>
									Criando Conta...
								</span>
							) : (
								'Criar Conta com 1 clique'
							)}
						</Button>
					) : (
						<p onClick={handleTextClick} className="underline text-gray-900 w-full text-center h-9 cursor-pointer">
							Deseja criar outra conta?
						</p>
					)}
				</div>
			)}
		</div>
	);
};

export default AppModal;
