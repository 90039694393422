const WidgetTikTokIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_4" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<g>
				<path
					className="st0tiktok"
					d="M2285.5,333.8c1.2,74.1-19,136.2-61.2,191.4c-45.8,60-93.5,117.7-152.1,166.2c-29,24-57.9,33.2-95.6,22.8
			c-76.2-21-153.2-38.7-232-48.2c-37.5-4.5-49.8-30.9-27.8-58.1c127.4-157.5,254-315.9,391-465.3c4.5-4.9,9-9.7,13.7-14.4
			c46-45.6,101.7-35,126.6,24.8c15.2,36.6,22.9,75.4,30.8,114.2C2283.5,290.9,2284.5,314.7,2285.5,333.8z"
				/>
				<g>
					<path
						className="st0tiktok"
						d="M2349.5,1929c-0.4-3-0.8-6-1.2-9c-15.7,5.8-32.9,9.9-51.6,12.5c-19.6,2.8-42.8,5.6-66.2,5.6
				c-11.9,0-22.9-0.7-33.8-2.1c-46.7-6.1-85.6-24.5-118.8-55.8c-26.9-25.5-49.5-59-71.3-105.5c-4.4-9.5-8.5-19.1-12.6-28.5
				c-4.2-9.9-8.5-20.1-13.1-29.7c-4.9-10.4-9.5-10.4-11.7-10.4c-0.4,0-0.9,0-1.2,0.1c-11.9,1.1-15.4,6.3-17,10.8
				c-8.1,21.9-13.5,38.8-17.7,54.5c-20.6,76.4-41,133.4-66.1,184.9c-30.4,62.4-72.8,112.5-126.1,149.1
				c-50.3,34.5-111.4,57.9-181.8,69.3c-21.8,3.6-44.8,5.4-68.3,5.4c-20.4,0-41.2-1.2-63.4-3.9c-76.2-9.1-131.6-51.4-164.7-125.9
				c-31-69.5-44.4-143.4-56.6-219.4c-3.2-20-3.5-34.7,5.1-46.6c8.5-11.8,23.5-16.6,40.7-21.2c325.1-86.4,656.9-168.9,977.8-248.7
				c11.8-3,23.8-5.9,35.7-8.9c-6.5-19-13.6-38.1-21.4-57.4c-4.2-10.4-11.9-23.9,1.3-31.1c45.9-24.9,74.5-81.2,97.5-126.8
				c17.5-34.6,1.6-56.8-36.8-53.5c-22.6,1.9-40.8-3.9-51.3-25.7c-6.1-12.6-14-24.6-18.6-37.6c-30-84.8-68.5-166-107.3-247.1
				c-39-81.6-102.3-141-188.3-169.5c-44.3-14.7-81.3-21.6-127.1-27.7c-23.7-3.3-80.1-2.8-80.1-2.8s-57.3-50.5-137.3-80.9
				c-53.3-23.7-110-34.1-169.5-30.6c-118.4,8.2-190.5,86.9-193.6,90.3c0,0-70.8-8.8-118.4-5.8c-52,3.4-103.9,28.1-149.5,60
				c10.7-52.4,21.2-99.5,29.9-146.9c16.5-89.9,15.6-177.1-40.2-256.2c-36.2-51.2-74.2-99.9-137.5-119c-77.2-23.3-126.6,5.9-141.5,84
				c-6,31.6-11.4,63.3-15.1,95.2c-17.7,156.1-26,312.9-36.1,469.6c-1.6,25.1,2.1,51.9-11.2,74.9c-16.3,28-28.8,57.5-38.1,88.5
				c-29.6,99-55.5,198.3-66.7,301.8c-16.3,150.9-25.9,301.7-19.5,453.3c4.4,105.6,5.6,211.4,19.2,316.8
				c11.8,91.7,13.3,184.8,32.2,275.7c9.3,44.9,12.9,91.3,29.6,134.5c49.4,127.1,139.4,213.5,265.5,264
				c114.8,46,231.1,85.5,354.5,100.7c149,18.4,280.6-31.2,405.8-104.9c5.1-3.5,10.4-6.8,15.8-10.6c2.8-2,2.5-6.1-0.2-7.8
				c-36.4-19.5-71.9-40.9-102.9-65.3c-69.6-54.9-148.2-90.7-231-119.5c-134.4-46.9-262-107.1-373.5-197.5
				c-136.4-110.5-242.9-241.7-286.4-416c-9.6-38.2-19-76.5-24.3-119.6c-0.6-4.8,5.9-6.8,8.2-2.5c8.2,16.3,19.1,38.1,24.8,50.4
				c47.2,101.5,97.2,199.3,165.3,288.6c107.3,140.7,247.8,239.6,411.8,301.8c141.2,53.6,278.8,111.2,400.3,203.5
				c45,34.2,99.4,53.8,155.2,65.7c78.5,16.7,158,15.4,236.8,4.5c129.1-17.8,231.7-77.8,292.1-198.1c27.4-54.7,45-113.1,55.4-173.2
				C2369.8,2240.6,2369.7,2085,2349.5,1929z M1966.3,1461c-32.2,91.6-92.6,131.8-180,130.8c-93.9-1.2-169.1-39.1-193.7-121.4
				c-1.2-4.1,3.2-7.6,6.8-5.4c123.1,73.8,243.6,64.6,363.1-7.2C1964.6,1456.6,1967.2,1458.6,1966.3,1461z M2018.5,1292
				c-6.9,33.8-15.7,64.3-26.4,94.3c-4.8,13.4-15.1,19.5-25.5,27.2c-123.5,90-246.3,87.4-368.6,11.1c-9-5.6-9.8-11.5-8.3-20.2
				c7.9-45.8,27.1-85.5,58.3-120.2c16.3-18.2,32.1-22,54.2-11.5c63.6,30.1,130.4,44.6,201.2,39.7c36.7-2.6,71.9-12.3,108.9-25.7
				C2015.8,1285.4,2019.2,1288.4,2018.5,1292z M2022.3,1237.4c-99.2,60.1-199.5,48.8-300.4,4.6c-15.7-6.9-10.3-13.9-0.6-20.2
				c38.7-25,77.7-49.6,116.6-74.4c6.8-4.3,13.2-3.8,21.1-1.8c60.3,15.5,121.2,26,186.9,8.9c3.6-1,7,2.3,6.2,6
				c-4.7,21.2-8.6,39-12.6,56.8C2037.4,1227.1,2030.2,1232.6,2022.3,1237.4z M1901.7,1109.4c44-26.6,83.6-50,127.2-65
				c30.6-10.5,36.1-4.8,37.8,26.7c1.3,23.8-3.6,38.8-29.9,47.1c-43.7,13.9-85.8,12-133.8-0.3
				C1898.9,1116.8,1898.1,1111.5,1901.7,1109.4z M1786.3,766.9c132.9,5,252.7,48,313.3,182.4c34.8,77.3,72.2,153.6,100.6,233.7
				c4.7,13.1,11,25.8,17.8,38c21.9,38.3,38.7,50.6,79.3,52.8c5.9,0.3,9.4,6.8,6.4,12c-27.1,47-59.1,92.4-115.6,103.1
				c-46.3,8.8-92,18.2-138.9-8.2c-12.8-7.1-12.9-11.3-9.6-23.2c21-76.2,41.8-152.4,57.6-229.9c5.6-27.4,10.5-54.9,3.5-83.3
				c-8-32.2-26.4-46.2-59.3-40.9c-44.1,7.1-136.6,61.5-142,63.4c-8.3,3.1-12.2,1.6-15.3-6c-2-5.2-14.8-94.6-22.9-130.7
				c-10.3-45.1-61.5-133.8-78.1-157.3C1781.3,770.4,1783.2,766.8,1786.3,766.9z M1265.8,733c63.2-58.7,135.3-96.5,224.5-79.7
				c85.3,16,159.7,56.4,225.8,112.4c73.3,62.2,110.1,142.7,122.9,235.7c3.2,22.8,9,59.1,11.3,80.9c1.2,10.9,0.2,13.7-6.6,18.3
				c-62.7,38.3-137,82.4-193.2,130.6c-61.9,53-95.3,119.3-99.5,200.2c-0.8,13.9,0.4,53.8,0.4,53.8c-1.8,1-4.6,0.7-9-1.7
				c-140.1-77-268.3-165.6-316.4-330.4c-35.7-105.8-36.8-205-35.6-254.3C1192,825.9,1229.2,766.8,1265.8,733z M1137.1,1563.3
				c-52.8-16.4-104.5-34.3-153.3-61.2c-75.6-41.7-121-106.1-143.1-187.1c-32.3-118.1-32.1-235.4,18.4-349.4
				c21.1-47.6,51-89.4,84.3-129.7c35.2-42.7,75.1-75.9,129.3-90.2c40.4-10.6,80.6-10.4,126.2-6.4c4.6,0.4,7.2,5.6,4.7,9.5
				c-24.5,38.6-40.6,75.4-47.3,116.3c-13.1,79.9,0.2,157.4,17.5,235.4c36.6,164.9,128,289.5,274.3,371.9
				c27.9,17.8,81.8,46.7,84.1,48.5c3,2.3,2.7,6.9-0.6,8.7C1488.7,1554.5,1261.3,1601.7,1137.1,1563.3z M2066.1,2430.4
				c-4.8,12.4-16.3,15.3-28.5,16.2c-36.9-0.6-68.9-13.1-94.4-40.3c-7.6-8.1-12.2-18.5-8-29.5c4.7-12.2,16.7-16.3,28.3-16.2
				c36,0.4,67.5,12.7,93.1,38.5C2065,2407.6,2070.6,2418.3,2066.1,2430.4z M2247.7,2385.3c-7.7,8.6-17.4,16.4-30.2,11.9
				c-12.2-4.3-16.8-15.2-18-27.2c-3.2-28.8,3-55.7,17.9-80.3c9.5-15.5,20.4-33.8,41.8-27.2c19.8,6.2,19,26.5,19.2,46
				C2276.1,2335.9,2267.5,2363,2247.7,2385.3z"
					/>
				</g>
				<path
					className="st0tiktok"
					d="M2459.6,1474.8c10.9,0,12.4,7.9,14.9,32.3c3.7,36.7,8.8,73.5,6.2,102.5c1.1,76.9-7.6,143.3-39.1,206.2
			c-30.9,61.7-81.3,91.9-147,101.1c-21.3,3-42.6,5.4-64,5.4c-10.6,0-21.1-0.6-31.7-2c-92.4-12.2-141.5-74.8-177.8-152.4
			c-9-19.3-16.6-39.1-25.7-58.3c-5.2-10.9-13-19.5-26-19.5c-0.9,0-1.7,0-2.7,0.1c-14.1,1.2-25.5,7.5-30.5,21.2
			c-6.7,18.3-13,36.9-18.1,55.8c-16.9,62.5-36.7,124.1-65.1,182c-60.2,123.3-163.3,188.1-296.2,209.7c-22,3.5-43.9,5.2-65.9,5.2
			c-20.4,0-41-1.3-61.6-3.8c-74.4-8.8-122.9-50.9-152.2-116.6c-30.5-68.5-43.7-142-55.4-215.5c-5.7-35.3,0.4-41,34.3-50
			c391.7-104.1,785.4-200.6,1178.6-298.7C2446.3,1476.7,2454.1,1474.8,2459.6,1474.8 M2459.6,1443.2L2459.6,1443.2L2459.6,1443.2
			c-9.2,0-18.7,2.4-31.9,5.7l-0.8,0.2c-67,16.7-135.1,33.7-200.9,50c-320.9,79.8-652.9,162.3-978.2,248.8
			c-19.5,5.2-37.9,11.1-49.5,27.2c-13,18-10.9,39.6-7.9,58.4c12.3,77,26,152.2,57.8,223.4c17.1,38.4,40.1,69.2,68.5,91.5
			c30.2,23.9,66.9,38.6,108.9,43.6c22.9,2.7,44.2,4,65.3,4c24.4,0,48.3-1.8,71-5.6c35.6-5.8,69.3-14.6,100-26.1
			c31.9-12,61.7-27.4,88.3-45.8c55.5-38.1,99.6-90.4,131.3-155.1c25.5-52.4,46.2-110.2,67.2-187.7c4.1-15.4,9.5-31.7,17.3-53.1
			c0.1,0,0.3-0.1,0.5-0.1c0.1,0.3,0.3,0.6,0.4,0.9c4.5,9.4,8.5,19,12.9,29.2c4,9.5,8.3,19.2,12.8,29c22.5,48.4,46.3,83.4,74.7,110.2
			c17.1,16.1,36,29.4,56.2,39.1c21.7,10.6,45.8,17.6,71.4,20.9c11.5,1.5,23.2,2.2,35.8,2.2c24.4,0,48.3-2.9,68.4-5.7
			c38.5-5.4,72.1-17.5,99.7-35.9c29.7-19.9,53.7-47.6,71.2-82.4c16.5-33,27.8-67.8,34.6-106.5c5.8-33,8.3-69.9,7.9-112.7
			c2.1-26.9-1.2-57-4.5-88.8c-0.7-5.9-1.2-12-1.9-17.9c-1.5-14.7-2.8-24.9-6.4-34C2492.6,1452.8,2478.1,1443.2,2459.6,1443.2
			L2459.6,1443.2z"
				/>
				<path
					className="st0tiktok"
					d="M2006.9,1474.6c-1.2,0.6-2.5-0.4-2.2-1.6c6.1-22.3,12-40.2,17.8-57.1c1.1-3,2.7-8.7,8.3-5.9l29.6,15.9
			c3.4,2.6,1.5,6.4-1.8,9.3C2050.8,1442,2012.7,1471.6,2006.9,1474.6z"
				/>
			</g>
			<g>
				<path
					className="st0tiktok"
					d="M1626.7,1730.2c-40.2,128-83.2,263.8-121.7,386.7c-0.9,2.9-3.5,4.9-6.6,5c-16.1,0.5-66.6,1.3-89.3-4.3
			c-16-4-20.6-7.5-18.7-17c34-105.3,68.7-223.6,101.6-329.3c2.2-7.2,3.9-14.6,12.5-16.8c37.7-9.8,75.4-19.5,116.8-30.1
			C1624.6,1723.5,1627.8,1726.9,1626.7,1730.2z"
				/>
				<path
					className="st0tiktok"
					d="M2179.8,1874.9c-34.6-6.5-61.9-25-83.9-52.1c-6.2-7.7-3.5-15.2-1.2-23.2c19-65.3,37.9-130.8,56.5-196.2
			c2.7-9.4,6.1-15.1,17-17.7c34.9-8.3,69.6-18.4,104.3-27.9c13.5-3.7,17.9-0.5,13.4,14.1c-29.6,96.4-58.7,193-88.1,289.6
			C2195.3,1870.2,2192.9,1879.8,2179.8,1874.9z"
				/>
				<path
					className="st0tiktok"
					d="M1564.5,2118.2c-2.9,0.2-5-2.6-4.1-5.4c27.4-87.3,54.3-173.1,81.3-258.8c12.8-40.5,30.3-96.3,43.7-138.1
			c1.1-3.4,3.8-6,7.2-7l52.8-15.2c3.1-0.9,5.8,2,4.8,5c-15.4,47.7-105.1,324.7-126.8,392.5C1616,2112.8,1611.3,2115.5,1564.5,2118.2
			z"
				/>
				<path
					className="st0tiktok"
					d="M2390.1,1530.7c2.7-0.7,5.2,1.9,4.3,4.6c-19.1,61-84.6,271.6-104.9,335.9c-2.3,7.3-3.9,12.8-11.5,14.2
			c-10.7,2-29.8,3.4-36-2.8c-3.6-3.5-3.7-5.3-2.1-10.7c28.7-94,95.8-313.9,97.9-321.1c1.4-4.9,5.4-8.6,10.4-9.9L2390.1,1530.7z"
				/>
			</g>
		</g>
	</svg>
);
export default WidgetTikTokIconBranco;
