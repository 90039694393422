const FreteHero = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<g>
				<path
					className="dsadasfff"
					d="M1058.5,1998.1c2.1-7.8-8.6-12.1-12.5-5.2c-44.6,79-118.2,234.1-136,339.4c-16.6,97.9-23.4,204.1-27.9,295.8
			c-0.5,10.8-3.8,18.8-11.3,26.6c-40.4,41.4-104.6,35-135.2-14.7c-27.9-45.3-37.3-96.5-40-148.5c-10.3-198.6,37.7-382.8,141.3-551.9
			c91.6-149.4,203.9-282,341.4-390.9c136.5-108.1,296-172.8,459.6-228c14.1-4.7,28.2-9.9,42.3-14.5c49.5-16.6,86.2-45.9,110.8-93.9
			c31-60.6,62.2-121.5,106-174.7c24.5-29.9,52.6-56.1,83-79.6c30.6-23.8,64.3-17.4,91.5,16.9c34.2,43.1,50.2,93.7,59.2,147.2
			c11.1,66.3,11.6,133.1,10.8,200c-0.2,22.1,5.2,37.7,24.6,50.1c141.1,89.9,208.5,230.7,259.5,381.6c46,135.9,67.3,277.1,83.9,419.3
			c21.8,185.7,13.1,370.5-18.6,554.3c-11.9,69-41.2,129.6-113.3,152.8c-69.6,22.4-137.7,20.4-198.7-29.5
			c-47.7-39-83-86.2-108.7-141.8c-40.2-87.1-73.3-177.7-125.6-258.9c-18.1-28.1-36-56.4-60.9-79.3c-77.5-71.1-166.3-74.7-249.6-10.7
			c-71,54.6-120.4,127.1-165,202.9c-49.2,83.5-98.8,166.7-157.6,244c-57.9,76.1-153.4,110.5-239.4,84.7
			c-39.5-11.8-65.7-35.9-81.1-74.8c-32.9-83-34.6-169.4-29-256.1c5.6-85.6,12.9-171.2,28.2-255.8c18.5-103,41.4-204.9,68.4-306
			C1058.5,1998.3,1058.5,1998.2,1058.5,1998.1z"
				/>
			</g>
			<g>
				<path className="dsadasfff" d="M567.7,833L567.7,833L567.7,833z" />
				<path className="dsadasfff" d="M567.7,833" />
				<path
					className="dsadasfff"
					d="M1858.9,834.1c-0.6-1.7-0.5-3.7,0.5-5.4l11.5-19.3c3.3-5.6,7.8-13.5,10.5-18.2
			c15.8-27.3,94.5-157.7,92.6-518.2c-0.1-9.8-0.7-19.6-1.9-29.4c-3.4-27.6-10.6-88.3-25.3-111.3c-16.8-26.5-28.7-52-105.8-10.4
			c-75.3,40.7-196.1,95.3-280.5,262.5c-2.9,5.8-6.5,15.7-8.5,21.7c-1.3,4-8.6,9.4-38.4,7.3c-11.7-0.8-38.4-3.7-73-7.1
			c-1.7,2.5-39.4,58-54.7,82.2c-3.2,5.2-6.2,10.6-10.1,17.6c39-0.9,75.7-7.8,112.9-8.9c0.3,5.2-3.5,8.2-6,11.6
			c-67.1,99.8-134.2,199.6-201.4,299.4c-29.6,43.9-59.2,88-88.8,131.9c-2.4,3.7-7.8,10.8-7.8,10.8l-2.7-5.5c0,0,25.9-62.6,38.9-93.8
			c27.9-66.6,55.9-133.1,83.7-199.8c2-4.8,3.5-9.9,6-17c-44.4-1.7-87.3,3.6-130.3,5.7c-1.5,0.1-3.1,0.1-4.6,0.2
			c-44.4,1.8-88.9,5.5-136.4,8.5c24.5-44.8,49.6-86.2,73.8-128c24.1-41.7,48.6-86.4,73.1-127.8c0.2,0,0.4,0,0.6,0
			c-27.3,1-51.4,3.3-69.9,7.7c-5.7,1.3-23.8,5.1-29.2,7.4c-4.5,2-3.5,2.3-15.2-17.4c-15-25.4-114.7-206.2-314.8-256.6l-61.8-19.4
			c-1.5-0.5-3-1-4.5-1.6c-10.2-3.8-64.1-20.1-90,54.1c-28.7,82.2-116.3,292.8-46.2,615.6c4.9,22.7,12.2,70.3,10.5,77.7
			c-4.1,17.5-114.4,354.4-81,527.3c15.9,81.9,35.5,202.1,220.1,271.9c141.1,53.3,174.5,28.1,189.8,20c3.9-2.1,7.8-4.4,11.3-7.1
			c11.4-8.6,17.2-16.2,21.4-20.2l78.2-74.7c5.9-5.5,205.4-216,586-335.8c2.5-0.8,5.2-1.2,7.7-2c91-31.2,92.2-22.3,112.5-57.5
			c22.2-38.6,61.9-111.9,79.7-136.2c15.2-20.6,70.3-87,86.2-106c2-2.4,4.6-4.2,6.4-6.9c13.6-20.4,8.5-32.6-8.9-65.2
			C1865.6,850.6,1860.4,838.2,1858.9,834.1z"
				/>
			</g>
		</g>
	</svg>
);
export default FreteHero;
