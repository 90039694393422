const WidgetTikTokIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Camada_4"
		x={0}
		y={0}
		width={22}
        height={22}
		viewBox="0 0 3000 3000"
		{...props}>
		<style>{'.widgetTiktok1{fill:#080413}.widgetTiktok3{fill:#fefefe}.widgetTiktok5{fill:#fbfbfb}.widgetTiktok7{fill:#090514}'}</style>
		<path d="M1611.7 2780.6c-5.5 3.8-10.7 7.1-15.8 10.6-125.2 73.7-256.8 123.3-405.8 104.9-123.4-15.3-239.8-54.8-354.5-100.7-126.1-50.6-216.1-136.9-265.5-264-16.8-43.2-20.3-89.6-29.6-134.5-18.9-91-20.4-184-32.2-275.7-13.6-105.4-14.8-211.2-19.2-316.8-6.4-151.6 3.2-302.4 19.5-453.3 11.2-103.5 37-202.8 66.7-301.8 9.3-31 21.8-60.4 38.1-88.5 13.3-23 9.6-49.8 11.2-74.9 10.1-156.7 18.3-313.5 36.1-469.6 3.6-31.9 9-63.6 15.1-95.2 15-78 64.3-107.3 141.5-84 63.3 19.1 101.3 67.7 137.5 119 55.8 79.1 56.7 166.3 40.2 256.2-8.7 47.4-19.2 94.5-29.9 146.9 45.6-31.9 97.5-56.6 149.5-60 47.6-3.1 118.4 5.8 118.4 5.8 3.2-3.4 75.2-82.1 193.6-90.3 59.5-3.5 116.2 6.9 169.5 30.6 80 30.4 137.3 80.9 137.3 80.9s56.4-.5 80.1 2.8c45.9 6.1 82.8 13 127.1 27.7 86.1 28.5 149.3 88 188.3 169.5 38.8 81.1 77.2 162.2 107.3 247.1 4.6 13 12.5 25 18.6 37.6 10.6 21.8 28.7 27.6 51.3 25.7 38.5-3.3 54.3 18.9 36.8 53.5-22.9 45.7-51.6 101.9-97.5 126.8-13.2 7.2-5.6 20.7-1.3 31.1 65.1 161.7 83.1 309.5 105.4 481.1 20.2 156 20.3 311.5-6.3 466.6-10.4 60.1-27.9 118.5-55.4 173.2-60.3 120.3-162.9 180.3-292.1 198.1-78.8 10.8-158.3 12.2-236.8-4.5-55.8-11.9-110.2-31.6-155.2-65.7-121.5-92.3-259.1-149.9-400.3-203.5-164-62.3-304.5-161.2-411.8-301.8-68.1-89.3-118.1-187.1-165.3-288.6-5.7-12.3-16.6-34.1-24.8-50.4-2.2-4.3-8.7-2.3-8.2 2.5 5.3 43.2 14.7 81.5 24.3 119.6 43.5 174.3 150 305.5 286.4 416 111.5 90.4 239.1 150.5 373.5 197.5 82.8 28.9 161.5 64.7 231 119.5 31.1 24.5 66.6 45.9 102.9 65.3 2.8 1.6 3.1 5.7.3 7.7z" className="widgetTiktok1" />
		<path d="M2285.5 333.8c1.2 74.1-19 136.2-61.2 191.4-45.8 60-93.5 117.7-152.1 166.2-29 24-57.9 33.2-95.6 22.8-76.2-21-153.2-38.7-232-48.2-37.5-4.5-49.8-30.9-27.8-58.1 127.4-157.5 254-315.9 391-465.3 4.5-4.9 9-9.7 13.7-14.4 46-45.6 101.7-35 126.6 24.8 15.2 36.6 22.9 75.4 30.8 114.2 4.6 23.7 5.6 47.5 6.6 66.6zM1265.8 733c63.2-58.7 135.3-96.5 224.5-79.7 85.3 16 159.7 56.4 225.8 112.4 73.3 62.2 110.1 142.7 122.9 235.7 3.2 22.8 9 59.1 11.3 80.9 1.2 10.9.2 13.7-6.6 18.3-62.7 38.3-137 82.4-193.2 130.6-61.9 53-95.3 119.3-99.5 200.2-.8 13.9.4 53.8.4 53.8-1.8 1-4.6.7-9-1.7-140.1-77-268.3-165.6-316.4-330.4-35.7-105.8-36.8-205-35.6-254.3 1.6-72.9 38.8-132 75.4-165.8z" className="widgetTiktok1" />
		<path
			d="M1198.9 739.2c4.6.4 7.2 5.6 4.7 9.5-24.5 38.6-40.6 75.4-47.3 116.3-13.1 79.9.2 157.4 17.5 235.4 36.6 164.9 128 289.5 274.3 371.9 27.9 17.8 81.8 46.7 84.1 48.5 3 2.3 2.7 6.9-.6 8.7-42.9 25.1-270.3 72.3-394.5 33.9-52.8-16.4-104.5-34.3-153.3-61.2-75.6-41.7-121-106.1-143.1-187.1-32.3-118.1-32.1-235.4 18.4-349.4 21.1-47.6 51-89.4 84.3-129.7 35.2-42.7 75.1-75.9 129.3-90.2 40.4-10.7 80.6-10.5 126.2-6.6z"
			style={{
				fill: '#25f3ed',
			}}
		/>
		<path
			d="M2297.4 1273.8c5.9.3 9.4 6.8 6.4 12-27.1 47-59.1 92.4-115.6 103.1-46.3 8.8-92 18.2-138.9-8.2-12.8-7.1-12.9-11.3-9.6-23.2 21-76.2 41.8-152.4 57.6-229.9 5.6-27.4 10.5-54.9 3.5-83.3-8-32.2-26.4-46.2-59.3-40.9-44.1 7.1-136.6 61.5-142 63.4-8.3 3.1-12.2 1.6-15.3-6-2-5.2-14.8-94.6-22.9-130.7-10.3-45.1-61.5-133.8-78.1-157.3-1.8-2.6.1-6.1 3.3-6 132.9 5 252.7 48 313.3 182.4 34.8 77.3 72.2 153.6 100.6 233.7 4.7 13.1 11 25.8 17.8 38 21.8 38.4 38.6 50.7 79.2 52.9z"
			style={{
				fill: '#fa175f',
			}}
		/>
		<path d="M2012.3 1286.7c3.5-1.2 6.9 1.7 6.2 5.4-6.9 33.8-15.7 64.3-26.4 94.3-4.8 13.4-15.1 19.5-25.5 27.2-123.5 90-246.3 87.4-368.6 11.1-9-5.6-9.8-11.5-8.3-20.2 7.9-45.8 27.1-85.5 58.3-120.2 16.3-18.2 32.1-22 54.2-11.5 63.6 30.1 130.4 44.6 201.2 39.7 36.7-2.7 71.9-12.4 108.9-25.8z" className="widgetTiktok3" />
		<path d="M2045.9 1154.5c3.6-1 7 2.3 6.2 6-4.7 21.2-8.6 39-12.6 56.8-2.1 9.8-9.3 15.3-17.3 20.1-99.2 60.1-199.5 48.8-300.4 4.6-15.7-6.9-10.3-13.9-.6-20.2 38.7-25 77.7-49.6 116.6-74.4 6.8-4.3 13.2-3.8 21.1-1.8 60.4 15.5 121.3 25.9 187 8.9zM1599.4 1465c123.1 73.8 243.6 64.6 363.1-7.2 2.1-1.2 4.7.8 3.8 3.2-32.2 91.6-92.6 131.8-180 130.8-93.9-1.2-169.1-39.1-193.7-121.4-1.2-4.1 3.2-7.6 6.8-5.4z" className="widgetTiktok3" />
		<path
			d="M1902.9 1117.9c-4-1.1-4.8-6.4-1.2-8.5 44-26.6 83.6-50 127.2-65 30.6-10.5 36.1-4.8 37.8 26.7 1.3 23.8-3.6 38.8-29.9 47.1-43.7 13.9-85.8 12-133.9-.3z"
			style={{
				fill: '#fefdfc',
			}}
		/>
		<path d="M2037.6 2446.6c-36.9-.6-68.9-13.1-94.4-40.3-7.6-8.1-12.2-18.5-8-29.5 4.7-12.2 16.7-16.3 28.3-16.2 36 .4 67.5 12.7 93.1 38.5 8.3 8.4 14 19.2 9.5 31.3-4.8 12.4-16.3 15.3-28.5 16.2zM2278.4 2308.5c-2.2 27.4-10.8 54.5-30.7 76.9-7.7 8.6-17.4 16.4-30.2 11.9-12.2-4.3-16.8-15.2-18-27.2-3.2-28.8 3-55.7 17.9-80.3 9.5-15.5 20.4-33.8 41.8-27.2 19.7 6.2 19 26.4 19.2 45.9z" className="widgetTiktok5" />
		<path d="M2006.9 1474.6c-1.2.6-2.5-.4-2.2-1.6 6.1-22.3 12-40.2 17.8-57.1 1.1-3 2.7-8.7 8.3-5.9l29.6 15.9c3.4 2.6 1.5 6.4-1.8 9.3-7.8 6.8-45.9 36.4-51.7 39.4z" className="widgetTiktok1" />
		<path
			d="M1490.9 2180c-20.4 0-41.2-1.2-63.4-3.9-76.2-9.1-131.6-51.4-164.7-125.9-31-69.5-44.4-143.4-56.6-219.4-3.2-20-3.5-34.7 5.1-46.6 8.5-11.8 23.5-16.6 40.7-21.2 325.1-86.4 656.9-168.9 977.8-248.7 65.9-16.4 134-33.3 201-50.1l.8-.2c12.5-3.1 20.7-5.2 28-5.2 11.9 0 20.8 5.9 25.2 16.9 2.9 7.1 4 16.3 5.4 29.6.6 6 1.2 12 1.9 18 3.4 31.4 6.6 61.1 4.4 86.6 1.2 88.5-11.4 154.1-40.8 212.7-16.2 32.3-38.4 58-65.8 76.4-26 17.4-56.5 28.3-93.2 33.4-19.6 2.8-42.8 5.6-66.2 5.6-11.9 0-22.9-.7-33.8-2.1-46.7-6.1-85.6-24.5-118.8-55.8-26.9-25.5-49.5-59-71.3-105.5-4.4-9.5-8.5-19.1-12.6-28.5-4.2-9.9-8.5-20.1-13.1-29.7-4.9-10.4-9.5-10.4-11.7-10.4-.4 0-.9 0-1.2.1-11.9 1.1-15.4 6.3-17 10.8-8.1 21.9-13.5 38.8-17.7 54.5-20.6 76.4-41 133.4-66.1 184.9-30.4 62.4-72.8 112.5-126.1 149.1-50.3 34.5-111.4 57.9-181.8 69.3-21.8 3.6-44.9 5.3-68.4 5.3z"
			style={{
				fill: '#fff',
			}}
		/>
		<path d="M2459.6 1474.8c10.9 0 12.4 7.9 14.9 32.3 3.7 36.7 8.8 73.5 6.2 102.5 1.1 76.9-7.6 143.3-39.1 206.2-30.9 61.7-81.3 91.9-147 101.1-21.3 3-42.6 5.4-64 5.4-10.6 0-21.1-.6-31.7-2-92.4-12.2-141.5-74.8-177.8-152.4-9-19.3-16.6-39.1-25.7-58.3-5.2-10.9-13-19.5-26-19.5-.9 0-1.7 0-2.7.1-14.1 1.2-25.5 7.5-30.5 21.2-6.7 18.3-13 36.9-18.1 55.8-16.9 62.5-36.7 124.1-65.1 182-60.2 123.3-163.3 188.1-296.2 209.7-22 3.6-43.9 5.2-65.9 5.2-20.4 0-41-1.3-61.6-3.8-74.4-8.8-122.9-50.9-152.2-116.6-30.5-68.5-43.7-142-55.4-215.5-5.7-35.3.4-41 34.3-50 391.7-104.1 785.4-200.6 1178.6-298.7 11.7-2.8 19.5-4.7 25-4.7m0-31.6c-9.2 0-18.7 2.4-31.9 5.7l-.8.2c-67 16.7-135.1 33.7-200.9 50-320.9 79.8-652.9 162.3-978.2 248.8-19.5 5.2-37.9 11.1-49.5 27.2-13 18-10.9 39.6-7.9 58.4 12.3 77 26 152.2 57.8 223.4 17.1 38.4 40.1 69.2 68.5 91.5 30.2 23.9 66.9 38.6 108.9 43.6 22.9 2.7 44.2 4 65.3 4 24.4 0 48.3-1.8 71-5.6 35.6-5.8 69.3-14.6 100-26.1 31.9-12 61.7-27.4 88.3-45.8 55.5-38.1 99.6-90.4 131.3-155.1 25.5-52.4 46.2-110.2 67.2-187.7 4.1-15.4 9.5-31.7 17.3-53.1.1 0 .3-.1.5-.1.1.3.3.6.4.9 4.5 9.4 8.5 19 12.9 29.2 4 9.5 8.3 19.2 12.8 29 22.5 48.4 46.3 83.4 74.7 110.2 17.1 16.1 36 29.4 56.2 39.1 21.7 10.6 45.8 17.6 71.4 20.9 11.5 1.5 23.2 2.2 35.8 2.2 24.4 0 48.3-2.9 68.4-5.7 38.5-5.4 72.1-17.5 99.7-35.9 29.7-19.9 53.7-47.6 71.2-82.4 16.5-33 27.8-67.8 34.6-106.5 5.8-33 8.3-69.9 7.9-112.7 2.1-26.9-1.2-57-4.5-88.8-.7-5.9-1.2-12-1.9-17.9-1.5-14.7-2.8-24.9-6.4-34-7.1-17.3-21.6-26.9-40.1-26.9z" />
		<path d="M1626.7 1730.2c-40.2 128-83.2 263.8-121.7 386.7-.9 2.9-3.5 4.9-6.6 5-16.1.5-66.6 1.3-89.3-4.3-16-4-20.6-7.5-18.7-17 34-105.3 68.7-223.6 101.6-329.3 2.2-7.2 3.9-14.6 12.5-16.8 37.7-9.8 75.4-19.5 116.8-30.1 3.3-.9 6.5 2.5 5.4 5.8zM2179.8 1874.9c-34.6-6.5-61.9-25-83.9-52.1-6.2-7.7-3.5-15.2-1.2-23.2 19-65.3 37.9-130.8 56.5-196.2 2.7-9.4 6.1-15.1 17-17.7 34.9-8.3 69.6-18.4 104.3-27.9 13.5-3.7 17.9-.5 13.4 14.1-29.6 96.4-58.7 193-88.1 289.6-2.5 8.7-4.9 18.3-18 13.4z" className="widgetTiktok1" />
		<path d="M1564.5 2118.2c-2.9.2-5-2.6-4.1-5.4 27.4-87.3 54.3-173.1 81.3-258.8 12.8-40.5 30.3-96.3 43.7-138.1 1.1-3.4 3.8-6 7.2-7l52.8-15.2c3.1-.9 5.8 2 4.8 5-15.4 47.7-105.1 324.7-126.8 392.5-7.4 21.6-12.1 24.3-58.9 27zM2390.1 1530.7c2.7-.7 5.2 1.9 4.3 4.6-19.1 61-84.6 271.6-104.9 335.9-2.3 7.3-3.9 12.8-11.5 14.2-10.7 2-29.8 3.4-36-2.8-3.6-3.5-3.7-5.3-2.1-10.7 28.7-94 95.8-313.9 97.9-321.1 1.4-4.9 5.4-8.6 10.4-9.9l41.9-10.2z" className="widgetTiktok7" />
	</svg>
);
export default WidgetTikTokIcon;
