const AutoMagicoIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<path
				className="gdsg"
				d="M1880.5,771.8c-9.7-27.7-49.5-36.4-94.5-29c-59.8,9.8-180.1,39.2-197.2,36.2
		c-39.7-7.2-153.7-101.9-219.2-173.6c-48-52.5-94.5-106.4-141.6-159.8c-105.5-119.8-229.8-212.1-381.3-266.4
		c-160-57.4-323.8-61.8-489.5-37.7c-68.4,10-132.2,34.9-184.8,82.1c-17,15.2-31.8,33.2-23.4,58.6c8.1,24.8,24.5,29.5,65.8,22.5
		c40.6-6.9,81.1-6.4,120.8,5.5c129.8,39,205.9,132.5,251.8,253.8c41.7,110.3,35.4,224.8,18.9,338.7c-6,41.3-24.5,79.7-66.9,95.2
		c-45.5,16.7-89.9,36.6-137.3,47.5c-19.8,4.6-37.3,13.9-51.6,28.5c-18.8,19.2-23.2,42.7-16.2,67.5c6.5,23.2,25.7,34.1,47.8,39.3
		c18.6,4.4,37.6,5.7,56.7,6.1c66.9,1.3,133.6-2.9,200.3-5.1l0,0c26.2-43.8,52.3-87.6,78.4-131.6c7.2-12.2,15.2-23.1,28.4-29.4
		c140.7-68.2,291.4-95.2,445.3-110.1c78.8-7.7,157.6-3.3,236.5-0.5c60.3,2,119.2,5.9,171.4,40.7c2.8,1.8,6.4,2.5,9.7,3.6
		c75-31.3,150.3-61.8,222.8-98.4C1873.4,834.9,1889.1,796.5,1880.5,771.8z"
			/>
			<path
				className="hhfgh"
				d="M1858.5,2440.5c-12.2-7.9-6-26.8,8.4-25.8c20.8,1.3,41.5,2.7,62.1,4.6c108.7,10,216.4-1.5,323.8-15.2
		c18.9-2.5,35.1,0.7,52,9.2c145,72.4,294.7,135.1,436.5,214.1c27.4,15.2,53.1,32.7,75.5,54.8c50,49.5,48.9,107.7-4.4,152.8
		c-78.8,66.8-165.7,86-262,39.7c-96.7-46.6-186.5-105.1-276.6-163c-139-89.2-276.7-180.4-414.9-270.8
		C1858.7,2440.6,1858.6,2440.6,1858.5,2440.5z"
			/>
			<g>
				<path
					className="hhfgh"
					d="M2423.4,2261.5c-4.7-29-12.5-57.5-26-83.9c-54.1-104.9-125.5-197.8-206.6-282.7
			c-168.4-176.5-339-350.9-508.6-526.2c-26.9-27.8-26.3-29.1,8.2-49.7c58.5-35.2,121.2-63.1,178.3-100.7c49-32.3,52.4-62.9,12-105.5
			c-4.8-5-9.9-9.8-15-14.4c-72.2-65.2-149.8-123.2-234.4-171.4c-3.3-1.2-12-5-14.7-6.8c-52.2-34.6-106.1-35.2-166.4-37.4
			c-78.9-2.8-157.7-7-236.5,0.5c-153.8,14.9-304.6,42-445.3,110.1c-13.1,6.3-21.1,17.4-28.4,29.4c-25.9,43.9-52.2,87.7-78.4,131.6
			c-91.6,204.5-137.4,416.3-97.1,640.4c24.8,138.7,69.8,271.4,148.1,389.1c103.3,155.3,249,263,418.8,333.8
			c203.9,85,418.9,115.9,639.7,99.1c37.4-2.9,76.3-3.1,111.6-20.1c31.2-15.1,33.5-42.5,4.8-62.1c-8.5-5.8-18.2-10-27.3-14.9
			c-136.8-72.5-273.7-146.2-394.5-242.9c-113.2-90.6-199.9-204-231.9-350.5c-11.6-53.1-19.5-107-28.2-160.7
			c-2.5-14.8-2-31.5,16.8-36.1c18.6-4.6,26.2,8.6,30.9,23.9c1.2,4.1,46.5,150.4,75.9,216.6c63.5,142.8,162.9,253,302.9,322.5
			c92.6,46,194.8,57.5,296.9,64.9c99,7,197.7,0.5,296-10.5c45.6-5.1,91.1-10.4,136.6-16.9C2403,2324.1,2429,2295.8,2423.4,2261.5z
			 M1260.8,1185.4c-48.9,6.6-79.9-14.7-92.7-65.4c-4-15.7-6.2-32.3-6.7-48.6c-2.2-63.8,40-100.3,103.1-89.6
			c24,4.1,40.6,18.3,51,39.8c11.3,23.9,16,49.2,16.1,67.7C1332.8,1149.8,1309.5,1178.9,1260.8,1185.4z"
				/>
			</g>
			<g>
				<path
					className="hhfgh"
					d="M1902.8,744.2c-9.7-27.7-49.5-36.4-94.5-29c-59.8,9.8-180.1,39.2-197.2,36.2
			c-39.7-7.2-153.7-101.9-219.2-173.6c-48-52.5-94.5-106.4-141.6-159.8C1144.8,298.2,1020.5,206,869,151.6
			c-17.2-6.2-34.4-11.6-51.7-16.7l-0.2-0.3c-143.4-41.2-289.7-42.4-437.5-20.8c-68.4,10-132.2,34.9-184.8,82.1
			c-17,15.2-31.8,33.2-23.4,58.6c8.1,24.8,24.5,29.5,65.8,22.5c40.6-6.9,81.1-6.4,120.8,5.5c129.8,39,205.9,132.5,251.8,253.8
			c41.7,110.3,35.4,224.8,18.9,338.7c-6,41.3-24.5,79.7-66.9,95.2c-45.5,16.7-89.9,36.6-137.3,47.5c-19.8,4.6-37.3,13.9-51.6,28.5
			c-18.8,19.2-23.2,42.7-16.2,67.5c6.5,23.2,25.7,34.1,47.8,39.3c18.6,4.4,37.6,5.7,56.7,6.1c66.9,1.3,73.1-0.7,139.8-3
			c23.2-45.4,45.4-114.4,69.2-159.6c18.4-34.8,62.3-66.3,75.5-72.6c140.7-68.2,310.6-91.5,464.5-106.4c36.3-3.6,85.1-6.9,121.6-6.7
			c0,0,100.3-2.3,142.7-0.9c60.3,2,122,14.6,174.2,49.3c2.8,1.8,52.6,36,55.9,37.1c75-31.3,76.9-31.3,149.4-67.8
			C1895.6,807.4,1911.4,768.9,1902.8,744.2z M1178.7,667.1c-59.4-70.9-160.6-112.6-233.2-80.6c59.6-57.4,60.2-142.9,27.9-220.6
			c68.3,65.5,132.4,100,206.5,79.7C1127.5,505.5,1145.8,575.5,1178.7,667.1z"
				/>
			</g>
		</g>
	</svg>
);
export default AutoMagicoIconBranco;
