import { ReactNode, forwardRef } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

interface DropdownMenuTriggerProps {
	children: ReactNode;
	disabled?: boolean;
}

export const DropdownMenuTrigger = forwardRef<HTMLButtonElement, DropdownMenuTriggerProps>(({ children, disabled }, ref) => {
	return (
		<DropdownMenuPrimitive.Trigger ref={ref} asChild disabled={disabled}>
			<button className='w-full'>
				{children}
			</button>
		</DropdownMenuPrimitive.Trigger>
	);
});

DropdownMenuTrigger.displayName = 'DropdownMenuTrigger';
