import { PaginateNav, PaginateInfor, Container, Paginate, Filters, BoxLoading, Search, Check, DropDown, Infor, ListCard, ButtonAdd, ActionsSelect, ActionsSelectButton, ButtonCustom, ContainerInfor, BoxInforInitial, BoxInforInitialLeft, BoxMessage, BoxInforInitialRight } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { AiOutlineSearch, AiOutlineLoading } from "react-icons/ai";
import { IoIosAdd, IoIosAddCircle } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { RiCheckFill } from "react-icons/ri";
import { useState } from "react";
import Card from "../../components/card";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalAdd from "../../components/modal-add";
import ModalListReviews from "../../components/modal-list-reviews";
import ModalListQuestions from "../../components/modal-list-reviews";
import { isVisibleScript } from "../../../../utils/displayScript";
import AlertInfor from "../../../../components/Alert";
import { toast } from "react-toastify";
import { AiOutlineCheck, AiFillEyeInvisible, AiOutlineDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import CardLoading from "../../components/card-loading";
import ModalInforVideo from "../../../../components/modal-infor-video";
import ModalInforVideoIntegration from "../../../../components/modal-infor-video";
import ModalInforVideoClean from "../../../../components/modal-infor-video";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import { getTranslation } from "../../../../translations";
import { VscFileCode } from "react-icons/vsc";
import ModalCodeHome from "../../components/modal-code-home";
import LoadingLogo from "../../../../components/Loading-logo";
import LoadingLogoLily from "../../../../components/Loading-logo-lily";
import HelpClick from "../../../../components/HelpClick";
import ModalImportShopeeNew from "../../components/modal-import-shopee-new";
import Layout from "../../../../components/tailwind/layout";
import LoadingLogoLilyNew from "../../../../components/Loading-logo-lily-new";
import { ContainerLoading } from "../../../reviews/pages/home-new/styled";

import ModalSearchAllPlatformProdducts from "../../components/modal-search-all-platform-products";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import ButtonSinc from "../../components/button-sinc";
import LayoutNew from "../../../../components/tailwind/layout-new";
import PageIntegration from "../../../../components/page-clean";
import TableProducts from "../../components/tailwind/table";
import LayoutUI from "../../../../components/tailwind/layout-ui";

const Home = () => {

    const [visibleModalImportShopee, setVisibleModalImportShopee] = useState(false)
    const [importShopeeProductSelected, setImportShopeeProductSelected] = useState(null)

    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [visibleModalAdd, setVisibleModalAdd] = useState(false)
    const [visibleModalListQuestions, setVisibleModalListQuestions] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const statusUpdateDataProduct = useSelector(state => state.reducerUpdateDataProduct)

    //teste
    const [visibleModalCodeHome, setVisibleModalCodeHome] = useState(false)
    const [controlUpdate, setControlUpdate] = useState(false)

    const products = useSelector(state => state.reducerProducts)
    const statusUpdateProduct = useSelector(state => state.reducerUpdateProduct)
    const statusDeleteProduct = useSelector(state => state.reducerDeleteProduct)
    const statusAddProduct = useSelector(state => state.reducerAddProduct)

    const [visibleModalInforProducts, setVisibleModalInforProducts] = useState(false)
    const [visibleModalInforProductsIntegration, setVisibleModalInforProductsIntegration] = useState(false)
    const [visibleModalInforProductsClean, setVisibleModalInforProductsClean] = useState(false)

    const [checkedAll, setCheckedAll] = useState(false)
    const statusDeleteSelectProducts = useSelector(state => state.reducerDeleteSelectProducts)
    const statusUpdateSelectProducts = useSelector(state => state.reducerUpdateSelectProducts)
    const productsSelect = useSelector(state => state.reducerControlSelectProducts)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const [visibleModalSearchAllPlatform, setVisibleModalSearchAllPlatform] = useState(false)

    useEffect(() => {
        if (!statusUpdateProduct.loading && !statusUpdateProduct.error && statusUpdateProduct.data.length !== 0) {
            goSearch()
        }
    }, [statusUpdateProduct])

    useEffect(() => {
        if (!statusDeleteProduct.loading && !statusDeleteProduct.error && statusDeleteProduct.data.length !== 0) {
            goSearch()
        }
    }, [statusDeleteProduct])

    useEffect(() => {
        if (!statusAddProduct.loading && !statusAddProduct.error && statusAddProduct.data.length !== 0) {
            goSearch()
        }
    }, [statusAddProduct])

    // useEffect(() => {
    //     if (typeof search !== "undefined") {
    //         dispatch({
    //             type: "SAGA_LIST_PRODUCTS", payload: {
    //                 search: search,
    //                 perPage: perPage,
    //                 page: page
    //             }
    //         })
    //     }

    //     if (typeof search !== "undefined") {
    //         dispatch({
    //             type: "SAGA_LIST_PRODUCTS_IMPORT", payload: {
    //                 search: "",
    //                 perPage: 40,
    //                 page: 1
    //             }
    //         })
    //     }
    // }, [])

    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_PRODUCTS", payload: {
                search: search,
                perPage: perPage,
                page: page
            }
        })
    }

    // useEffect(() => {
    //     goSearch()
    // }, [page])

    const countItens = () => {
        let result = 0
        if (page == products.data?.meta?.last_page) result = products.data?.meta?.total
        else result = page * perPage
        return result
    }


    useEffect(() => {
        if (!statusUpdateSelectProducts.loading && !statusUpdateSelectProducts.error && statusUpdateSelectProducts.data.length == 0) {
        } else {
            const id = "statusUpdateSelectReviews"
            if (statusUpdateSelectProducts.loading) {
                toast.loading(translation.products.aguarde, { toastId: id })
            } else {
                if (statusUpdateSelectProducts.error) {
                    toast.update(id, { render: translation.products.errorOperacao, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.products.operacaoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    handleChecked(false)
                    goSearch()
                }
            }
        }
    }, [statusUpdateSelectProducts])




    const deleteAll = () => {
        dispatch({
            type: "SAGA_DELETE_SELECT_PRODUCTS",
            payload: {
                itens: productsSelect
            }
        })
    }


    const handleChecked = (checked) => {
        setCheckedAll(checked)
    }





    const getInforProduct = () => {
        if (
            products.data?.length <= 0 &&
            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform !== "custom" &&
            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform !== "hotmart" &&
            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform !== "landpage"
        ) {
            return (
                <label>
                    {translation.products.nenhumProduto}.
                    <strong onClick={() => { history.push("/integracoes") }}>{translation.products.cliqueAqui}</strong>
                    {translation.products.integrarPlataforma}
                </label>
            )

        } else if (products.data?.length <= 0) {
            return (
                <label>
                    {translation.products.nenhumProduto},
                    <strong onClick={() => { setVisibleModalAdd(true) }}>{translation.products.cliqueAqui}</strong>
                    {translation.products.importarProduto}
                </label>
            )

        }
    }

    useEffect(() => {

        if (controlUpdate == true) {
            goSearch()
            setControlUpdate(false)
        }
    }, [controlUpdate])

    useEffect(() => {
        goSearch()
    }, [page])



    useEffect(() => {
        if (!statusUpdateDataProduct.loading && !statusUpdateDataProduct.error && statusUpdateDataProduct.data.length == 0) {
        } else {
            const id = "statusUpdateDataProduct"
            if (statusUpdateDataProduct.loading) {
                toast.loading(translation.products.card.aguarde, { toastId: id })
            } else {
                if (statusUpdateDataProduct.error) {
                    toast.update(id, { render: translation.products.card.errorSincronizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.products.card.sincronizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    goSearch()
                }
            }
        }
    }, [statusUpdateDataProduct])

    return (
        <>

            <ModalImportShopeeNew visible={visibleModalImportShopee} setVisibleModal={setVisibleModalImportShopee} item={importShopeeProductSelected} />
            <ModalInforVideo visible={visibleModalInforProducts} setVisibleModal={setVisibleModalInforProducts}
                video={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/EYnOcGcORB8" : "https://www.youtube.com/embed/TjvieSWNCr0"}
            />
            <ModalInforVideoIntegration visible={visibleModalInforProductsIntegration} setVisibleModal={setVisibleModalInforProductsIntegration} video={"https://www.youtube.com/embed/Ve4aHQ3y1-s"} />
            <ModalInforVideoClean visible={visibleModalInforProductsClean} setVisibleModal={setVisibleModalInforProductsClean} video={"https://www.youtube.com/embed/2XA-aqmmXvQ"} />
            <ModalCodeHome visible={visibleModalCodeHome} setVisibleModal={setVisibleModalCodeHome} />
            <ModalAdd visible={visibleModalAdd} setVisibleModal={setVisibleModalAdd} />
            <ModalSearchAllPlatformProdducts goSearch={goSearch} visible={visibleModalSearchAllPlatform} setVisibleModal={setVisibleModalSearchAllPlatform} />


            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900"> {translation.sidebar.meusProdutos}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {translation.title.produtos}
                            </p>
                        </div>
                    </div>
                    <PageIntegration />
                </div> :
                <>
                    <TableProducts
                        setImportShopeeProductSelected={setImportShopeeProductSelected}
                        setControlUpdate={setControlUpdate}
                        goSearch={goSearch}
                        visibleModalImport={visibleModalImportShopee} setVisibleModalImport={setVisibleModalImportShopee}
                        setVisibleModalAdd={setVisibleModalAdd}
                        setVisibleModalCodeHome={setVisibleModalCodeHome}
                        search={search}
                        setSearch={setSearch}
                        page={page}
                        setPage={setPage}
                    />
                </>}

            {/* <div style={{ margin: '20px' }} >

                {products.loading ?
                    <ContainerLoading>
                        <LoadingLogoLilyNew />
                    </ContainerLoading> :
                    typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == "undefined" || JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
                        <ContainerInfor ContainerInfor >
                            <BoxMessage>
                                <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>

                                <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                    {translation.products.nenhumaIntegracao}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { history.push("/integracoes") }} >{translation.products.cliqueAqui}</strong>.
                                </label>

                            </BoxMessage>
                        </ContainerInfor> :
                        products.data?.default == true && products.data?.data.length == 0 ?
                            <BoxMessage>
                                <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                    {translation.products.naoPossui}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { setVisibleModalInforProductsClean(true) }} >{translation.products.cliqueAqui}</strong>.
                                </label>
                            </BoxMessage> :
                            <>

                                <Filters>
                                    <Search >
                                        <input
                                            type={"text"}
                                            placeholder={translation.products.pesquisar}
                                            value={search}
                                            onChange={(e) => { setSearch(e.target.value) }} />
                                        <span onClick={() => { setPage(1); goSearch() }}>
                                            <AiOutlineSearch size={25} color={"gray"} />
                                        </span>
                                    </Search>


                                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "nuvemshop" ||
                                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "loja_integrada" ||
                                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "shopify" ?
                                        <ButtonSinc /> : null}

                                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                                        <div style={{ display: "flex", gap: "20px" }}>
                                            <ButtonAdd onClick={() => { setVisibleModalCodeHome(true) }}>
                                                <label>
                                                    Código homepage
                                                </label>
                                            </ButtonAdd>
                                            <ButtonAdd onClick={() => { setVisibleModalAdd(true) }}>
                                                <label>
                                                    {translation.products.importarManual}
                                                </label>
                                            </ButtonAdd>
                                        </div> :
                                        <></>}

                                </Filters>
                                {products.data?.data?.length > 0 ?
                                    <>
                                        {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                                            <ActionsSelect>
                                                <span>
                                                    <input type="checkbox" checked={checkedAll} onChange={(e) => { handleChecked(e.target.checked) }} />
                                                </span>
                                                <label>{translation.products.selecionarTodos}</label>

                                                <ActionsSelectButton>
                                                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ||
                                                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ?
                                                        <>
                                                            <span>
                                                                <AiOutlineDelete size={20} color={"gray"} onClick={() => { productsSelect.length > 0 ? deleteAll() : toast.warning(translation.products.nenhumItem) }} />
                                                                <label>{translation.products.desejaExcluir}</label>
                                                            </span>
                                                        </>
                                                        : null
                                                    }
                                                </ActionsSelectButton>
                                            </ActionsSelect> : null}
                                        <ListCard>
                                            {products.data.data?.map((item) => { return <Card visibleModal={visibleModalImportShopee} setVisibleModal={setVisibleModalImportShopee} setControlUpdate={setControlUpdate} setImportShopeeProductSelected={setImportShopeeProductSelected} key={item.id} checkedAll={checkedAll} item={item} /> })}
                                            <Paginate>
                                                <PaginateNav>
                                                    <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                                        <MdKeyboardArrowLeft size={35} color={products.data.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                                    </span>
                                                    <label>{products.data?.meta?.current_page}</label>
                                                    <span onClick={() => { if (page < products.data?.meta?.last_page) setPage(page + 1) }}>
                                                        <MdKeyboardArrowRight size={35} color={products.data.meta?.last_page == page ? "gray" : "#19B674"} />
                                                    </span>
                                                </PaginateNav>
                                                <PaginateInfor>
                                                    <label>{translation.products.total} {countItens()} - {products.data?.meta?.total}</label>
                                                </PaginateInfor>
                                            </Paginate>
                                        </ListCard>
                                    </>
                                    :
                                    <label style={{ marginTop: "50px", display: "flex", flex: "1", justifyContent: "center" }}>{translation.products.nenhumProduto}.</label>}
                            </>
                }


                {!products.loading &&
                    <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                        <div onClick={() => { setVisibleModalInforProducts(true) }}>
                            <HelpClick text={translation.alert.produtos} />
                        </div>
                    </div>}
            </div> */}
            {/* {isVisibleScript(false)} */}

            {!products.loading &&
                <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                    <div onClick={() => {
                        setVisibleModalInforProducts(true)
                    }}>
                        <HelpClick text={translation.alert.produtos} />
                    </div>
                </div>}
            {isVisibleScript(false)}
        </ >
    )
}

export default Home
