const BotaoZapIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Camada_1" x={0} y={0} width={22} height={22} viewBox="0 0 3000 3000" {...props}>
		<style>{'.zapzapfillbc{fill:transparent !important}'}</style>
		<path d="M1248.6 449.6c-512.9 0-929.5 416.4-929.5 929.3 0 229 83.2 438.8 220.8 601 1 1.2 37.7 55.1 49.2 85.3 13.4 34.5 8.6 63.5 3.5 78.8-19.1 57.2-104.4 212-180.9 343.2 143.7-53.7 322.4-124.8 453.1-164.6 93.7-28.5 169.3-38.5 206.7-31.1 57.4 11 116.7 16.9 177.2 16.9 512.9 0 929.3-416.5 929.3-929.5-.2-513-416.6-929.3-929.4-929.3zm123.3 1050.6c-192.9 0-349.4-156.7-349.4-349.6s156.5-349.4 349.4-349.4 349.4 156.5 349.4 349.4-156.6 349.6-349.4 349.6z" className="zapzapfillbc" />
		<path d="M1360.2 867.1c-97.8 0-177.2 79.4-177.2 177.2 0 97.9 79.4 177.2 177.2 177.2 97.9 0 177.2-79.3 177.2-177.2 0-97.6-79.3-177.2-177.2-177.2z" className="zapzapfillbc" />
		<path d="M1371.9 801.3c-192.9 0-349.4 156.5-349.4 349.4s156.5 349.6 349.4 349.6 349.4-156.7 349.4-349.6c-.1-192.8-156.6-349.4-349.4-349.4zm-11.7 420.3c-97.8 0-177.2-79.3-177.2-177.2 0-97.8 79.4-177.2 177.2-177.2 97.9 0 177.2 79.4 177.2 177.2 0 97.9-79.3 177.2-177.2 177.2z" className="zapzapfillbc2" />
		<path d="m2839 1656.6-413.4-265.5 401.7-328.4c49.3-33.9 67.7-86.5 41.7-135.5-25.8-48.9-79.4-61.8-135.5-42l-407.6 152.5c-144.8-457.2-572.7-789-1077.4-789-623.7 0-1130 506.4-1130 1130 0 276 99.1 529.1 264 725.4l5.7 10c-56.5 123.3-232.3 415.8-263.9 460.1-32.9 46.1-25.8 84.4-17.3 107.3 9 24.2 25.3 45.1 52.8 58.7 17.2 8.6 57.4 17.8 114.5 3.8 117.4-28.1 413.4-154.3 618.5-220.4 60.2-19.5 136.8-33.9 143.6-35.1 68.6 13.2 139.6 20 212.1 20 494.9 0 915.8-318.6 1068.6-761.9l443.4 94.2c54.8 14.5 110-2.1 131.6-53.2 21.7-50.5-3.4-100.8-53.1-131zm-1590.4 651.7c-60.5 0-119.8-5.7-177.2-16.9-37.4-7.4-113 2.6-206.7 31.1-130.7 39.8-309.3 110.9-453.1 164.6 76.5-131.3 161.8-286 180.9-343.2 5.1-15.3 9.8-44.2-3.5-78.8-11.4-30.2-48-84.1-49.2-85.3-137.6-162.1-220.8-372-220.8-601 0-512.9 416.5-929.3 929.5-929.3s929.3 416.4 929.3 929.3-416.4 929.5-929.2 929.5z" className="zapzapfillbc2" />
	</svg>
);
export default BotaoZapIconBranco;
