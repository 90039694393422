const DropiIconBranco = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 577.27 322" {...props}>
			<defs>
				<style>{'.ffgfdgfdgdg{clip-path:url(#clip-path);}.dfgdgre{clip-path:url(#clip-path-2);}.rrrrtyyy{clip-path:url(#clip-path-3);}'}</style>
				<clipPath id="clip-path">
					<path className="cls-1dro" d="M165.94,0H149a40,40,0,0,0-40,40V82.18c-.44-.15-.87-.31-1.32-.45-1.3-.4-2.61-.78-3.94-1.12q-4-1-8.1-1.65a83.69,83.69,0,0,0-25.28,0q-4.11.63-8.1,1.65c-1.33.34-2.64.72-3.94,1.12A82.62,82.62,0,0,0,30.22,97q-1.5,1.27-3,2.61a78.69,78.69,0,0,0-5.63,5.63q-1.32,1.5-2.61,3A82.87,82.87,0,0,0,3.75,136.32c-.4,1.3-.78,2.61-1.12,3.94q-1,4-1.65,8.1a83.6,83.6,0,0,0,4,41.18c.94,2.54,2,5,3.14,7.44a83.6,83.6,0,0,0,6,10.43c1.49,2.2,3.08,4.34,4.77,6.39q1.27,1.5,2.61,3c1.8,2,3.67,3.84,5.63,5.63a74.55,74.55,0,0,0,6.15,5.07,82.61,82.61,0,0,0,25,12.76c1.3.4,2.61.78,3.94,1.12q4,1,8.1,1.65a83.69,83.69,0,0,0,25.28,0,79.6,79.6,0,0,0,8.1-1.65c1.33-.34,2.64-.72,3.94-1.12.45-.14.88-.3,1.32-.45a83.07,83.07,0,0,0,38.05-26c1.69-2.05,3.28-4.19,4.77-6.39a82.37,82.37,0,0,0,6-10.43q1.74-3.63,3.14-7.44A82.38,82.38,0,0,0,166,164h0V0ZM83,187a26,26,0,1,1,26-26A26,26,0,0,1,83,187Z" />
				</clipPath>
				<clipPath id="clip-path-2">
					<path className="cls-1dro" d="M535,148.36q-.63-4.12-1.65-8.1c-.34-1.33-.72-2.64-1.12-3.94a83,83,0,0,0-54.55-54.54c-1.3-.4-2.61-.78-3.94-1.12q-4-1-8.1-1.65a83.69,83.69,0,0,0-25.28,0q-4.11.63-8.1,1.65c-1.33.34-2.64.72-3.94,1.12-.45.14-.88.3-1.32.45A82.85,82.85,0,0,0,400.22,97q-1.5,1.27-3,2.61a78.69,78.69,0,0,0-5.63,5.63q-1.32,1.5-2.61,3a82.94,82.94,0,0,0-15.22,28.12c-.4,1.3-.78,2.61-1.12,3.94q-1,4-1.65,8.1a81.82,81.82,0,0,0-.88,9.64H370V322h17a40,40,0,0,0,40-40V239.82c.44.15.87.31,1.32.45,1.3.4,2.61.78,3.94,1.12q4,1,8.1,1.65a83.69,83.69,0,0,0,25.28,0,79.6,79.6,0,0,0,8.1-1.65c1.33-.34,2.64-.72,3.94-1.12a83.32,83.32,0,0,0,39.37-26.47c1.69-2.05,3.28-4.19,4.77-6.39a82.37,82.37,0,0,0,6-10.43q1.74-3.63,3.14-7.44A83.5,83.5,0,0,0,535,148.36ZM453,187a26,26,0,1,1,26-26A26,26,0,0,1,453,187Z" />
				</clipPath>
				<clipPath id="clip-path-3">
					<path className="cls-1dro" d="M233,80a82.76,82.76,0,0,0-39.55,10A39.77,39.77,0,0,0,167,80H150V244h57V163a26,26,0,0,1,52,0h57A83,83,0,0,0,233,80Z" />
				</clipPath>
			</defs>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<g id="Layer_2-2" data-name="Layer 2">
						<g id="Layer_1-2-2" data-name="Layer 1-2">
							<g id="Layer_2-2-2" data-name="Layer 2-2">
								<g id="Layer_1-2-2-2" data-name="Layer 1-2-2">
									<g id="Camada_2" data-name="Camada 2">
										<g id="Camada_1-2" data-name="Camada 1-2">
											<path className="cls-1dro" d="M342,78a83,83,0,1,0,83,83A83,83,0,0,0,342,78Zm0,109a26,26,0,1,1,26-26A26,26,0,0,1,342,187Z" />
											<path className="cls-1dro" d="M165.94,0H149a40,40,0,0,0-40,40V82.18c-.44-.15-.87-.31-1.32-.45-1.3-.4-2.61-.78-3.94-1.12q-4-1-8.1-1.65a83.69,83.69,0,0,0-25.28,0q-4.11.63-8.1,1.65c-1.33.34-2.64.72-3.94,1.12A82.62,82.62,0,0,0,30.22,97q-1.5,1.27-3,2.61a78.69,78.69,0,0,0-5.63,5.63q-1.32,1.5-2.61,3A82.87,82.87,0,0,0,3.75,136.32c-.4,1.3-.78,2.61-1.12,3.94q-1,4-1.65,8.1a83.6,83.6,0,0,0,4,41.18c.94,2.54,2,5,3.14,7.44a83.6,83.6,0,0,0,6,10.43c1.49,2.2,3.08,4.34,4.77,6.39q1.27,1.5,2.61,3c1.8,2,3.67,3.84,5.63,5.63a74.55,74.55,0,0,0,6.15,5.07,82.61,82.61,0,0,0,25,12.76c1.3.4,2.61.78,3.94,1.12q4,1,8.1,1.65a83.69,83.69,0,0,0,25.28,0,79.6,79.6,0,0,0,8.1-1.65c1.33-.34,2.64-.72,3.94-1.12.45-.14.88-.3,1.32-.45a83.07,83.07,0,0,0,38.05-26c1.69-2.05,3.28-4.19,4.77-6.39a82.37,82.37,0,0,0,6-10.43q1.74-3.63,3.14-7.44A82.38,82.38,0,0,0,166,164h0V0ZM83,187a26,26,0,1,1,26-26A26,26,0,0,1,83,187Z" />
											<g className="ffgfdgfdgdg">
												<path className="cls-1dro" d="M165.94,0H149a40,40,0,0,0-40,40V82.18c-.44-.15-.87-.31-1.32-.45-1.3-.4-2.61-.78-3.94-1.12q-4-1-8.1-1.65a83.69,83.69,0,0,0-25.28,0q-4.11.63-8.1,1.65c-1.33.34-2.64.72-3.94,1.12A82.62,82.62,0,0,0,30.22,97q-1.5,1.27-3,2.61a78.69,78.69,0,0,0-5.63,5.63q-1.32,1.5-2.61,3A82.87,82.87,0,0,0,3.75,136.32c-.4,1.3-.78,2.61-1.12,3.94q-1,4-1.65,8.1a83.6,83.6,0,0,0,4,41.18c.94,2.54,2,5,3.14,7.44a83.6,83.6,0,0,0,6,10.43c1.49,2.2,3.08,4.34,4.77,6.39q1.27,1.5,2.61,3c1.8,2,3.67,3.84,5.63,5.63a74.55,74.55,0,0,0,6.15,5.07,82.61,82.61,0,0,0,25,12.76c1.3.4,2.61.78,3.94,1.12q4,1,8.1,1.65a83.69,83.69,0,0,0,25.28,0,79.6,79.6,0,0,0,8.1-1.65c1.33-.34,2.64-.72,3.94-1.12.45-.14.88-.3,1.32-.45a83.07,83.07,0,0,0,38.05-26c1.69-2.05,3.28-4.19,4.77-6.39a82.37,82.37,0,0,0,6-10.43q1.74-3.63,3.14-7.44A82.38,82.38,0,0,0,166,164h0V0ZM83,187a26,26,0,1,1,26-26A26,26,0,0,1,83,187Z" />
											</g>
											<path className="cls-1dro" d="M535,148.36q-.63-4.12-1.65-8.1c-.34-1.33-.72-2.64-1.12-3.94a83,83,0,0,0-54.55-54.54c-1.3-.4-2.61-.78-3.94-1.12q-4-1-8.1-1.65a83.69,83.69,0,0,0-25.28,0q-4.11.63-8.1,1.65c-1.33.34-2.64.72-3.94,1.12-.45.14-.88.3-1.32.45A82.85,82.85,0,0,0,400.22,97q-1.5,1.27-3,2.61a78.69,78.69,0,0,0-5.63,5.63q-1.32,1.5-2.61,3a82.94,82.94,0,0,0-15.22,28.12c-.4,1.3-.78,2.61-1.12,3.94q-1,4-1.65,8.1a81.82,81.82,0,0,0-.88,9.64H370V322h17a40,40,0,0,0,40-40V239.82c.44.15.87.31,1.32.45,1.3.4,2.61.78,3.94,1.12q4,1,8.1,1.65a83.69,83.69,0,0,0,25.28,0,79.6,79.6,0,0,0,8.1-1.65c1.33-.34,2.64-.72,3.94-1.12a83.32,83.32,0,0,0,39.37-26.47c1.69-2.05,3.28-4.19,4.77-6.39a82.37,82.37,0,0,0,6-10.43q1.74-3.63,3.14-7.44A83.5,83.5,0,0,0,535,148.36ZM453,187a26,26,0,1,1,26-26A26,26,0,0,1,453,187Z" />
											<g className="dfgdgre">
												<path className="cls-1dro" d="M535,148.36q-.63-4.12-1.65-8.1c-.34-1.33-.72-2.64-1.12-3.94a83,83,0,0,0-54.55-54.54c-1.3-.4-2.61-.78-3.94-1.12q-4-1-8.1-1.65a83.69,83.69,0,0,0-25.28,0q-4.11.63-8.1,1.65c-1.33.34-2.64.72-3.94,1.12-.45.14-.88.3-1.32.45A82.85,82.85,0,0,0,400.22,97q-1.5,1.27-3,2.61a78.69,78.69,0,0,0-5.63,5.63q-1.32,1.5-2.61,3a82.94,82.94,0,0,0-15.22,28.12c-.4,1.3-.78,2.61-1.12,3.94q-1,4-1.65,8.1a81.82,81.82,0,0,0-.88,9.64H370V322h17a40,40,0,0,0,40-40V239.82c.44.15.87.31,1.32.45,1.3.4,2.61.78,3.94,1.12q4,1,8.1,1.65a83.69,83.69,0,0,0,25.28,0,79.6,79.6,0,0,0,8.1-1.65c1.33-.34,2.64-.72,3.94-1.12a83.32,83.32,0,0,0,39.37-26.47c1.69-2.05,3.28-4.19,4.77-6.39a82.37,82.37,0,0,0,6-10.43q1.74-3.63,3.14-7.44A83.5,83.5,0,0,0,535,148.36ZM453,187a26,26,0,1,1,26-26A26,26,0,0,1,453,187Z" />
											</g>
											<path className="cls-1dro" d="M233,80a82.76,82.76,0,0,0-39.55,10A39.77,39.77,0,0,0,167,80H150V244h57V163a26,26,0,0,1,52,0h57A83,83,0,0,0,233,80Z" />
											<g className="rrrrtyyy">
												<path className="cls-1dro" d="M233,80a82.76,82.76,0,0,0-39.55,10A39.77,39.77,0,0,0,167,80H150V244h57V163a26,26,0,0,1,52,0h57A83,83,0,0,0,233,80Z" />
											</g>
											<path className="cls-1dro" d="M560.27,80h17V204a40,40,0,0,1-40,40h-17V120A40,40,0,0,1,560.27,80Z" />
											<circle className="cls-1dro" cx="548.77" cy="35" r="26" />
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
};
export default DropiIconBranco;
