import React, { useEffect, useRef, useState } from 'react'
import { BoxImage, Card, CardPlay, Comment, More, Name, Stars } from './styled'
import { FaPlay, FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa'
import { HiBadgeCheck } from 'react-icons/hi'
import { useSelector } from 'react-redux'

const CardBK = (props) => {

    //console.log("props card ", props)


    const getMedia = () => {

        if (props?.review?.media?.length == 0) {
            return <span style={{ minWidth: "100%", minHeight: "1px", backgroundColor: "transparent" }} />
        } else {
            //console.log("props?.review?.media[0]", props?.review?.media[0])
            if (props?.review?.media[0]?.type == "image") {
                return (
                    <BoxImage>
                        <img src={props.review?.media[0]?.url} />
                    </BoxImage>
                )
            } else if (props?.review?.media[0].type == "youtube") {
                return (
                    <div style={{ width: "100%", }}>
                        <iframe
                            style={{ width: "100%", height: "180px", borderRadius: "16px 16px 0px 0px" }}
                            src={`https://www.youtube.com/embed/${props?.review?.media[0].url}`}
                            title=""
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen="true" />

                    </div>
                )
            } else if ("props?.review?.media[0].type" == "tiktok") {
                return (
                    <CardPlay id='abrir' tiktok={true}>
                        <iframe
                            src={`https://tiktok.com/embed/${"props?.review?.media[0].url"}`}
                            style={{ border: "none", width: '-webkit-fill-available', height: '-webkit-fill-available', margin: '0px auto', borderRadius: '0px', }}
                            allowfullscreen
                            scrolling="no"
                            allow="encrypted-media;"
                        />
                    </CardPlay>
                )
            } else if (props?.review?.media[0].type == "video") {
                return (
                    <BoxImage>
                        <div>
                            <img src={props.review?.media[0]?.url} />
                            <span />
                        </div>
                        <FaPlay style={{ cursor: "pointer" }} size={40} color='#ffffff' />
                    </BoxImage>
                )
            }
        }
    }


    const configControl = useSelector(state => state.reducerControlConfig)
    return (
        <Card>
            {getMedia()}
            {/* {props.review?.media[0]?.url &&
                <BoxImage>
                    <img src={props.review?.media[0]?.url} />
                </BoxImage>} */}

            {/* <Image image={"https://via.placeholder.com/600x400"}>
                <Overlay percentage={50} color={"red"} />
            </Image> */}
            <Stars >
                <FaStar size={20} color={props.color_star_active} />
                <FaStar size={20} color={props.review.rating > 1 ? props.color_star_active : props.color_star_inactive} />
                <FaStar size={20} color={props.review.rating > 2 ? props.color_star_active : props.color_star_inactive} />
                <FaStar size={20} color={props.review.rating > 3 ? props.color_star_active : props.color_star_inactive} />
                <FaStar size={20} color={props.review.rating > 4 ? props.color_star_active : props.color_star_inactive} />
            </Stars>

            <Name style={{ fontFamily: `${configControl?.font}, sans-serif` }}>
                {props.review?.name}  {props.review.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={18} color={props.color_verified} />}
            </Name>

            <Comment style={{ fontFamily: `${configControl?.font}, sans-serif` }}>
                {props.review?.message}
            </Comment>

            {/* <More>
                Mostrar mais
            </More> */}
        </Card>
    )
}

export default CardBK