import React, { useState } from 'react'
import { BoxButtons, BoxColors, BoxOptions, BoxTema, ButtonHelp, ButtonReset, ButtonSave, Carrossel, Container, Favoritas, Item, ItemActive, ItemSelect, SelectCustom, Subtitle } from './styled'

import IconReset from "../../../../assets/imgs/icon-reset.svg";
import IconCamera from "../../../../assets/imgs/icon-camera.svg";
import IconSeta from "../../../../assets/imgs/seta-back.svg";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useDispatch, useSelector } from 'react-redux';
import { TextAreaCustom } from '../form-email/styled';
import { CounterItemsCircle } from '../box-config-tema/styled';
import { BoxLoadingCustom, InputFileCustomTeste, InputTextAreaCustom, InputTextCustom, ItemColor } from '../box-config-email/styled';
import { useEffect } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { FaUpload } from 'react-icons/fa';
import imageCompression from 'browser-image-compression';

import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { getTranslation } from '../../../../translations';

import { BarSubmenu, BarSubmenuItem, ItemOption, Main, MenuOption, Title } from "../bar-config-product-cores/styled";
import { IoIosArrowBack } from 'react-icons/io';
import IconFavorite from "../../../../assets/imgs/icon-coracao.svg";
import IconSlider from "../../../../assets/imgs/icon-slider-home.svg";
import BoxConfigTema from '../box-config-tema';

import IconImgProduct from "../../../../assets/imgs/icons-sidebar/submenu-product.svg";
import IconImgProductInativo from "../../../../assets/imgs/icons-sidebar/submenu-product-inativo.svg";
import IconImgEmail from "../../../../assets/imgs/icons-sidebar/submenu-email-inativo.svg";
import IconImgEmailAtivo from "../../../../assets/imgs/icons-sidebar/submenu-email-ativo.svg";
import IconImgHome from "../../../../assets/imgs/icon-homepage.svg";
import IconImgHomeAtivo from "../../../../assets/imgs/icon-homepage-active.svg";
import IconImgTeste from "../../../../assets/imgs/teste-00.svg";
import { useHistory, useLocation } from 'react-router';
import { InputTextColor } from '../bar-config-email-cores/styled';
import { Tooltip } from '../bar-config-product-ajustes/styled';

const BarConfigHomeColor = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const selectedMenu = useLocation();
    const reviewsFavorites = useSelector(state => state.reducerReviews)

    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const controlSaveHomeColor = useSelector(state => state.reducerControlSaveHomeColor)
    const [theme, setTheme] = useState("")

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const saveTheme = () => {

        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                homereviews_background_color: configControl.homereviews_background_color,
                homereviews_arrow_color: configControl.homereviews_arrow_color,
                //homereviews_step_color: configControl.homereviews_arrow_color,
                homereviews_color_title: configControl.homereviews_color_title,
                homereviews_background_color_card: configControl.homereviews_background_color_card,
                homereviews_font_color_card: configControl.homereviews_font_color_card,
                homereviews_color_verified_card: configControl.homereviews_color_verified_card,
                homereviews_color_star_card: configControl.homereviews_color_star_card,
                homereviews_color_border_card: configControl.homereviews_color_border_card,
                homereviews_color_star_card: configControl.homereviews_color_star_card,
                homereviews_pagination_color: configControl.homereviews_pagination_color
            }
        })

    }

    const getDefaultTheme = () => {
        const themaDefault = {
            id: config.data.id,
            homereviews_background_color: "#FFFFFF",
            homereviews_arrow_color: "#308BF7",
            //homereviews_step_color: "#161718",
            homereviews_color_title: "#212121",
            homereviews_font_color_card: "#212121",
            homereviews_background_color_card: "#F1F1F1",
            homereviews_color_verified_card: "#5CB85C",
            homereviews_color_star_card: "#DAA520",
            homereviews_color_border_card: "#F1F1F1",
            homereviews_pagination_color: "#308BF7"
        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })
    }


    useEffect(() => {

        switch (theme) {
            case "dark":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        homereviews_background_color: "#000000",
                        homereviews_arrow_color: "#308BF7",
                        //homereviews_step_color: "#c1c1c1",
                        homereviews_color_title: "#FFFFFF",
                        homereviews_font_color_card: "#EBEBEB",
                        homereviews_background_color_card: "#212121",
                        homereviews_color_verified_card: "#5CB85C",
                        homereviews_color_star_card: "#DAA520",
                        homereviews_color_border_card: "#F1F1F1",
                        homereviews_pagination_color: "#308BF7"
                    }
                })
                break;
            case "light":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        homereviews_background_color: "#FFFFFF",
                        homereviews_arrow_color: "#308BF7",
                        //homereviews_step_color: "#161718",
                        homereviews_color_title: "#212121",
                        homereviews_font_color_card: "#212121",
                        homereviews_background_color_card: "#F1F1F1",
                        homereviews_color_verified_card: "#5CB85C",
                        homereviews_color_star_card: "#DAA520",
                        homereviews_color_border_card: "#F1F1F1",
                        homereviews_pagination_color: "#308BF7"
                    }
                })
                break;
            default:
                break;
        }
    }, [theme])

    useEffect(() => {
        if (
            configControl.homereviews_background_color == "#FFFFFF" &&
            configControl.homereviews_arrow_color == "#308BF7" &&
            //configControl.homereviews_step_color == "#161718" &&
            configControl.homereviews_color_title == "#212121" &&
            configControl.homereviews_font_color_card == "#212121" &&
            configControl.homereviews_background_color_card == "#F1F1F1" &&
            configControl.homereviews_color_verified_card == "#5CB85C" &&
            configControl.homereviews_color_star_card == "#DAA520" &&
            configControl.homereviews_color_border_card == "#F1F1F1" &&
            configControl.homereviews_pagination_color == "#308BF7"
        ) {
            setTheme("light")
        }
        else if (
            configControl.homereviews_background_color == "#000000" &&
            configControl.homereviews_arrow_color == "#308BF7" &&
            //configControl.homereviews_step_color == "#c1c1c1" &&
            configControl.homereviews_color_title == "#FFFFFF" &&
            configControl.homereviews_font_color_card == "#EBEBEB" &&
            configControl.homereviews_background_color_card == "#212121" &&
            configControl.homereviews_color_verified_card == "#5CB85C" &&
            configControl.homereviews_color_star_card == "#DAA520" &&
            configControl.homereviews_color_border_card == "#F1F1F1" &&
            configControl.homereviews_pagination_color == "#308BF7"
        ) {
            setTheme("dark")
        }
        else {
            setTheme("custom")
        }

    }, [configControl])





    const isSaveHomeColor = () => {

        if (
            configControl.homereviews_background_color == config.data.homereviews_background_color &&
            configControl.homereviews_arrow_color == config.data.homereviews_arrow_color &&
            //configControl.homereviews_step_color == config.data.homereviews_step_color &&
            configControl.homereviews_color_title == config.data.homereviews_color_title &&
            configControl.homereviews_font_color_card == config.data.homereviews_font_color_card &&
            configControl.homereviews_background_color_card == config.data.homereviews_background_color_card &&
            configControl.homereviews_color_verified_card == config.data.homereviews_color_verified_card &&
            configControl.homereviews_color_star_card == config.data.homereviews_color_star_card &&
            configControl.homereviews_color_border_card == config.data.homereviews_color_border_card &&
            configControl.homereviews_pagination_color == config.data.homereviews_pagination_color
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_COLOR_SET", payload: false })
            return false
        }
    }




    useEffect(() => {
        dispatch({
            type: "SAGA_LIST_REVIEWS", payload: {
                rating: "",
                status: "all",
                search: "",
                perPage: 10,
                page: 1,
                medias: "",
                favorite: true
            }
        })
    }, [])

    const verifyBar = (teste) => {
        if (selectedMenu.pathname.slice(13) == "/home" && reviewsFavorites?.data?.meta?.total < 8) {
            return false
        } else {
            return true
        }
    }

    const visibleFormHome = () => {

        if (selectedMenu.pathname == "/personalizar/home") {
            if (
                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "" &&
                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != null &&
                typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "undefined"
                // &&            reviewsFavorites?.data?.meta?.total > 7
            ) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }

    }
    return (
        <Main >
            {/* <BarSubmenu >
                <BarSubmenuItem
                    active={false}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveHomeColor() && history.push("/personalizar/tema") }}
                >
                    {selectedMenu.pathname.slice(13) == "/" || selectedMenu.pathname.slice(13) == "" || selectedMenu.pathname.slice(13) == "/tema" ?
                        <img width={24} src={IconImgProduct} /> :
                        <img width={24} src={IconImgProductInativo} />}

                    <Tooltip>
                        Página do produto
                    </Tooltip>

                </BarSubmenuItem>
                <BarSubmenuItem
                    active={false}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveHomeColor() && history.push("/personalizar/email") }}
                >
                    {selectedMenu.pathname.slice(13) == "/email" ?
                        <img width={24} src={IconImgEmailAtivo} /> :
                        <img width={24} src={IconImgEmail} />}

                    <Tooltip>
                        E-mail
                    </Tooltip>

                </BarSubmenuItem>
                <BarSubmenuItem
                    active={true}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveHomeColor() && history.push("/personalizar/home") }}
                >
                    {selectedMenu.pathname.slice(13) == "/home" ?
                        <img width={24} src={IconImgHomeAtivo} /> :
                        <img width={24} src={IconImgHome} />}

                    <Tooltip>
                        Página do inicial
                    </Tooltip>

                </BarSubmenuItem>


            </BarSubmenu> */}

            <Container visible={visibleFormHome()}>
                <Title>

                    {/* <span onClick={() => { isSaveHomeColor() && history.push("/personalizar") }}>
                        <IoIosArrowBack />
                    </span> */}
                    <label>{translation.thema.homepage.title}</label>
                </Title>

                <MenuOption>
                    {/* <ItemOption active={false} onClick={() => { dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/habilitar" }) }}>
                    Habilitar
                </ItemOption> */}

                    <ItemOption active={true} onClick={() => {
                        isSaveHomeColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/cores" })
                    }}>
                        {translation.thema.homepage.cores}
                    </ItemOption>
                    <ItemOption active={false} onClick={() => {
                        isSaveHomeColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/config" })
                    }}>
                        {translation.thema.homepage.avancado}
                    </ItemOption>
                </MenuOption>

                <BoxTema>

                    <label>{translation.thema.boxConfigEmail.combinacaoCores}:</label>
                    <SelectCustom onChange={(e) => { setTheme(e.target.value) }}>
                        <option value="light" selected={theme == "light" ? true : false}>Light</option>
                        <option value="dark" selected={theme == "dark" ? true : false}>Dark</option>
                        <option disabled value="custom" selected={theme == "custom" ? true : false}>{translation.thema.boxConfigEmail.personalizado}</option>
                    </SelectCustom>

                </BoxTema>

                <BoxColors>
                    <label>Widget:</label>
                    <Item>
                        <label>{translation.thema.boxConfigHome.titulo}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_color_title}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_color_title: e.target.value } }) }}
                            />
                            {/* <label>{configControl?.homereviews_color_title}</label> */}
                            <InputTextColor type='text' value={configControl.homereviews_color_title} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_color_title: e.target.value } }) }} />

                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigHome.fundo}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_background_color}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_background_color: e.target.value } }) }}
                            />
                            {/* <label>{configControl.homereviews_background_color}</label> */}
                            <InputTextColor type='text' value={configControl.homereviews_background_color} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_background_color: e.target.value } }) }} />

                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigTema.navegacao}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_arrow_color}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_arrow_color: e.target.value } }) }}
                            />
                            <InputTextColor type='text' value={configControl.homereviews_arrow_color} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_arrow_color: e.target.value } }) }} />
                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigTema.paginacao}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_pagination_color}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_pagination_color: e.target.value } }) }}
                            />
                            <InputTextColor type='text' value={configControl.homereviews_pagination_color} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_pagination_color: e.target.value } }) }} />
                        </span>
                    </Item>

                    {/* <Item>
                        <label>Seta</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_arrow_color}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_arrow_color: e.target.value } }) }}
                            />
                            <label>{configControl.homereviews_arrow_color}</label>
                        </span>
                    </Item>

                    <Item>
                        <label>Step</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_step_color}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_step_color: e.target.value } }) }}
                            />
                            <label>{configControl.homereviews_step_color}</label>
                        </span>
                    </Item> */}

                </BoxColors>

                <BoxColors>
                    <label>Card:</label>
                    <Item>
                        <label>{translation.thema.boxConfigHome.fundo}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_background_color_card}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_background_color_card: e.target.value } }) }}
                            />
                            {/* <label>{configControl?.homereviews_color_title}</label> */}
                            <InputTextColor type='text' value={configControl.homereviews_background_color_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_background_color_card: e.target.value } }) }} />

                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigTema.borda}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_color_border_card}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_color_border_card: e.target.value } }) }}
                            />
                            <InputTextColor type='text' value={configControl.homereviews_color_border_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_color_border_card: e.target.value } }) }} />
                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigTema.fonte}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_font_color_card}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_font_color_card: e.target.value } }) }}
                            />
                            <InputTextColor type='text' value={configControl.homereviews_font_color_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_font_color_card: e.target.value } }) }} />

                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.configs.estrelas}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_color_star_card}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_color_star_card: e.target.value } }) }}
                            />
                            <InputTextColor type='text' value={configControl.homereviews_color_star_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_color_star_card: e.target.value } }) }} />

                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigTema.iconeVerificado}</label>
                        <span>
                            <input
                                type={"color"}
                                value={configControl.homereviews_color_verified_card}
                                onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_color_verified_card: e.target.value } }) }}
                            />
                            <InputTextColor type='text' value={configControl.homereviews_color_verified_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_color_verified_card: e.target.value } }) }} />

                        </span>
                    </Item>

                </BoxColors>

                <ButtonHelp>
                    <img src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons>
                    <ButtonReset onClick={() => getDefaultTheme()}>
                        <label>{translation.thema.boxConfigEmail.restaurar}</label>
                        {/* <img src={IconReset} /> */}
                    </ButtonReset>
                    <ButtonSave onClick={() => saveTheme()}>
                        {translation.thema.boxConfigEmail.salvar}
                    </ButtonSave>
                </BoxButtons>

            </Container>
        </Main>

    )
}

export default BarConfigHomeColor