import React, { useState, useEffect } from 'react'
import { BarSubmenu, BarSubmenuItem, BoxButtons, BoxColors, BoxTema, ButtonHelp, ButtonReset, ButtonSave, Container, Item, ItemOption, Main, MenuOption, SelectCustom, Subtitle, Title } from './styled'

import IconReset from "../../../../assets/imgs/icon-reset.svg";
import IconSeta from "../../../../assets/imgs/seta-back.svg";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { useSelector, useDispatch } from 'react-redux';
import { getTranslation } from '../../../../translations';

import { IoIosArrowBack } from "react-icons/io";

import IconImgProduct from "../../../../assets/imgs/icons-sidebar/submenu-product.svg";
import IconImgProductInativo from "../../../../assets/imgs/icons-sidebar/submenu-product-inativo.svg";
import IconImgEmail from "../../../../assets/imgs/icons-sidebar/submenu-email-inativo.svg";
import IconImgEmailAtivo from "../../../../assets/imgs/icons-sidebar/submenu-email-ativo.svg";
import IconImgHome from "../../../../assets/imgs/icon-homepage.svg";
import IconImgHomeAtivo from "../../../../assets/imgs/icon-homepage-active.svg";
import { useHistory, useLocation } from 'react-router';

import IconImgTeste from "../../../../assets/imgs/teste-00.svg";
import { InputTextColor } from '../bar-config-email-cores/styled';
import { Tooltip } from '../bar-config-product-ajustes/styled';

const BarConfigProduct = () => {
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const dispatch = useDispatch()

    const selectedMenu = useLocation();

    const history = useHistory()

    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)

    const [theme, setTheme] = useState("")

    const [layoutConfig, setLayoutConfig] = useState("")

    const [color_background_item, set_color_background_item] = useState("#FFFFFF")
    const [color_text, set_color_text] = useState("#000000")
    const [color_verified, set_color_verified] = useState("#000000")
    const [color_star_active, set_color_star_active] = useState("#FACC15")
    const [color_star_inactive, set_color_star_inactive] = useState("#cccccc")
    const [max_width, set_max_width] = useState("")





    const getDefaultTheme = () => {
        const themaDefault = {
            id: config.data.id,
            star_color_primary: "#DAA520",
            star_color_secondary: "#D3D3D3",
            font_color_primary: "#000000",
            font_color_secondary: "#FFFFFF",
            primary_color: "#000000",
            background_color_primary: "#FFFFFF",
            background_color_secondary: "#F1F1F1",
            border_card_color: "#000000",
            background_color_resume_primary: "#f2f2f2",
            background_color_resume_secondary: "#ffffff",
            layout_configs: "null",
            
        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })
    }


    useEffect(() => {
        switch (theme) {
            case "dark":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        star_color_primary: "#DAA520",
                        star_color_secondary: "#D3D3D3",
                        font_color_primary: "#FFFFFF",
                        font_color_secondary: "#FFFFFF",
                        primary_color: "#808080",
                        background_color_primary: "#000000",
                        background_color_secondary: "#000000",
                        border_card_color: "#ffffff"
                    }
                })
                break;
            case "light":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        star_color_primary: "#DAA520",
                        star_color_secondary: "#D3D3D3",
                        font_color_primary: "#000000",
                        font_color_secondary: "#FFFFFF",
                        primary_color: "#000000",
                        background_color_primary: "#FFFFFF",
                        background_color_secondary: "#F1F1F1",
                        border_card_color: "#000000"
                    }
                })
                break;
            default:
                break;
        }
    }, [theme])

    useEffect(() => {
        if (
            configControl.star_color_primary == "#DAA520" &&
            configControl.star_color_secondary == "#D3D3D3" &&
            configControl.font_color_primary == "#000000" &&
            configControl.font_color_secondary == "#FFFFFF" &&
            configControl.primary_color == "#000000" &&
            configControl.background_color_primary == "#FFFFFF" &&
            configControl.background_color_secondary == "#F1F1F1" &&
            configControl.border_card_color == "#000000"
        ) {
            setTheme("light")
        }
        else if (
            configControl.star_color_primary == "#DAA520" &&
            configControl.star_color_secondary == "#D3D3D3" &&
            configControl.font_color_primary == "#FFFFFF" &&
            configControl.font_color_secondary == "#000000" &&
            configControl.primary_color == "#808080" &&
            configControl.background_color_primary == "#000000" &&
            configControl.background_color_secondary == "#000000" &&
            configControl.border_card_color == "#ffffff"
        ) {
            setTheme("dark")
        }
        else {
            setTheme("custom")
        }
    }, [configControl])





    const isSaveProductColor = () => {

        if (
            config.data.star_color_primary == configControl.star_color_primary &&
            config.data.star_color_secondary == configControl.star_color_secondary &&
            config.data.font_color_primary == configControl.font_color_primary &&
            config.data.font_color_secondary == configControl.font_color_secondary &&
            config.data.primary_color == configControl.primary_color &&
            config.data.background_color_primary == configControl.background_color_primary &&
            config.data.background_color_secondary == configControl.background_color_secondary &&
            config.data.border_card_color == configControl.border_card_color
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_COLOR_SET", payload: false })
            return false
        }
    }


    useEffect(() => {

        // const data = {
        //     color_background_item: color_background_item,
        //     color_text: color_text,
        //     color_star_active: color_star_active,
        //     color_star_inactive: color_star_inactive,
        //     color_verified: color_verified
        // }

        // console.log("teste data", data)

        // if (
        //     color_background_item == "" ||
        //     color_text == "" ||
        //     color_star_active == "" ||
        //     color_star_inactive == "" ||
        //     color_verified
        // ) {

        // } else {
        //     const data = {
        //         color_background_item: color_background_item,
        //         color_text: color_text,
        //         color_star_active: color_star_active,
        //         color_star_inactive: color_star_inactive,
        //         color_verified: color_verified
        //     }
        //     dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify(data) } })
        // }
    }, [color_background_item, color_text, color_star_active, color_star_inactive, color_verified])


    useEffect(() => {

        console.log("effect config", configControl)
        try {
            if (configControl?.layout_configs != "null") {
                const data = JSON.parse(configControl?.layout_configs)
                // set_color_background_item(data.color_background_item)
                // set_color_text(data.color_text)
                set_color_star_active(data.color_star_active)
                set_color_star_inactive(data.color_star_inactive)
                set_color_verified(data.color_verified)
                set_max_width(data.max_width)
            } else {
                // set_color_background_item("#FFFFFF")
                // set_color_text("#000000")
                set_color_star_active("#FACC15")
                set_color_star_inactive("#CCCCCC")
                set_color_verified("#000000")
                set_max_width("")

            }
        } catch (error) {

        }



        // console.log("configControl", configControl?.layout_configs)
        // if (configControl?.layout_widget == "modelo04" &&
        //     configControl?.layout_configs != "" &&
        //     configControl?.layout_configs != undefined
        // ) {
        //     const data = JSON.parse(configControl?.layout_configs)
        //     data.color_background_item != color_background_item && set_color_background_item(data.color_background_item)
        //     data.color_text != color_text && set_color_text(data.color_text)
        //     data.color_star_active != color_star_active && set_color_star_active(data.color_star_active)
        //     data.color_star_inactive != color_star_inactive && set_color_star_inactive(data.color_star_inactive)
        //     data.color_verified != color_verified && set_color_verified(data.color_verified)
        // }
    }, [configControl])

    const saveTheme = () => {

        console.log("configControl", configControl)
        if (configControl?.layout_widget == "modelo04") {
            dispatch({
                type: "SAGA_UPDATE_CONFIG", payload: {
                    id: config.data?.id,
                    layout_configs: configControl.layout_configs,
                }
            })
        } else {
            dispatch({
                type: "SAGA_UPDATE_CONFIG", payload: {
                    id: config.data?.id,
                    star_color_primary: configControl.star_color_primary,
                    star_color_secondary: configControl.star_color_secondary,
                    font_color_primary: configControl.font_color_primary,
                    font_color_secondary: configControl.font_color_secondary,
                    primary_color: configControl.primary_color,
                    background_color_primary: configControl.background_color_primary,
                    background_color_secondary: configControl.background_color_secondary,
                    border_card_color: configControl.border_card_color,
                    background_color_resume_primary: configControl.background_color_resume_primary,
                    background_color_resume_secondary: configControl.background_color_resume_secondary
                }
            })
        }



    }

    const changeData = (item, value) => {
        const data = {
            // color_background_item: color_background_item,
            // color_text: color_text,
            color_star_active: color_star_active,
            color_star_inactive: color_star_inactive,
            color_verified: color_verified,
            max_width: max_width,

        }
        // if (item == "color_background_item") {
        //     data["color_background_item"] = value
        //     set_color_background_item(value)
        // } else if (item == "color_text") {
        //     data["color_text"] = value
        //     set_color_text(value)
        // }
        // else 
        if (item == "color_star_active") {
            data["color_star_active"] = value
            set_color_star_active(value)
        }
        else if (item == "color_star_inactive") {
            data["color_star_inactive"] = value
            set_color_star_inactive(value)
        }
        else if (item == "color_verified") {
            data["color_verified"] = value
            set_color_verified(value)
        }else if (item == "max_width") {
            data["max_width"] = value
            set_max_width(value)
        }

        dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify(data) } })
    }

    return (
        <Main>
            {/* <BarSubmenu isFull={false}>
                <BarSubmenuItem
                    active={true}
                    style={{position: "relative"}}
                    onClick={() => { isSaveProductColor() && history.push("/personalizar/tema") }}
                >
                    {selectedMenu.pathname.slice(13) == "/" || selectedMenu.pathname.slice(13) == "" || selectedMenu.pathname.slice(13) == "/tema" ?
                        <img width={24} src={IconImgProduct} /> :
                        <img width={24} src={IconImgProductInativo} />}
                    <Tooltip>
                        Página do produto
                    </Tooltip>

                </BarSubmenuItem>
                <BarSubmenuItem
                    active={false}
                    style={{position: "relative"}}
                    onClick={() => { isSaveProductColor() && history.push("/personalizar/email") }}
                >
                    {selectedMenu.pathname.slice(13) == "/email" ?
                        <img width={24} src={IconImgEmailAtivo} /> :
                        <img width={24} src={IconImgEmail} />}

                    <Tooltip>
                        E-mail
                    </Tooltip>

                </BarSubmenuItem>

                <BarSubmenuItem
                    active={false}
                    style={{position: "relative"}}
                    onClick={() => { isSaveProductColor() && history.push("/personalizar/home") }}
                >
                    {selectedMenu.pathname.slice(13) == "/home" ?
                        <img width={24} src={IconImgHomeAtivo} /> :
                        <img width={24} src={IconImgHome} />}

                    <Tooltip>
                        Página do inicial
                    </Tooltip>

                </BarSubmenuItem>

            </BarSubmenu> */}

            <Container>
                <Title>
                    {/* <span onClick={() => { isSaveProductColor() && history.push("/personalizar") }}>
                        <IoIosArrowBack />
                    </span> */}

                    <label>{translation.thema.boxConfigTema.title} </label>
                </Title>

                {/* <Subtitle>
                {translation.thema.boxConfigTema.paginaProduto}
            </Subtitle> */}

                <MenuOption>
                    <ItemOption active={true} onClick={() => {
                        isSaveProductColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "produto/cores" })
                    }}>
                        {translation.thema.boxConfigTema.cores}
                    </ItemOption>
                    <ItemOption active={false} onClick={() => {
                        isSaveProductColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "produto/config" })
                    }}>
                        {translation.thema.boxConfigTema.avancado}
                    </ItemOption>
                </MenuOption>

                {
                    configControl.layout_widget == "modelo04" ?
                        <BoxColors>
                            <label>{"Cores"}:</label>
                            {/* <Item>
                                <label>{"Fundo item"}</label>
                                <span>
                                    <input type={"color"} value={color_background_item} onChange={(e) => { changeColors("color_background_item", e.target.value) }} />
                                    <InputTextColor type='text' value={color_background_item} onChange={(e) => { changeColors("color_background_item", e.target.value) }} />
                                </span>
                            </Item>
                            <Item>
                                <label>{"Texto"}</label>
                                <span>
                                    <input type={"color"} value={color_text} onChange={(e) => { changeColors("color_text", e.target.value) }} />
                                    <InputTextColor type='text' value={color_text} onChange={(e) => { changeColors("color_text", e.target.value) }} />
                                </span>
                            </Item> */}
                            <Item>
                                <label>{"Estrela ativa"}</label>
                                <span>
                                    <input type={"color"} value={color_star_active} onChange={(e) => { changeData("color_star_active", e.target.value) }} />
                                    <InputTextColor type='text' value={color_star_active} onChange={(e) => { changeData("color_star_active", e.target.value) }} />
                                </span>
                            </Item>
                            <Item>
                                <label>{"Estrela inativa"}</label>
                                <span>
                                    <input type={"color"} value={color_star_inactive} onChange={(e) => { changeData("color_star_inactive", e.target.value) }} />
                                    <InputTextColor type='text' value={color_star_inactive} onChange={(e) => { changeData("color_star_inactive", e.target.value) }} />
                                </span>
                            </Item>
                            <Item>
                                <label>{"Icone verificado"}</label>
                                <span>
                                    <input type={"color"} value={color_verified} onChange={(e) => { changeData("color_verified", e.target.value) }} />
                                    <InputTextColor type='text' value={color_verified} onChange={(e) => { changeData("color_verified", e.target.value) }} />
                                </span>
                            </Item>
                        </BoxColors> :
                        <>
                            <BoxTema>
                                <label>{translation.thema.boxConfigTema.combinacaoCores.toUpperCase()}:</label>
                                <SelectCustom name="select_theme_reviews" onChange={(event) => { setTheme(event.target.value) }}                        >
                                    <option value="light" selected={theme == "light" ? true : false}>Light</option>
                                    <option value="dark" selected={theme == "dark" ? true : false}>Dark</option>
                                    <option disabled value="custom" selected={theme == "custom" ? true : false}>{translation.thema.boxConfigTema.personalizado}</option>
                                </SelectCustom>

                            </BoxTema>

                            <BoxColors>
                                <label>{translation.thema.boxConfigTema.corTema}:</label>
                                <Item>
                                    <label>{translation.thema.boxConfigTema.corPrincipal}</label>
                                    <span>
                                        {/* <div />
                        <label>#000000</label> */}
                                        <input type={"color"} value={configControl.primary_color} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { primary_color: e.target.value } }) }} />
                                        {/* <label>{configControl.primary_color}</label> */}
                                        <InputTextColor type='text' value={configControl.primary_color} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { primary_color: e.target.value } }) }} />
                                    </span>
                                </Item>

                                <Item>
                                    <label>{translation.thema.boxConfigTema.corFundoPrincipal}</label>
                                    <span>
                                        <input type={"color"} value={configControl.background_color_primary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_primary: e.target.value } }) }} />
                                        {/* <label>{configControl.background_color_primary}</label> */}
                                        <InputTextColor type='text' value={configControl.background_color_primary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_primary: e.target.value } }) }} />

                                    </span>
                                </Item>

                                <Item>
                                    <label>{translation.thema.boxConfigTema.corFundoAuxiliar}</label>
                                    <span>
                                        <input type={"color"} value={configControl.background_color_secondary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_secondary: e.target.value } }) }} />
                                        {/* <label>{configControl.background_color_secondary}</label> */}
                                        <InputTextColor type='text' value={configControl.background_color_secondary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_secondary: e.target.value } }) }} />

                                    </span>
                                </Item>

                                <Item>
                                    <label>{translation.thema.boxConfigTema.corEstrelaAtiva}</label>
                                    <span>
                                        <input type={"color"} value={configControl.star_color_primary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { star_color_primary: e.target.value } }) }} />
                                        {/* <label>{configControl.star_color_primary}</label> */}
                                        <InputTextColor type='text' value={configControl.star_color_primary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { star_color_primary: e.target.value } }) }} />

                                    </span>
                                </Item>

                                <Item>
                                    <label>{translation.thema.boxConfigTema.corEstrelaInativa}</label>
                                    <span>
                                        <input type={"color"} value={configControl.star_color_secondary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { star_color_secondary: e.target.value } }) }} />
                                        {/* <label>{configControl.star_color_secondary}</label> */}
                                        <InputTextColor type='text' value={configControl.star_color_secondary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { star_color_secondary: e.target.value } }) }} />

                                    </span>
                                </Item>

                                <Item>
                                    <label>{translation.thema.boxConfigTema.corPrincipalTexto}</label>
                                    <span>
                                        <input type={"color"} value={configControl.font_color_primary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_color_primary: e.target.value } }) }} />
                                        {/* <label>{configControl.font_color_primary}</label> */}
                                        <InputTextColor type='text' value={configControl.font_color_primary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_color_primary: e.target.value } }) }} />

                                    </span>
                                </Item>

                                <Item>
                                    <label>{translation.thema.boxConfigTema.corAuxiliarTexto}</label>
                                    <span>
                                        <input type={"color"} value={configControl.font_color_secondary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_color_secondary: e.target.value } }) }} />
                                        {/* <label>{configControl.font_color_secondary}</label> */}
                                        <InputTextColor type='text' value={configControl.font_color_secondary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_color_secondary: e.target.value } }) }} />
                                    </span>
                                </Item>

                                <Item>
                                    <label>{translation.thema.boxConfigTema.borda}</label>
                                    <span>
                                        <input type={"color"} value={configControl.border_card_color} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { border_card_color: e.target.value } }) }} />
                                        {/* <label>{configControl.border_card_color}</label> */}
                                        <InputTextColor type='text' value={configControl.border_card_color} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { border_card_color: e.target.value } }) }} />
                                    </span>
                                </Item>

                                <Item>
                                    <label>{translation.thema.boxConfigTema.fundoResumo}</label>
                                    <span>
                                        <input type={"color"} value={configControl.background_color_resume_primary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_resume_primary: e.target.value } }) }} />
                                        {/* <label>{configControl.background_color_resume_primary}</label> */}
                                        <InputTextColor type='text' value={configControl.background_color_resume_primary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_resume_primary: e.target.value } }) }} />
                                    </span>
                                </Item>

                                <Item>
                                    <label>{translation.thema.boxConfigTema.fundoAuxResumo}</label>
                                    <span>
                                        <input type={"color"} value={configControl.background_color_resume_secondary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_resume_secondary: e.target.value } }) }} />
                                        {/* <label>{configControl.background_color_resume_secondary}</label> */}
                                        <InputTextColor type='text' value={configControl.background_color_resume_secondary} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_resume_secondary: e.target.value } }) }} />
                                    </span>
                                </Item>

                            </BoxColors>
                        </>
                }



                <ButtonHelp>
                    <img src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons>
                    <ButtonReset onClick={() => getDefaultTheme()}>
                        <label>{translation.thema.boxConfigTema.restaurar}</label>
                        {/* <img src={IconReset} /> */}
                    </ButtonReset>
                    <ButtonSave onClick={() => { saveTheme() }}>
                        {translation.thema.boxConfigTema.salvar}
                    </ButtonSave>
                </BoxButtons>

            </Container >
        </Main>

    )
}

export default BarConfigProduct