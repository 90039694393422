import { SparklesIcon } from '@heroicons/react/16/solid';
import ItemIcon from './ItemIcon';
import ItemText from './ItemText';
import { motion } from 'framer-motion';
import { Button } from './Button';
import { EmpreenderApps } from '../lib/AppsArray';

interface btnSuporteProps {
	app: string;
}

const ButtonSuporte = ({ app }: btnSuporteProps) => {
	const list = {
		visible: { opacity: 1 },
		hidden: { opacity: 1 },
	};

	const item = {
		visible: { rotate: 0 },
		hidden: { rotate: 180 },
	};

	const scaleItem = {
		visible: { scale: 1.1 },
		hidden: {
			scale: 1,
		},
	};

	const appObj = EmpreenderApps.find((item) => item.tags.some((tag) => tag.toLowerCase() === app.toLowerCase()));

	return (
		<Button asChild className="cursor-pointer z-[0] justify-start w-full px-1.5 gap-3">
			<motion.a href={`http://suporte.com.br?app=${appObj?.eplus}`} target="_blank" initial="hidden" whileHover="visible" variants={list}>
				<ItemIcon>
					<div className="relative">
						<motion.div
							transition={{
								type: 'spring',
								bounce: 0.5,
							}}
							variants={item}
							className="absolute w-full h-full rounded-full !m-0"
							style={{ background: 'linear-gradient(180deg, #86DDFA 0%, #6B56A6 20.5%, #D97BF7 53%, #F0CF83 74%, #95F3DD 93%)' }}></motion.div>
						<div className="!m-0 absolute z-[1] top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 bg-white group-hover:bg-gray-100 h-[calc(100%-3px)] w-[calc(100%-3px)] rounded-full flex items-center justify-center">
							<motion.div
								className="!m-0"
								transition={{
									type: 'spring',
									bounce: 0.5,
								}}
								variants={scaleItem}>
								<SparklesIcon width={10} height={10} />
							</motion.div>
						</div>
					</div>
				</ItemIcon>
				<ItemText>Suporte</ItemText>
			</motion.a>
		</Button>
	);
};

export default ButtonSuporte;
