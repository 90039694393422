const AutoMagicoIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Camada_1" width="22" height="22"  x={0} y={0} viewBox="0 0 3000 3000" {...props}>
		<style>{'.automagicofill{fill:#080413}.automagicofill4{fill:#fefefe}'}</style>
		<path d="M2412.3 2261.5c-4.7-29-12.5-57.5-26-83.9-54.1-104.9-125.5-197.8-206.6-282.7-168.4-176.5-339-350.9-508.6-526.2-26.9-27.8-26.3-29.1 8.2-49.7 58.5-35.2 121.2-63.1 178.3-100.7 49-32.3 52.4-62.9 12-105.5-4.8-5-9.9-9.8-15-14.4-72.2-65.2-149.8-123.2-234.4-171.4-3.3-1.2-6.9-1.7-9.7-3.6-52.2-34.6-111.1-38.5-171.4-40.7-78.9-2.8-157.7-7-236.5.5-153.8 14.9-304.6 42-445.3 110.1-13.1 6.3-21.1 17.4-28.4 29.4-25.9 43.9-52.2 87.7-78.4 131.6-91.6 204.5-137.4 416.3-97.1 640.4 24.8 138.7 69.8 271.4 148.1 389.1 103.3 155.3 249 263 418.8 333.8 203.9 85 418.9 115.9 639.7 99.1 37.4-2.9 76.3-3.1 111.6-20.1 31.2-15.1 33.5-42.5 4.8-62.1-8.5-5.8-18.2-10-27.3-14.9-136.8-72.5-273.7-146.2-394.5-242.9-113.2-90.6-199.9-204-231.9-350.5-11.6-53.1-19.5-107-28.2-160.7-2.5-14.8-2-31.5 16.8-36.1 18.6-4.6 26.2 8.6 30.9 23.9 1.2 4.1 46.5 150.4 75.9 216.6 63.5 142.8 162.9 253 302.9 322.5 92.6 46 194.8 57.5 296.9 64.9 99 7 197.7.5 296-10.5 45.6-5.1 91.1-10.4 136.6-16.9 41.4-5.8 67.3-34.1 61.8-68.4z" className="automagicofill" />
		<path
			d="M1891.6 744.2c-9.7-27.7-49.5-36.4-94.5-29-59.8 9.8-180.1 39.2-197.2 36.2-39.7-7.2-153.7-101.9-219.2-173.6-48-52.5-94.5-106.4-141.6-159.8-105.4-119.8-229.8-212-381.2-266.4-17.3-6.2-34.6-11.7-52-16.8L1311.6 879c42.4.3 84.9 2.2 127.3 3.7 60.3 2 119.2 5.9 171.4 40.7 2.8 1.8 6.4 2.5 9.7 3.6 75-31.3 150.3-61.8 222.8-98.4 41.7-21.2 57.4-59.7 48.8-84.4z"
			style={{
				fill: '#fe4f02',
			}}
		/>
		<path
			d="M368.4 113.8c-68.4 10-132.2 34.9-184.8 82.1-17 15.2-31.8 33.2-23.4 58.6 8.1 24.8 24.5 29.5 65.8 22.5 40.6-6.9 81.1-6.4 120.8 5.5 129.8 39 205.9 132.5 251.8 253.8C640.3 646.6 634 761.1 617.5 875c-6 41.3-24.5 79.7-66.9 95.2-45.5 16.7-89.9 36.6-137.3 47.5-19.8 4.6-37.3 13.9-51.6 28.5-18.8 19.2-23.2 42.7-16.2 67.5 6.5 23.2 25.7 34.1 47.8 39.3 18.6 4.4 37.6 5.7 56.7 6.1 66.9 1.3 133.6-2.9 200.3-5.1 26.2-43.8 52.3-87.6 78.4-131.6 7.2-12.2 15.2-23.1 28.4-29.4 140.7-68.2 291.4-95.2 445.3-110.1 36.4-3.6 72.7-4.5 109.2-4.3L806 134.7c-143.5-41.2-289.7-42.4-437.6-20.9z"
			style={{
				fill: '#ce1b02',
			}}
		/>
		<path d="M1847.4 2440.5c-12.2-7.9-6-26.8 8.4-25.8 20.8 1.3 41.5 2.7 62.1 4.6 108.7 10 216.4-1.5 323.8-15.2 18.9-2.5 35.1.7 52 9.2 145 72.4 294.7 135.1 436.5 214.1 27.4 15.2 53.1 32.7 75.5 54.8 50 49.5 48.9 107.7-4.4 152.8-78.8 66.8-165.7 86-262 39.7-96.7-46.6-186.5-105.1-276.6-163-139-89.2-276.7-180.4-414.9-270.8-.2-.3-.3-.3-.4-.4z" className="automagicofill" />
		<path d="M1320.5 1089.4c1.1 60.4-22.2 89.5-70.8 96-48.9 6.6-79.9-14.7-92.7-65.4-4-15.7-6.2-32.3-6.7-48.6-2.2-63.8 40-100.3 103.1-89.6 24 4.1 40.6 18.3 51 39.8 11.3 24 16 49.3 16.1 67.8zM934.4 586.5c59.6-57.4 60.2-142.9 27.9-220.6 68.3 65.5 132.4 100 206.5 79.7-52.3 60-34 129.9-1.1 221.6-59.5-71-160.7-112.7-233.3-80.7z" className="automagicofill4" />
	</svg>
);
export default AutoMagicoIcon;
