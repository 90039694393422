const WidgetInstagramIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Camada_4" x={0} y={0} width={22} height={22} viewBox="0 0 3000 3000" {...props}>
		<style>{'.widgetInsta1{fill:#080413}.widgetInsta2{fill:#fefefe}.widgetInsta6{fill:#fbfbfb}'}</style>
		<path d="M1686.3 2780.6c-5.5 3.8-10.7 7.1-15.8 10.6-125.2 73.7-256.8 123.3-405.8 104.9-123.4-15.3-239.8-54.8-354.5-100.7-126.1-50.6-216.1-136.9-265.5-264-16.8-43.2-20.3-89.6-29.6-134.5-18.9-91-20.4-184-32.2-275.7-13.6-105.4-14.8-211.2-19.2-316.8-6.4-151.6 3.2-302.4 19.5-453.3 11.2-103.5 37-202.8 66.7-301.8 9.3-31 21.8-60.4 38.1-88.5 13.3-23 9.6-49.8 11.2-74.9 10.1-156.7 18.3-313.5 36.1-469.6 3.6-31.9 9-63.6 15.1-95.2 15-78 64.3-107.3 141.5-84 63.3 19.1 101.3 67.7 137.5 119 55.8 79.1 56.7 166.3 40.2 256.2-8.7 47.4-19.2 94.5-29.9 146.9 45.6-31.9 97.5-56.6 149.5-60 47.6-3.1 118.4 5.8 118.4 5.8 3.2-3.4 75.2-82.1 193.6-90.3 59.5-3.5 116.2 6.9 169.5 30.6 80 30.4 137.3 80.9 137.3 80.9s56.4-.5 80.1 2.8c45.9 6.1 82.8 13 127.1 27.7 86.1 28.5 149.3 88 188.3 169.5 38.8 81.1 77.2 162.2 107.3 247.1 4.6 13 12.5 25 18.6 37.6 10.6 21.8 28.7 27.6 51.3 25.7 38.5-3.3 54.3 18.9 36.8 53.5-22.9 45.7-51.6 101.9-97.5 126.8-13.2 7.2-5.6 20.7-1.3 31.1 65.1 161.7 83.1 309.5 105.4 481.1 20.2 156 20.3 311.5-6.3 466.6-10.4 60.1-27.9 118.5-55.4 173.2-60.3 120.3-162.9 180.3-292.1 198.1-78.8 10.8-158.3 12.2-236.8-4.5-55.8-11.9-110.2-31.6-155.2-65.7-121.5-92.3-259.1-149.9-400.3-203.5-164-62.3-304.5-161.2-411.8-301.8C798 2102 748 2004.2 700.8 1902.7c-5.7-12.3-16.6-34.1-24.8-50.4-2.2-4.3-8.7-2.3-8.2 2.5 5.3 43.2 14.7 81.5 24.3 119.6 43.5 174.3 150 305.5 286.4 416 111.5 90.4 239.1 150.5 373.5 197.5 82.8 28.9 161.5 64.7 231 119.5 31.1 24.5 66.6 45.9 102.9 65.3 2.9 1.8 3.2 5.9.4 7.9z" className="widgetInsta1" />
		<path d="M2360 333.8c1.2 74.1-19 136.2-61.2 191.4-45.8 60-93.5 117.7-152.1 166.2-29 24-57.9 33.2-95.6 22.8-76.2-21-153.2-38.7-232-48.2-37.5-4.5-49.8-30.9-27.8-58.1 127.4-157.5 254-315.9 391-465.3 4.5-4.9 9-9.7 13.7-14.4 46-45.6 101.7-35 126.6 24.8 15.2 36.6 22.9 75.4 30.8 114.2 4.7 23.7 5.6 47.5 6.6 66.6z" className="widgetInsta1" />
		<path
			d="M1340.3 733c63.2-58.7 135.3-96.5 224.5-79.7 85.3 16 159.7 56.4 225.8 112.4 73.3 62.2 110.1 142.7 122.9 235.7 3.2 22.8 9 59.1 11.3 80.9 1.2 10.9.2 13.7-6.6 18.3-62.7 38.3-137 82.4-193.2 130.6-61.9 53-95.3 119.3-99.5 200.2-.8 13.9.4 53.8.4 53.8-1.8 1-4.6.7-9-1.7-140.1-77-268.3-165.6-316.4-330.4-35.7-105.8-36.8-205-35.6-254.3 1.6-72.9 38.8-132 75.4-165.8z"
			style={{
				fill: '#fa175f',
			}}
		/>
		<path
			d="M1273.4 739.2c4.6.4 7.2 5.6 4.7 9.5-24.5 38.6-40.6 75.4-47.3 116.3-13.1 79.9.2 157.4 17.5 235.4 36.6 164.9 128 289.5 274.3 371.9 27.9 17.8 81.8 46.7 84.1 48.5 3 2.3 2.7 6.9-.6 8.7-42.9 25.1-270.3 72.3-394.5 33.9-52.8-16.4-104.5-34.3-153.3-61.2-75.6-41.7-121-106.1-143.1-187.1-32.3-118.1-32.1-235.4 18.4-349.4 21.1-47.6 51-89.4 84.3-129.7 35.2-42.7 75.1-75.9 129.3-90.2 40.4-10.7 80.6-10.5 126.2-6.6z"
			style={{
				fill: '#cb01e3',
			}}
		/>
		<path
			d="M2371.9 1273.8c5.9.3 9.4 6.8 6.4 12-27.1 47-59.1 92.4-115.6 103.1-46.3 8.8-92 18.2-138.9-8.2-12.8-7.1-12.9-11.3-9.6-23.2 21-76.2 41.8-152.4 57.6-229.9 5.6-27.4 10.5-54.9 3.5-83.3-8-32.2-26.4-46.2-59.3-40.9-44.1 7.1-136.6 61.5-142 63.4-8.3 3.1-12.2 1.6-15.3-6-2-5.2-14.8-94.6-22.9-130.7-10.3-45.1-61.5-133.8-78.1-157.3-1.8-2.6.1-6.1 3.3-6 132.9 5 252.7 48 313.3 182.4 34.8 77.3 72.2 153.6 100.6 233.7 4.7 13.1 11 25.8 17.8 38 21.8 38.4 38.6 50.7 79.2 52.9z"
			style={{
				fill: '#f9b607',
			}}
		/>
		<path d="M1708 1902.2c-49.2-5.1-98.4-10.6-147.8-15.2-76.1-7-149.8 3.7-220 34.2-33.1 14.4-67.2 27.1-97.1 48.1-16.4 11.5-31.9 7-45.4-4.6-44.5-38.7-130.6-161.7-142.7-181.2-5.7-9.2 1.5-20.9 12.3-20 21.7 1.8 53.9 10 62.7 13.1 22.4 8.2 54.7 19.3 75.3 30.9 4.5 2.5 7.6 3.5 13.5 1.9 121.2-56.2 269.5-55.5 394-21.5 44.9 12.2 80.7 40.6 114.6 71.1 6.2 5.7 23.3 27.7 33 40.5 2.7 3.5-.2 8.5-4.5 8.1-14.6-1.7-39.3-4.4-47.9-5.4zM2056 1690.4c-.3-38.2 8.1-74.1 28.7-106.4 35.2-55.1 101.3-66 152.6-25.5 75.7 59.8 99.1 193.5 48.2 275.8-37.1 59.9-108.7 69.8-161.5 21.1-48.1-44.2-65.6-101.7-68-165zM2086.8 1286.7c3.5-1.2 6.9 1.7 6.2 5.4-6.9 33.8-15.7 64.3-26.4 94.3-4.8 13.4-15.1 19.5-25.5 27.2-123.5 90-246.3 87.4-368.6 11.1-9-5.6-9.8-11.5-8.3-20.2 7.9-45.8 27.1-85.5 58.3-120.2 16.3-18.2 32.1-22 54.2-11.5 63.6 30.1 130.4 44.6 201.2 39.7 36.8-2.7 71.9-12.4 108.9-25.8z" className="widgetInsta2" />
		<path d="M2120.4 1154.5c3.6-1 7 2.3 6.2 6-4.7 21.2-8.6 39-12.6 56.8-2.1 9.8-9.3 15.3-17.3 20.1-99.2 60.1-199.5 48.8-300.4 4.6-15.7-6.9-10.3-13.9-.6-20.2 38.7-25 77.7-49.6 116.6-74.4 6.8-4.3 13.2-3.8 21.1-1.8 60.5 15.5 121.3 25.9 187 8.9zM1674 1465c123.1 73.8 243.6 64.6 363.1-7.2 2.1-1.2 4.7.8 3.8 3.2-32.2 91.6-92.6 131.8-180 130.8-93.9-1.2-169.1-39.1-193.7-121.4-1.3-4.1 3.1-7.6 6.8-5.4z" className="widgetInsta2" />
		<path
			d="M1977.5 1117.9c-4-1.1-4.8-6.4-1.2-8.5 44-26.6 83.6-50 127.2-65 30.6-10.5 36.1-4.8 37.8 26.7 1.3 23.8-3.6 38.8-29.9 47.1-43.7 13.9-85.9 12-133.9-.3z"
			style={{
				fill: '#fefdfc',
			}}
		/>
		<path d="M2112.2 2446.6c-36.9-.6-68.9-13.1-94.4-40.3-7.6-8.1-12.2-18.5-8-29.5 4.7-12.2 16.7-16.3 28.3-16.2 36 .4 67.5 12.7 93.1 38.5 8.3 8.4 14 19.2 9.5 31.3-4.8 12.4-16.3 15.3-28.5 16.2zM2352.9 2308.5c-2.2 27.4-10.8 54.5-30.7 76.9-7.7 8.6-17.4 16.4-30.2 11.9-12.2-4.3-16.8-15.2-18-27.2-3.2-28.8 3-55.7 17.9-80.3 9.5-15.5 20.4-33.8 41.8-27.2 19.8 6.2 19 26.4 19.2 45.9z" className="widgetInsta6" />
		<path
			d="M2081.5 1474.6c-1.2.6-2.5-.4-2.2-1.6 6.1-22.3 12-40.2 17.8-57.1 1.1-3 2.7-8.7 8.3-5.9l29.6 15.9c3.4 2.6 1.5 6.4-1.8 9.3-7.9 6.8-46 36.4-51.7 39.4z"
			style={{
				fill: '#f4165d',
			}}
		/>
	</svg>
);
export default WidgetInstagramIcon;
