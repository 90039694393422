
import { ThemeProvider } from "styled-components";
import Routes from "./routers";
import GlobalStyle from "./styles/global";
import { ThemeDefault } from "./styles/themes/default";
import { ToastContainer } from 'react-toastify';
import { useEffect } from "react";
import { BoxBarApps } from "./components/Header/styled";
import { register } from 'swiper/element/bundle';


function App() {
  register();
  //
  // useEffect(() => {
  //   const script1 = document.createElement("script");
  //   script1.innerHTML = 'var ps_config = {workspace_id : "7efd6fdd-42f5-479d-b2d7-2bf5e61f0138"};'
  //   document.body.appendChild(script1);

  //   const script2 = document.createElement("script")
  //   script2.type = "text/javascript"
  //   script2.src = "https://cdn.productstash.io/js/widget.min.js?v=0.8";
  //   script2.defer = "defer"
  //   document.body.appendChild(script2);

  //   const script3 = document.createElement("script")
  //   script3.type = "text/javascript"
  //   script3.src = "https://empreender.nyc3.digitaloceanspaces.com/wcr/user_wfaqjs/619678452e2db.js";
  //   script3.defer = "defer"
  //   document.body.appendChild(script3);


  // }, [])
  return (
    <ThemeProvider theme={ThemeDefault}>
      <ToastContainer position="top-center" style={{ zIndex: "999999999999999999999999" }} />
      <GlobalStyle />
      {/* <BoxBarApps className="container__script_top_menu_lily"></BoxBarApps> */}
      <Routes />
    </ThemeProvider>
  );
}
export default App;
