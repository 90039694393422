const CampoPersonalizadoIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<g>
				<path
					className="jjiwiwiw"
					d="M1737.1,2579.4c0.8-0.2,2-0.4,3.3-0.6c12.5-2.1,19.4,13.9,9.4,21.6c-64.9,49.6-223,152.3-460.2,191.8
			c-107.2,16.6-215,25.4-324,18.6c-149.4-9.2-284.6-57.4-408.7-139.4c-222.3-146.9-366.5-348.9-422.7-609.9
			c-31.4-146-27.5-293.6-3.5-440.8c1.4-8.6,5.6-14.8,10.8-20.9c121.1-138.9,269.2-228.7,454.2-255.2c83.7-12,167.3-18.6,251.5-14.9
			c88.2,3.7,171.9,25.3,250.8,65.7c23.4,12,11.1,21.1-1,30.1c-61.5,45.8-114.5,99.8-160.8,160.9
			c-77.7,102.8-120.7,220.5-126.5,346.9c-14.5,311.6,175.9,574.4,492.9,638.7c29.7,6.1,59.6,12,89.6,15.4
			C1443.1,2593.3,1673.8,2592.8,1737.1,2579.4z"
				/>
				<path
					className="jjiwiwiw"
					d="M1637.5,1257.9c0.5,12.9-4.4,16.3-17.7,15.1c-134.8-11.5-264.6,8.5-387.9,65.1c-14.2,6.5-24.9,4.8-37.6-1.7
			c-81.6-42-165.2-78-257.1-92.2c-76.6-11.8-153.5-12.6-230.2-7.2c-105.2,7.4-208.8,24.7-308.2,62.2c-46.3,17.5-148.3,72.4-192,96.3
			c-7,3.8-15-3.2-12.1-10.6c24.6-63.2,98.1-246.1,154.3-326.1C443.3,924.4,544.2,795.2,680.5,700C830.8,595.1,999,533,1179,501.1
			c92.4-16.4,185-18,278.3-11c15.8,1.2,20.8,9.1,26.3,20.8c43.9,92.9,68.8,192,97.1,290C1617.8,929,1631.5,1090,1637.5,1257.9z"
				/>
				<path
					className="jjiwiwiw"
					d="M1236.4,1460.2c-7.3-0.9-9.5-10.5-3.2-14.5c46.2-28.7,91.7-48,140.9-58.9c140.2-30.8,290.3-43.7,425.6,9.7
			c116.6,46,171.1,95.1,243.2,197.4c28.9,41,66,129.7,72.1,178c6.6,52.2,11.4,123.6,11.4,192.7c-12.3,178.9-64.1,294.2-170.4,431.6
			c-8.9,11.4-20,19.2-34,24.5c-65.9,24.8-130.3,53.2-200.5,66.3c-79.8,14.8-156.6,23.6-240.4,14.5c-61.2-6.7-164.9-34.8-236.8-57.5
			c-18.1-5.8-15.3-32.1,3.6-34c0.2,0,0.3,0,0.5,0c286.8-28.4,503.6-280.1,536.9-492.9c16.2-103.7-1.6-204.4-57.2-294.4
			c-52-84.2-133.1-122.1-230.3-126.4c-31.2-1.4-62.8-2.8-93.8,3.6c-9.5,2-17.9-0.9-26.2-3.7
			C1332.7,1481.4,1287.7,1466.4,1236.4,1460.2z"
				/>
				<g>
					<path
						className="jjiwiwiw"
						d="M2858.1,1090.6c-62.6-240.6-171.5-426.9-363.3-604.3c-153.9-129.8-272.7-181-427.7-259.2
				c-109.1-55.1-259.9-98.6-380.9-121.4c-80.2-15-115.8-15.5-147.2,81.8c-15.7,48.4-20.4,96.8-18.9,147.4
				c2.5,23.4,0.6,48.2,13.2,70.5c47.1,83.5,79.6,172.7,105.9,264.6c33.2,115.6,67.3,230.8,79.8,351.2c8,77.6,10.6,155.3,14.5,233.2
				c1,19.1,4.5,37.3,25.2,42.3c110.4,26.8,200.1,89.9,283.5,162.8c86.9,75.8,147.1,167.6,164.5,284.4c9,60,14.3,62.2,75.1,64.7
				c62.7,2.6,125.3,7.4,188.1,5.1c35,0,70,1.1,104.9-0.1c90.7-3.6,179.3-17.7,262.2-57.4c19-9.2,35.7-19.7,42.9-41.3
				c12.6-38.1,22.6-77,26.4-116.7C2922.5,1426.1,2901.5,1257.5,2858.1,1090.6z M2406.6,1396.5c-154.8,0-280.1-125.4-280.1-280.1
				s125.4-280.1,280.1-280.1c154.8,0,280.1,125.4,280.1,280.1S2561.3,1396.5,2406.6,1396.5z"
					/>
				</g>
				<circle className="jjiwiwiw" cx="2404.8" cy="1116.4" r="169.3" />
				<path
					className="jjiwiwiw"
					d="M1226.3,1591.8c-16.2,7.3-31.3,17.1-47.4,24.8c-9.5,4.6-23.2,12-30.8,3.1c-7.2-8.5,4.1-23,7.9-29.3
			c5.6-9.1,13-17.1,18.6-26.1c10.4-16.5,4.6-27.4-15-26.9c-27.9,0.7-51.3,12.5-71.1,31.7c-10.2,6-18.4,13.9-26.2,22.7
			c-0.6,0.7-1.2,1.4-1.8,2.1v0.2C962.8,1705.4,923,1793.7,916,1938.9c-1.4,29,5.9,105.1,15.4,133.3c3.8,11.2,10.4,20.5,22.5,18.9
			c12.1-1.7,15.2-13,16.7-24.3c14.1-107.6,47.2-147.8,90.2-184.6v-0.2c10.5-9.1,21.6-17.8,33.2-27.4c22.6-14.3,47.3-26.2,67.5-43.4
			c58.4-49.5,105.1-107.2,110.3-188.1C1274.2,1587.6,1258.7,1577.1,1226.3,1591.8z"
				/>
			</g>
		</g>
		<g id="Camada_3"></g>
	</svg>
);
export default CampoPersonalizadoIconBranco;
