import { ActionsSelect, Container, Filters, PaginateInfor, PaginateNav, Search, ListCard, Paginate, ContainerInfor, BoxMessage, ActionsDropdown, DateFilter, ItemDateFilter, OptionsUpdate, MenuOption } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { AiOutlineSearch, AiFillCheckSquare } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";
import Card from "../../components/card-pedidos";
import { useDispatch, useSelector } from "react-redux";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { HiOutlineBadgeCheck, HiBadgeCheck } from "react-icons/hi";
import { isVisibleScript } from "../../../../utils/displayScript";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AiFillHeart, AiOutlineHeart, AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import { IoIosArrowDown, IoMdArrowDropdown, IoMdTrash } from "react-icons/io";
import { getTranslation } from "../../../../translations";
import LoadingLogoLily from "../../../../components/Loading-logo-lily";
import { BsHash } from "react-icons/bs";

import { RxUpdate } from "react-icons/rx";

import ModalSearchPlatform from '../../components/modal-search-platform'
import ModalSearchAllPlatformDefault from '../../components/modal-search-all-platform-default'
import HelpClick from "../../../../components/HelpClick";
import Layout from "../../../../components/tailwind/layout";
import { ContainerLoading } from "../../../reviews/pages/home-new/styled";
import LoadingLogoLilyNew from "../../../../components/Loading-logo-lily-new";
import LayoutNew from "../../../../components/tailwind/layout-new";
import TableOrders from "../../components/tailwind/table";
import PageIntegration from "../../../../components/page-clean";
import ModalInforVideo from "../../../../components/modal-infor-video";
import LayoutUI from "../../../../components/tailwind/layout-ui";

const Home = () => {

    const [dateInicioFilter, setDateInicioFilter] = useState("")
    const [dateFimFilter, setDateFimFilter] = useState("")
    const [visibleMenuUpdate, setVisibleMenuUpdate] = useState(false)

    const [visibleModalInforOrders, setVisibleModalInforOrders] = useState(false)


    const history = useHistory();
    const [visibleMenu, setVisibleMenu] = useState(false)
    const dispatch = useDispatch()
    const orders = useSelector(state => state.reducerOrders)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [search, setSearch] = useState("")
    const [checkedAll, setCheckedAll] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const closeMenu = (event) => { setVisibleMenu(false) };

    useEffect(() => {
        if (visibleMenu) {
            window.addEventListener('click', closeMenu);
            return () => {
                window.removeEventListener('click', closeMenu);
            };
        }
    }, [visibleMenu])

    // const goSearch = () => {
    //     dispatch({
    //         type: "SAGA_LIST_ORDERS", payload: {
    //             search: search,
    //             perPage: perPage,
    //             page: page,
    //             iniDate: dateInicioFilter,
    //             finDate: dateFimFilter,
    //             user_token: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.user_token
    //         }
    //     })
    // }

    // useEffect(() => { goSearch() }, [page,])




    return (
        <>
            <ModalInforVideo visible={visibleModalInforOrders} setVisibleModal={setVisibleModalInforOrders}
                video={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/WSAtkSt8R7s" : "https://www.youtube.com/embed/WSAtkSt8R7s"}
            />

            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Pedidos</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {translation.title.pedidos}
                            </p>
                        </div>
                    </div>
                    <PageIntegration />
                </div> :
                <>
                    <TableOrders />
                    {/* <div style={{ margin: "20px" }} >

                {orders.loading ?
                    <ContainerLoading>
                        <LoadingLogoLilyNew />
                    </ContainerLoading> :
                    typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == "undefined" || JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
                        <ContainerInfor ContainerInfor >
                            <BoxMessage>
                                <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                    {translation.reviews.nenhumaIntegracao}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { history.push("/integracoes") }} >{translation.reviews.cliqueAqui} </strong>.
                                </label>
                            </BoxMessage>
                        </ContainerInfor> :
                        orders.data?.default == true && orders.data?.data.length == 0 ?
                            <BoxMessage>
                                <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                    {"nenhum pedido encontrado"}
                                </label>
                            </BoxMessage> :
                            <>
                                <Filters>
                                    <Search >
                                        <input
                                            type={"text"}
                                            placeholder={translation.reviews.pesquisar}
                                            value={search}
                                            onChange={(e) => { setSearch(e.target.value) }} />
                                        <span onClick={() => { setPage(1); goSearch() }}>
                                            <AiOutlineSearch size={25} color={"gray"} />
                                        </span>
                                    </Search>

                                    <div style={{ display: "flex", visibility: "visible" }}>
                                        <DateFilter>
                                            <ItemDateFilter>
                                                <label>De:</label>
                                                <input id="date" type="date" value={dateInicioFilter} onChange={(e) => {
                                                    setDateInicioFilter(e.target.value)
                                                }} />
                                            </ItemDateFilter>
                                            <ItemDateFilter>
                                                <label> Até:</label>
                                                <input id="date" type="date" value={dateFimFilter} onChange={(e) => {
                                                    setDateFimFilter(e.target.value)
                                                }} />
                                            </ItemDateFilter>

                                        </DateFilter>
                                    </div>
                                </Filters>

                                {
                                    orders?.data?.data?.length != 0 ?
                                        <>
                                            <ListCard>
                                                {orders.data.data?.map((item) => { return (<Card key={item.id} checkedAll={checkedAll} item={item} />) })}
                                                <Paginate>
                                                    <PaginateNav>
                                                        <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                                            <MdKeyboardArrowLeft size={35} color={orders.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                                        </span>
                                                        <label>{orders.data?.meta?.current_page}</label>
                                                        <span onClick={() => { if (page < orders.data?.meta?.last_page) setPage(page + 1) }}>
                                                            <MdKeyboardArrowRight size={35} color={orders.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                                        </span>
                                                    </PaginateNav>
                                                    <PaginateInfor>
                                                        <label>{translation.reviews.total} {countItens()} - {orders.data?.meta?.total}</label>
                                                    </PaginateInfor>
                                                </Paginate>
                                            </ListCard>
                                        </> :
                                        <label style={{ marginTop: "50px", display: "flex", flex: "1", justifyContent: "center" }}>{"Nenhum pedido encontrado."}</label>
                                }
                            </>
                }

                {!orders.loading &&
                    <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                        <div onClick={() => {
                            //setVisibleModalInforReviews(true)
                        }}>
                            <HelpClick text={translation.alert.pedidos} />
                        </div>
                    </div>}

            </div> */}
                    {!orders.loading &&
                        <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                            <div onClick={() => {
                                setVisibleModalInforOrders(true)
                            }}>
                                <HelpClick text={translation.alert.pedidos} />
                            </div>
                        </div>}
                    {isVisibleScript(false)}
                </>}
        </ >
    )
}

export default Home
