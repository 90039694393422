const DijitalIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<g>
				<path
					className="lkokok"
					d="M1550.1,2670.7c-22.1-12.1-46.1-19-70.3-25.5c-27.5-7.3-55-14.2-80-28.7c-31.2-18-36.5-27-46.2-59.9
			c-5-16.5-1-40-8.4-55.7c-8.9-19.2-26.1-29.4-48.9-30.9v0.4c-7.2-0.5-15.2,0-23.7,1.4c-24.5,7.8-33.9,25.1-40.3,40.4
			c-6.6,15.8-13.2,33.4-18.9,49.6c-17.1,48.3-58.7,69.9-89,76.8c-37.5,8.6-35.6,62.5-3.4,83.5c21.6,14,45.2,25.1,68.1,37.1
			c39.7,20.8,75.1,46.2,94.5,88.7c3.2,7,5.6,14.5,9.6,21c1,1.7,2.2,3.2,3.2,4.6v-0.3c21.7,29.1,57.5,35.3,86.8,14.9
			c21.7-15.2,20.9-39.1-2.1-73c-5.8-8.5-15.4-15.8-12.2-32.7c13.6,11.3,24.8,20.2,35.7,29.5c25.2,21.4,51.9,40,84.4,48.6
			c18.9,5.1,34.5-0.2,46.2-14.9c12.2-15.2,11.3-32.2,2.4-48.6c-9.2-17.1-23.5-29.5-39.2-40.6c-7.7-5.5-15.7-10.7-23-16.6
			c-3.8-3.1-8.6-7.2-4.5-12.9c3.4-4.7,7.6-0.9,11.5,0.4c13,4.4,25.9,9.7,39.1,13.2c20.8,5.6,44.1-6.2,50.4-25.6
			C1578.8,2693.6,1567.6,2680.3,1550.1,2670.7z"
				/>
				<path
					className="lkokok"
					d="M1965,2588.2c-6.3-14.8-19.5-21.8-34.3-25.2c-24.6-5.7-47.8-14.9-70.1-26.2c-30.5-15.5-53.4-36.6-60.1-72.8
			c-3.2-17.3-3.4-37.1-25-48.7c-7.7-4.3-15.5-6.4-22.9-6.6v-0.2c-24.8-0.6-46.6,20-59.3,52.1c-13.7,34.7-19.4,58.9-83.6,80.7
			c-39.7,13.4-37.8,56.9-9,87.4c15.2,16,34.4,25.9,53.9,34.9c30.5,13.9,52.8,35.1,64.6,66.8c2.6,6.9,7.7,16.6,12.3,24.6
			c5.1,8.9,12.3,16.3,20.9,21.5v0.3c3.6,2.2,7.4,3.9,11.5,5.3c23.5,7.4,48.7,4.3,61.2-8.6c22.3-22.8,16.2-40.9,0-62.7
			c-3.1-4.1-10.4-14.1-4.7-18.2c4.7-3.4,9.8,0.2,13.6,3.9c19.4,18.6,39.2,36.8,64,47.9c18.3,8.2,35.4,6.7,49.3-9.5
			c13.8-16,13.4-32.9,3.2-50.3c-8.1-13.6-18.8-24.7-32.6-32.6c-5.3-3-11.3-5.3-13.5-10.9c-1-2.7,0.8-5.6,3.8-5.8
			c6.5-0.3,11.9,4.3,18,5.3c14.9,2.2,27.4-1.7,37.1-13.4C1973.8,2614.6,1970.6,2601.2,1965,2588.2z"
				/>
			</g>
			<g>
				<path
					className="lkokok"
					d="M2580.4,834.1c-66-44.1-133.1-86.4-199.7-129.6c-13-8.5-26.1-16.8-28.1-34.8c-4.9-43.7-38.9-366.4-57.8-483.1
			c-8.6-53.4-29.2-77.6-82.8-83.2c-168.4-17.4-829.5,35.4-1031.9,107.7c-49.9,17.8-64.5,58.5-63.7,99.8
			c3.4,168.8,135.2,1205.4,128.2,1286.8c-3.5,41.5-8.5,82.8-29.5,120c-38.1,67.2-112.1,80.1-171,30.2
			c-30.5-25.7-51.3-58.4-66.7-94.4c-58.6-137.4-82.5-282.9-94-430.4c-5.1-64.9-28-87.6-91.6-78c-67.3,10.1-134.9,18.8-201.9,30.6
			c-53.2,9.3-109.1,17.4-163.4,36.5c-31.3,10.9-48.6,19.3-50.9,52.1c-1.3,19.8,24.4,193.6,45.2,323
			c23.4,145.6,64.9,286.3,134.6,417.8c87,164.4,208.4,288.4,389.1,347.5c101,32.9,204.7,46,310.3,50.3c157.8,6.2,314.6,1,467-44.5
			c189.4-56.6,351.7-153.8,465-321.4c129.1-191.2,160.2-302,179.8-526.1c5.7-64.6,10.8-236.4,6.2-301.1
			c-1.7-22.4,5.2-38.6,21.3-54.8c67.5-68.2,134.3-137.4,199.9-207.6C2638.5,899.8,2634.6,870.3,2580.4,834.1z M2065.2,749.2
			c-7.5,126.9-109.2,199.7-231.3,166.1c-34-9.3-60.7-28.6-80.8-57.1c-62.4-88.6-63.5-234.1-3-323.8c9.9-14.8,22.4-25.8,38.2-34.1
			c111-57.6,220.8-12.8,258.8,105.6c12.5,39.1,17.7,79.2,18.3,120C2065.3,733.6,2065.7,741.4,2065.2,749.2z"
				/>
			</g>
		</g>
	</svg>
);
export default DijitalIconBranco;
