import { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { BadgeCheck, BoxStar, Container, Name, Text } from './styled'
import ImgCard from "../../../../assets/imgs/icon-sapato.svg";
import { HiBadgeCheck } from "react-icons/hi";
import { getTranslation } from "../../../../translations";

const Card03DropiCarousel = (props) => {

    const [icon, setIcon] = useState({ size: 20, color: "orange" })
    const config = useSelector(state => state.reducerControlConfig)

    useEffect(() => {
        setIcon({ ...icon, colorActive: config?.homereviews_color_star_card, colorInative: "#dcdcdc" })
    }, [config])

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <div>
            <Container
                colorBorder={config?.homereviews_color_border_card}
                background={config?.homereviews_background_color_card}
                sizeBorder={config?.homereviews_size_border_card}
                radiusBorder={config?.homereviews_radius_border_card}
            >
                <img style={{ borderRadius: "10px" }} src={ImgCard} width="100" height="140" />
                <BoxStar position={config.position_star_review} fontColor={config.homereviews_font_color_card} >
                    <span>
                        <AiFillStar size={icon.size} color={props.item.qtdStars > 0 ? icon.colorActive : icon.colorInative} />
                        <AiFillStar size={icon.size} color={props.item.qtdStars > 1 ? icon.colorActive : icon.colorInative} />
                        <AiFillStar size={icon.size} color={props.item.qtdStars > 2 ? icon.colorActive : icon.colorInative} />
                        <AiFillStar size={icon.size} color={props.item.qtdStars > 3 ? icon.colorActive : icon.colorInative} />
                        <AiFillStar size={icon.size} color={props.item.qtdStars > 4 ? icon.colorActive : icon.colorInative} />
                    </span>
                </BoxStar>
                <Name fontColor={config.homereviews_font_color_card} >
                    {props.item.name}
                    {config.homereviews_visible_verified_card == "1" &&
                        <BadgeCheck>
                            {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={15} color={config?.homereviews_color_verified_card} />}
                            <label>{translation.thema.cardReviewDropiPreview.compraVerificada}</label>
                        </BadgeCheck>}
                    <label style={{ visibility: config.visible_date_review == "1" ? "visible" : "hidden" }} >{translation.thema.cardReviewDropiPreview.haDias}</label>
                </Name>
                <Text fontColor={config.homereviews_font_color_card} >{props.item.text}</Text>
            </Container>
            <div style={{ borderRadius: `0px 0px ${props => props.radiusBorder}px ${props => props.radiusBorder}px`, alignItems: "center", borderTop: "1px solid gray", padding: "16px", display: "flex", gap: "20px", backgroundColor: `${config?.homereviews_background_color_card}` }}>
                <img style={{ display: "flex", maxWidth: "50px", maxHeight: "50px", objectFit: "cover" }} src={ImgCard} />
                <label style={{ fontSize: "14px" }}>Nome do produto</label>
            </div>
        </div>

    )
}

export default Card03DropiCarousel