import React from 'react'
import LayoutNew from '../../../../components/tailwind/layout-new'
import TableQuestions from '../../../questions/components/tailwind/table'
import { useParams } from 'react-router-dom';
import Navbar from '../../components/tailwind/navbar';
import LayoutUI from '../../../../components/tailwind/layout-ui';

const Questions = () => {

    const { id } = useParams();

    return (
        <>
            {/* <TableReviews /> */}
            <TableQuestions productId={id} navbar={<Navbar />} />
        </>
    )
}

export default Questions