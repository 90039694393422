import { motion } from 'framer-motion';

interface boxProps {
	plataforma: string;
	image: string;
	index?: number;
}

const IntegracaoBox = ({ plataforma, image, index = 0 }: boxProps) => {
    const delay = index * 0.05;
	return (
		<motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay }} className="border border-gray-200 rounded-md size-7 flex items-center justify-center">
			<img alt={plataforma} className="w-3.5" src={image} />
		</motion.div>
	);
};

export default IntegracaoBox;
