const BrindeNoCarrinhoIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 3000 3000"
    {...props}
  >
    <g>
      <path
        className="sadsadsadhjkll"
        d="M902.8,735.8c-21.4-17.4-41.1-36.8-60-56.9c-34.6-36.7-64.2-76.9-85.2-122.9c-15-32.6-24.2-67-31.1-102.1
		c-7.2-36.6-6.9-73.4-5.2-110.4c0.8-17.4,1.9-34.9,3.5-52.2c2.7-29.1,8.5-57.4,20-84.5c18-42.6,50.2-68.7,93.6-82.4
		c17.6-5.6,36.1-7.6,54.3-10.6c21.2-3.5,42.5-6.8,63.7-9.8c7.8-1.1,15.6-1.8,23.4-2.1c21.5-0.8,43-1.7,64.5-1.9
		c44.9-0.5,88,7.7,130.1,22.8c33.2,11.9,65.2,26.3,96.3,42.9c28.9,15.4,53,37.1,75.6,60.5c42.1,43.8,77.5,92.8,111,143.3
		c37.3,56.3,72,114.3,105.1,173.3c43.4,77.2,85.3,155.2,128,232.8c1.1,2,1.9,4.2,4.3,6.1c1.7-2.9,2-6.1,2.6-9
		c8.1-37.8,16.2-75.7,24.3-113.4c15.2-71,30.9-141.8,49.9-211.8c12-44,24.9-87.5,42.8-129.6c8.7-20.5,17.9-41,31.9-58.4
		c25.9-32.4,59.8-52.5,100-62c28.6-6.8,57.6-7,86.7-3.6c18.8,2.2,37.4,5.9,55.8,10.4c31.1,7.7,58.2,23.1,83.3,42.3
		c30.2,23.1,55.8,50.7,77.2,82.1c10.9,16,18.1,33.9,24.9,51.8c14.8,38.8,21.5,79,19.4,120.5c-1.8,37.3-9.7,73.6-19.5,109.6
		c-7.3,26.8-20.1,50.6-38,71.9c-13.4,16-28.5,30.1-44.7,43.2c-28.6,23.2-59.9,42.3-92.2,59.8c-57.7,31.3-118,56.7-179.2,80.3
		c-61.8,23.8-123.7,47.6-185.3,71.9c-10.8,4.3-20.5,5.6-32.1,3.3c-38.2-7.5-76.7-13.7-114.9-20.4c-54-9.5-108.1-19-162.1-28.3
		c-56.5-9.8-112.9-20.3-169.1-32.4c-39.6-8.5-267-72.8-310.7-95.5C932.4,757.2,903.4,738.6,902.8,735.8z"
      />
      <path
        className="sadsadsadhjkll"
        d="M706.8,2077.7c4.4-2.2,3-8.8-1.9-9c-7.8-0.4-15.7-0.8-23.4-1.2c-59.9-3-119.9-3.3-179.9-4.6
		c-5.8-0.1-11.6-1.2-17.3-1.4c-6.2-0.2-9.9-3.3-11.9-8.8c-2.1-5.7-4.1-11.4-6.1-17.1c-32.2-97.5-91.8-312.1-91.9-321
		c0-5.3,0.4-5.8,5.6-5c11.3,1.7,22.3,4.4,33.1,8.1c21.7,7.6,43.3,15.5,65.1,22.9c19,6.5,38.4,12.1,58.1,17.7
		c3.8,1.1,6.6-3.6,3.8-6.4c-24-23.9-83-82.5-114.9-112.3c-23.1-21.5-46.2-43.3-67.7-66.5c-5.4-5.8-10.1-12-14.3-18.7
		c-1.7-2.7-2.1-5.3-1.2-8.6c12-42.1,22-84.6,32.8-127c21.6-84.5,41.6-169.4,65.3-253.3c3.7-13.2,7.7-26.2,11.7-39.3
		c0.8-2.7,0.7-6.4,4.7-7c3.9-0.5,7.7,0.2,10.6,3c1.8,1.7,3.3,3.8,4.9,5.8c69.6,86.6,137.6,174.5,205.5,262.5
		c91.8,119,183.3,238.3,272.3,359.5c16.9,23,33.8,46.2,50.1,69.6c16.6,23.7,29.7,49.3,40.6,76.2c7.2,17.5,9.3,36.2,11.7,54.7
		c3.2,25.7,0.5,50.9-6,76c-3,11.7-8.6,22.1-15.2,32.1c-16.8,25.3-36.1,48.7-57.2,70.8c-80.1,83.6-163.2,164.1-248.7,241.9
		c-4.1,3.8-8.6,6.9-13.9,8.9c-5,1.9-10.5,1.3-14.9-1.7c-4.5-3-8.7-6.4-12.3-10.6c-25.9-30.2-50.3-61.5-74.5-93
		c-19.9-26-56-74-71.2-94.1c-3.8-5-2.5-12.2,2.8-15.6c15.9-10.3,47.2-29.9,62.4-36.9C629.9,2116.4,680.9,2090.8,706.8,2077.7z"
      />
      <path
        className="sadsadsadhjkll"
        d="M868.6,762.4c7.2,4.7,14.4,9.5,21.7,13.9c35,21.2,72.6,36.6,111,50.4c27.3,9.9,55.1,18.2,83.1,25.9
		c3.6,1,7.4,1.6,10.7,3.8c-47.9,7.7-95.7,15.7-143.7,23c-28.7,4.5-57.2,9.9-85.4,17.2c-11.3,2.9-22.3,6.4-32.8,11.8
		c-19.6,10.1-35.2,24.4-46.8,43.2c-5,8.2-7.1,17.3-8.9,26.4c-3.5,18.6-5.9,37.5-6.4,56.4c-1.7,71.2-0.9,142.3,0.7,213.3
		c0.9,39.8,2.6,159.9,2.6,178.2c0,7.6-9.3,11.4-14.6,5.9c-10.4-10.7-99-122-147.1-183.6c-8-10.2-12.3-22.7-12.4-35.6
		c-0.1-30.5-2.8-331.8-2.3-351.8c0.2-11.9,4.8-21.5,13.3-29.6c6.1-5.8,12.9-10.6,20.1-14.8c21.5-12.4,44.7-19.8,68.9-24.9
		c54.9-11.5,110.3-20.8,165.9-28.9C867.2,762.7,867.9,762.5,868.6,762.4z"
      />
      <path
        className="sadsadsadhjkll"
        d="M2448.4,2677.1c0.1,11.1-2.3,21.5-7,31.6c-5.1,10.9-13.2,19-23.6,24.9c-21.3,12-43.1,13.5-65.4,3.2
		c-7.9-3.6-8-3.4-13,3.1c-10.2,13.2-24.2,19.2-40.2,22.2c-20.8,3.9-40.3,0.7-58.3-10.4c-2.3-1.4-3.9-1.6-5.9,0.4
		c-2.2,2.1-4.6,4-6.9,6c-19.7,16.4-42.4,20.4-67,15.7c-2.2-0.4-4.5-0.9-6.7-1.4c-25.5-6.1-41.6-21.7-48.1-47.3
		c-3.1-12.1-4.2-24.4-5.9-36.7c-0.5-3.8-0.8-7.6-1.5-11.3c-0.8-3.9,0.6-5.5,4.5-6.1c9.3-1.3,18.4-3,27.7-4.5
		c48.3-8,96.7-16,145.1-23.9c41.7-6.9,83.3-13.6,124.9-20.4c8-1.3,16-2.8,23.9-4.1c2.6-0.4,5.3-1.3,6.9,2.6
		c5.6,14.1,12.8,27.5,15.4,42.7C2448.1,2668,2448.4,2672.5,2448.4,2677.1z"
      />
      <path
        className="sadsadsadhjkll"
        d="M1626.2,2888.1c-14.9,0.3-27.7-4-39.7-11c-4.1-2.4-6.4-2-9.7,1c-10,9.2-21.1,16.5-34.6,19.5
		c-22.7,5.1-44.3,1.6-64.6-9.3c-12.2-6.5-19.6-17.1-24.5-29.8c-3.8-10-5.6-20.4-7.1-31c-1.2-8.3-2.3-16.5-3.2-24.8
		c-0.7-6.3-0.6-6.4,5.4-7.4c27.7-4.6,55.3-9.1,83-13.7c41.9-6.9,83.7-13.8,125.6-20.7c37.2-6.2,74.3-12.3,111.5-18.4
		c3.9-0.7,6.6-0.1,8.2,4.2c5.1,13.8,12.3,26.6,14.6,41.4c2.5,16,0.6,31.2-6.5,45.9c-5,10.4-12.8,18-22.7,23.7
		c-22.3,12.8-45.1,13.6-68.2,2.5c-5.7-2.7-5.8-2.6-9.4,2.4c-9.6,13.3-22.9,20.2-38.7,23.3C1639,2887.3,1632.3,2888.4,1626.2,2888.1z
		"
      />
      <g>
        <path
          className="sadsadsadhjkll"
          d="M2323.5,1750.1c-82.3-0.6-144.4,63.7-145.1,143.9c-0.7,79.1,64.7,144.2,143.7,144.4
			c79.1-1,144-60.9,144.4-143.5C2466.9,1814.2,2405,1750.7,2323.5,1750.1z"
        />
        <path
          className="sadsadsadhjkll"
          d="M2656.4,1207.9c-1.2-17.1-6.1-32.1-16.5-45.5c-15-19.2-31.8-36.8-51.3-51.5c-43.8-33.1-88.2-65.4-132.8-97.5
			c-46.9-33.7-93.8-67.3-140.6-101c-4.9-3.5-10.1-6.4-16.1-5.2c-6.5,1.3-13,1.4-19.4,2.2c-28.8,3.7-57.6,7.6-86.3,11.4
			c-24.5,3.2-49,6.4-73.6,9.7c-32.8,4.3-65.6,8.8-98.4,13.1c-28.5,3.8-57.1,7.5-85.6,11.2c-29.5,3.9-59.1,7.9-88.6,11.8
			c-21.5,2.9-43.1,5.6-64.6,8.4c-27.5,3.7-55,7.4-82.6,11.1c-28.8,3.8-57.6,7.5-86.4,11.3c-29.3,3.9-58.5,7.9-87.8,11.8
			c-27.8,3.7-55.6,7.3-83.3,11c-32.8,4.4-65.5,8.9-98.3,13.3c-23.7,3.2-47.5,6.3-71.2,9.6c-31.5,4.3-62.9,9-94.4,13.2
			c-31.8,4.3-63.4,9.5-94.7,16.9c-25.6,6.1-49.4,16-70,32.9c-26.7,22-44.8,48.6-50.1,83.8c-4.1,26.6-5.7,53.2-6.2,80.1
			c-1,56.2,0.2,112.3,0.7,168.6c0.1,16.7,0.4,33.4,0.7,50.1c0.6,28.3,1.8,56.6,2.1,84.9c0.5,42.5,2.5,84.9,2.1,127.4
			c0,4,1.2,7.3,3.6,10.5c9.7,12.7,19,25.5,28.5,38.4c9.5,13,18,26.7,27.6,39.6c16,21.4,28.7,44.7,40.6,68.5
			c23.3,47,30.3,96.6,23.9,148.2c-3.5,28.5-12.3,55.2-29.8,78.6c-2,2.6-3.8,5.4-5.7,8.1c-18.9,26.4-40.5,50.5-63,73.9
			c-52.2,54.2-156.5,155.1-185.4,182.9c-4.1,4-4.1,10.6,0.1,14.5c25.7,24.1,109.6,102.9,152.9,143.5
			c57.8,54.1,115.8,108.1,175.9,159.9c30.5,26.3,61.2,52.2,95,74.3c15.5,10.1,31.2,19.7,48.2,27c12.1,5.1,24.4,7.8,37.6,7.5
			c17.2-0.4,34.4-1.6,51.5-3.2c31.7-3,63.5-5.9,94.9-11.2c80.3-13.7,160.6-27.1,240.8-41.1c78.5-13.7,156.8-27.9,235.2-41.9
			c85.3-15.3,170.7-30.8,255.9-47.1c102.1-19.4,204.1-39.5,305.3-62.6c12.1-2.8,24.5-4.3,35.9-9.3c17.6-7.8,34-17.9,48.5-30.7
			c14.1-12.3,24.1-27.1,29.7-45.1c10.9-34.9,18.9-70.3,20.1-107c1.8-56.9,3.7-113.8,5.4-170.8c1.4-48.1,2.5-96.2,3.8-144.3
			c1.8-67.1,3.7-134.1,5.4-201.2c1.4-53.4,2.5-106.8,3.8-160.2c1.8-72.7,3.6-145.2,5.4-217.9c0.9-38,1.6-76,2.4-113.8
			C2658.1,1315.1,2660.1,1261.6,2656.4,1207.9z M1719.9,2026.1c-27.8-20.7-56.8-36.1-87.8-43.7c-41.1-10-80.3-0.8-118.6,16.8
			c-25.9,11.9-49.8,29.2-72.5,50.1c-17,15.8-33.9,32.1-51.4,46.7c-25.6,21.3-57.5,4.5-67.8-34.8c-6.7-25.6-2-55,14.1-70.6
			c54.4-53.2,109.7-104.9,176.4-126.2c23.9-7.7,48.1-13.5,73.3-15c53.2-1.9,104.5,15.8,153.6,48c29.8,19.5,58.3,42.6,87.1,64.8
			c18.7,14.5,27.3,40.5,24.2,68.3c-2.9,26-16.8,46-35.8,51c-11.7,3.1-22.5-1-32.9-8.9C1761.1,2057.2,1740.5,2041.5,1719.9,2026.1z
			 M2175.2,2207.4c-9.5,10.9-20.1,20.7-30.7,30.5c-20.8,19.2-42,38.2-64.9,54.8c-9.7,7-19.7,13.3-30.6,18.1
			c-8.7,3.9-17.6,6.2-27.2,5.6c-19.3-0.7-38.1-4.7-56.7-9.7c-45-12-89-26.6-132.9-42.2c-8.8-3.1-16-8.8-22-15.7
			c-7.4-8.5-7.2-20.5,0.2-29.1c9.5-11,19.3-21.3,29.5-31.7c37.1-37.7,75.1-74.6,114.1-110.2c5.9-5.3,12.3-9.8,19.4-13
			c6.6-2.9,13.1-3.3,19.9-1c7.5,2.5,14.8,5.5,21.5,9.4c48.8,28.1,97.5,56.6,145.3,86.5c6.6,4.1,12.2,9.1,16.5,15.4
			C2184,2185.6,2183.5,2197.8,2175.2,2207.4z M2323.2,2157.3c-146.9,0.5-264-115.2-264.1-263.1c-0.1-145.7,113.8-263.1,262.5-263.5
			c148.8-0.4,262.2,116.3,264.6,263.1C2583.9,2042.1,2469.2,2156.8,2323.2,2157.3z"
        />
      </g>
    </g>
  </svg>
);
export default BrindeNoCarrinhoIconBranco;
