import { BarLeft, ButtonDefault, Box, BoxActions, ButtonPreview, InputTextCustom, InputFileCustom, SelectCustom, BoxLoading, BoxLoadingCustom, FormCustom, InputCustomItem, ButtonSave, ErrorMessage, BoxFormTitle, BoxForm, InputCustomItemColor, TextAreaCustom, Tooltip, ContainerPreview, Submenu, BoxControlMobile, BarSubmenu, BarSubmenuItem, NewBoxCustom, NewBarLeft, ItemBarLeft, NewBarLeftTitle, Timer, ItemBarLeftCustom, BoxControlItem, BoxTimerControl } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { displayScript, isVisibleScript } from "../../../../utils/displayScript";
import { FaRegQuestionCircle, FaUpload } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import { ImageFileToBase64 } from "../../../../utils/convert";
import imageCompression from 'browser-image-compression';
import { set } from "date-fns";
import ModalPreviewEmail from "../../components/modal-preview-email";
import { useHistory, useLocation } from "react-router-dom";
import FormTema from "../../components/form-tema";
import FormEmail from "../../components/form-email";
import * as Yup from 'yup';
import AlertTimeUpdate from "../../../../components/Alert";
import FormLoading from "../../components/form-loading";
import FormHome from "../../components/form-home";
import { getTranslation } from "../../../../translations";
import PreviewTheme from "../../components/preview-theme";

import SetaImg from "../../../../assets/imgs/icons-sidebar/submenu-seta.svg";
import IconImgProduct from "../../../../assets/imgs/icons-sidebar/submenu-product.svg";
import IconImgProductInativo from "../../../../assets/imgs/icons-sidebar/submenu-product-inativo.svg";
import IconImgEmail from "../../../../assets/imgs/icons-sidebar/submenu-email-inativo.svg";
import IconImgEmailAtivo from "../../../../assets/imgs/icons-sidebar/submenu-email-ativo.svg";
import IconImgHome from "../../../../assets/imgs/icon-homepage.svg";
import IconImgHomeAtivo from "../../../../assets/imgs/icons-sidebar/submenu-home-ativo.svg";
import BarConfigProduct from "../../components/bar-config-product-cores";
import BarConfigProductAjustes from "../../components/bar-config-product-ajustes";
import BarConfigEmail from "../../components/bar-config-email-cores";
import BarConfigEmailAjustes from "../../components/bar-config-email-ajustes";
import BarConfigHomeHabilitar from "../../components/bar-config-home-habilitar";
import BarConfigHomeConfig from "../../components/bar-config-home-config";

import { IoIosArrowBack, IoMdTimer } from "react-icons/io";
import { BiTimeFive } from "react-icons/bi";
import BarConfigHomeColor from "../../components/bar-config-home-color";
import ModalAlertSave from "../../components/modal-alert-save";
import LoadingLogo from "../../../../components/Loading-logo";
import FormMenu from "../../components/form-menu";
import BarConfigAvaliarConfig from "../../components/bar-config-avaliar-config";
import BarConfigAvaliarColor from "../../components/bar-config-avaliar-color";
import FormAvaliar from "../../components/form-avaliar";
import { Google, Home2, MessageQuestion, Notification, Star1 } from "iconsax-react";
import ModalConfigTimeEmail from "../../components/modal-config-time-email";
import BarConfigPerguntarConfig from "../../components/bar-config-perguntar-config";
import BarConfigPerguntarColor from "../../components/bar-config-perguntar-color";
import FormPerguntar from "../../components/form-perguntar";
import LoadingLogoLily from "../../../../components/Loading-logo-lily";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import ModalInforVideo from "../../../../components/modal-infor-video";
import ModalConfigGoogle from "../../components/modal-config-google";
import FormMural from "../../components/form-mural";
import BarConfigMuralAjustes from "../../components/bar-config-mural-ajustes";
import BarConfigMuralColor from "../../components/bar-config-mural-cores";
import HelpClick from "../../../../components/HelpClick";
import ModalConfigNotifications from "../../components/modal-config-notifications";
import Layout from "../../../../components/tailwind/layout";
import { ContainerLoading } from "../../../reviews/pages/home-new/styled";
import LoadingLogoLilyNew from "../../../../components/Loading-logo-lily-new";
import LayoutNew from "../../../../components/tailwind/layout-new";
import LazyLoadingThema from "../../../../components/lazy-loading-thema";
import LayoutUI from "../../../../components/tailwind/layout-ui";

const Home = () => {

    const [visibleModalInforCustom, setVisibleModalInforCustom] = useState(false)

    const dispatch = useDispatch()
    const selectedMenu = useLocation();
    const statusUpdateConfig = useSelector(state => state.reducerUpdateConfig)
    const reducerUpdateConfigMural = useSelector(state => state.reducerUpdateConfigMural)
    const config = useSelector(state => state.reducerGetConfig)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const edit = useSelector(state => state.reducerControlEdit)

    const history = useHistory()
    const reviewsFavorites = useSelector(state => state.reducerReviews)

    const [visibleModalAlertSave, setVisibleModalAlertSave] = useState(false)

    const [visibleModalEmail, setVisibleModalEmail] = useState(false)
    const [visibleModalNotifications, setVisibleModalNotifications] = useState(false)
    const [visibleModalGoogle, setVisibleModalGoogle] = useState(false)


    useEffect(() => {
        dispatch({ type: "SAGA_GET_CONFIG" })
        dispatch({ type: "SAGA_GET_CONFIG_MURAL" })
    }, [])


    useEffect(() => {
        const id = "statusUpdateConfig"
        if (!(statusUpdateConfig.loading == false && !statusUpdateConfig.error == false && statusUpdateConfig.data.length == 0)) {
            if (statusUpdateConfig.loading) {
                toast.loading(translation.thema.aguarde, { toastId: id })
            } else {
                if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.thema.podeLevar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 5000, closeOnClick: true })
                    dispatch({ type: "SAGA_GET_CONFIG", })
                }
            }
        } else if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    }, [statusUpdateConfig])

    useEffect(() => {
        const id = "reducerUpdateConfigMural"
        if (!(reducerUpdateConfigMural.loading == false && !reducerUpdateConfigMural.error == false && reducerUpdateConfigMural.data.length == 0)) {
            if (reducerUpdateConfigMural.loading) {
                toast.loading(translation.thema.aguarde, { toastId: id })
            } else {
                if (reducerUpdateConfigMural.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.thema.podeLevar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 5000, closeOnClick: true })
                    dispatch({ type: "SAGA_GET_CONFIG_MURAL", })
                }
            }
        } else if (reducerUpdateConfigMural.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    }, [reducerUpdateConfigMural])





    const routerBar = () => {
        if (selectedMenu.pathname == "/personalizar") {
            return null
            return (
                <NewBarLeft>
                    <ModalConfigNotifications visible={visibleModalNotifications} setVisibleModal={setVisibleModalNotifications} />
                    <ModalConfigTimeEmail visible={visibleModalEmail} setVisibleModal={setVisibleModalEmail} />
                    <ModalConfigGoogle visible={visibleModalGoogle} setVisibleModal={setVisibleModalGoogle} />

                    <NewBarLeftTitle>
                        Personalizar
                    </NewBarLeftTitle>
                    <ItemBarLeft onClick={() => { history.push("/personalizar/tema") }}>
                        <img width={40} src={IconImgProductInativo} />
                        <label onClick={() => { history.push("/personalizar/tema") }}>
                            {translation.thema.avaliacoesPerguntas}
                        </label>
                    </ItemBarLeft>

                    {/* <ItemBarLeft onClick={() => { history.push("/personalizar/perguntas") }}>
                        <FaRegQuestionCircle size={40} color="gray" />
                        <label onClick={() => { history.push("/personalizar/perguntas") }}>
                            Perguntas no Produto
                        </label>
                    </ItemBarLeft> */}

                    {/* <ItemBarLeft onClick={() => { history.push("/personalizar/tela-avaliacao") }}>
                        <label onClick={() => { history.push("/personalizar/tela-avaliacao") }}>
                            Tela de avaliar
                        </label>
                    </ItemBarLeft>

                    <ItemBarLeft onClick={() => { history.push("/personalizar/tela-pergunta") }}>
                        <label onClick={() => { history.push("/personalizar/tela-pergunta") }}>
                            Tela de perguntar
                        </label>
                    </ItemBarLeft> */}
                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "montink" &&
                        <ItemBarLeft ItemBarLeft onClick={() => { history.push("/personalizar/home") }}>
                            <img width={40} src={IconImgHome} />
                            <label onClick={() => { history.push("/personalizar/home") }}>
                                Página Inicial
                            </label>
                        </ItemBarLeft>}

                    {/* <ItemBarLeft onClick={() => { history.push("/personalizar/email") }}>
                        <img width={40} src={IconImgEmail} />
                        <label onClick={() => { history.push("/personalizar/email") }}>
                            E-mail
                        </label>
                    </ItemBarLeft> */}


                    <ItemBarLeftCustom style={{ position: "relative" }} >
                        <BoxControlItem onClick={() => { history.push("/personalizar/email") }}>
                            <img width={40} src={IconImgEmail} />
                            <label onClick={() => { history.push("/personalizar/email") }}>
                                E-mail
                            </label>
                        </BoxControlItem>
                        <Timer >
                            <BoxTimerControl onClick={() => { history.push("/personalizar/email") }} />
                            <BiTimeFive onClick={() => { setVisibleModalEmail(true) }} size={24} color={"#80808099"} />
                            <label> {translation.thema.TempoEmail}</label>
                        </Timer>
                    </ItemBarLeftCustom>

                    <ItemBarLeft onClick={() => { history.push("/personalizar/avaliar") }}>
                        <Star1 size="55" color="#808080" variant="TwoTone" />
                        <label onClick={() => { history.push("/personalizar/avaliar") }}>
                            {translation.thema.telaAvaliar}
                        </label>
                    </ItemBarLeft>

                    <ItemBarLeft onClick={() => { history.push("/personalizar/perguntar") }}>
                        <MessageQuestion size="55" color="#808080" />
                        <label onClick={() => { history.push("/personalizar/perguntar") }}>
                            {translation.thema.telaPerguntar}
                        </label>
                    </ItemBarLeft>

                    {
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration != undefined &&
                            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "nuvemshop" ?
                            <ItemBarLeft onClick={() => { setVisibleModalGoogle(true) }}>
                                <Google size="55" color="#808080" />
                                <label onClick={() => { setVisibleModalGoogle(true) }}>
                                    Google
                                </label>
                            </ItemBarLeft> : null
                    }

                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "nuvemshop" ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "bagy" ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "cartpanda" ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "shopify" ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "loja_integrada" ?
                        <ItemBarLeft onClick={() => { history.push("/personalizar/mural") }}>
                            <img width={40} src={IconImgHome} />
                            <label onClick={() => { history.push("/personalizar/mural") }}>
                                {"Mural"}
                            </label>
                        </ItemBarLeft> : null}

                    <ItemBarLeft onClick={() => { setVisibleModalNotifications(true) }}>
                        <Notification size="55" color="#808080" />
                        <label onClick={() => { setVisibleModalNotifications(true) }}>
                            {translation.thema.telaNotificacoes}
                        </label>
                    </ItemBarLeft>

                </NewBarLeft >
            )
        }
        if (selectedMenu.pathname == "/personalizar/tema") {
            if (edit == "produto/config") {
                return <BarConfigProductAjustes />
            } else {
                return <BarConfigProduct />
            }
        } else if (selectedMenu.pathname == "/personalizar/email") {
            return <BarConfigEmailAjustes />
            // if (edit == "email/config") {
            //     return <BarConfigEmailAjustes />
            // } else {
            //     return <BarConfigEmail />
            // }yarn start
        }
        else if (selectedMenu.pathname == "/personalizar/home") {
            if (edit == "home/config") {
                return <BarConfigHomeConfig />
            } else {
                return <BarConfigHomeColor />
            }
        } else if (selectedMenu.pathname == "/personalizar/avaliar") {
            if (edit == "avaliar/config") {
                return <BarConfigAvaliarConfig />
            } else {
                return <BarConfigAvaliarColor />
            }
        } else if (selectedMenu.pathname == "/personalizar/perguntar") {
            if (edit == "perguntar/config") {
                return <BarConfigPerguntarConfig />
            } else {
                return <BarConfigPerguntarColor />
            }
        } else if (selectedMenu.pathname == "/personalizar/mural") {
            if (edit == "mural/config") {
                return <BarConfigMuralAjustes />
            } else {
                return <BarConfigMuralColor />
            }
        } else {
            return null
        }
    }

    useEffect(() => {
        dispatch({
            type: "SAGA_LIST_REVIEWS", payload: {
                rating: "",
                status: "all",
                search: "",
                perPage: 10,
                page: 1,
                medias: "",
                favorite: true
            }
        })
    }, [])

    const visibleFormHome = () => {
        if (selectedMenu.pathname == "/personalizar/home") {
            if (
                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "" &&
                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != null &&
                typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "undefined"
                //&&                reviewsFavorites?.data?.meta?.total > 7
            ) {
                return true
            } else {
                return false
            }
        } else if (selectedMenu.pathname == "/personalizar/email") {
            return false
        } {
            return true
        }

    }

    const getVideo = () => {
        switch (selectedMenu.pathname) {
            case "/personalizar":
                return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "-_80jYsEim0" : "0PpJxHtdF3Q"
            case "/personalizar/tema":
                return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "y1pfumk01Ts" : "ExsJ2Itfi6g"
            case "/personalizar/home":
                return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "UMGcr-Wonxk" : "98NqWdEmmz8"
            case "/personalizar/email":
                return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "ng0LAhQ9mY8" : "oSzEsQA_n_o"
            case "/personalizar/avaliar":
                return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "-JOm8II4qe8" : "BDhqUfMTpCI"
            case "/personalizar/perguntar":
                return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "WotY2E0BrzQ" : "Jc-y7zsZ6vc"
            case "/personalizar/mural":
                return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "eL3-5GogGB4" : "eL3-5GogGB4"
            default:
                return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "-_80jYsEim0" : "0PpJxHtdF3Q"
        }
    }

    const getText = () => {
        switch (selectedMenu.pathname) {
            case "/personalizar":
                return translation.alert.personalizar
            case "/personalizar/tema":
                return translation.alert.personalizarAvaliacaoPergunta
            case "/personalizar/home":
                return translation.alert.personalizarHome
            case "/personalizar/email":
                return translation.alert.personalizarEmail
            case "/personalizar/avaliar":
                return translation.alert.personalizarAvaliar
            case "/personalizar/perguntar":
                return translation.alert.personalizarPerguntar
            // case "/personalizar/mural":
            //     return translation.alert.personalizarPerguntar
            default:
                return translation.alert.personalizar
        }
    }
    const reducerControlVisibleModalEmail = useSelector(state => state.reducerControlVisibleModalEmail)
    const reducerControlVisibleModalGoogle = useSelector(state => state.reducerControlVisibleModalGoogle)
    const reducerControlVisibleModalNotifications = useSelector(state => state.reducerControlVisibleModalNotifications)
    return (
        <>
            <ModalConfigNotifications visible={reducerControlVisibleModalNotifications} setVisibleModal={setVisibleModalNotifications} />
            <ModalConfigTimeEmail visible={reducerControlVisibleModalEmail} setVisibleModal={setVisibleModalEmail} />
            <ModalConfigGoogle visible={reducerControlVisibleModalGoogle} setVisibleModal={setVisibleModalGoogle} />

            <ModalInforVideo visible={visibleModalInforCustom} setVisibleModal={setVisibleModalInforCustom} video={`https://www.youtube.com/embed/${getVideo()}`} />
            {/* <ModalAlertSave visible={visibleModalAlertSave} setVisible={setVisibleModalAlertSave}  /> */}


            {config.loading ?
                // <FormLoading />
                // <ContainerLoading>
                //     <LoadingLogoLilyNew />
                // </ContainerLoading>
                <LazyLoadingThema />
                :
                <>
                    <BoxControlMobile active={edit != "" ? true : false}>
                        <BarLeft isFull={visibleFormHome()} >
                            {routerBar()}
                        </BarLeft>
                        <div style={{ display: "flex", flex: "1", justifyContent: "center", minHeight: "100%" }}>
                            {selectedMenu.pathname == "/personalizar/tema" && <FormTema />}
                            {selectedMenu.pathname == "/personalizar" && <FormMenu />}
                            {selectedMenu.pathname == "/personalizar/email" && <FormEmail />}
                            {selectedMenu.pathname == "/personalizar/home" && <FormHome />}
                            {selectedMenu.pathname == "/personalizar/avaliar" && <FormAvaliar />}
                            {selectedMenu.pathname == "/personalizar/perguntar" && <FormPerguntar />}
                            {selectedMenu.pathname == "/personalizar/mural" && <FormMural />}
                        </div>
                    </BoxControlMobile>
                </>


                /*<BoxControlMobile active={edit != "" ? true : false}>
                 <BarLeft isFull={visibleFormHome()} >
                      {routerBar()}
                  </BarLeft>
                  <div style={{ display: "flex", flex: "1", justifyContent: "center", height: "100%", }}>
                      {selectedMenu.pathname == "/personalizar/tema" && <FormTema />}
                      {selectedMenu.pathname == "/personalizar" && <FormTema />}
                      {selectedMenu.pathname == "/personalizar/email" && <FormEmail />}
                      {selectedMenu.pathname == "/personalizar/home" && <FormHome />}
                  </div>
              </BoxControlMobile> */
            }




            <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                <div onClick={() => { setVisibleModalInforCustom(true) }}>
                    <HelpClick text={getText()} />
                </div>
            </div>
            {isVisibleScript(false)}
        </ >
    )
}

export default Home

