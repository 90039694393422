const PromocaoAgendadaIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<path
				className="st0page"
				d="M1980.2,598.5c7.7,2.5,15.1-0.1,20.3-7.5c1.9-2.7,3.4-5.8,4.7-8.9c11.7-30.1,23.3-60.4,34.9-90.6
		c1.3-3.4,2.5-6.9,2.4-10.8c0-8.5-4.4-15.2-12-18.2c-7.2-2.7-14.5-0.7-20.2,5.4c-3,3.2-4.8,7.3-6.4,11.4
		c-11.2,29.3-22.3,58.6-33.4,87.9c-1.5,4.1-3.1,8.2-2.9,12.7C1967.4,589,1972,595.8,1980.2,598.5z"
			/>
			<path
				className="st0page"
				d="M2333.5,949.3c3.6-0.9,7.1-2.3,10.3-4.1c28.3-15,56.5-30.1,84.7-45.2c2.6-1.4,5.3-3,7.6-5
		c4.8-3.8,6.9-9,7.3-14.7c-0.2-9.8-7.7-17.3-17.4-17.9c-5.2-0.3-9.9,1.1-14.5,3.6c-28.7,15.5-57.5,31-86.2,46.6
		c-3.9,2.1-7.4,4.7-10,8.3c-4.7,6.3-5,14.3-0.9,20.8C2318.5,948.4,2325.5,951.3,2333.5,949.3z"
			/>
			<path
				className="st0page"
				d="M2797.1,1395.3c-6.6-21.3-22.1-33.2-42.9-39.2c-9.9-2.8-20.1-2.4-30.1-2.4c-24.6-0.2-49.3-0.3-73.9-0.5
		c-2-26.3-5.3-52.4-9.2-78.5c-3.6-23.3-7.8-46.6-12.9-69.6c-4.1-18.6-15.4-30.3-34-34.9c-9.5-2.3-18.7-0.2-27.8,2.9
		c-69.5,24-139.1,47.9-208.5,71.8c-58.9,20.4-118,41-176.9,61.5c-9.1-3.5-18.3-7-27.4-10.7c-11.4-4.5-219.3-85.5-298.1-120.3
		c-1.5-0.7-3.1-1.2-3.9-2.9c26.3-6.4,39.7-18.5,48.4-44.5c3.4-10.2,6.1-20.7,8.6-31.2c12.1-49.2,20.7-99.1,27.9-149.3
		c5.1-35.4,9.3-71,8.9-106.9c-0.4-31.4-1.1-62.9-2-94.2c-1-34.5-1.8-68.9-2.4-103.4c-0.4-22-10.1-37.3-30.6-45.6
		c-0.9-0.4-1.8-0.9-2.7-1.2c-38.1-14.7-77.1-27.1-115.6-40.4c-20.2-6.9-38.6-2.5-54.6,11.9c-4.8,4.3-9.5,8.6-14,13.2
		c-20.4,20.7-41.2,41-62.4,61c-38.4,36.1-76.8,72.3-115.4,108.2c-14.6,13.6-26.1,29.1-34,47.4c-3.8,8.6-8,17-12.1,25.6
		c0,0-0.4-8.4-0.4-12.4c0-55.6,0-111.3,0-166.9c0-4.6-0.3-9.3-0.4-13.8c0.5-3.7,2.6-6.9,4.3-10.1c11.1-23.7,22.5-47.4,33.4-71.2
		c8.3-18.2,3.8-34.4-12.7-45.9c-7.9-5.6-16.5-10.3-24.7-15.4c0,0,0.2-15.3,0.3-22.9c0,0,0,0-0.1,0c1-0.1,2-0.4,3-0.4
		c26.8-1.2,44.1-14.5,52.2-40.1c2.9-9.1,3.8-18.5,3.8-28c0.1-43.3,0.5-86.5,0.6-129.8c0.1-34.8-0.2-69.6-0.3-104.5
		c0-10.3-2.1-20.2-6.8-29.4c-10.3-20.5-26.9-31.7-50-32.1c-23.6-0.4-40.6,10.9-51.4,31.5c-5.8,11.1-7.8,22.9-7.8,35.3
		c0,36.2,0,72.3,0,108.5c0,3.8-0.3,7.4-0.4,11.2c0,0,0,0,0,0c-13.2,0.4-26.3,1.1-39.5,2c-55.7,3.6-110.9,11.4-165.3,23.4
		c-231.4,51.2-429.9,161.9-594.4,332.4c-147.6,152.8-245.8,333.4-295.5,540c-13.9,57.8-23.3,116.5-27.4,176c-2.9,1.1-6,0.6-9,0.7
		c-14.8,0.2-29.7,0.3-44.5,0.4c-11,0-21.4,1.8-31.4,6.3c-23.6,10.7-36.4,32.4-34.3,58.7c1.8,22.7,17.8,40.9,42,47.6
		c8.5,2.3,17.2,3.2,26,3.2c15.8,0.1,31.6,0.3,47.6,0.4c0,0,0,0,0,0c0.5,38.2,3.5,76.1,7.8,114c6.5,56.1,16.9,111.5,32,165.8
		c64.4,234.1,189.7,429.7,373.6,587.8c24.8,21.4,50.5,41.8,77.6,60.2c0,0.1,0,0.3,0,0.4c0.2,2.3,0.5,4.7,0.5,7.1
		c1,54.4,2.2,108.8,3,163.2c1.1,78.4,2,156.8,3,235.2c0.2,14.9-0.1,29.7,1.5,44.5c1.7,16.3,9.5,24.9,25.6,28.4
		c5.4,1.2,10.8,1.2,16.1,1.3c64.9,1.1,129.7,2.2,194.6,3.2c86.5,1.4,173,2.5,259.6,4.1c104.4,1.8,208.8,4.2,313.2,6
		c72,1.2,144,1.1,216,0.5c6.8-0.1,13.5-0.8,20.1-2.1c22.4-4.5,35.4-18.4,38.8-41c0.9-5.8,0.8-11.5,0.6-17.1
		c-1.6-37.1-3.4-74.2-5.1-111.4c-2-47.6-3.9-95.2-5.7-142.8c-0.4-10.8-0.3-21.6-0.5-32.5c0-0.1,0-0.1,0-0.2c2.8-1,5.7-1.9,8.4-3
		c161.1-61,302-152.9,422.7-275.7c118.9-121.1,207.3-261.5,265.3-420.9c29.7-81.9,49.8-166,60.5-252.5c5.4-43.2,8-86.6,9-130.1
		c0.1,0,0.2,0,0.3,0c26-0.1,51.9-0.3,78-0.4c7.8,0,15.5-0.5,23-2.5C2788.2,1458.7,2806.8,1426.8,2797.1,1395.3z M560.2,862.4
		c6.3-6,13.2-6.8,22.4-2c16.8,8.6,33.5,17.4,50.1,26.5c18.5,9.9,36.6,20.2,55.1,29.9c7.4,4,10.7,9.9,11.4,17.5
		c0.1,14.6-13.8,23.9-26.8,17.5c-21.2-10.4-42.1-21.5-62.9-32.6c-15.4-8.3-30.5-17.4-45.9-25.6C549.2,886.1,551.8,870.3,560.2,862.4
		z M548.9,1430.6c-2.2,12.1-10.2,20.3-21.4,25.4c-8.4,3.8-17.3,5.7-26.6,5.9c-77.7,0.8-155.5,1.5-233.2,1.1
		c-9.8,0-19.6-1.2-28.7-5.4c-14.1-6.4-22.5-17.1-24-32.6c-1.6-16,4.1-29.3,17.1-38.8c10.1-7.4,21.8-10.2,34.4-10.1
		c38.5,0.1,77.1,0,115.6,0s77.1,0.1,115.6-0.1c8.9,0,17.5,1.2,25.7,4.9C544,1389.9,553.1,1407.8,548.9,1430.6z M689.6,1905.9
		c-35.5,20.3-71.5,39.5-107.7,58.2c-3,1.5-6.1,2.9-9.3,2.6c-8.3-0.1-16.1-6.5-18.7-15c-2.4-8.4-0.1-14.2,8.3-19.7
		c30.9-20.1,63.5-37.2,96.6-53.3c4.6-2.2,9-4.6,13.7-6.5c9.6-4,19.6-0.3,24.2,8.5C701.9,1890.8,699.6,1900.1,689.6,1905.9z
		 M1525.9,801c6.9-14.9,16.2-27.9,28.3-39.2c40.5-37.8,80.9-75.8,121.1-113.8c18.2-17.1,35.8-34.9,53.7-52.4
		c6.8-6.6,13.2-13.6,20.7-19.4c10.5-8.2,22-11.2,35.2-7.5c12.4,3.3,24.2,8.3,36.4,12.4c25.3,8.6,50.5,17.1,75.6,26.4
		c25.2,9.2,29.4,16,30,42.8c0.8,35.8,1.6,71.6,2.6,115.7c0.9,18.1,1.2,44.3,1.6,70.7c0.5,31.5-2.4,62.8-6.7,93.8
		c-6.3,45.8-13.8,91.4-23.6,136.6c-3.7,16.8-7.6,33.6-12.4,50.1c-1.6,5.5-3.6,11-6.2,16c-10,19.7-26.2,27.7-47.8,23.1
		c-6.6-1.3-13.1-3.4-19.4-5.8c-107.6-39.2-214.2-80.7-319.2-126.4c-8.3-3.7-16.6-7.4-24.1-12.8c-12.2-8.8-17.4-20.3-14-35.2
		c1.1-5,2-9.9,3.8-14.7C1481.3,907.5,1501.5,853.4,1525.9,801z M1458.4,151.1c4.8-23.3,22.8-37.9,45.2-36.7
		c21,1.1,37.9,18.7,39.9,41.6c0.6,6.7,0.5,13.5,0.5,20.3c0,34.8,0,69.7,0,104.5c-0.1,0-0.2,0-0.3,0c0,38.2-0.1,76.4,0,114.6
		c0,11-1.3,21.6-6.2,31.5c-7,14.3-18.5,22-34.5,22.5c-16.7,0.6-29.5-6.2-37.7-20.8c-4.8-8.4-7.1-17.4-7.3-27.2
		c-0.4-19-1-37.8-1.1-56.8c-0.2-58.5-0.2-116.9-0.3-175.5C1456.5,163.1,1457.2,157.1,1458.4,151.1z M966.9,464.9
		c7.9-5.6,17.5-4.5,23,3.6c5,7.2,9.1,14.9,13.3,22.6c13.4,25.4,23.9,52,35.5,78.2c1.1,2.5,1.6,5.1,1.5,7.5
		c0,13.9-7.6,21.8-20.3,21.3c-4.6-0.2-8.4-1.7-11.7-5.2c-3.3-3.4-5.7-7.5-7.6-11.9c-13.5-29.8-26.9-59.5-40.3-89.4
		C955.6,481.1,958.2,471,966.9,464.9z M2046.3,2340.8c-0.5,6.5-1.4,12.9-7.1,17.1c-9.1,6.8-22.7,6-28.3-6
		c-9.5-20.5-19.4-40.9-28.9-61.4c-6-12.9-11.8-25.8-17.2-38.8c-4.2-10-1.1-17.9,8.1-23.1c9.6-5.5,18.5-3.6,24.3,5.9
		c8.4,13.5,15.7,27.6,22.3,42c8.2,18.2,16,36.4,24,54.7C2044.9,2334.2,2046.6,2337.2,2046.3,2340.8z M2510.1,1853.2
		c-13.1,28.3-108.9,163.3-116.1,173.5c-18.9,26.7-43.4,45-75.8,52.2c-12.3,2.7-24.7,3.5-37.2,2.9c-14.9-0.7-29.7-1.1-44.6-0.4
		c-46.7,2.3-85.7-15-119.6-46c-4.8-4.4-9.3-8.8-14-13.3c-14.3-13.3-30.8-17.3-48.6-8.6c-50.2,24.7-102.7,45.4-147.8,79.7
		c-1.3,1-2.6,2-4.2,2.9c-23.9,14.7-34.4,36.6-36.1,64.2c-2.8,47.7-2.3,95.2-0.7,142.9c1.2,34.8,1.7,69.6,2.9,104.4
		c2.3,65.2,4.9,130.3,7.3,195.6c0.9,23.3,1.6,46.7,2.5,69.9c2.2,54.7,4.5,109.4,6.7,164.1c0.2,6.3,0.4,12.4-0.3,18.4
		c-1.6,12.6-8.4,21.2-20.3,25.6c-8.3,3-17,4-25.9,4.1c-102.7,1.2-205.5-0.2-308.2-2.4c-69.6-1.5-139.3-3.1-208.8-4.2
		c-44.2-0.6-88.5-1.4-132.8-2c-39.6-0.6-79.1-1.1-118.7-1.8c-71-1.1-141.9-2.3-212.9-3.6c-3.8-0.1-7.5-0.1-11.2-0.7
		c-11.7-1.8-15.9-6.3-16.5-18.1c-0.8-15.2-1.1-30.4-1.3-45.6c-0.5-41.9-0.8-83.8-1.4-125.8c-0.6-43.2-1.3-86.5-2-129.7
		c-0.7-41.9-1.3-83.8-2-125.7c-0.7-44.2-1.4-88.5-2-132.8c-1-67.6-1.9-135.2-3-202.8c-0.4-22.9,3.4-45,10.3-66.7
		c50.9-160.3,101.8-320.5,152.9-480.8c9.6-30.3,15.3-61.6,19.2-93.1c5.3-42.9,9.8-85.9,15.6-128.7c4.8-34.8,8.6-69.7,13-104.6
		c4.5-35.8,9-71.7,13.4-107.5c3.8-30.1,7.2-60.4,10.9-90.5c4.4-35.8,8.9-71.7,13.5-107.5c4.3-33.9,8.5-67.7,12.9-101.5
		c2.2-17.1,6.1-33.9,12.9-49.9c4.6-10.7,10.5-20.3,18.4-28.9c47.8-52.5,96.6-104.1,145.7-155.4c28.9-30.3,58.1-60.4,87.2-90.5
		c6.1-6.3,12.7-12.2,18.7-18.7c9.4-10.1,19.8-12.2,32.9-7.1c22.7,8.8,45.4,17.2,67.7,27.2c11.4,5.1,22.5,10.8,33.7,16.5
		c5.4,2.7,10.4,6.2,15.7,9.2c14.1,8.3,17,19.1,9.8,34.3c-26.6,56.2-53.5,112.3-80.6,168.1c-11,22.5-21.4,45.2-33.3,67.2
		c-12.1,22.3-19.2,45.9-24.1,70.6c-13.9,69.9-26.1,140.1-38.6,210.2c-1.6,9.2-4.2,18.4-4.6,27.9c5.2,0.3,8.3-2.5,12-4.2
		c12.3-5.7,24.2-12,36.6-17.3c44-19.2,89.2-22,135.7-10c32.6,8.4,63.7,20.3,94.5,33.2c109.7,45.7,676.4,277.5,751.3,307.7
		c40.2,16.2,67.2,44.1,79.3,86.1c3.3,11.7,7.2,23.2,10.8,34.9c24.1,79.2,43.5,159.6,58.1,241.1c1.8,10.2,3.7,20.6,4.6,31
		C2524.9,1814,2519,1833.9,2510.1,1853.2z M2784.9,1413.9c-0.8,17.5-10.1,29.7-26,36.8c-8.1,3.7-16.8,4.8-25.8,4.7
		c-38.2-0.1-76.4,0-114.6,0c0-0.1,0-0.1,0-0.2c-37.8,0-75.8,0-113.6,0c-9.5,0-18.9-1.2-27.9-4.5c-16.5-6-26.4-19-27.1-35.6
		c-0.8-18.9,7.7-33.3,24.3-40.7c10.3-4.6,21.4-6,32.7-6.1c22.6-0.1,45.3-0.2,68-0.3c53.5,0,106.8,0,160.3,0c9.3,0,18.2,1.9,26.4,6.3
		C2777.3,1382.9,2785.7,1395.9,2784.9,1413.9z"
			/>
			<path
				className="st0page"
				d="M2028,1956.4c12.8-1.3,25.4-4.1,38.1-4.9c19.2-1.2,35.2,4.8,48.2,19c4.4,4.8,8.2,9.8,12.2,14.9
		c5.7,7.2,11.8,14,18.8,19.9c24.6,20.8,52.1,34.1,84.8,37.3c29.3,2.9,57.9,2.7,86.1-6.5c13.9-4.6,25.8-11.8,34.4-23.8
		c4.6-6.3,9.6-12.3,14.2-18.5c38.3-52.4,72.7-107.4,106.7-162.7c0.7-1.1,1.4-2.3,2-3.6c3.1-7.3,1.2-14.4-5.5-18.5
		c-3.1-1.9-6.7-3.2-10-4.9c-222.7-102.2-445.6-204.3-668.9-305.2c-78.7-35.6-157.4-71.4-236.9-105.1c-5.6-2.3-11-5.2-18.5-6.4
		c1.7,15.5,2.5,29.9,0,44.3c-11.2,65.1-76,111.9-139.5,100.7c-57-10-98.6-52.9-104.1-110.1c-8.7-91.2,82.7-155.3,159.6-128
		c14.4,5.1,27.7,12.5,41.4,19.1c213.8,101.4,428.5,200.8,642.9,301.1c7.7,3.7,14.7,3.3,22.4,0.6c48.6-17.3,97.3-34.6,146-51.9
		c13.9-5,22.7-1.8,29.4,11.2c13.2,25.5,26.3,51,39.3,76.6c7.3,14.4,4.4,25.9-8.6,35.4c-7.1,5.2-14.4,10-21.5,15.2
		c-6.5,4.8-6.4,6.8,0.8,10.3c14.3,6.9,28.7,13.5,43.1,20.3c25.1,11.8,50.1,23.6,75.1,35.5c4.7,2.2,9,3,13.2-0.7
		c3.7-3.3,3.8-7.6,2.6-12.2c-6.6-26.9-13.1-53.8-19.6-80.7c-0.8-3.4-1.4-6-6.3-6c-10.7,0.1-13.1-2-16.1-12.5
		c-9.5-32.8-19-65.5-28.2-98.3c-3.9-13.6-3.4-14.2,8.5-22.2c3.8-2.5,5.4-5.2,4.7-10c-4.8-35-21.5-62.1-51.9-80.5
		c-13.1-7.9-26.8-14.2-41-19.9c-36.3-14.6-72.7-28.9-108.9-43.9c-121.6-50.4-243.1-101-364.6-151.6c-54.5-22.7-110.2-42.6-164-66.9
		c-36.3-16.4-72.4-33.3-109.9-46.7c-32-11.4-64.6-19.9-98.4-22.6c-55.5-4.4-102.8,14.1-142.8,52.3c-18.7,17.9-33.6,38.8-47.9,60.1
		c-38.9,58.1-74.2,118.5-112.2,177.2c-1.3,1.9-2.2,4.2-3.2,6.3c-3.1,7.1-2.5,13.9,2.4,20c2.1,2.6,4.5,5.1,6.9,7.4
		c25.8,25.5,51.4,50.9,77.2,76.3c67.6,66.2,135.3,132.4,202.9,198.6c66.1,64.8,132,129.9,196.9,196.1c27.2,27.7,54.3,55.6,81.2,83.6
		c7.7,8,16,12.9,27.7,11.6c21.8-2.5,43.7-4.5,65.5-6.7c37.3-3.8,74.6-7.7,111.9-11.5C1973.6,1961.6,2000.8,1959.2,2028,1956.4z
		 M2088.5,1772.1c5.4-2.6,10.5-1.8,15.5,0.7c4.6,2.3,8.4,5.6,12.1,9.1c9.6,9.4,19.1,19.2,28.9,28.4c37.4,35.5,82,52.6,133.7,50
		c14.5-0.7,29.1-0.3,43.6,0.1c16.7,0.4,24.5,9.8,22.5,26.5c-1,8.5-5.6,14.2-13.9,16.8c-4,1.2-7.9,1.8-12,1.8c-12.2,0-24.3,0-37.9,0
		c-14.1,0.3-29.6,1.2-45.1-1.1c-25.5-3.9-48.9-12.8-71-25.8c-30.6-18-56.5-41.6-80-68.1c-2.7-3-5.1-6.4-6.7-10.1
		C2073.6,1790,2078.1,1777.2,2088.5,1772.1z"
			/>
			<path
				className="st0page"
				d="M1481.1,2820.1c-45.8-152.9-91.6-305.8-137.7-458.6c-94.7-314.3-189.7-628.7-284.6-942.9
		c-2.4-7.9-4.2-16.1-7.9-24.3c-2.5,1.5-2.4,2.9-2.5,4.2c-2.6,20.4-5.3,40.9-7.8,61.3c-3.5,28.5-8.1,56.8-16.8,84.3
		c-50.2,158.2-100.3,316.5-150.6,474.7c-8.9,28.2-14.5,56.7-13.7,86.6c0.8,29.7,0.4,59.4,0.5,89.3c0.4,125.8,0,251.4,1.3,377.2
		c0.7,64.2,0.8,128.4,1.3,192.6c0.1,18.6,0.6,37.1,1.2,55.7c0.4,11.7,4.6,15.7,15.9,16.6c1.7,0.1,3.3,0.1,5.1,0.2
		c86.1,1.1,172.3,2.3,258.4,3.4c69.3,0.9,138.6,1.8,207.8,2.7c36.1,0.5,72.3,1.1,108.4,1.7c4.8,0.1,9.4,0.1,14.2,0
		c10.9-0.4,13.1-3.1,10.7-13.9C1483.4,2827.2,1482.1,2823.7,1481.1,2820.1z"
			/>
			<path
				className="st0page"
				d="M1116.2,1348.1c6.4,3.9,10.2,1.5,11.4-5.7c0.3-1.6,0.2-3.3,0.4-5.1c2.8-33.7,5.7-67.3,8.6-101
		c3.9-45.1,7.6-90.2,11.6-135.3c2.1-24.5,4.4-49.1,6.9-73.6c3.6-33.6,7.6-67.1,11.3-100.7c3.7-33.3,6.9-66.5,10.4-99.8
		c2.1-20,9.6-36.8,24.1-51.9c61.7-64.4,121.7-130.4,181.3-197c14.2-15.8,28.8-31.3,43.2-47.1c7.4-8.1,6.2-15.1-3.7-20.2
		c-6.9-3.6-13.9-7-21-10.1c-8.2-3.7-15.8-2.4-22.3,4.1c-1.9,1.9-3.9,3.9-5.6,5.9c-63,72.4-127.9,143.1-192.6,214
		c-13.9,15.2-27.8,30.4-41.4,45.9c-8.3,9.5-14.7,20.3-16.8,33c-1.7,9.9-2.9,20.1-4.3,30.1c-5,39.2-10,78.4-14.9,117.6
		c-5.3,42.9-10.2,85.8-15.6,128.7c-5,39.9-10.3,79.7-15.3,119.6c-2.6,21.4-7,42.7-8.1,70.6
		C1059.9,1305.3,1084.7,1328.8,1116.2,1348.1z"
			/>
			<path
				className="st0page"
				d="M1498.7,986.6c12.6,7.9,26.4,13.4,40.5,18.2c6,2,9.4,0,12.6-5.6c1.1-2,1.9-4.3,2.7-6.5
		c16.3-47.3,36.3-93,55.6-139.1c5-11.9,12.4-21.5,22.3-29.5c10-8,19.9-16.2,29.5-24.7c55.9-50,109.9-102,164.6-153.4
		c1.7-1.6,3.4-3.2,4.8-5.2c5.8-8.1,3.8-17.1-5.5-20.8c-11.6-4.7-23.4-8.6-35.2-12.7c-2.8-1-5.9-1.2-7.1-1.4
		c-9.6,0.1-17.1,3.1-24.5,9.9c-5.7,5.3-11.1,11-16.5,16.4c-54.9,55.1-109.5,110.4-168,161.7c-9.1,8-15.9,17.1-20.4,28.4
		c-12.2,30.7-24.4,61.5-36.8,92.1c-7.3,18.2-14.9,36.2-22.2,54.4C1491.3,978.2,1491.9,982.2,1498.7,986.6z"
			/>
		</g>
	</svg>
);
export default PromocaoAgendadaIcon;
