import { KeyIcon } from '@heroicons/react/24/outline';
import { eplusHub } from '../api/eplus';
import { useSubMenu } from '../SubMenuContext';

interface ContaProps {
	conta: {
		id_conta: string;
		nome: string;
		chave_empreender: string;
		vencimento_segundos: string;
		inclusao_segundos: string;
	};
}

const PlusContaPreview = ({ conta }: ContaProps) => {
	const { appEndPoint, headers } = useSubMenu();

	const entrarNaConta = (id_conta: string) => {
		const options = {
			method: 'POST',
			credentials: 'include' as RequestCredentials,
			headers,
			body: JSON.stringify({
				acao: 'logarConta',
				id_conta,
			}),
		};

		eplusHub(options, appEndPoint).then((res) => {
			try {
				if (res.code === 200) window.location.href = res.data.link_magico_redirecionar;
			} catch (error) {
				console.log(error);
			}
		});
	};

	const currentTimestamp = Math.floor(Date.now() / 1000);
	const venceu = parseInt(conta.vencimento_segundos) <= currentTimestamp;

	const twelveHoursInMs = 12 * 60 * 60 * 1000;
	const targetDate = new Date(parseInt(conta.inclusao_segundos) * 1000);
	const now = new Date();

	const isWithin12Hours = now.getTime() - targetDate.getTime() <= twelveHoursInMs;

	return (
		<div className="bg-white border rounded-md !p-4 flex flex-row items-center justify-between">
			<div className="flex flex-col gap-2">
				<div className="flex flex-row items-center gap-1">
					<p className="font-medium">{conta.nome}</p>
					<span className={`size-2 ${parseInt(conta.vencimento_segundos) !== 0 ? (venceu ? 'bg-yellow-500' : 'bg-green-500') : ''} rounded-full relative top-[1px]`}></span>
				</div>
				<div className="w-full flex flex-row justify-start items-center gap-1">
					<span className="bg-gray-100 rounded-md px-2.5 py-1 flex flex-row gap-1 items-center text-xs">
						<KeyIcon className="size-3.5" />
						{conta.chave_empreender}
					</span>
					{isWithin12Hours ? <span className="text-green-500 !p-1 px-2 rounded-md border border-green-500 text-xs">Nova!</span> : null}
				</div>
			</div>
			<button onClick={() => entrarNaConta(conta.id_conta)} className="text-gray-900 border border-gray-900 py-1 px-4 rounded-md text-xs font-semibold hover:bg-gray-900 hover:text-white">
				Entrar
			</button>
		</div>
	);
};

export default PlusContaPreview;
