import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import LayoutNew from '../../../../components/tailwind/layout-new'
import TableProducts from '../../components/tailwind/table-products'

const Products = () => {

    const order = useSelector((state) => state.reducerGetOrder);
    const dispatch = useDispatch()
    const [products, setProducts] = useState(null)

    const { id } = useParams();

    const data = [
        {
            "id": 5382202,
            "order_id": 2911637,
            "product_id": 2022960,
            "created_at": "2024-09-20T09:50:00.000-03:00",
            "updated_at": "2024-09-20T09:50:00.000-03:00",
            "product": {
                "id": 2022960,
                "name": "Produto teste",
                "description": "<p>Produto teste&nbsp;Produto teste&nbsp;Produto teste&nbsp;Produto teste&nbsp;Produto teste&nbsp;Produto teste</p>\r\n",
                "image_url": "https://dcdn.mitiendanube.com/stores/002/274/798/products/produto-teste-ab34e4128c551e331a17011963516885-1024-1024.png",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/produto-teste/",
                "active": 1,
                "product_id": "192485596",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2023-12-19T14:24:13.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        },
        {
            "id": 5382203,
            "order_id": 2911637,
            "product_id": 2022954,
            "created_at": "2024-09-20T09:50:00.000-03:00",
            "updated_at": "2024-09-20T09:50:00.000-03:00",
            "product": {
                "id": 2022954,
                "name": "Caneca Lily Webhook Teste",
                "description": "<p><span style=\"box-sizing: border-box; margin: 0px; padding: 0px; font-weight: bolder; color: rgb(33, 37, 41); font-family: Rubik, sans-serif; font-size: 12px;\">Dimens&otilde;es:</span></p>\r\n\r\n<p style=\"box-sizing: border-box; margin: 0px 0px 1rem; paddi",
                "image_url": "",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/caneca-lily-webhook-teste/",
                "active": 1,
                "product_id": "128284973",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2023-12-19T14:24:13.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        },
        {
            "id": 5382204,
            "order_id": 2911637,
            "product_id": 2022953,
            "created_at": "2024-09-20T09:50:00.000-03:00",
            "updated_at": "2024-09-20T09:50:00.000-03:00",
            "product": {
                "id": 2022953,
                "name": "Caneca Lily",
                "description": "<p><span style=\"box-sizing: border-box; margin: 0px; padding: 0px; font-weight: bolder; color: rgb(33, 37, 41); font-family: Rubik, sans-serif; font-size: 12px;\">Dimens&otilde;es:</span></p>\r\n\r\n<p style=\"box-sizing: border-box; margin: 0px 0px 1rem; paddi",
                "image_url": "https://dcdn.mitiendanube.com/stores/002/274/798/products/lily-caneca-0011-c882f4b18d183097ed16576290340676-1024-1024.jpg",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/caneca-lily/",
                "active": 1,
                "product_id": "126242846",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2023-12-19T14:24:13.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        },
        {
            "id": 5382205,
            "order_id": 2911637,
            "product_id": 2022952,
            "created_at": "2024-09-20T09:50:00.000-03:00",
            "updated_at": "2024-09-20T09:50:00.000-03:00",
            "product": {
                "id": 2022952,
                "name": "Camiseta Lily 2",
                "description": "<p><span style=\"color: rgb(33, 37, 41); font-family: Rubik, sans-serif; font-size: 12px;\">A estampa &eacute; produzida com impress&atilde;o digital (DTG) e o processo &eacute; realizado com o que h&aacute; de mais moderno no mercado, em impressora espec&i",
                "image_url": "https://dcdn.mitiendanube.com/stores/002/274/798/products/lily00311-2c1fe8c5c22c2d5db316576289556404-1024-1024.jpg",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/camiseta-lily-2/",
                "active": 1,
                "product_id": "126242738",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2023-12-19T14:24:13.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        },
        {
            "id": 5382206,
            "order_id": 2911637,
            "product_id": 2736691,
            "created_at": "2024-09-20T09:50:01.000-03:00",
            "updated_at": "2024-09-20T09:50:01.000-03:00",
            "product": {
                "id": 2736691,
                "name": "Camiseta Corinthians Majestic Masculina - Preto",
                "description": "<p><span style=\"color: rgb(77, 77, 77); font-family: &quot;Open Sans&quot;, sans-serif; font-size: 14px;\">A Camiseta Corinthians Majestic Masculina &eacute; o manto oficial do torcedor que quer exibir a paix&atilde;o de ser Tim&atilde;o em qualquer lugar.",
                "image_url": "https://dcdn.mitiendanube.com/stores/002/274/798/products/d65-1047-006_zoom1-91228b1478b51167f617128728770070-1024-1024.webp",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/camiseta-corinthians-majestic-masculina-preto/",
                "active": 1,
                "product_id": "208391804",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2024-04-11T19:01:54.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        },
        {
            "id": 5382207,
            "order_id": 2911637,
            "product_id": 2222788,
            "created_at": "2024-09-20T09:50:01.000-03:00",
            "updated_at": "2024-09-20T09:50:01.000-03:00",
            "product": {
                "id": 2222788,
                "name": "Camiseta Básica Masculina",
                "description": "<p><span style=\"color: rgb(35, 31, 32); font-family: Futura, sans-serif; font-size: 16px;\">Com estilo e versatilidade, a Camiseta B&aacute;sica Masculina na cor preta &eacute; a pe&ccedil;a essencial no guarda-roupa masculino. Confeccionada em tecido maci",
                "image_url": "https://dcdn.mitiendanube.com/stores/002/274/798/products/wallpaper-2-ab962e28eb07c0a89f17062162096762-1024-1024.png",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/camiseta-basica-masculina/",
                "active": 1,
                "product_id": "198759911",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2024-01-25T17:57:46.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        },
        {
            "id": 5382208,
            "order_id": 2911637,
            "product_id": 2022957,
            "created_at": "2024-09-20T09:50:06.000-03:00",
            "updated_at": "2024-09-20T09:50:06.000-03:00",
            "product": {
                "id": 2022957,
                "name": "Camisa girafa oculos",
                "description": "<p>Camisa girafa oculos&nbsp;Camisa girafa oculos&nbsp;Camisa girafa oculos&nbsp;Camisa girafa oculos&nbsp;Camisa girafa oculos&nbsp;Camisa girafa oculos</p>\r\n",
                "image_url": "https://dcdn.mitiendanube.com/stores/002/274/798/products/girafa-02-e5c84863aec48b9b9417011962249358-1024-1024.jpg",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/camisa-girafa-oculos/",
                "active": 1,
                "product_id": "192485110",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2023-12-19T14:24:13.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        },
        {
            "id": 5382209,
            "order_id": 2911637,
            "product_id": 2022956,
            "created_at": "2024-09-20T09:50:08.000-03:00",
            "updated_at": "2024-09-20T09:50:08.000-03:00",
            "product": {
                "id": 2022956,
                "name": "Camisa girafa masculino",
                "description": "<p>Camisa girafa masculino&nbsp;&nbsp;Camisa girafa masculino&nbsp;&nbsp;Camisa girafa masculino&nbsp;Camisa girafa masculino&nbsp;Camisa girafa masculino&nbsp;Camisa girafa masculino&nbsp;&nbsp;Camisa girafa masculino&nbsp;</p>\r\n",
                "image_url": "https://dcdn.mitiendanube.com/stores/002/274/798/products/girafa-03-164862f2207591a43f17011961754929-1024-1024.jpg",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/camisa-girafa-masculino/",
                "active": 1,
                "product_id": "192484926",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2023-12-19T14:24:13.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        },
        {
            "id": 5382210,
            "order_id": 2911637,
            "product_id": 2022958,
            "created_at": "2024-09-20T09:50:10.000-03:00",
            "updated_at": "2024-09-20T09:50:10.000-03:00",
            "product": {
                "id": 2022958,
                "name": "Camisa girafa animada",
                "description": "<p>Camisa girafa animada&nbsp;Camisa girafa animada&nbsp;Camisa girafa animada&nbsp;Camisa girafa animada&nbsp;Camisa girafa animada</p>\r\n",
                "image_url": "https://dcdn.mitiendanube.com/stores/002/274/798/products/02-7d6513b269d4917df317011962634141-1024-1024.jpeg",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/camisa-girafa-animada/",
                "active": 1,
                "product_id": "192485261",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2023-12-19T14:24:13.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        },
        {
            "id": 5382211,
            "order_id": 2911637,
            "product_id": 2022955,
            "created_at": "2024-09-20T09:50:14.000-03:00",
            "updated_at": "2024-09-20T09:50:14.000-03:00",
            "product": {
                "id": 2022955,
                "name": "Camisa girafa amarela",
                "description": "<p>Camisa girafa amarela&nbsp;Camisa girafa amarela&nbsp;Camisa girafa amarela&nbsp;Camisa girafa amarela&nbsp;Camisa girafa amarela&nbsp;Camisa girafa amarela</p>\r\n",
                "image_url": "https://dcdn.mitiendanube.com/stores/002/274/798/products/girafa-01-7160362c8e4beda2d817011960994291-1024-1024.jpeg",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/camisa-girafa-amarela/",
                "active": 1,
                "product_id": "192484740",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2023-12-19T14:24:13.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        },
        {
            "id": 5382212,
            "order_id": 2911637,
            "product_id": 2022959,
            "created_at": "2024-09-20T09:50:15.000-03:00",
            "updated_at": "2024-09-20T09:50:15.000-03:00",
            "product": {
                "id": 2022959,
                "name": "Camisa branca girafa alegre",
                "description": "<p>Camisa branca girafa alegre&nbsp;Camisa branca girafa alegre&nbsp;Camisa branca girafa alegre&nbsp;Camisa branca girafa alegre&nbsp;Camisa branca girafa alegre</p>\r\n",
                "image_url": "https://dcdn.mitiendanube.com/stores/002/274/798/products/0123-b0866db2451c93f86217011963018625-1024-1024.jpeg",
                "canonical_url": "https://lily19.lojavirtualnuvem.com.br/produtos/camisa-branca-girafa-alegre/",
                "active": 1,
                "product_id": "192485425",
                "user_id": 1226,
                "integration_id": 29020,
                "created_at": "2023-12-19T14:24:13.000-03:00",
                "updated_at": "2024-10-21T13:25:38.000-03:00"
            }
        }
    ]

    useEffect(() => {
        //dispatch({ type: "SAGA_GET_SALE", payload: id, });
    }, [])

    // useEffect(() => {
    //     console.log("order", order)
    //     if (order?.data?.data?.order_items) {
    //         setProducts(JSON.parse(order?.data?.data?.order_items))
    //     }
    // }, [order])

    return (
        <>
            <TableProducts
                products={data}
            />
        </>
    )
}

export default Products