import { Box, Container, BoxContents, BoxMedias, ItemMedia, SubTitle, Profile, Avatar, BoxDate, IconType, } from "./styled"
import { AiFillClockCircle, AiFillStar } from "react-icons/ai";

import { getTranslation } from "../../../../translations";
import { useSelector } from "react-redux";
import { GoLinkExternal } from "react-icons/go";

const CardMural03 = (props) => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const configControl = useSelector(state => state.reducerControlConfigMural)

    const getIconType = () => {
        switch ("google") {
            case "google":
                return (
                    <IconType>
                        <img src={"https://imagepng.org/wp-content/uploads/2019/08/google-icon-1.png"} />
                    </IconType>
                )

            default:
                return null
        }
    }

    return (
        <Container
            backgrounColor={configControl.mural_background_color_card}
            borderColor={configControl.mural_border_color_card}
            borderSize={configControl.mural_border_size_card}
            borderRadius={configControl.mural_border_radius_card}
        >
            <Box>
                {getIconType()}
                <BoxContents color={""}>
                    <Profile>
                        {/* <Avatar>
                            <img src={"https://cdn.icon-icons.com/icons2/1999/PNG/512/avatar_people_person_profile_student_user_icon_123383.png"} />
                        </Avatar> */}
                        <span style={{ display: "flex", flexDirection: "column" }}>
                            <p style={{ fontFamily: `${configControl.mural_title_font_widget}`, fontSize: "16px", color: `${configControl.mural_font_color_card}` }}>
                                {props.item.name}
                            </p>
                            {/* <label style={{ fontFamily: `${configControl.mural_title_font_widget}`, fontSize: "10px", cursor: "pointer", color: `${configControl.mural_font_color_card}` }}>
                                {translation.thema.configs.verPublicacao}
                                <GoLinkExternal style={{ marginLeft: "5px", cursor: "pointer" }} size={14} />
                            </label> */}
                        </span>

                    </Profile>

                    <SubTitle>
                        <span>
                            <AiFillStar size={15} color={"orange"} />
                            <AiFillStar size={15} color={4 > 1 ? "orange" : "gray"} />
                            <AiFillStar size={15} color={4 > 2 ? "orange" : "gray"} />
                            <AiFillStar size={15} color={4 > 3 ? "orange" : "gray"} />
                            <AiFillStar size={15} color={5 > 4 ? "orange" : "gray"} />
                        </span>
                    </SubTitle>

                    <label style={{ fontFamily: `${configControl.mural_title_font_widget}`, color: `${configControl.mural_font_color_card}` }}>
                        {props.item.msg}
                    </label>

                    <BoxMedias>
                        {Array(0).fill(
                            <ItemMedia>
                                <img src={"https://i.ytimg.com/vi/zNSjRVvxm08/hqdefault.jpg"} />
                            </ItemMedia>
                        )}
                    </BoxMedias>
                    {configControl?.mural_visible_date_card == "false" ? null :
                        <BoxDate style={{ fontFamily: `${configControl.mural_title_font_widget}`, }}>
                            <label>01/01/2024</label>
                        </BoxDate>}
                </BoxContents>
            </Box>
        </Container >
    )
}

export default CardMural03
