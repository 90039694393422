const PromocaoAgendadaIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Camada_1" x={0} y={0} width={22} height={22} viewBox="0 0 3000 3000" {...props}>
		<style>{'.promoAgendada1{fill:#080413}.promoAgendada2{fill:#fefefe}.promoAgendada3{fill:#0a0413}'}</style>
		<path
			d="M1499.1 1537.4V287c0-7.7-6.3-13.8-13.8-13.8h-.1c-625.7 0-1137.8 486.5-1178.6 1102-47.3 712.1 545.8 1305.1 1257.9 1257.8 615.4-40.8 1102-552.9 1102-1178.6 0-87-9.4-171.9-27.3-253.7-5.6-25.7-32.4-40.8-57.3-32.2l-1078.8 371.8c-2 .6-4-.8-4-2.9z"
			style={{
				fill: '#ff0037',
			}}
		/>
		<path d="M1519.7 507.1c6.5 5.7 7.9 15.7 5.4 21.7-7.3 18.2-100.7 211.8-123 255.4-9.7 19.2-5.7 12.4-9 19.4-10.1 21.1-51.4 269.1-55.3 284.4 24.9-11 90.3-30.2 161.4-20 86.3 12.4 181 57.4 221.7 73.4 125.2 48.9 249.7 99.6 374.6 149.4 6.4 2.6 13.1 4 19.7 6 13.1.9 25.4 4.7 37.7 9.6 76.4 30.6 152.8 61.6 228.9 93 37.5 15.5 63.1 41.1 75.4 79 32.6 100.4 58.2 202.7 75.5 307 5.7 34.6-7.5 65.1-23.6 94.5-14.7 26.9-30.9 53.2-46.2 79.6-14.4 25-40.1 44-56.8 68.9-29.8 44.4-71.1 63.7-124.3 59.5-26.7-2.2-53.9 2.6-80.5-3.4-36-8-64.8-29-90-54-20.1-20-37.7-19.1-61.6-6.8-46 23.7-86.6 38.5-128.7 68.7-19.9 14.2-40.5 26.2-46.6 49.2-6.1 23.1-6.4 63.5-6.6 87.5-1.1 93.9 19.6 592.6 21.5 626.4 1.7 30.2-12.3 43.3-45.2 43.9l-986.3.6c-39.8-.6-40.4-1.1-40.8-39.8-1.5-161.8-6.7-689.3-6.8-768.6 0-21 3.6-41.4 9.9-61.3 52-162 102.4-324.6 154.6-486.6 18.6-57.8 21.5-118 29.2-177.4 24.4-187.8 45.9-376 70.3-563.8 3.5-26.8 11.9-58.2 29.9-78.2 64.5-71.4 240.9-256.4 258.5-271.4 7.8-6.7 13.1-11.5 30-4.9 29.9 10.3 101.7 38.3 127.1 59.1z" className="promoAgendada1" />
		<path d="M1980.4 836.1c-.1 3.2-.1 6.4-.1 9.5 0 35-20.1 177-42.7 256-10.9 37.8-33.6 48.9-70.3 35.5-110.8-40.2-220.4-82.9-328.4-129.9-38.4-16.7-45.2-30.8-30.2-70.4 19.7-52.3 41.2-110.8 65.7-161.1 7.2-14.8 13.4-21.6 25.2-33 58.2-56.2 120-110.5 176.8-168 24.6-24.9 35-36.2 68.4-23.7 25.1 9.3 76.2 25.8 98.4 34.2 35 13.3 32.9 19.8 33.5 62.4.6 46.5 4.9 153.2 3.7 188.5zM486.9 1374.3c33.5.2 53.7 19.2 53.7 45.9 0 26.3-20.7 41.1-53.9 41.5-49.1.6-205.6 1-234.8.7-30.4-.3-49.9-16.8-50.4-42.1-.6-24.8 18.6-44.2 48.7-45.8 30-.8 187.5-.1 236.7-.2z" className="promoAgendada1" />
		<path d="M2472.3 1771.7c-40.3-18.9-84.1-40.1-125.3-59.1-2.5-1.1-2.8-4.6-.5-6.1l26.6-18.8c10.7-7.5 14.1-21.6 8.2-33.2l-42-81.7c-4.8-9.3-15.8-13.5-25.6-9.8-37.5 14-130.2 46.3-156 55.2-4.3 1.5-9 1.2-13.1-.7-67.5-31.5-644.7-300.3-668.1-313.1-46.7-25.7-93.1-25.1-137.2 5.6-41.3 28.9-60.6 80.6-48.3 128.4 13.6 53 48.3 84.7 102 94.6 75.1 13.7 147.1-51.1 142.8-128.3-.3-6-1-12-1.6-18.5 15.3-.1 848 382.1 942.6 425.7 7.7 3.6 10.6 13 6.1 20.3-23.2 37.9-89.4 145-129 191.9-12 14.1-36.4 21-57.6 24.2-24.4 3.7-38.4 1.9-63.2-.7-32.6-3.4-59.4-18.2-83.8-39.6-20.6-18-25.8-39.8-53.5-49.3-20.4-7.1-52.6 2-70.9 3.7-78.1 6.7-163.3 16.3-241.4 23.8-24.8 2.4-25.5 4.4-44.4-15.3-157.2-164.1-332.6-331.7-494.4-491.2-22.4-22.1-44.7-44.6-67.4-66.6-10.3-10-11.6-18.5-3.1-31.8 42.1-65.1 81-132.4 125-196 44.5-64.4 105.6-87.9 186.7-79.6 93.6 9.5 174.4 50.9 258.6 83.8 196.7 76.7 392 157.1 588.5 234.4 46.8 18.4 84.9 41.5 93.8 95.9.9 5.4 2.8 9.4.7 12.8-.5.9-4.8 3.5-8.9 5.7-4.7 2.7-7.1 8-5.7 13.2 4.3 17.8 23.4 83 31.3 109.5 1.9 6.6 8.3 10.7 15.1 9.7l5.3-.7 21.6 89.7c2.2 8.7-6.2 15.6-13.9 12zM1044.2 1394.3c.2-1.5 2.5-1.7 2.9-.2 25.2 83.3 300.8 995.9 435.7 1447.3 7.6 25.4 7.6 25.7-18.4 25.3l-591.2-.8c-15.9-.2-20.1-5.3-20.4-21.6-3.6-243.5 1.5-501.1.2-744.6-.2-40.6 14.7-76.3 26.2-113.3 46.4-148.2 93.7-296.1 140.5-444.2 15.2-47.9 16.8-98.1 24.5-147.9zM1422.6 501.8c6.9 3.1 8.7 12.1 3.5 17.5-47.4 50.1-85 94.9-131.5 144.2-24.8 26.3-77.3 85.2-102.9 110.7-9.1 9-16.2 20.1-19.4 55.6-7.3 79.6-20.7 177.7-27.3 257.3-7 84.8-14.3 169.7-21.6 255.5-.4 4.3-4.9 7.1-8.8 5.2-14.2-6.7-33.3-25-37.4-29.4-20.2-21.9-20-50-15.3-82.7 16-110.2 28-220.9 41.7-331.4 4.4-35 8.9-70.1 13.9-105.1 2.5-17.4 12.7-31 24.2-43.7 72.5-79.8 156.3-170.5 227.1-251.8 10-11.8 17.1-20.3 34.1-11.5.6.3 19.3 9.4 19.7 9.6z" className="promoAgendada2" />
		<path d="M690.9 931c-3.5 13.2-13.2 21.8-26.7 15.2-37.8-18.6-74.9-39.1-111.7-59.8-8.6-4.9-11.3-14.8-6.1-24.5 5.6-10.7 16.2-13.6 25.7-8.7 36.9 19 73.2 39 109.5 58.9 6.5 3.6 9.3 10.1 9.3 18.9zM671.6 1874.4c8.4.6 14.8 4.6 17.8 12.5 3.6 9 1 17.9-6.9 22.4-36.8 20.6-74 40.8-111.5 60.1-8.7 4.5-18.4 1.5-24.2-7.8-5.1-8.3-4.6-18.1 2.9-23.1 37.2-24.6 77-44.6 117.1-63.7 1.6-.6 3.3-.3 4.8-.4zM961.8 453.9c8.4-4.9 18.2-3.7 23 3.8 21.8 34.4 35.8 72.7 50.6 105.3.6 14.6-3.2 21.7-12.4 24.8-9.2 3.2-18.2 0-22.1-8-16.8-34.4-33-69.2-48.4-104.3M2051.5 2345.1c1 14.7-3.3 21.3-12.3 24.6-9.3 3.5-18.2.1-22.1-7.7-16.8-34.4-32.9-69.2-48.2-104.3-3.5-8 .6-16.8 9.1-21.6 8.5-4.8 18.2-3.9 23 3.8 21.4 34.5 35.8 72.4 50.5 105.2z" className="promoAgendada3" />
		<path d="M1872.7 598.6c12.3 4.2 13.5 16.1 4 25-42.7 40-161.1 153.1-192.7 177.7-13.1 10.1-20.5 16.5-27.3 32.7-16.4 38.7-43.5 102.2-56.8 142-5 15.1-10.7 14.4-22.5 9.4-43.9-18.8-43.9-18.8-34-41 6.6-15.5 40.6-100.2 50.8-127 15.5-36 10.3-27.6 32.2-47.5 47.5-41.3 132.9-126.9 177.3-171.8 12.4-12.5 24.4-16.4 40.8-9.8 9.1 3.7 18.5 7 28.2 10.3z" className="promoAgendada2" />
		<path
			d="M2283.9 1909.9c-72.7 5.9-135.8-32.8-190.7-93.7-9.5-10.6-16.1-22-5.5-35.1 10.2-12.8 21.8-7.8 32.1 1.5 8 7.3 15.8 15 23.4 22.9 40.6 42.2 88.3 61.7 143.6 58.8 13.7-.7 27.6-.1 41.4-.2 13.5 0 25.2 4.7 25.6 21.6.5 18.9-12.1 23.7-26.3 24.1-12.1.3-24.2.1-43.6.1z"
			style={{
				fill: '#090514',
			}}
		/>
		<g id="Camada_4">
			<path d="M1031 558.2c5.8 13.2 2.9 24.1-7.7 28.7-10.3 4.6-20.8-.4-26.7-13.4-8.8-19.2-36.4-80.4-41.4-91.9-5.2-12.1-1.5-23.5 8.4-28 9.7-4.4 20.9 0 26.3 12.4 5.7 11.7 32.4 72.9 41.1 92.2zM2009.8 573.8c-5.2 13.4-15 19.1-25.7 14.9-10.6-4.1-14.3-15.1-9.4-28.5 7.3-19.9 31.2-82.6 35.8-94.3 4.8-12.2 15.5-17.7 25.7-13.8 9.9 3.8 14.9 14.8 9.8 27.5-4.4 12.1-28.7 74.3-36.2 94.2zM2351.3 940.2c-12.8 6.8-23.8 4.6-29.2-5.6-5.3-10-1-20.8 11.5-27.7 18.5-10.2 77.6-42 88.7-47.9 11.7-6 23.3-3.3 28.5 6.4 5 9.4 1.5 20.9-10.5 27.2-11.2 6.5-70.2 37.6-89 47.6z" className="promoAgendada1" />
		</g>
		<path d="M1544.5 385.4c-.2 33.5-16.6 53.6-43.4 53.6-26.3 0-43.5-20.6-44-53.8-.6-49.1-1-204.7-.7-233.9.3-30.4 18.9-51.2 44.3-51.2 24.8 0 44.4 19.7 44.2 51.2.5 29.9-.5 184.8-.4 234.1zM2513.1 1454.7c-33.5-.2-53.6-16.6-53.6-43.4 0-26.3 20.6-43.5 53.8-44 49.1-.6 204.7-1 233.9-.7 30.4.3 51.2 18.9 51.2 44.3 0 24.8-19.7 44.4-51.2 44.2-30 .5-184.8-.5-234.1-.4z" className="promoAgendada1" />
	</svg>
);
export default PromocaoAgendadaIcon;
