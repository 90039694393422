import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { EmpreenderApps } from '../lib/AppsArray';

interface ToggleMenuBoxProps {
	isOpen: boolean;
	app: string;
}

const searchApps = (keyword: string) => {
	return EmpreenderApps.filter((app) => app.tags.some((tag) => tag.toLowerCase().includes(keyword.toLowerCase())));
};

const ToggleMenuBox = ({ isOpen, app }: ToggleMenuBoxProps) => {
	const selectedApp = searchApps(app);

	return (
		<div className="w-[230px] h-9 bg-white flex items-center py-1.5 px-3 rounded-md gap-3 hover:bg-gray-100 transition select-none">
			<div className="text-gray-900">{selectedApp[0].icon}</div>
			<div className="flex flex-row w-full items-center justify-between !m-0">
				<span className="text-gray-800 font-semibold">{app}</span>

				<motion.div className="size-3 text-gray-500" animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.25, ease: [0, 0.71, 0.2, 1.01] }}>
					<ChevronDownIcon className="stroke-[2.5px]" />
				</motion.div>
			</div>
		</div>
	);
};

export default ToggleMenuBox;
