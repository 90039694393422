
const INITIAL_STATE = false

const ControlVisibleModalGoogle = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CONFIG_CONTROL_VISIBLE_MODAL_GOOGLE_SET':
            return action.payload
        case 'CONFIG_CONTROL_VISIBLE_MODAL_GOOGLE_GET':
            return state;
        default:
            return state;
    }
}

export default ControlVisibleModalGoogle