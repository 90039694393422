import { twMerge } from 'tailwind-merge';

interface SkelletonProps {
	width?: string;
	className?: string;
	center?: string;
}

const GradientSkeleton = () => {
	return <div className="w-full h-full bg-gradient-to-r from-transparent via-gray-300 to-transparent -translate-x-full animate-[shimmer_2s_infinite]"></div>;
};

const SkelletonLoader = ({ width, className, center }: SkelletonProps) => {
	const randomLoaderWidth = () => {
		const lengths = [10, 20, 30, 40, 50, 60, 70, 80, 90];

		return lengths[Math.floor(Math.random() * lengths.length)];
	};

	return (
		<div className={`w-full ${center ? 'flex items-center justify-center' : ''}`}>
			<div className={`flex space-x-4 ${center ? 'w-full' : ''}`}>
				<div className={`flex-1 ${center ? 'flex items-center justify-center' : ''}`}>
					<div className={twMerge('h-3.5 bg-gray-200 rounded relative overflow-hidden', className || '')} style={{ width: width || `${randomLoaderWidth()}%` }}>
						<GradientSkeleton />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SkelletonLoader;
