import React, { useState, useEffect } from 'react'
import { BoxButtons, BoxColors, ButtonHelp, ButtonReset, ButtonSave, Container, Item, ItemOption, Main, MenuOption, Title } from './styled'


import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { useSelector, useDispatch } from 'react-redux';
import { getTranslation } from '../../../../translations';

import { IoIosArrowBack } from "react-icons/io";
import { useHistory, useLocation } from 'react-router';
import { InputTextColor } from '../bar-config-email-cores/styled';


const BarConfigMuralColor = () => {
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const dispatch = useDispatch()
    const selectedMenu = useLocation();
    const history = useHistory()
    const config = useSelector(state => state.reducerGetConfigMural)
    const configControl = useSelector(state => state.reducerControlConfigMural)
    const [theme, setTheme] = useState("")

    const saveTheme = () => {

        dispatch({
            //type: "SAGA_UPDATE_CONFIG_MURAL", payload: {...config.data[0], data_config: JSON.stringify(configControl) }
            type: "SAGA_UPDATE_CONFIG_MURAL", payload: {
                id: config.data[0]?.id,
                data_config: JSON.stringify(configControl)
            }
        })

    }

    const getDefaultTheme = () => {
        const themaDefault = {
            mural_background_color_widget: "#ffffff",
            mural_background_color_card: "#FFFFFF",
            mural_font_color_widget: "#222222",
            mural_font_color_card: "#222222",
            mural_border_color_card: "#F2f2f2",

            mural_title_text_widget: configControl.mural_title_text_widget,
            mural_title_font_widget: configControl.mural_title_font_widget,
            mural_title_size_widget: configControl.mural_title_size_widget,
            mural_title_position_widget: configControl.mural_title_position_widget,
            mural_model_widget: configControl.mural_model_widget,
            mural_model_card: configControl.mural_model_card,
            mural_border_size_card: configControl.mural_border_size_card,
            mural_border_radius_card: configControl.mural_border_radius_card,
        }
        dispatch({
            type: "SAGA_UPDATE_CONFIG_MURAL", payload: {
                id: config.data[0]?.id,
                data_config: JSON.stringify(themaDefault)
            }
        })
    }


    const isSaveMuralColor = () => {

        const data = JSON.parse(config.data[0]?.data_config)

        if (
            configControl.mural_background_color_widget == data.mural_background_color_widget  &&
            configControl.mural_background_color_card == data.mural_background_color_card &&
            configControl.mural_font_color_widget == data.mural_font_color_widget &&
            configControl.mural_font_color_card == data.mural_font_color_card &&
            configControl.mural_border_color_card == data.mural_border_color_card
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_MURAL_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_MURAL_COLOR_SET", payload: false })
            return false
        }
    }


    return (
        <Main>
            <Container>
                <Title>
                    {/* <span onClick={() => { history.push("/personalizar") }}>
                        <IoIosArrowBack />
                    </span> */}
                    <label>{"Mural"} </label>
                </Title>

                <MenuOption>
                    <ItemOption active={true} onClick={() => {
                        dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "mural/cores" })
                    }}>
                        {translation.thema.boxConfigTema.cores}
                    </ItemOption>
                    <ItemOption active={false} onClick={() => {
                        isSaveMuralColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "mural/config" })
                    }}>
                        {translation.thema.boxConfigTema.avancado}
                    </ItemOption>
                </MenuOption>

                {/* <BoxTema>
                    <label>{translation.thema.boxConfigTema.combinacaoCores.toUpperCase()}:</label>
                    <SelectCustom name="select_theme_reviews" onChange={(event) => { setTheme(event.target.value) }}                        >
                        <option value="light" selected={theme == "light" ? true : false}>Light</option>
                        <option value="dark" selected={theme == "dark" ? true : false}>Dark</option>
                        <option disabled value="custom" selected={theme == "custom" ? true : false}>{translation.thema.boxConfigTema.personalizado}</option>
                    </SelectCustom>

                </BoxTema> */}

                <BoxColors>
                    <label>{"Widget"}:</label>
                    <Item>
                        <label>{translation.thema.configs.fundo}</label>
                        <span>
                            <input type={"color"} value={configControl.mural_background_color_widget} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_background_color_widget: e.target.value } }) }} />
                            <InputTextColor type='text' value={configControl.mural_background_color_widget} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_background_color_widget: e.target.value } }) }} />
                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.configs.fonte}</label>
                        <span>
                            <input type={"color"} value={configControl.mural_font_color_widget} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_font_color_widget: e.target.value } }) }} />
                            <InputTextColor type='text' value={configControl.mural_font_color_widget} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_font_color_widget: e.target.value } }) }} />
                        </span>
                    </Item>


                </BoxColors>

                <BoxColors>
                    <label>{translation.thema.configs.card}:</label>
                    <Item>
                        <label>{translation.thema.configs.fundo}</label>
                        <span>
                            <input type={"color"} value={configControl.mural_background_color_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_background_color_card: e.target.value } }) }} />
                            <InputTextColor type='text' value={configControl.mural_background_color_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_background_color_card: e.target.value } }) }} />
                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.configs.borda}</label>
                        <span>
                            <input type={"color"} value={configControl.mural_border_color_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_border_color_card: e.target.value } }) }} />
                            <InputTextColor type='text' value={configControl.mural_border_color_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_border_color_card: e.target.value } }) }} />
                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.configs.fonte}</label>
                        <span>
                            <input type={"color"} value={configControl.mural_font_color_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_font_color_card: e.target.value } }) }} />
                            <InputTextColor type='text' value={configControl.mural_font_color_card} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_font_color_card: e.target.value } }) }} />
                        </span>
                    </Item>

                </BoxColors>

                <ButtonHelp>
                    <img src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons style={{ marginBottom: "10px" }} >
                    <ButtonReset onClick={() => getDefaultTheme()}>
                        <label>{translation.thema.boxConfigTema.restaurar}</label>
                    </ButtonReset>
                    <ButtonSave onClick={() => { saveTheme() }}>
                        {translation.thema.boxConfigTema.salvar}
                    </ButtonSave>
                </BoxButtons>

            </Container >
        </Main>

    )
}

export default BarConfigMuralColor