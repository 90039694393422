interface FetchOptions {
	method?: string;
	credentials?: RequestCredentials;
	body?: string;
	headers?: Record<string, string>;
	[key: string]: unknown;
}

export const eplusHub = async (options: FetchOptions, endpoint?: string) => {
	if (!endpoint) {
		throw new Error(' necessário definir "appEndPoint" no componente da Sidebar.');
	}

	return fetch(`https://${endpoint}/eplus/hub`, options)
		.then((res) => res.json())
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log({ err });
			// throw err;
		});
};
