const ReplayIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<path
				className="st0replay"
				d="M2556.4,1437.2c-1.1-3.7-5.4-5.5-8.6-3.4c-11.5,7-28.9,27.6-40.7,37.8c-72.8,63-145.7,120.9-227.8,173.5
		c-43.7,28-88.9,52.5-135.2,74.1l0,0c0,0,0,0-0.1,0l-0.1-0.1c-92.9,43.4-317,120.3-642.1,179.5c-142.6,26-292.4,26.5-447,17
		c-13.9-1-30.2,16.5-30.1,30.5l-3.4,110.3c0.1,15.6,5.6,27.1,21.1,28.9c124.8,14.3,253.9,13.5,436.3-2c4.7-0.4,43-6.2,47.6-7
		c57-8.5,105.9-18.4,152.4-29.8c0.1,0,0.3-0.1,0.4-0.1c78.6-19.3,150.7-43.2,243.5-72.9c117.5-37.4,231.4-82.6,336.9-142.7
		c0.1,0,0.1-0.1,0.2-0.1l0,0c95-54,183.1-120.2,261.1-204c23.7-25.3,48.6-48.2,55.3-77.6C2580.4,1531.2,2562.7,1460.7,2556.4,1437.2
		z"
			/>
			<path
				className="st0replay"
				d="M2465.8,913.3c4.6,72.3-48.4,26.9-60.6,16.3c-38.6-33.2-203.5-181-436.1-255.7c-31.7-10.2-63-21.1-94.4-32.4
		c-35.1-12.6-43.2-30.9-27.6-64.9c41.5-90,91.7-175.4,142.5-260.5c27.4-46,54.2-92.3,87.8-134.1c38.3-47.6,63.9-48.3,120.6-0.6
		c64.8,69.6,209.8,206,240.1,484.2C2451.2,725,2464.3,891.3,2465.8,913.3z"
			/>
			<g>
				<path
					className="st0replay"
					d="M2868.1,1475.1c-49.1-36.8-118.2-51.6-161.8-64c-34.4-11.1-34-1.2-33.9,27.4c0.1,27.1,9.6,108,12.5,133.4
			c4.6,38-2.6,100.3-26.2,132.4c-26.6,36.1-57.6,68.4-91.8,97.5c-77,65.6-162.1,119.8-245.8,176.2
			c-133.7,90-282.7,143.4-437.8,181.2c-104,25.3-209.5,41.6-315.8,54.9c-131.3,16.4-248.9,32.9-381.4,23
			c-75.5-5.7-140.1-14.3-221.8-34.1c-26-6.2-47.8-18.1-52.1-43c-22.1-128.1-8.7-271.6,11.2-345.6c15.3-57.1,68.4-48.1,78.9-47.8
			c156.6,5,311.6-9.9,465.8-36.8c207.7-36.4,413.7-79,607.6-164.9c116-51.4,223.5-117.1,319-201.8c36.3-32,146.7-124.5,121.5-168.8
			c-42.4-74.5-88.1-144.2-153.2-201.7c-96.6-85.7-201.3-158-318.2-212.7c-116.5-54.4-239.4-85.1-367.3-94.4
			c-101.1-7.4-202.3,1.5-300.5,23.9c-46.7,10.7-75.4-2.7-104.8-31.6c-5-4.9-9.9-9.9-15.1-14.6c-53.4-48.1-106.6-96.1-166.2-137.1
			c-115-79.3-240.5-138.2-364.7-199.9c-14.2-7-28.9-12.6-44.1-17.1c-55-16.4-84-3.8-108.9,47.6c-18.3,38-31.9,78.1-44.8,117.9
			c-30.2,93.6-53.3,188.4-60.9,287.4c-6.1,80.8-7,161.6-1.7,241.9c3,45.7-7.2,82.3-31.2,118.3c-15.2,22.8-26.6,47.5-37.4,72.5
			c-82.6,189.2-124.2,386.8-117.9,593.5c0.9,28-5.6,53.4-18.3,78.3c-45.6,91-132.2,222.9-159.3,325.4
			c-17.4,66.2,93.4,185.5,132.6,226.8c20.6,21.6,43.1,41.3,67.4,58.6c98.2,69.8,201.7,131,311.9,179.4
			c221.6,97.3,448.2,178.1,692.9,192.9c90.1,5.5,180.5,4.4,270.6-0.3c26.3-1.3,257.9-16.1,338.1-6.8c51.8,5.9,277.9,12.1,278.1,11.4
			c90.6,3.7,167.8-25,226.8-96.7c62.4-75.9,97.7-170.6,104.7-267.3c2.6-34.9,2-62.8,10.5-95.9c8.5-33,20.9-65,39.3-95.9
			c38.6-64.8,74.2-131.3,109.4-197.9c50.1-94.7,98.2-238,139.8-336.6c24.9-59,46.2-119.5,63.9-181.1
			C2908.2,1511.5,2903.8,1506.5,2868.1,1475.1z M2393.3,2393.4c-3.6,38.3-10.3,80.4-25.7,120.7c-7.1,18.5-19.1,26.8-38.8,16.7
			c-42.4-21.4-82.2-46.7-115.4-81c-18.7-19.5-17.7-31.2,4.4-47.2c39.3-28.3,83-48.5,129.6-61.2
			C2379.1,2332.8,2394.2,2349.3,2393.3,2393.4z"
				/>
			</g>
		</g>
	</svg>
);
export default ReplayIconBranco;
