const MelhorApp = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<g id="Camada_3"></g>
			<path
				className="st0lp"
				d="M552.2,1367.4c739.6,247.9,1479.2,495.9,2218.7,743.8c11.5,3.9,7.5,21.1-4.6,19.4
		c-880.5-123.3-1761-246.7-2641.5-370.1c-8.4-1.2-11.6-11.7-5.2-17.5c140.9-124.6,281.8-249.1,422.7-373.8
		C545.1,1367.1,548.9,1366.3,552.2,1367.4z"
			/>
			<path
				className="st0lp"
				d="M1780.8,1646.8c7.9,2.7-2.9-5,4.1-1c24.1,11,25.2,11.5,49.3,22.6c153.6,72.3,303.3,141.6,457.9,210.9
		c6,3,11,6,17.1,9c77.3,33.1,151.6,69.3,229,103.4c6,3,11,6,17.1,9c76.3,34.1,149.6,69.3,226,103.4c6,3,11,6,17.1,8
		c6,2,12.1,5,18.1,7c2,1,3,0,5,1c20.1,9,33,15.9,54.1,21.9c0,0,2.5,1.6,2.7,1.6c2.1,0.7,2.9-3.3,1.1-4.5c-1-0.6-1.9-1.2-2.6-1.9
		c-35.1-27.1-67.3-51.2-101.4-78.3c-64.3-51.2-122.5-95.4-187.8-145.6c-121.5-91.4-229-172.7-349.5-265.1c-11-8-21.1-16.1-32.1-25.1
		c-4-2-8-5-12.1-7c-142.6-111.5-279.2-211.9-416.1-321.3"
			/>
			<path className="st0lp" d="M1192.6,996.7c-114.6,71.1-225,138.4-343.5,213.7c-2,2-1,6,1,7c102.4,47.2,110,50.9,211.3,97.2" />
			<path
				className="st0lp"
				d="M675,1837.7c35.9,91.5,71.7,182.9,107.7,274.4c3.5,8.9,12.1,14.8,21.6,14.9c687.5,8.6,1386.1,8.1,2073.4,17
		c-365.3-50-730.6-100.3-1095.9-151.1C1412.7,1941.4,1043.9,1889.7,675,1837.7z"
			/>
			<path className="st0lp" d="M848,1214.9c32.8,93.8,54.6,179.9,87.5,273.7" />
			<path className="st1lplp" d="M842.1,2118.8c-33.7-87.4-67.4-174.7-101.1-262.1" />
			<path className="st1lplp" d="M892.7,2124.3c-33.7-87.4-67.4-174.7-101.1-262.1" />
			<path className="st1lplp" d="M943.2,2127.8c-33.7-87.4-67.4-174.7-101.1-262.1" />
			<path className="st1lplp" d="M988.4,2124.3c-33.7-87.4-67.4-174.7-101.1-262.1" />
			<path className="st1lplp" d="M1038,2120.3c-33.9-88.1-64.3-166.6-97.7-253.3" />
			<path className="st1lplp" d="M1089.6,2127.8c-33.7-87.4-61-158-94.7-245.3" />
			<path className="st1lplp" d="M1138.2,2124.3c-33.7-87.4-57.4-148.7-91.1-236.1" />
			<path className="st1lplp" d="M1190.7,2134.2c-33.7-87.4-59.9-155.2-93.6-242.5" />
			<path className="st1lplp" d="M1238.2,2126.5c-33.7-87.4-53.8-139.5-87.6-226.9" />
			<path className="st1lplp" d="M1288.8,2131.9c-33.7-87.4-52.4-135.8-86.1-223.1" />
			<path className="st1lplp" d="M1339.4,2135.4c-33.7-87.4-51.9-134.5-85.6-221.8" />
			<path className="st1lplp" d="M1384.5,2131.9c-33.7-87.4-46.3-120-80-207.4" />
			<path className="st1lplp" d="M1435.1,2128.1c-33.7-87.4-44.3-114.7-78-202.1" />
			<path className="st1lplp" d="M1485.6,2135.4c-33.7-87.4-44-114.1-77.7-201.4" />
			<path className="st1lplp" d="M1536.2,2131.9c-33.7-87.4-39.5-102.1-73.1-189.5" />
			<path className="st1lplp" d="M1586.7,2141.9c-33.7-87.4-42-108.9-75.7-196.2" />
			<path className="st1lplp" d="M1631.4,2130.4c-33.7-87.4-34.6-89.9-68.4-177.2" />
			<path className="st1lplp" d="M1682,2135.8c-33.7-87.4-33.7-87.4-67.4-174.7" />
			<path className="st1lplp" d="M1732.6,2139.3c-33.7-87.4-33.7-87.4-67.4-174.7" />
			<path className="st1lplp" d="M1777.7,2135.8c-33.7-87.4-29-75.1-62.8-162.5" />
			<path className="st1lplp" d="M1828.3,2131.9c-33.7-87.4-22.8-58.9-56.4-146.3" />
			<path className="st1lplp" d="M1878.9,2139.3c-33.7-87.4-20.1-52.1-53.8-139.5" />
			<path className="st1lplp" d="M1929.4,2135.8c-33.7-87.4-19.7-51.1-53.4-138.5" />
			<path className="st1lplp" d="M1980,2145.7c-33.7-87.4-19.2-49.6-52.9-137" />
			<path className="st1lplp" d="M2027.5,2138.1c-33.7-87.4-8.1-21.1-41.9-108.5" />
			<path className="st1lplp" d="M2078.1,2143.4c-33.7-87.4-6-15.7-39.8-103" />
			<path className="st1lplp" d="M2128.6,2147c-33.7-87.4-7.4-19.2-41.1-106.5" />
			<path className="st1lplp" d="M2173.8,2143.4c-33.7-87.4-2.7-7-36.5-94.4" />
			<path className="st1lplp" d="M2224.3,2139.6c-33.7-87.4-2.2-5.6-35.9-93" />
			<path className="st1lplp" d="M2274.9,2147c-33.7-87.4-1.3-3.5-35-90.9" />
			<path className="st1lplp" d="M2325.5,2143.4c-33.7-87.4-0.9-2.2-34.5-89.6" />
			<path className="st1lplp" d="M2376.1,2153.5c-33.7-87.4-0.6-1.5-34.3-88.9" />
			<path className="st1lplp" d="M2426.7,2153.5c-33.7-87.4,1.8,4.6-31.9-82.7" />
			<path className="st1lplp" d="M2477.2,2147c-33.7-87.4,4.8,12.6-28.8-74.8" />
			<line className="st1lplp" x1="994.4" y1="1508.8" x2="912" y2="1245.9" />
			<line className="st1lplp" x1="1027.6" y1="1443.9" x2="974.4" y2="1279.3" />
			<path className="st2lp" d="M1023.1,1303" />
			<path className="st2lp" d="M1124.2,1565.1" />
			<path className="st2lp" d="M1075.7,1316.1" />
			<path className="st2lp" d="M1176.8,1578.2" />
			<path className="st2lp" d="M1132.6,1335.7" />
			<path className="st2lp" d="M1230.4,1589.1" />
			<path className="st2lp" d="M1192.6,1363.1" />
			<path className="st2lp" d="M1287.3,1608.4" />
			<path className="st2lp" d="M1257.3,1396.2" />
			<path className="st2lp" d="M1348.4,1632.3" />
			<path className="st2lp" d="M1674.9,1589.1" />
			<path className="st2lp" d="M1741.5,1761.6" />
			<path className="st1lplp" d="M1857.9,1810.2c-33.7-87.4-31.2-80.9-65-168.3" />
			<path className="st1lplp" d="M1914.9,1826.6c-33.7-87.4-25.8-67-59.6-154.3" />
			<path className="st1lplp" d="M1974.4,1854.9c-33.7-87.4-25.2-65.4-58.9-152.7" />
			<path className="st1lplp" d="M2025,1858.4c-33.7-87.4-13.8-35.8-47.5-123.1" />
			<path className="st1lplp" d="M2080.7,1882.4c-33.7-87.4-13.1-33.7-46.7-121.1" />
			<path className="st1lplp" d="M2136.8,1906.7c-33.7-87.4-10.9-28.3-44.7-115.7" />
			<path className="st1lplp" d="M2191,1919.3c-33.7-87.4-6.1-15.9-39.9-103.2" />
			<path className="st1lplp" d="M2251,1940.3c-33.7-87.4-3.5-9-37.2-96.4" />
			<path className="st1lplp" d="M2306.1,1962.1c-33.7-87.4-1.8-4.7-35.5-92.1" />
			<path className="st1lplp" d="M2362.3,1976.9c-33.7-87.4,0,0-33.7-87.4" />
			<path className="st1lplp" d="M2417.7,1995c-33.7-87.4,3.2,8.4-30.4-78.9" />
			<path className="st1lplp" d="M2472.1,2008.2c-33.7-87.4,7.5,19.6-26.2-67.8" />
			<path className="st1lplp" d="M2529.8,2037.3c-33.7-87.4,5.8,15.1-27.9-72.3" />
			<path className="st0lp" d="M1725,984.5l222.7,90.4c4.9,2.1,5.2,9,0.4,11.3l-192.6,93.9" />
			<g>
				<path
					className="st3lp"
					d="M1756.8,1190.5c-29.9-242.6-48.1-323.2-137.2-376.8c-118.4-71.3-257.5,7.9-271.2,15.3
			c-67.2,35.8-126.5,93.4-194.7,230.9c-51.2,103.4-91,226.9-140.1,454.4l775.6,264.3C1789.2,1778.6,1778.1,1363.1,1756.8,1190.5z"
				/>
			</g>
			<g>
				<path
					className="st4lp"
					d="M1468.2,969.8c-75.2,0-136.3,61-136.3,136.3c0,75.2,61,136.3,136.3,136.3s136.3-61,136.3-136.3
			S1543.5,969.8,1468.2,969.8z M1468.2,1173.6c-37.4,0-67.6-30.2-67.6-67.6c0-37.4,30.2-67.6,67.6-67.6s67.6,30.2,67.6,67.6
			C1535.8,1143.3,1505.6,1173.6,1468.2,1173.6z"
				/>
			</g>
		</g>
	</svg>
);
export default MelhorApp;
