const DropiIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Camada_1"
		x={0}
		y={0}
		width={22}
        height={22}
		viewBox="0 0 3000 3000"
		{...props}>
		<style>{'.dropi1{fill:#080413}'}</style>
		<path
			d="M1759.1 1096.8c-222.6 0-403.1 180.4-403.1 403.1 0 222.6 180.4 403.1 403.1 403.1 222.6 0 403.1-180.4 403.1-403.1s-180.6-403.1-403.1-403.1zm0 537c-72.8 0-131.8-59-131.8-131.8 0-72.8 59-131.8 131.8-131.8 72.8 0 131.8 59 131.8 131.8 0 72.8-59 131.8-131.8 131.8z"
			style={{
				fill: '#008dfe',
			}}
		/>
		<path d="M2630.7 1300.5c-.1-11.9 1.9-24.4-6.6-34.7-68.4-95.5-161.3-150.9-277.6-165.2-119.9-14.7-226 18.1-316.5 98.9-63.1 61.1-112.7 130.6-128.3 218.9-13.3 74.8-14.3 150.9-13.2 226.7 1.1 71.8-1.4 548.8-1.8 625.8 0 5.9 4.6 10.6 10.4 10.8 24.5.7 85.2 1.9 112.1-3.5 101.3-20.3 162.5-96.5 162.7-200.5.1-63.3 0-126.6 0-191.1 0-1.5 1.4-2.5 2.8-2.1 62.3 18 123.2 23.5 185.4 13.1 107.1-18 194-69.3 258.9-157 7.1-7.9 7.1-17.9 6.9-27.4-3.4-137.6 6.5-275.1 4.8-412.7zm-208.6 200.7c-1.3 68.4-58 124.7-125.4 124.6-68.4-.1-125.3-58.1-124.9-127.4.4-67.5 57.9-124.8 125.3-124.5 68.7.2 126.4 58.8 125 127.3z" className="dropi1" />
		<path
			d="M2618.5 1713.7c-1.7-138.9 3.6-277.6 5.3-416.4.3-24.1 4.8-52.1 18.2-78.7 35.6-70.3 92.7-107.9 171.1-111.9h78.4c4.6 0 8.3 3.7 8.3 8.3.1 59.1.7 420.7-1.1 602.1-.9 98.5-84.9 180.5-183.4 184.5-19.8.8-68.9 1-88.7 1-4.6 0-8.4-3.8-8.3-8.4.6-48 .3-169.6.2-180.5z"
			style={{
				fill: '#fe186d',
			}}
		/>
		<circle
			cx={2762}
			cy={888.7}
			r={126}
			style={{
				fill: '#febc00',
			}}
		/>
		<path d="M1628 1442.3c-12-92.1-20.8-110.5-61.8-167.2-11.8-15.9-25.1-30.6-39.5-44.3-9.8-8.9-23.1-20.5-33.8-28.6-.6-.5-1.3-1-1.9-1.5-.2-.2-.4-.3-.6-.5-134.8-102.1-295.8-118.8-449.5-48.6-3.3 1.3-3.5.8-4 .5-28.4-22.4-71-45-106.9-46.2-12.4-.4-20.3-.3-23.1-.2-.6 0-1.1-.5-1.1-1.1 0-18.1-.3-254.2.8-363 .2-19.6 1.4-23.9-12.3-23.9-19.9 0-51.5.2-71.8.1-82.2.2-155.4 53.6-183.2 131-12.4 34.5-9.7 70-9.9 105.3-.3 53.6-.1 107.2-.1 161.6-221.5-64.3-409.2 46.7-488.2 206.8-83.4 169.2-36.9 376.1 110.7 492.3 88.4 69.6 189 98.1 300.7 84.6 112.2-13.6 201.9-68.3 275.5-159.3 0 43.4-.3 129.8-.3 155.4 0 4.1 3.3 7.3 7.3 7.3h261.8c4.1 0 7.3-3.3 7.3-7.4-.8-122.1.3-264.7.7-386 0-4.2.2-8.3.6-12.4v-3.1c0-69.8 56.1-126.4 125.3-126.4 69.2 0 125.3 56.6 125.3 126.4V1496.5c91.7 1.3 179.4 2.8 271.2 4.1.4-15.3 2.6-39.3.8-58.3zM502.2 1625.6c-67.4-.9-124.7-58.5-124.9-125.7-.2-68 59.1-126.9 126.8-126.1 67.6.9 124.6 58.3 124.9 125.7.2 68.1-59 127-126.8 126.1z" className="dropi1" />
	</svg>
);
export default DropiIcon;
