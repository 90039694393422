const App365Icon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Camada_1" x={0} y={0} viewBox="0 0 3000 3000" width="22" height="22" {...props}>
		<style>{'.fill365{fill:#04d9a6 !important;}.fill2365{fill:#00b086 !important;}'}</style>
		<g id="Layer_2_1_">
			<g id="Layer_1-2_1_">
				<path d="M282.6 556.4c-93.7 0-170.5 73.1-176.1 165.5-6.2 102.9 74.8 187.3 178 187.3h225.6c2.3 0 4.6-.9 6.3-2.3l385.4-333.3c6.8-5.9 2.6-17.1-6.3-17.1l-612.9-.1zM1855.3 556.4h815.3c97.4 0 176.4 78.9 176.4 176.4 0 97.4-78.9 176.4-176.4 176.4h-815.3c-97.4 0-176.4-78.9-176.4-176.4.1-97.4 79-176.4 176.4-176.4z" />
			</g>
		</g>
		<path
			d="M2900 1739.6c.1 333.5-223.4 610.5-543.6 686.2-331.9 78.5-680.5-101.7-807.7-417.6-34-84.5 5.1-179.9 87.9-214.4 84.2-35 178.8 1.8 214.5 85.8 52.9 124.4 143.9 204.3 276.4 230.2 266.6 52 469.4-151.7 458.3-379.9-8.8-181.3-133.2-320.6-313.5-352-103.7-18-198.1-.9-279 71.6-24.5 21.9-198 147.8-291 1.3-44.7-70.3-37.8-154.8 14.1-220.1 85.8-107.9 204.3-149.8 332.7-174.6 51.2-9.9 103.3-10.3 154.9-14.1 93.6-6.9 204.7 11 289.1 48.2 240.6 106.5 406.7 327 406.9 649.4z"
			style={{
				fill: '#080413',
			}}
		/>
		<path d="M411.7 1890.3c-29-83.6-120.3-127.9-203.9-98.9s-127.9 120.3-98.9 203.9c2.2 6.5 5 12.9 8.1 19.1 33.9 79.4 81.1 149.1 137.8 207.6l235.3-216.2c-32.8-32.4-59.6-71.5-78.4-115.5zM987.1 1107.5 1272 867.2c74.6-62.5 84.3-173.6 21.7-248-.1-.1-.2-.3-.3-.4-62.5-74.6-173.6-84.3-248-21.7-.1.1-.3.2-.4.3l-749.1 630.8c-10.5 8.3-20 17.8-28.2 28.2-49.9 65.6-47.6 157.2 5.6 220.2 62.8 74.2 173.8 83.8 248.4 21.5l118.9-99.1c43.6-13.2 89.2-18 134.5-14.2 103.1 4.3 194.3 51.1 257.5 122.8l227.9-209.4c-73.2-84.4-166.8-150.4-273.4-190.7z" className="fill365" />
		<path d="M1013.3 2374.5c346.9-148 508.2-549.3 360.1-896.3-28.2-66.2-66.4-126.8-112.7-180.1l-227.9 209.4c59.7 67.8 94.5 157.8 90.5 255.2-8.3 200.6-177.5 356.3-378 348-97.4 0-188.4-39-255-104.9L255 2221.9c191 197 490.7 266.7 758.3 152.6z" className="fill2365" />
		<text
			className="fill2365"
			style={{
				fontSize: '11.6489px',
				fontFamily: '&quot',
			}}
			transform="translate(1223.607 1517.324)">
			{'00B086'}
		</text>
	</svg>
);
export default App365Icon;
