const SakIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<path
				className="st0Sak"
				d="M2064.5,235.4C1451.1-76.2,701,168.3,389.4,781.6s-67.1,1363.3,546.2,1675l836.2,418.3
		c32.5,16.5,68.5,25,105,25c75.6-0.4,146.3-36.9,190.4-98.5c44.3-61.1,56-140,31.2-211.3l-43.6-128.7
		c239.6-119.2,434.5-312.4,555.8-551C2922.4,1297.1,2677.8,547.1,2064.5,235.4z M2564.8,1539.9c-0.2,1.4-0.5,2.7-0.7,4.1
		c-0.1,0.4-0.1,0.8-0.2,1.2c-42.9,228.1-158,435.8-326.1,592.7c-1.9,1.8-3.8,3.5-5.7,5.3c-1.1,1-2.2,2-3.3,3
		c-107.7,98.1-235.4,174.8-376.2,223.4l91.9,271c2.2,6.7,3.5,13.3,3.8,19.7c0,0.2,0,0.3,0,0.5c0,0.5,0,0.9,0,1.4
		c0.2,11.8-2.3,23.5-7.7,34.3c-1.7,3.4-3.6,6.5-5.7,9.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.5,0.7-1,1.3-1.5,2c-0.4,0.5-0.8,1-1.1,1.5
		c0,0-0.1,0.1-0.1,0.1c-3.2,3.9-6.7,7.4-10.6,10.6c-0.2,0.1-0.4,0.3-0.5,0.4c-0.4,0.4-0.9,0.7-1.4,1.1
		c-21.5,16.3-51.2,20.1-76.8,7.2l-836.4-418.5c-278.6-142.4-482.2-398.3-558-701.8c-6.8-27-12.4-54-17-81c-0.4-2.3-0.8-4.7-1.2-7
		c-0.2-1.2-0.4-2.3-0.6-3.5c-7.6-47.9-12.1-97-13.2-146.8v-48.1c2.3-104.8,19.4-205.9,49.4-301.4c0.4-1.3,0.8-2.6,1.3-4
		c0.8-2.4,1.6-4.9,2.4-7.3c0.9-2.7,1.8-5.4,2.7-8c0.2-0.7,0.4-1.3,0.7-2c30-88.7,71.4-173,122.7-250.8c1-1.4,1.9-2.9,2.8-4.3
		c0.4-0.5,0.7-1.1,1.1-1.6c56.1-83.5,123.4-158.9,199.9-223.8c73.4-62.1,155.3-114.7,243.6-155.7c61.1-28.3,125.8-51.3,193.6-68.3
		c0.4-0.1,0.9-0.2,1.3-0.3c4.1-0.4,8.1-0.7,12.4-0.7c51.9,0,95.4,36.3,106.5,84.7l196.3,521c39,103.3,134.5,174.3,244.7,181.5
		c248.8,16.3,666.7,43.6,668.6,43.6c55.5,0,101.2,41.1,108.5,94.6c0.1,1.2,0.3,2.3,0.4,3.5c0,0,0,0,0,0c0.3,3.6,0.6,7.4,0.6,11.1
		c0,0,0,0.1,0,0.1h0.8c3.8,36.9,5.6,74,5.6,111.9C2582.1,1412.3,2576.2,1477.1,2564.8,1539.9z"
			/>
			<path
				className="st0Sak"
				d="M1499.4,1908.7c-310.3,0-562.5-252.4-562.5-562.5c0-52.1-42.2-94.1-94.1-94.1c-52.1,0-94.1,42.2-94.1,94.1
		c0,414,336.7,750.7,750.7,750.7c51.9,0,94.3-41.9,94.3-94C1593.5,1950.8,1551.3,1908.7,1499.4,1908.7z"
			/>
		</g>
	</svg>
);
export default SakIconBranco;
