import React from 'react';

const ParcelamentoIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 934.92 1079.57" {...props}>
		<defs></defs>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<polygon className="clspar-1" points="132.27 345.01 132.27 885.94 0 809.6 0 270.01 132.27 345.01" />
				<polygon className="clspar-1" points="934.92 269.79 806.72 344.86 617.67 235.7 469.63 150.22 321.56 235.7 204.09 303.55 132.27 345.01 0 270.01 99.55 212.53 262.39 118.51 467.64 0 672.86 118.51 934.92 269.79" />
				<polygon className="clspar-1" points="934.92 269.79 934.92 809.78 783.94 896.95 512.89 1053.43 467.61 1079.57 467.61 927.86 469.6 929.02 502.29 910.15 697.81 797.27 806.72 734.41 806.72 344.86 934.92 269.79" />
				<polygon className="clspar-1" points="467.61 927.86 467.61 1079.57 422.36 1053.43 396.86 1038.69 262.7 961.25 262.7 809.56 418.57 899.55 436.98 910.15 467.61 927.86" />
				<polygon className="clspar-1" points="467.52 539.8 467.52 776.82 447.65 765.35 436.46 758.87 262.18 658.28 262.18 421.32 467.52 539.8" />
				<polygon className="clspar-1" points="672.71 421.23 672.71 658.37 606.42 696.62 487.39 765.35 467.52 776.82 467.52 539.8 672.71 421.23" />
				<polygon className="clspar-1" points="672.71 421.23 467.52 539.8 262.18 421.32 305.9 396.1 377.42 354.82 467.52 302.78 557.66 354.82 672.71 421.23" />
			</g>
		</g>
	</svg>
);

export default ParcelamentoIconBranco;
