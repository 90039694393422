const LilyIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<style type="text/css">{'.st0lily{fill:none;stroke:#FFFFFF;stroke-width:48.9506;stroke-miterlimit:10;}.st2lily{fill:none;}'}</style>
		<g>
			<g>
				<g>
					<g>
						<g>
							<g>
								<path
									className="st0lily"
									d="M1770.5,2772.3c149-185.5,588.8-1177.7,787.9-1660.5c53-128.5,115.2-254.3,133.4-394.9
							c11.7-90.4,4.4-179.9-34-261.5c-63.4-135.1-175.5-205.7-324.2-215c-144.5-9.1-289-15.9-433.8-16.6
							c-44.5-0.2-38.1-2.1-52.9-42.6c-2.1-5.6-4-11.2-6.5-16.6c-31-65.2-122.2-88-172.6-36.2c-17.3,17.7-37.5,52-50,73.2
							c-7,11.9-9.1,16.7-24.1,16.6c-158.5-0.6-291.5,0.8-449.9,1.6c-14.1,0-13.7-4.8-20.7-15.9c-15.2-24.5-29.6-53.8-51.3-73.5
							c-43-38.7-115-44.9-158.4,8.4c-12.5,15.3-27.9,51.7-32.6,70.5c-2.8,11.4-2.1,16-15.2,16.2c-45.2,0.7-58.2,0.5-103.1,4.5
							c-65.8,5.8-132.3,2.5-197.4,19.5c-119,31.1-195.8,110.8-234.5,221.1c-46,131.4-30.9,266.4,21.8,393.1
							c172.4,414.5,366,819.6,559.8,1224.4c50.4,105.2,102.8,210.5,158.6,313.1c0.1-0.1,34.8,63.5,34.9,63.4"
								/>
							</g>
						</g>
					</g>
				</g>
				<path
					className="st1lily"
					d="M1520.7,857.6c7-26,14.6-59.5,34.7-78.6c8.3-7.9,18.1-12.2,30.6-18.2c21-8.6,49.9-23.9,68.1-27.6
			c7.3-1.5,5.7-2.1,12.8,1.9c25.5,14.2,59.2,36.9,70.6,46.5c25.2,14.8,32.3,78.4,35.1,115.2c0.8,11.4-8.6,9-14.6,7.4
			c-73.2-19.6-147.5-30.5-224.9-30.3C1524.6,873.8,1518.5,865.8,1520.7,857.6z"
				/>
				<g>
					<path
						className="st2lily"
						d="M2061.9,1632.3c-5-0.1-10,2-15,2.5c-63.8,7-123.4-0.7-171.2-49.6c-12.6-12.8-27.7-19.8-42.2-4.9
				c-14.6,15-6.2,29.1,5.9,42.2c45.9,49.7,105.8,63.6,173.5,68.7c16.5-2.4,36.7-4.8,56.8-8.5c14.5-2.7,22.6-12.8,21-27.4
				C2089,1639.1,2077,1632.8,2061.9,1632.3z"
					/>
					<path
						className="st2lily"
						d="M1262.3,2300.1c-21.1,29-28.4,63.1-30,93.3c-0.1,43.7,10.8,79.1,30.9,111.5c32.4,52.3,101,60.5,144.2,17.6
				c53.6-53.1,65.4-144.8,27.2-209.8C1391.1,2238.8,1311.6,2232.5,1262.3,2300.1z"
					/>
					<path
						className="st1lily"
						d="M1054.2,2414.6L1054.2,2414.6c0,0.1,0,0.2,0.1,0.3c4,15.6,101.8,201.8,145.2,282.7
				c73.2,136.3,195.8,198.9,342.7,202.4c104,2.5,195.3-45.7,261.9-130.7c0.5-0.6,0.8-0.9,1.5-2c-92-8.6-155.8-61.2-196.1-139.2
				c-37.8-73.2-49.8-148.4-36.9-223.3c6-35,17.5-69.9,34.4-104.6c25.2-52,67.5-88.6,123.3-107.9c7-2.4,6.9-2.5,14.3-3.7
				c0.4,1.7,0.9,3.2,1.4,4.9c0.2-0.6,0.2-1.2,0-1.9c-0.6-2.3-1.2-4.6-1.9-6.8c-23.2-90.5-49.7-180.1-72.8-270.6
				c-5.7-22.3-9.1-29.4-20.8-33c-46.7-14.5-101.3-54.6-137.1-86c-5.7-5-28.8-25-25.3-32.9c4.3-9.6,30.8,5.1,38.5,8.1
				c36.5,14.6,70.9,30.1,111.6,43.4c24.6,8,53.1,11.7,77.9,19.3l0,0c0.7,2.4,183.5,49.1,261.6,32.5c65.6-13.9,99-36.7,153.6-78.4
				c124.8-95.6,174.1-232.3,193.5-379.8c15-114.5-38-204.2-133.3-267.4c-103.8-68.8-228.1-101-347.2-129.7c-0.4-0.1-0.7-0.2-1.1-0.3
				l0,0c0.4,0.1,0.7,0.2,1.1,0.3c-18.5-5.4-37-11-55.7-16.1c-100.1-26.7-200.8-45.7-305.5-38.6c-55,3.7-109.7,10.3-164.3,16.9
				c-17.8,2.1-26.7-4.3-32.7-22.1c-14.6-43.2-32.3-85.4-48-128.3c-11.1-30.3-32.4-46.2-62.1-60.4c-25.9-11.6-54.8-23-80.1-32.5
				c-7.3-2.7-10.8-2-15.9,2.7c-18.2,16.5-39,36.1-58.1,56.9c-25.9,28.1-35.7,53.2-32.6,87.9c5.1,55.8,9.5,111.8,14.1,167.6
				c0.7,9.1,3.5,18.9-5.9,25.7c0.2,1.2,0.5,2.4,0.7,3.6c0,0,0,0.1,0,0.2c14.3,74.6-6,154.6-74.2,183c-25.4,10.6-52.1,12.2-76.2,0.1
				c-40.2-20.2-73.7-48.2-85.1-94.9c-0.2-0.8-0.4-1.7-0.6-2.4c-0.1-0.4-0.2-0.7-0.3-1.1c-0.1-0.3-0.2-0.7-0.3-1
				c-0.1-0.5-0.3-1-0.4-1.6c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.7-0.4-1.4-0.5-2c-0.6-2.3-1.1-4.3-1.6-6.1c0,0-1.7,0.4-3.5,0.9
				c-57.5,15.3-72.2,35.3-49.2,91.5c9.9,24.3,21.9,47.6,36.2,69.6c62,95.2,158.2,128,267,94.2c32.8-10.1,65-22.4,98.5-30.9
				c3.3-0.8,5.8,3.1,3.6,5.7c-32.9,39.9-67.9,76.1-122.6,83.9c-8.5,1.2-12.1,4.3-11.1,12.9c6.7,63.6,13.3,131.2,20,194.9
				c-0.1-0.8-0.1-1.7-0.2-2.3c138.1-4.8,232,89.9,255,224c16.8,98.1,5.9,190.5-71.3,262.8c-38.5,36-82.4,65.5-137.7,70.4
				c-1.2,0.7,6.7,168.7,10.3,188.1l0.1-0.1 M1407.5,2522.6c-43.2,42.9-111.9,34.7-144.2-17.6c-20.1-32.5-31-67.8-30.9-111.5
				c1.6-30.2,8.9-64.4,30-93.3c49.3-67.6,128.8-61.3,172.4,12.7C1472.9,2377.8,1461.1,2469.5,1407.5,2522.6z M1637.2,1353.4
				c-16.6,15.1-34.8,12.1-50.6-1.8c-10.8-9.5-19.6-21.2-29.7-31.6c-45.4-45.8-107.1-50-158.4-11c-4.7,3.5-9.4,7.4-14.5,10.4
				c-20.1,12.2-41,9.7-52.1-7.1c-12.5-18.9-7.6-36.2,8.3-50.1c37.5-32.6,79.5-55.1,130.3-51.5c75.7-2,126.9,39.2,169.8,94
				C1652.6,1320.5,1652.9,1339.1,1637.2,1353.4z M2069.7,1682.7c-20.1,3.7-40.3,6.1-56.8,8.5c-67.7-5.1-127.6-19.1-173.5-68.7
				c-12.2-13.1-20.5-27.2-5.9-42.2c14.5-14.9,29.6-7.9,42.2,4.9c47.8,48.8,107.4,56.6,171.2,49.6c5-0.5,10-2.6,15-2.5
				c15.1,0.5,27.2,6.8,28.8,23C2092.3,1669.9,2084.2,1680,2069.7,1682.7z"
					/>
				</g>
			</g>
			<path
				className="st1lily"
				d="M989.5,190.9c-2.1,0.4-5.4,1.4-9,3.5c-14,8.2-16.6,27.2-18.3,37.4c-7.4,42.7-14.4,85.4-19.6,128.4
		c-1.9,15.5-8,21-22.7,24.6c-49.5,12.1-98.5,25.7-147.6,39.4c-15.4,4.3-33.2,8.1-35.3,28.1c-2.2,20.8,14.7,29.6,29.7,37.3
		c40.8,20.9,81.7,41.9,123.5,60.4c17.2,7.6,21.9,17.4,20.2,34.7c-3.3,33.8-5.4,67.9-7.7,101.9c-0.9,14.4-2.5,29.1-0.8,43.3
		c3,26.9,20.2,34.6,43.2,20.6c5.8-3.5,10.9-8.1,16.5-12.1c34.2-24.5,61.1-56.8,92.5-84.4c3-2.7,7.4-5.1,11.2-6.5
		c5.9-1.9,12.5-2.1,18.6-0.1c5,1.6,10,3.1,15,4.9c37.3,13.6,74.2,28.2,111.8,40.9c14.1,4.8,30.6,10.7,43.2-2.7
		c12.5-13.2,6.2-29.5,0.6-43.3c-14.6-36-29.2-72.3-47.1-106.6c-10.8-20.8-12.7-28.3,2.8-44.9c27.9-29.6,58.1-67.2,84-98.6
		c10.2-12.5,22.1-26.6,11.7-43.4c-9.9-15.9-27.6-14.8-43.8-12.6c-39.6,5.1-87.9,11-127.2,17.7c-20.6,3.5-24.8-0.8-35.9-21.4
		c-22.9-42.5-49.2-83.3-74.9-124.4c-7.8-12.5-16.9-25.2-33.8-22.3c-0.2,0-0.4,0.1-0.6,0.1H989.5z"
			/>
			<path
				className="st1lily"
				d="M1994.8,438.8c-4.7-18.1-22-22.3-38.1-25c-39.3-6.9-87.2-15.4-126.8-20.7c-20.7-2.7-23.4-8.2-28-31
		c-9.3-47.4-22.3-94.2-34.7-140.9c-3.7-14.3-8.6-29.1-25.8-31.2c-0.2,0-0.3,0-0.5,0l0.1-0.4c-3.2-0.2-6.6,0-9.8,0.7
		c-15.4,3.7-22.1,17.5-28.6,30.3c-19.7,38.6-39.1,77.3-56.8,116.9c-6.4,14.3-13.9,17.7-29,16.8c-50.8-3.1-101.8-4.6-152.6-6.1
		c-16-0.5-34.2-2.1-42.1,16.4c-8.2,19.3,5.3,32.6,17.3,44.4c32.8,32.1,65.6,64.2,100.1,94.3c14.2,12.4,15.7,23.1,9.1,39.2
		c-13.2,31.3-25.3,63.2-37.5,95c-5.1,13.4-11,27-13.6,41.1c-5.1,26.6,9.1,39.1,35.2,32.5c6.7-1.7,12.8-4.5,19.3-6.7
		c39.9-13.2,75.2-36.1,113.3-53.2c3.9-1.7,7.8-2.6,11.9-2.7l-0.1,0.4c6.5-0.2,12.9,1.7,18.5,5.4c4.4,2.9,8.6,5.9,12.9,9.2
		c31.6,24.1,62.5,49,94.6,72.2c12,8.7,26,19.3,42.1,10.2c15.8-9,14.7-26.3,13.4-41.2c-3.2-38.7-6.5-77.7-13.3-115.8
		c-4.2-23.1-3.7-30.8,15.9-42.1c35.6-20.3,75.6-47.3,109.7-69.6C1984.3,467.9,1999.8,458,1994.8,438.8z"
			/>
		</g>
	</svg>
);
export default LilyIcon;
