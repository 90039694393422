import { ReactNode } from 'react';

interface ContentAppProps {
	children: ReactNode;
}

const ContentApp = ({ children }: ContentAppProps) => {
	return <>{children}</>;
};
export default ContentApp;
