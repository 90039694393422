import * as Modal from '@radix-ui/react-dialog';
import { ButtonHTMLAttributes, ReactNode } from 'react';

interface ModalProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: ReactNode;
}

const ModalTrigger = ({ children, ...props }: ModalProps) => {
	return (
		<Modal.Trigger asChild {...props}>
			{children}
		</Modal.Trigger>
	);
};

export default ModalTrigger;
