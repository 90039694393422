import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import store from "./store";
import { Provider } from 'react-redux';
import { BurgerMenuProvider } from './hooks/useBurgerMenu'
import "../src/assets/style.css"
import "../src/assets/styleteste.css"
import 'react-phone-input-2/lib/style.css'
import { Helmet } from 'react-helmet';
import "./index.css"
import BarPlus from './components/bar-plus';
//import  "./styles/scrollbar.css"
import "./empreenderUI/styles/empreender.css";


ReactDOM.render(
  <React.StrictMode>
    <BarPlus />
    <Provider store={store}>
      <BurgerMenuProvider>
        <App />
      </BurgerMenuProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

