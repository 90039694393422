const ChegueiIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<style type="text/css">{'.ereee21e{display:none;}.chegueisdsdsd{display:inline;}.chegieuiooo{fill:#080413;}.dsdddddddddd{fill:#FEFEFE;}.eldenring{fill:#FDFDFD;}.fdfdfdfdfdf{fill:#FDFDFE;}.tttt4t4{fill:#FEFD00;}.vbvcb{fill:#FFE000;}.hngn{fill:none;}'}</style>
		<g className="ereee21e">
			<g id="Camada_4" className="chegueisdsdsd">
				<path
					className="st2ch"
					d="M2317.5,956.1c0,441.1-376.4,889.1-817.5,889.1s-859.4-439.5-859.4-880.6S1038.2,248,1479.3,248
			S2317.5,515,2317.5,956.1z"
				/>
			</g>
			<g className="chegueisdsdsd">
				<g>
					<path
						className="chegieuiooo"
						d="M1464.2,2693.5c-35,102.9-116.4,145.8-206.4,176.5c-93.9,32-191.3,38.6-290.1,34
				c-85.6-4-170.7-12-251.9-39.9c-176.6-60.7-288.1-186-345.5-361.2c-45.3-138.2-47.9-278.9-24.5-421.7
				c32.6-198.3,107.2-379.6,213.9-549c33.8-53.6,121.9-199.2,134.4-217c5.5-7.8,15-25.1,19.5-25.3c0,0,11,21,15.3,29.8
				c25.7,52.3,60.2,92.7,96.2,137.4c81.3,101,193.4,150.4,314.2,184.9c145.6,41.5,339.7,62,491.4,50.8
				c178.7-13.2,322.8-85.1,463.5-201.4c58.5-48.4,90-76.3,127.1-142.9c7.2-12.8,14.3-30.1,14.3-30.1c3.9,0.9,13.6,24.2,19.6,34.4
				c28.6,49,223.5,366.6,271.3,487.7c58,146.9,96.5,298.6,82.4,457.3c-16.7,187-77.5,356-229.9,479
				c-74.1,59.8-161.4,89.4-254.2,104.6c-113.8,18.7-228.2,26.3-343.2,14.7c-73.8-7.4-144.8-25.4-208.5-65.4
				C1521.9,2798.8,1481.9,2757.4,1464.2,2693.5z"
					/>
					<path
						className="chegieuiooo"
						d="M1148.8,667.3c115.5,5.4,206.1,48.8,238.5,180.6c13.2,53.7-11.9,90.7-67.1,93.2
				c-86.9,4-144,52.9-187.7,121.1c-24.4,38.1-56.3,62.9-98.9,76.1c-68.9,21.4-167.7-50.8-176.3-117.8
				C830.8,813.9,986.3,674.4,1148.8,667.3z"
					/>
					<path
						className="chegieuiooo"
						d="M1826.9,667.3c103.7-0.2,261.7,89.6,284.3,246.3c4.5,31.3,12.4,62,6.5,94.5
				c-11.8,65.1-65.3,119.1-133.8,132.4c-49,9.5-109-25.3-143.4-78.5c-44-68.1-100.9-116.9-187.9-120.9
				c-55.1-2.5-80.1-39.5-66.9-93.3C1618.5,714.5,1711.9,671.6,1826.9,667.3z"
					/>
					<path
						className="chegieuiooo"
						d="M2133,185.5c147.7-1.9,239.2,89.1,279.4,224.3c19.2,64.5,17.8,130.5-4.3,195c-20,58.3-66.3,87.7-120.5,108.4
				c-13.7,5.2-16.9-5.8-19.8-12C2208,576.3,2122,476.5,2009.3,397.5c-24.7-17.3-51.4-30.9-78.6-45.7c-14.5-7.9-17-13.8-11.2-30.8
				C1950.7,229.5,1998.9,187.6,2133,185.5z"
					/>
					<path
						className="chegieuiooo"
						d="M852.1,175.4c30.4-0.5,59.9,3.9,89.4,10.7c73.1,16.9,104.9,71.5,126.2,135.9c4.5,13.6-7.2,16-14.6,19.7
				c-77.4,38.2-146.1,88.1-208.3,147.6C786,545.5,739.5,610.8,703,683.1c-8,15.9-14.6,16.9-29.9,10.6
				c-72.4-30-104.8-87.7-112.6-161.6c-9.5-90.9,12.4-174.1,66.4-248.3C682.5,207.5,759.3,174.6,852.1,175.4z"
					/>
					<path
						className="chegieuiooo"
						d="M1490.2,1274.9c-32.4-3-61.4-9.3-87.4-26.7c-22.8-15.3-47.2-28.2-70.2-43.4c-32.9-21.6-58-48.2-53.2-92.1
				c3.9-36,29-61.1,64.9-65.2c34.9-4,68,5.8,101.6,11.5c39.3,6.6,78,6.5,116.5-5.2c14.3-4.3,29.1-6.6,44.4-6.4
				c56.8,0.9,90.2,55.5,64.1,106c-2.7,5.2-5.6,10.5-9.6,14.6c-43.2,44.8-89,86-150.9,103.5
				C1503.2,1273.7,1495.6,1274.1,1490.2,1274.9z"
					/>
					<path
						className="dsdddddddddd"
						d="M972.4,2100.3c0.6-95.8,21.6-185.1,82-262.2c23.6-30.1,54.5-51.9,85.3-73.6c14-9.8,29.3-9.5,40,5.5
				c10.7,14.9,5.2,28.7-7.5,40c-5.6,5-11.6,9.7-17.8,13.9c-78.1,52.7-110.3,130.4-121.8,220.1c-4.1,31.8-4.5,63.5-4.4,95.3
				c0.1,20-6.7,36.4-29,36c-22.8-0.4-27.8-17.4-26.8-37.3C972.9,2125.5,972.4,2112.9,972.4,2100.3z"
					/>
					<path
						className="dsdddddddddd"
						d="M1945.5,2100.4c0,15.1,0.2,30.2,0,45.3c-0.2,17.7-8.9,29.5-26.9,29.8s-27.7-11.6-28.4-28.8
				c-1.4-34.3-0.4-68.5-4.8-102.8c-11.5-88.8-43.1-165.9-119.9-218.6c-6.2-4.3-12.3-8.8-18-13.6c-13.6-11.5-20.7-25.6-9.1-41.6
				c11.6-16.1,27.2-14.2,42.2-4.1c53.7,36,98,80,125,140.3C1933.3,1968.1,1945.9,2032.8,1945.5,2100.4z"
					/>
					<path
						className="eldenring"
						d="M1091.6,2081c-5.6-87.6,34.9-154.2,96.6-210.9c13.8-12.7,29.6-18.4,44.3-2.8c14.6,15.6,8.1,31.1-5.5,44
				c-34.4,32.7-63.8,68.1-75,116c-5.4,23.3-2.6,46.6-3.1,70c-0.6,27.6-10.2,42.7-29,41.3c-25-1.8-27.6-20.2-27.5-40
				C1092.4,2092.7,1091.8,2086.9,1091.6,2081z"
					/>
					<path
						className="fdfdfdfdfdf"
						d="M1768.9,2073.5c4.7-63.4-26-110.7-68.4-152.7c-24.8-24.5-29.3-40.7-14.6-54.8c13.9-13.4,30.1-9,53.8,13.6
				c65.4,62.2,95.9,137.2,85.5,227.9c-2.1,18.4-9.1,31.4-28.7,30.9c-19.5-0.6-27.1-14.3-27.5-32.4
				C1768.7,2095.1,1768.9,2084.2,1768.9,2073.5z"
					/>
				</g>
				<g>
					<path
						className="tttt4t4"
						d="M1835.7,2367.6c-46.5-20.6-62.9-62.4-77.1-105.8c-22.5-68.3-34.2-139.3-50.3-209.2
				c-19.3-83.9-86.1-150.5-190.4-165.8c8.9-11.6,14.3-26.1,14.3-41.8c0-36.8-29-66.8-65.4-68.4v773.1c0.3,0.7,0.7,1.7,0.7,1.7
				l0.8-2.4c21.2-57.3,66.7-87.9,124.3-102.1c56.7-14,113.6-27,170.8-38.9c23.7-4.9,47.7-9.5,68.9-21.7
				C1838.2,2383,1852.8,2375.2,1835.7,2367.6z"
					/>
					<path
						className="vbvcb"
						d="M1463.7,1776.6c-37.8,0-68.4,30.6-68.4,68.4c0,15.9,5.4,30.5,14.5,42.1c-102.5,15.7-179.9,84-204.9,180.5
				c-14.2,54.8-26.8,110.1-41,164.9c-13.5,52.1-28.2,103.7-77.9,135.1c-9.8,6.2-9,13.6,1.2,18.8c8.9,4.5,18,8.7,27.5,11.7
				c53.3,17.3,108.5,26.4,163.7,34.7c79.8,11.9,155.3,29.3,188.2,116.2c0,0,0.1,0.3,0.2,0.7v-773.1
				C1465.7,1776.6,1464.7,1776.6,1463.7,1776.6z"
					/>
				</g>
			</g>
			<g id="Camada_3" className="chegueisdsdsd"></g>
		</g>
		<g>
			<g>
				<g>
					<path
						className="st2ch"
						d="M779.4,1266.9c25.2,51.3,59,90.9,94.3,134.8c79.7,99.1,189.7,147.5,308.1,181.3
				c142.8,40.7,333.1,60.8,481.9,49.8c175.3-12.9,316.6-83.5,454.6-197.5c57.4-47.5,88.3-74.8,124.6-140.1c0.3-0.6,0.7-1.2,1-1.8
				c3.9-7.9,7.7-15.9,11.4-23.9c1-2.4,1.6-3.8,1.6-3.8h0.1c51.2-112.5,80.2-234.2,80.2-355.3c0-432.6-389.4-694.4-822-694.4
				S692.8,486,692.8,918.6c0,108.8,25.9,217.3,71.6,319l0,0C764.4,1237.7,775.2,1258.3,779.4,1266.9z M1619.7,804.1
				c32.2-130.7,123.8-172.8,236.5-177c101.7-0.2,256.7,87.9,278.8,241.6c4.4,30.7,12.2,60.8,6.4,92.7
				c-11.6,63.8-64,116.8-131.2,129.8c-48.1,9.3-106.9-24.8-140.6-77c-43.2-66.8-99-114.6-184.3-118.6
				C1631.2,893.1,1606.7,856.9,1619.7,804.1z M1596.8,1006.1c14-4.2,28.5-6.5,43.5-6.3c55.7,0.9,88.5,54.4,62.9,104
				c-2.6,5.1-5.5,10.3-9.4,14.3c-42.4,43.9-87.3,84.3-148,101.5c-7.1,2.2-14.5,2.5-19.8,3.3c-31.8-2.9-60.2-9.1-85.7-26.2
				c-22.4-15-46.3-27.7-68.8-42.6c-32.3-21.2-56.9-47.3-52.2-90.3c3.8-35.3,28.4-59.9,63.6-63.9c34.2-3.9,66.7,5.7,99.6,11.3
				C1521.1,1017.7,1559.1,1017.6,1596.8,1006.1z M1191.2,627.1c113.3,5.3,202.1,47.9,233.9,177.1c12.9,52.7-11.7,89-65.8,91.4
				c-85.2,3.9-141.2,51.9-184.1,118.8c-23.9,37.4-55.2,61.7-97,74.6c-67.6,21-164.5-49.8-172.9-115.5
				C879.3,770.8,1031.8,634,1191.2,627.1z"
					/>
					<path
						className="st2ch"
						d="M2196.3,108.3c144.9-1.9,234.6,87.4,274,220c18.8,63.3,17.5,128-4.2,191.2c-19.6,57.2-65,86-118.2,106.3
				c-13.4,5.1-16.6-5.7-19.4-11.8c-58.6-122.5-143-220.4-253.5-297.8c-24.2-17-50.4-30.3-77.1-44.8c-14.2-7.7-16.7-13.5-11-30.2
				C2017.5,151.4,2064.8,110.3,2196.3,108.3z"
					/>
					<path
						className="st2ch"
						d="M859.3,100c29.8-0.5,58.7,3.8,87.7,10.5c71.7,16.6,102.9,70.1,123.8,133.3c4.4,13.3-7.1,15.7-14.3,19.3
				c-75.9,37.5-143.3,86.4-204.3,144.8C794.5,463,748.9,527,713.1,597.9c-7.8,15.6-14.3,16.6-29.3,10.4
				c-71-29.4-102.8-86-110.4-158.5c-9.3-89.1,12.2-170.7,65.1-243.5C693,131.5,768.3,99.3,859.3,100z"
					/>
				</g>
				<g>
					<path
						className="st9ch"
						d="M1135.1,2091.6c0.2,5.8,0.8,11.5,0.8,17.3c-0.1,19.4,2.5,37.5,27,39.2c18.4,1.4,27.9-13.4,28.4-40.5
				c0.5-22.9-2.3-45.8,3-68.7c11-47,39.8-81.7,73.6-113.8c13.3-12.7,19.7-27.9,5.4-43.2c-14.4-15.3-29.9-9.7-43.4,2.7
				C1169.3,1940.4,1129.6,2005.7,1135.1,2091.6z"
					/>
					<path
						className="st9ch"
						d="M1196.7,1839.4c6.1-4.1,12-8.7,17.5-13.6c12.5-11.1,17.8-24.6,7.4-39.2c-10.5-14.7-25.5-15-39.2-5.4
				c-30.2,21.3-60.5,42.7-83.7,72.2c-59.2,75.6-79.8,163.2-80.4,257.1c0,12.4,0.5,24.7,0,37c-1,19.5,3.9,36.2,26.3,36.6
				c21.9,0.4,28.5-15.7,28.4-35.3c-0.1-31.2,0.3-62.3,4.3-93.5C1088.5,1967.3,1120.1,1891.1,1196.7,1839.4z"
					/>
					<path
						className="st9ch"
						d="M1799.3,2084.2c0,10.5-0.2,21.2,0.1,31.9c0.4,17.8,7.8,31.2,27,31.8c19.2,0.5,26.1-12.3,28.1-30.3
				c10.2-89-19.7-162.5-83.9-223.5c-23.2-22.2-39.1-26.5-52.8-13.3c-14.4,13.8-10,29.7,14.3,53.7
				C1773.8,1975.7,1803.9,2022,1799.3,2084.2z"
					/>
					<path
						className="st9ch"
						d="M1933.4,1920.3c-26.5-59.1-69.9-102.3-122.6-137.6c-14.7-9.9-30-11.8-41.4,4c-11.4,15.7-4.4,29.5,8.9,40.8
				c5.6,4.7,11.6,9.1,17.7,13.3c75.3,51.7,106.3,127.3,117.6,214.4c4.3,33.6,3.3,67.2,4.7,100.8c0.7,16.9,10.2,28.5,27.9,28.2
				c17.7-0.3,26.2-11.9,26.4-29.2c0.2-14.8,0-29.6,0-44.4C1972.9,2044.3,1960.6,1980.9,1933.4,1920.3z"
					/>
					<path
						className="st2ch"
						d="M2542.3,1855.7c-46.9-118.8-238-430.2-266.1-478.3c-5.8-9.9-15.2-32.4-19.1-33.7c-0.6,1.3-1.2,2.5-1.8,3.8
				c-2.3,5.4-6.8,15.4-11.4,23.9c-0.3,0.6-0.7,1.2-1,1.8c-36.4,65.3-67.3,92.7-124.6,140.1c-138,114.1-279.3,184.6-454.6,197.5
				c-148.8,11-339.1-9.1-481.9-49.8c-118.5-33.8-228.4-82.3-308.1-181.3c-35.3-43.8-69.1-83.5-94.3-134.8c-4.2-8.6-15-29.2-15-29.2
				l0,0c-4.4,0.2-13.7,17.2-19.1,24.8c-12.3,17.5-98.7,160.2-131.8,212.8c-104.6,166.1-177.8,343.9-209.8,538.4
				c-22.9,140-20.4,278,24,413.6c56.3,171.8,165.6,294.7,338.8,354.2c79.6,27.4,163.1,35.2,247,39.1c96.9,4.5,192.4-2,284.5-33.3
				c88.3-30.1,168.1-72.2,202.4-173.1c17.4,62.7,56.6,103.3,106.8,134.6c62.5,39.2,132.1,56.9,204.5,64.1
				c112.8,11.4,225,3.9,336.6-14.4c91-14.9,176.6-43.9,249.3-102.6c149.5-120.6,209.1-286.4,225.5-469.8
				C2637,2148.6,2599.2,1999.8,2542.3,1855.7z M1717.9,1880.7c13.6-13.1,29.5-8.8,52.8,13.3c64.1,61,94.1,134.6,83.9,223.5
				c-2.1,18-8.9,30.8-28.1,30.3c-19.1-0.6-26.6-14-27-31.8c-0.3-10.7-0.1-21.4-0.1-31.9c4.6-62.2-25.5-108.6-67.1-149.8
				C1707.9,1910.4,1703.5,1894.6,1717.9,1880.7z M1273.3,1882c14.3,15.3,7.9,30.5-5.4,43.2c-33.7,32.1-62.6,66.8-73.6,113.8
				c-5.3,22.9-2.5,45.7-3,68.7c-0.6,27.1-10,41.9-28.4,40.5c-24.5-1.8-27.1-19.8-27-39.2c0-5.8-0.6-11.5-0.8-17.3
				c-5.5-85.9,34.2-151.2,94.7-206.8C1243.4,1872.3,1258.9,1866.7,1273.3,1882z M1072.9,2148.8c0.1,19.6-6.6,35.7-28.4,35.3
				c-22.4-0.4-27.3-17.1-26.3-36.6c0.5-12.3,0-24.6,0-37c0.6-94,21.2-181.5,80.4-257.1c23.1-29.5,53.4-50.9,83.7-72.2
				c13.7-9.6,28.7-9.3,39.2,5.4c10.5,14.6,5.1,28.1-7.4,39.2c-5.5,4.9-11.4,9.5-17.5,13.6c-76.6,51.7-108.2,127.9-119.5,215.9
				C1073.2,2086.5,1072.8,2117.6,1072.9,2148.8z M1860,2391c-20.8,12-44.3,16.5-67.6,21.3c-56.1,11.7-111.9,24.4-167.5,38.1
				c-56.5,13.9-101.1,43.9-121.9,100.1l-0.8,2.4c0,0-0.4-1-0.7-1.7v-2.5c-32.7-83.6-106.1-100.5-183.9-112.1
				c-54.1-8.1-108.3-17.1-160.5-34c-9.3-2.9-18.2-7.1-27-11.5c-10-5.1-10.8-12.4-1.2-18.4c48.7-30.8,63.2-81.4,76.4-132.5
				c13.9-53.7,26.3-108,40.2-161.7c24.5-94.6,100.4-161.6,200.9-177c-8.9-11.4-14.2-25.7-14.2-41.3c0-37.1,30-67.1,67.1-67.1
				c1,0,2,0,3,0v0.1c35.3,2.1,63.3,31.3,63.3,67c0,15.4-5.3,29.6-14,41c102.3,15,167.8,80.3,186.7,162.6
				c15.8,68.6,27.3,138.2,49.3,205.2c13.9,42.6,30,83.6,75.6,103.8C1880.1,2380.1,1865.8,2387.8,1860,2391z M1972.5,2155
				c-0.2,17.4-8.7,28.9-26.4,29.2c-17.7,0.3-27.2-11.4-27.9-28.2c-1.4-33.6-0.4-67.2-4.7-100.8c-11.3-87.1-42.3-162.7-117.6-214.4
				c-6.1-4.2-12.1-8.6-17.7-13.3c-13.3-11.3-20.3-25.1-8.9-40.8c11.4-15.8,26.7-13.9,41.4-4c52.7,35.3,96.1,78.5,122.6,137.6
				c27.2,60.6,39.5,124.1,39.1,190.4C1972.5,2125.4,1972.7,2140.2,1972.5,2155z"
					/>
				</g>
			</g>
			<g id="Camada_3_1_"></g>
		</g>
	</svg>
);
export default ChegueiIcon;
