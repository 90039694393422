import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
 //@import url('https://fonts.googleapis.com/css?family=Poppins:400,600');
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none ;
  } 

    body{
      font-family: 'Poppins', sans-serif;
      color: ${props => props.theme.colors.font.default};
      //background-color: #FBF9F6 ;
      background-color: #FFFFFF ;
    }

    h3 {
      font-size: 1rem;
      font-weight: 700;
    }

    button {
      font-family: 'Poppins', sans-serif;
    }

    .spinner {
      animation: spin infinite 1s linear;

      /*You can increase or decrease the timer (5s) to 
      increase or decrease the speed of the spinner*/
    }

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }


    /* body {
      overflow: hidden;
    }
    :root {
      interpolate-size: allow-keywords;
    }
    .custom-scroll::-webkit-scrollbar {
      width: 4px;
    }

    .custom-scroll.zero-scroll::-webkit-scrollbar {
      width: 0;
    }

    .custom-scroll::-webkit-scrollbar-track {
      background: transparent;
    }

    .custom-scroll::-webkit-scrollbar-thumb {
      background: rgb(229 231 235);
      border-radius: 0.75rem;
    }

    [data-radix-popper-content-wrapper] {
      z-index: 10 !important;
    }
    [data-radix-popper-content-wrapper] {
      z-index: 10 !important;
    } */

`;

export default GlobalStyle;