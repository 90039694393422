const FreteHero = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Camada_1" x={0} y={0} width={22} height={22} viewBox="0 0 3000 3000" {...props}>
		<style>{'.freteHeroFill{fill:#080413 !important;}'}</style>
		<path d="M1058.5 1998.1c2.1-7.8-8.6-12.1-12.5-5.2-44.6 79-118.2 234.1-136 339.4-16.6 97.9-23.4 204.1-27.9 295.8-.5 10.8-3.8 18.8-11.3 26.6-40.4 41.4-104.6 35-135.2-14.7-27.9-45.3-37.3-96.5-40-148.5-10.3-198.6 37.7-382.8 141.3-551.9 91.6-149.4 203.9-282 341.4-390.9 136.5-108.1 296-172.8 459.6-228 14.1-4.7 28.2-9.9 42.3-14.5 49.5-16.6 86.2-45.9 110.8-93.9 31-60.6 62.2-121.5 106-174.7 24.5-29.9 52.6-56.1 83-79.6 30.6-23.8 64.3-17.4 91.5 16.9 34.2 43.1 50.2 93.7 59.2 147.2 11.1 66.3 11.6 133.1 10.8 200-.2 22.1 5.2 37.7 24.6 50.1 141.1 89.9 208.5 230.7 259.5 381.6 46 135.9 67.3 277.1 83.9 419.3 21.8 185.7 13.1 370.5-18.6 554.3-11.9 69-41.2 129.6-113.3 152.8-69.6 22.4-137.7 20.4-198.7-29.5-47.7-39-83-86.2-108.7-141.8-40.2-87.1-73.3-177.7-125.6-258.9-18.1-28.1-36-56.4-60.9-79.3-77.5-71.1-166.3-74.7-249.6-10.7-71 54.6-120.4 127.1-165 202.9-49.2 83.5-98.8 166.7-157.6 244-57.9 76.1-153.4 110.5-239.4 84.7-39.5-11.8-65.7-35.9-81.1-74.8-32.9-83-34.6-169.4-29-256.1 5.6-85.6 12.9-171.2 28.2-255.8 18.5-103 41.4-204.9 68.4-306-.1-.6-.1-.7-.1-.8z" className="freteHeroFill" />
		<path d="M1005.6 1578.2c5.9-5.5 205.4-216 586-335.8 2.5-.8 5.2-1.2 7.7-2 91-31.2 92.2-22.3 112.5-57.5 22.2-38.6 61.9-111.9 79.7-136.2 15.2-20.6 70.3-87 86.2-106 2-2.4 4.6-4.2 6.4-6.9 13.6-20.4 8.5-32.6-8.9-65.2-9.6-18.1-14.7-30.5-16.3-34.5-.6-1.7-.5-3.7.5-5.4l11.5-19.3c3.3-5.6 7.8-13.5 10.5-18.2 15.8-27.3 94.5-157.7 92.6-518.2-.1-9.8-.7-19.6-1.9-29.4-3.4-27.6-10.6-88.3-25.3-111.3-16.8-26.5-28.7-52-105.8-10.4-75.3 40.7-196.1 95.3-280.5 262.5-2.9 5.8-6.5 15.7-8.5 21.7-1.3 4-8.6 9.4-38.4 7.3-44-3.1-298.7-35.2-396.6-12.2-5.7 1.3-23.8 5.1-29.2 7.4-4.5 2-3.5 2.3-15.2-17.4-15-25.4-114.7-206.2-314.8-256.6L696 115.2c-1.5-.5-3-1-4.5-1.6-10.2-3.8-64.1-20.1-90 54.1-28.7 82.2-116.3 292.8-46.2 615.6s12.3 49.7 12.3 49.7 2.3 10.5-1.8 28-114.4 354.4-81 527.3c15.9 81.9 35.5 202.1 220.1 271.9 141.1 53.3 174.5 28.1 189.8 20 3.9-2.1 7.8-4.4 11.3-7.1 11.4-8.6 17.2-16.2 21.4-20.2l78.2-74.7z" className="freteHeroFill" />
		<path
			d="M1488.7 497.3c-37.3 1.1-73.9 8-112.9 8.9 3.9-7 6.9-12.4 10.1-17.6 15.8-24.8 54.8-82.3 54.8-82.3s-5.3-.4-8.3-.8c-22.9-2.8-43.5-4.1-66.5-7-13.5-1.7-28.4-3-43.7-3.9l-142 246c43-2 86-7.4 130.4-5.7-2.4 7.1-3.9 12.1-6 17-27.9 66.7-55.9 133.2-83.7 199.8-13 31.2-38.9 93.8-38.9 93.8l2.7 5.5s5.4-7.1 7.8-10.8c29.6-43.9 59.2-88 88.8-131.9 67.2-99.8 134.3-199.6 201.4-299.4 2.5-3.4 6.3-6.4 6-11.6z"
			style={{
				fill: '#f100a6',
			}}
		/>
		<path
			d="M1186.3 393.6c-24.5 41.4-49 86.1-73.1 127.8-24.2 41.8-49.3 83.1-73.8 128 47.5-3 91.9-6.7 136.4-8.5 1.5-.1 3.1-.1 4.6-.2l142-246c-52.7-3.4-110.7-2.8-136.1-1.1z"
			style={{
				fill: '#ff37c1',
			}}
		/>
	</svg>
);
export default FreteHero;
