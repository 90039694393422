const LilyIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		id="Camada_1"
		x={0}
		y={0}
		width={22}
        height={22}
		viewBox="0 0 3000 3000"
		{...props}>
		<style>{'.lily0{fill:#090513}.lily1{fill:#fecd00}.lily2{fill:#fe7d00}.lily3{fill:#fe7c00}.lily4{fill:#feec75}'}</style>
		<path d="M2666.6 448.3c-63.9-136.1-176.8-207.3-326.7-216.6-145.6-9.1-291.2-16.1-437.1-16.7-44.8-.2-44.8-.6-59.7-41.5-2.1-5.7-4-11.3-6.5-16.7-31.3-65.7-92-75.8-142.8-23.6-17.4 17.8-31 38.7-43.6 60.1-7 12-15 17.5-30.1 17.4-159.7-.6-319.4-.5-479 .2-14.2 0-21.3-4.9-28.3-16.2-15.3-24.7-31-49.3-52.9-69.2-43.3-39-95.9-31.3-125.7 18.9-10.2 17.1-16.4 35.7-21.1 54.7-2.8 11.4-6.6 16.7-19.8 16.9-45.5.7-91.1 1.3-136.3 5.2-66.3 5.9-133.3 2.5-198.9 19.6-120 31.4-197.3 111.7-236.3 222.8-46.4 132.4-31.2 268.5 21.9 396.1 173.7 417.7 368.8 825.8 564.1 1233.8 50.8 106 101.1 212.3 157.3 315.7l.3-.3.2-.2c.1 1.4.1 2.1.1 2.1 2.8 13.7 303.3-36.5 451.3-33.1 104.8 2.5 377.3 254.2 377.3 254.2s0-.1-.1-.3c13.2-29.9 471.6-1035.5 672.3-1521.9 53.4-129.5 116.1-256.3 134.4-398 11.7-90.9 4.3-181.1-34.3-263.4z" className="lily0" />
		<path d="M1625.1 2625.9c-55.9-108.3-55.8-220.6-2.5-330.4 25.4-52.4 68-89.3 124.3-108.7 7-2.4 6.9-2.5 14.4-3.8.4 1.7.9 3.3 1.4 4.9.2-.6.2-1.3 0-1.9-.6-2.3-1.3-4.6-1.9-6.8-23.4-91.2-50.1-181.5-73.4-272.7-5.8-22.5-9.1-29.6-21-33.3-47-14.6-102.1-55-138.1-86.7-5.8-5-29.1-25.2-25.5-33.2 4.3-9.7 31.1 5.1 38.8 8.2 36.7 14.7 71.5 30.3 112.5 43.8 24.8 8.1 53.5 11.8 78.5 19.4-16.9-54.6-33.7-108.9-40.4-166.2-9.9-83.6-9.7-166.4 5.1-249.6 24.3-136.9 69.7-265.5 145-383 6.7-10.5 9.4-16.8 18.3-30.6.4.1.7.2 1.2.3-18.7-5.5-37.3-11.1-56.1-16.3-100.9-26.9-202.3-46.1-307.8-38.9-55.4 3.8-110.5 10.4-165.6 17-17.9 2.1-26.9-4.3-33-22.2-14.7-43.6-32.5-86.1-48.4-129.3-11.2-30.5-32.6-46.6-62.5-60.9-26.1-11.6-55.2-23.2-80.7-32.7-7.3-2.7-10.9-2-16.1 2.7-18.4 16.6-39.3 36.4-58.6 57.3-26.1 28.3-36 53.6-32.8 88.6 5.1 56.3 9.6 112.6 14.2 168.9.7 9.1 3.6 19-6 25.9.2 1.3.5 2.4.7 3.7v.2c14.4 75.1-6.1 155.7-74.7 184.4-25.6 10.7-52.5 12.3-76.8.1-40.5-20.4-74.3-48.6-85.7-95.6-.2-.8-.4-1.7-.6-2.4-.1-.4-.2-.7-.3-1.2-.1-.3-.2-.7-.3-1-.1-.5-.3-1-.4-1.6 0-.1-.1-.3-.1-.4-.2-.7-.4-1.4-.5-2-.6-2.3-1.2-4.3-1.6-6.2 0 0-1.7.4-3.6.9-57.9 15.4-72.7 35.6-49.5 92.2 10 24.5 22 48 36.5 70.1 62.4 95.9 159.4 129 269.1 94.9 33.1-10.2 65.5-22.6 99.3-31.2 3.4-.8 5.9 3.1 3.7 5.8-33.2 40.2-68.4 76.7-123.5 84.6-8.6 1.3-12.2 4.3-11.2 13 6.7 64.1 13.4 132.2 20.1 196.4-.1-.8-.1-1.7-.2-2.3 139.2-4.8 233.8 90.6 256.9 225.7 16.9 98.9 6 191.9-71.9 264.8-38.8 36.3-83 66-138.7 70.9-1.4.7 11.1 190.1 10.8 191.2 2.8 13.7 102.3 203.2 146.4 285.1 73.8 137.4 197.3 200.4 345.3 203.9 104.8 2.5 196.8-46.1 263.9-131.7.5-.6.8-.9 1.5-2-92.9-8.5-157.3-61.5-197.9-140.1z" className="lily1" />
		<path d="M1762.6 2187.9c-.4-1.7-.9-3.4-1.4-4.9-7.5 1.3-7.3 1.4-14.4 3.8-56.3 19.4-98.9 56.4-124.3 108.7-53.3 109.8-53.4 222.1 2.5 330.4 40.6 78.6 104.2 131.5 196.9 140.2.2 0 .4 0 .7.1 33.6-39.5 51-87.5 71.5-134.2.1 0-90-293.8-131.5-444.1z" className="lily2" />
		<path d="M1265.5 1883.9c-23.1-135.2-117.8-230.6-256.9-225.7 0 .6.1 1.5.2 2.3 4.4 56.6 42.5 527.1 46.1 559.2 55.7-4.9 99.9-34.6 138.7-70.9 77.9-72.9 88.8-166 71.9-264.9z" className="lily3" />
		<path d="M2211.7 1126.4c-104.6-69.4-229.8-101.8-349.9-130.7-.4-.1-.7-.2-1.2-.3-8.8 13.9-11.5 20.1-18.3 30.6-75.4 117.4-120.7 246.1-145 383-14.8 83.1-15 165.9-5.1 249.6 6.7 57.3 23.5 111.7 40.4 166.2.7 2.4 184.9 49.4 263.6 32.7 66.1-14 99.8-36.9 154.8-79 125.7-96.3 175.5-234 195-382.7 15.1-115.3-38.3-205.8-134.3-269.4z" className="lily4" />
		<path d="M1009.1 1059.7v-.2c-.2-1.3-.5-2.4-.7-3.7-82.5 26.8-157.9 51.1-240.3 77.9.2.8 1.3 5.2 1.6 6.2.2.6.3 1.4.5 2 0 .1.1.3.1.4.1.5.3 1 .4 1.6.1.3.2.7.3 1 .1.4.2.7.3 1.2.2.7.4 1.6.6 2.4 11.4 47 45.2 75.2 85.7 95.6 24.3 12.2 51.2 10.6 76.8-.1 68.7-28.6 89.1-109.3 74.7-184.3z" className="lily3" />
		<path d="M1535.7 842c7-26.2 14.7-59.9 34.9-79.2 8.4-8 18.3-12.3 30.9-18.4 21.2-8.7 50.3-24 68.6-27.8 7.3-1.5 5.8-2.1 12.9 1.9 25.7 14.3 59.6 37.2 71.2 46.8 25.4 14.9 32.5 79 35.4 116.1.8 11.4-8.7 9-14.7 7.5-73.8-19.7-148.6-30.7-226.6-30.5-8.7-.1-14.8-8.2-12.6-16.4z" className="lily1" />
		<path d="M1245.1 2389.5c1.6-30.4 8.9-64.9 30.2-94 49.6-68.1 129.8-61.8 173.7 12.8 38.5 65.5 26.7 157.8-27.4 211.4-43.6 43.2-112.7 34.9-145.4-17.7-20.2-32.8-31.2-68.4-31.1-112.5z" className="lily2" />
		<path
			d="M1485.2 1197.9c76.3-2 127.8 39.5 171.1 94.8 12.3 15.7 12.6 34.5-3.1 48.9-16.7 15.2-35.1 12.2-51-1.8-10.9-9.6-19.7-21.4-29.9-31.8-45.8-46.2-107.9-50.4-159.6-11.1-4.7 3.6-9.4 7.5-14.6 10.5-20.3 12.3-41.3 9.8-52.5-7.1-12.6-19-7.7-36.5 8.4-50.5 37.7-32.9 80-55.6 131.2-51.9z"
			style={{
				fill: '#080413',
			}}
		/>
		<path d="M2031.7 1682c-68.2-5.1-128.6-19.2-174.8-69.3-12.3-13.2-20.7-27.4-6-42.5 14.6-15 29.8-8 42.5 4.9 48.2 49.2 108.2 57 172.5 50 5-.5 10.1-2.6 15.1-2.5 15.2.5 27.4 6.8 29.1 23.2 1.6 14.7-6.6 24.9-21.2 27.6-20.3 3.8-40.6 6.2-57.2 8.6z" className="lily0" />
		<path d="M1307.7 377.9c10.3-12.6 22.2-26.8 11.8-43.8-10-16.1-27.8-14.9-44.2-12.7-39.9 5.1-88.6 11.1-128.1 17.8-20.8 3.6-25-.8-36.2-21.5-23.1-42.8-49.5-84-75.5-125.3-7.9-12.6-17-25.4-34.1-22.5-.2 0-.4.1-.6.1l75.6 460.4c6.2-2.1 13-2.3 19.4-.3 5 1.6 10.1 3.1 15.1 4.9 37.6 13.7 74.7 28.4 112.6 41.2 14.2 4.8 30.9 10.8 43.6-2.7 12.6-13.3 6.3-29.7.6-43.7-14.7-36.3-29.4-72.8-47.4-107.5-10.9-21-12.8-28.5 2.8-45.2 28-29.6 58.4-67.5 84.6-99.2z" className="lily4" />
		<path d="M991.5 173.7c-13.7 8.2-16.1 23.5-18.5 37.7-7.5 43-14.5 86.1-19.7 129.4-1.9 15.6-8.1 21.2-22.9 24.8-49.8 12.2-99.3 25.9-148.7 39.7-15.5 4.3-33.5 8.2-35.6 28.3-2.2 21 14.8 29.8 29.9 37.6 41.1 21.1 82.3 42.2 124.5 60.9 17.3 7.7 22 17.5 20.4 34.9-3.4 34.1-5.5 68.4-7.8 102.6-.9 14.5-2.5 29.3-.8 43.7 3 27.1 20.4 34.8 43.6 20.8 5.9-3.6 11-8.2 16.6-12.2 34.4-24.7 61.6-57.2 93.2-85 3.1-2.8 6.8-4.8 10.7-6.2l-75.6-460.4c-3.4.6-6.5 1.7-9.3 3.4z" className="lily1" />
		<path d="M1989.3 458.3c13.5-9 29.2-19 24.1-38.3-4.7-18.3-22.1-22.5-38.4-25.2-39.6-6.9-87.8-15.5-127.7-20.9-20.9-2.7-23.6-8.3-28.2-31.3-9.3-47.8-22.5-94.9-34.9-142-3.8-14.4-8.7-29.3-26-31.5h-.6l-64.2 462.2c6.5-.2 13 1.7 18.7 5.5 4.4 2.9 8.7 6 13 9.2 31.8 24.2 63 49.3 95.3 72.7 12.1 8.8 26.2 19.4 42.4 10.3 16-9 14.8-26.6 13.5-41.6-3.3-39-6.5-78.3-13.4-116.7-4.2-23.3-3.8-31.1 16.1-42.4 35.7-20.3 76-47.5 110.3-70z" className="lily4" />
		<path d="M1747.8 169.5c-15.5 3.8-22.2 17.6-28.9 30.5-19.8 38.9-39.4 77.9-57.2 117.8-6.4 14.4-14 17.8-29.2 16.9-51.2-3.1-102.5-4.6-153.7-6.2-16.2-.5-34.4-2.1-42.4 16.5-8.3 19.4 5.4 32.8 17.4 44.7 33.1 32.3 66.1 64.6 100.9 95 14.3 12.5 15.8 23.3 9.1 39.5-13.3 31.6-25.5 63.7-37.8 95.7-5.1 13.5-11.1 27.2-13.7 41.5-5.1 26.8 9.1 39.4 35.5 32.7 6.7-1.7 12.9-4.5 19.4-6.7 40.2-13.3 75.8-36.4 114.2-53.6 3.9-1.7 8-2.6 12.1-2.7l64.2-462.2c-3.3-.3-6.6-.1-9.9.6z" className="lily1" />
	</svg>
);
export default LilyIcon;
