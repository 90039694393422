import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import Alert from "../../../../components/Alert/index";
import Contents from "../../../../components/Contents";
import CardPlano from '../../components/CardPlano/index'
import ModalCupom from '../../components/ModalCupom/index'
import FormaPagamento from '../../components/FormaPagamento/index'
import TablePagamentos from '../../components/TablePagamentos/index'
import DadosNotaFiscal from '../../components/DadosNotaFiscal/index'
import Avisos from "../../components/Avisos";
import { BoxInforInitial, BoxInforInitialLeft, BoxInforInitialRight, Container, Cupom } from './styled'
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PagamentoProvider } from "../../hooks/usePagamento";
import { BoxLoading } from "../../../questions/pages/home/styled";
import { AiOutlineLoading } from "react-icons/ai";
import { displayScript, isVisibleScript } from "../../../../utils/displayScript";
import CardPlanoLoading from "../../components/CardPlano-loading";
import AvisosLoading from "../../components/Avisos-loading";
import LoadingLogo from "../../../../components/Loading-logo";
import LoadingLogoLily from "../../../../components/Loading-logo-lily";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import ModalInforVideo from "../../../../components/modal-infor-video";
import { getTranslation } from "../../../../translations";
import HelpClick from "../../../../components/HelpClick";
import { ContainerLoading } from "../../../reviews/pages/home-new/styled";
import LoadingLogoLilyNew from "../../../../components/Loading-logo-lily-new";
import Layout from "../../../../components/tailwind/layout";
import LayoutNew from "../../../../components/tailwind/layout-new";
import SelectCoin from "../../components/select-coin";
import LazyLoadingPlan from "../../../../components/lazy-loading-plan";
import LayoutUI from "../../../../components/tailwind/layout-ui";

const Home = () => {
    const payments = useSelector(state => state.reducerPayments)
    const dispatch = useDispatch()

    const [visibleModalInforAccount, setVisibleModalInforAccount] = useState(false)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const coins = [
        { id: "BRL", name: 'Real (R$)' },
        { id: "MXN", name: 'Peso Mexicano (MX$)' },
        { id: "ARS", name: 'Peso Argentino (AR$)' },
        { id: "COP", name: 'Peso Colmbiano (CO$)' },
        { id: "CLP", name: 'Peso Chileno (CL$)' },
        { id: "USD", name: 'Dólar (US$)' },
        { id: "EUR", name: 'Euro (€)' },
        { id: "GBP", name: 'Libra (£)' },
    ]

    const [selected, setSelected] = useState(coins[0])

    const changeValue = (value) => {
        console.log("changeValue", value)
        setSelected(value)
        dispatch({ type: 'SAGA_LIST_PAYMENTS', payload: value.id })
    }


    useEffect(() => {
        dispatch({ type: 'SAGA_LIST_PAYMENTS', payload: selected.id })
    }, [])

    useEffect(() => {
        console.log("payments", payments?.data.plansPlus)
    }, [payments])


    let pagamentoSimplificado = payments?.data?.pagamentoSimplificado;

    return (
        <>

            <ModalInforVideo visible={visibleModalInforAccount} setVisibleModal={setVisibleModalInforAccount}
                video={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/w5ChDgUEkuQ" : `https://www.youtube.com/embed/w5ChDgUEkuQ`}
            />


            {payments.loading ?
                // <ContainerLoading>
                //     <LoadingLogoLilyNew />
                // </ContainerLoading> :
                <LazyLoadingPlan/>:

                <PagamentoProvider>
                    <Container>
                        <Avisos avisoPlus={pagamentoSimplificado} />
                        <SelectCoin selected={selected} setSelected={setSelected} changeValue={changeValue} coins={coins} />
                        {/* <div style={{ display: "flex", gap: "20px" }}>
                            {payments?.data?.dadosPlanos?.planos && Object.values(payments?.data?.dadosPlanos?.planos).map((plan, index) => {
                                if (!plan.legacy || plan.id == payments.data.currentPlan.id) {
                                    return (
                                        <div style={{ display: "flex", gap: "20px", marginTop: "30px", }}>
                                            <CardPlano key={index} currentPlan={payments.data.currentPlan} idPlan={plan.id} name={plan.name} value={plan.value} features={payments.data.dadosPlanos.funcionalidades} />
                                        </div>
                                    )
                                } else {
                                    return <></>
                                }
                            })}
                        </div> */}

                        <div style={{ display: "flex", gap: "20px" }}>
                            {payments?.data?.plansPlus?.map((item, index) => {
                                if (item.legado == 0 || item.id_plano_externo == payments?.data?.currentPlan.id) {
                                    return (
                                        //"item"
                                        <div style={{ display: "flex", gap: "20px", marginTop: "30px", }}>
                                            <CardPlano key={index} plan={item} currentPlan={payments?.data?.currentPlan} />
                                        </div>
                                    )
                                }
                            })}
                        </div>


                        {!pagamentoSimplificado &&
                            <>
                                <Cupom>
                                    <ModalCupom cupomInitialValue={payments?.data?.user?.coupon ?? ""} />
                                </Cupom>
                                <FormaPagamento />
                            </>
                        }
                        <TablePagamentos faturasInitialValue={payments?.data?.transactions ?? []} />
                        {!pagamentoSimplificado &&
                            <DadosNotaFiscal dadosFiscais={payments?.data?.taxUser ?? {}} listaUfs={payments?.data?.ufList ?? []} />
                        }

                        {
                            !payments.loading &&
                            <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
                                <div onClick={() => { setVisibleModalInforAccount(true) }}>
                                    <HelpClick text={translation.alert.assinatura} />
                                </div>
                            </div>
                        }
                    </Container>

                </PagamentoProvider>
            }
            {isVisibleScript(false)}
        </ >
    );
}

export default Home