import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import React, { Children, cloneElement, isValidElement, ReactNode, forwardRef } from 'react';
import { DropdownMenuContent } from './DropdownMenuContent';
import { DropdownMenuTrigger } from './DropdownMenuTrigger';
import { useSubMenu } from '../../SubMenuContext';

interface DropdownMenuProps {
	children: ReactNode;
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
}

interface ItemMenuProps {
	isOpen: boolean;
}

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(({ children, isOpen, setIsOpen }: DropdownMenuProps, ref) => {
	const { clearSub } = useSubMenu();

	const handleOpenChange = (open: boolean) => {
		setIsOpen?.(open);
	};

	const sidebarElements = Children.map(children, (child) => {
		if (!isValidElement(child)) return null;

		if (child.type === DropdownMenuContent || child.type === DropdownMenuTrigger) {
			return cloneElement(child as React.ReactElement<ItemMenuProps>, {
				isOpen,
			});
		}

		return null;
	});

	return (
		<DropdownMenuPrimitive.Root
			open={isOpen}
			onOpenChange={(state) => {
				handleOpenChange(state);

				if (!state) clearSub?.();
			}}>
			<div ref={ref}>{sidebarElements}</div>
		</DropdownMenuPrimitive.Root>
	);
});

DropdownMenu.displayName = 'DropdownMenu';
