import React, { ReactNode, ReactElement, cloneElement } from 'react';
import { twMerge } from 'tailwind-merge';

interface ItemIconProps {
	children: ReactNode;
	className?: string;
}

const ItemIcon = ({ children }: ItemIconProps) => {
	const childWithClass = React.isValidElement(children)
		? cloneElement(children as ReactElement<ItemIconProps>, {
				className: twMerge(`${(children as React.ReactElement<ItemIconProps>).props.className || ''}`, 'size-6 group-[:not(.active)]:text-gray-400 group-[:not(.active)]:transition group-[:not(.active):hover]:text-gray-900'),
		  })
		: children;

	return <div className="size-6 flex-shrink-0 !m-0 flex items-center justify-center">{childWithClass}</div>;
};

export default ItemIcon;
