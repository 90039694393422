const DropDigitalIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg version="1.1" id="Camada_9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 3000 3000" {...props}>
		<g>
			<path
				className="ijeurieureu"
				d="M2213.9,686.3c-209-119.1-434.2-150.9-671.6-144.4c-10.2,0.3-18.3-8.7-16.7-18.8
		c4.9-31.7,20.2-86.9,56.5-121.5c2.6-2.5,6.3-3.9,10-3.9l99.6,0.1c7.8,0,14.1-6.3,14.1-14.1V269.1c0-3.2,0.9-6.3,2.7-8.9
		c2.5-3.8,6.7-9.6,12.4-15.3c38.7-35.4,78.2-69.7,119.4-102.3c46.9-37.1,80-37.9,123.3,3.4c114.1,108.6,198.3,236.3,236.9,391
		c11.1,45.4,19.8,91.2,26.9,139.9C2228.7,684.5,2220.6,690.1,2213.9,686.3z"
			/>
			<path
				className="ijeurieureu"
				d="M2401.4,574.9h122.9c4.6,0,8.3,3.7,8.3,8.3v268.7c0,4.5-3.7,8.3-8.2,8.3c-41,0.3-83.5,0.4-124.6,0.7
		c-4.6,0-8.4-3.7-8.4-8.4l1.6-269.4C2393.1,578.6,2396.8,574.9,2401.4,574.9z"
			/>
			<path
				className="ijeurieureu"
				d="M1578.3,249.7l-0.1,132.4c0,3.2-2.5,5.7-5.7,5.7h-125.6c-3.2,0-5.7-2.5-5.7-5.7V249.7c0-3.2,2.5-5.7,5.7-5.7
		h125.7C1575.8,243.9,1578.3,246.5,1578.3,249.7z"
			/>
			<path
				className="ijeurieureu"
				d="M1441.1,104.6l-0.1,134.6c0,2.5-2.1,4.6-4.6,4.6h-127.8c-2.5,0-4.6-2.1-4.6-4.6V104.6c0-2.5,2.1-4.6,4.6-4.6
		h127.9C1439.1,100,1441.1,102,1441.1,104.6z"
			/>
			<path
				className="ijeurieureu"
				d="M1013.8,457.2l-0.1,134.3c0,2.6-2.1,4.7-4.7,4.7H881.4c-2.6,0-4.7-2.1-4.7-4.7V457.2c0-2.6,2.1-4.7,4.7-4.7
		H1009C1011.7,452.3,1013.8,454.5,1013.8,457.2z"
			/>
			<path
				className="ijeurieureu"
				d="M660.2,2895.5v-135c0-2.5,2-4.5,4.5-4.5c37.4-0.1,109.5,0,128.2,0c2.5,0,4.5,2,4.5,4.5c0,45-0.1,90-0.1,135
		c0,2.5-2,4.5-4.5,4.5H664.7C662.2,2899.9,660.2,2897.9,660.2,2895.5z"
			/>
			<g>
				<path
					className="ijeurieureu"
					d="M2842.2,1439.8v-168.3c0-2-2.1-3.4-4-2.5l-130.7,60.5c-1.4,0.6-3,0.1-3.7-1.4
			c-12.2-25.8-116.7-244.1-170.6-295.6c-10.4-11-17.7-17.6-20-19.6c-0.5-0.4-1.2-0.7-1.8-0.7h-119.2c-1.6,0-2.7,1.3-2.7,2.7
			l-0.3,138.3c0,1.6-1.3,2.7-2.7,2.7h-131.5c-1.6,0-2.7-1.3-2.7-2.7v-138.3c0-1.6,1.3-2.7,2.7-2.7h131.8c1.6,0,2.7-1.3,2.7-2.8
			c0-46.6,0.1-91.2,0.1-137.8c0-0.7-0.3-1.5-0.8-2c-167-160.7-378.8-246.9-605.5-273.5c-123.9-14.5-248-12.8-371.9,3.6
			c-110.4,14.7-341,72.1-387.6,83.9c-4.9,1.3-8.4,5.7-8.4,10.7v179.4c0,1.6-1.3,2.7-2.7,2.7l-131.5,0.8c-1.6,0-2.8-1.3-2.8-2.7
			L878.2,732c0-0.4-0.1-0.9-0.3-1.4c-9-16.9-21.8-32.9-37.8-48.6c-60.6-58.9-122.1-114.7-198.2-152.6
			c-38.3-19.1-63.7-15.1-92.4,16.4c-29.9,32.6-58.7,66.1-83.2,103.1c-62,93.1-107.6,194.7-150,297.6
			c-45.6,111-77.4,225.6-87.5,345.9c-4.2,49.8-2.9,100.4-15.1,149.5c-23,92.8-46,185.5-53.8,281.5c-0.4,5.2-0.3,13.1-0.2,17.3
			c0,1.5,1.3,2.7,2.7,2.7h126.9c1.6,0,2.7,1.3,2.7,2.7v137.6c0,1.6-1.3,2.7-2.7,2.7l-128.7,1.3c-1.6,0-2.7,1.3-2.7,2.8
			c0.2,10.2,1.4,48.5,9.4,111.8c19.3,154.6,81.2,287.6,187.1,401c128,137.2,264.3,214,364.4,266.2c29.9,15.6,55.5,27.9,74.4,36.5
			c1.9,0.8,4-0.5,3.9-2.5c-0.7-41.3-0.7-164.4-0.7-184.6c0-1.6,1.3-2.7,2.7-2.7h134.4c1.6,0,2.7,1.3,2.7,2.7l-0.1,235.1
			c0,1.2,0.7,2.2,1.8,2.6l18.5,7.1c120,44,237.5,58,296.7,61.4c150.5,8.7,389-5.2,452.5-20.5c166-40.4,325.6-82.2,472.1-170.8
			c196.9-118.9,360.2-269.3,461.4-480.2c67-139.5,104.2-283.4,114.6-440.3c5.3-78.2,3-213.2,2.7-230.3c0-1.1,0.6-2.1,1.6-2.5
			l82.6-38.8C2841.6,1441.8,2842.2,1440.9,2842.2,1439.8z M2089.9,2211.4l-0.1,86.7c0,1.1-0.5,2-1.5,2.4l-97,51.6
			c-1.9,0.9-4.1-0.3-4.1-2.4v-84.5c0-2.1-2.2-3.5-4.1-2.4l-87.5,46.6c-1.9,0.9-4.1-0.3-4.1-2.4v-115.8c0-1.1,0.5-2,1.5-2.4
			l274.2-145.1c1.9-0.9,4.1,0.3,4.1,2.4l0.1,119.5c0,1.1-0.5,2-1.5,2.4l-78.6,41C2090.5,2209.4,2089.9,2210.4,2089.9,2211.4z
			 M2721.5,1453l-0.1,214.3c0,1.1-0.5,2-1.5,2.4l-116.8,63.3c-0.9,0.5-1.5,1.5-1.5,2.4c0,59.5-0.1,105.4-0.1,114.9
			c0,1.1-0.6,2.1-1.7,2.5l-83.8,37v-0.1l-94.1,43.2c-30.9,14.7-61.6,29.2-96.3,45.8c-1.9,0.8-4-0.4-4-2.5v-107v-13.5
			c0-2.1-2.2-3.4-4-2.5l-109,53.5c-1.9,0.9-4-0.4-4-2.5v-206.9c0-2-2-3.4-3.9-2.5l-103.7,44.4l0,0l-116,53.1l-125.2,57.4
			c-0.9,0.4-1.6,1.5-1.6,2.5v213.9c0,1.2-0.6,2.1-1.7,2.5L1749,2114c-1.1,0.4-1.7,1.5-1.7,2.5v113.8c0,1.2-0.7,2.2-1.8,2.6
			l-88.8,34.6l0,0l-89.7,37.2v0.1l-96.2,42.9c-1.9,0.8-3.9-0.5-3.9-2.5l0.1-89.3c0-2-2-3.3-3.8-2.6l-109.1,41.7
			c-1.8,0.7-3.8-0.6-3.8-2.6c-0.1-57.4-0.1-143.7-0.1-210.4c0-2-2-3.4-3.9-2.5c-29.5,12.4-59.3,24.9-91.1,38.4
			c-1.8,0.7-3.9-0.5-3.9-2.5v-86c0-1.1,0.6-2.1,1.7-2.5c10.6-4.7,70.1-31,97.3-42.9l0,0l117.1-52.9c0,0,0,0,0,0.1l99.2-45l0,0
			l90-40.6l90.6-40.9l107-48.6l126.8-57.9c38.9-17.3,77.4-34.5,116.3-51.8l0,0l107.4-48.5l117-53.1c33-15,67.3-30.5,100.3-45.5
			l94.1-42.6c0,0,0,0,0,0.1l85.5-38.5l120-53.8l0,0v-0.2c26.9-12.8,53.5-25.5,83.4-39.9c1.9-0.8,4,0.4,4,2.5
			c0,27.1,0.2,73.9,0.2,83.7c0,1.2-0.6,2.1-1.7,2.5l-84.3,37.3C2722.1,1450.9,2721.5,1451.8,2721.5,1453z"
				/>
			</g>
		</g>
	</svg>
);
export default DropDigitalIcon;
