const CampoPersonalizadoIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Camada_1"
    x={0}
    y={0}
    width={22}
    height={22}
    viewBox="0 0 3000 3000"
    {...props}
  >
    <style>{".campoPersonalizadofill{fill:#080413}"}</style>
    <path
      d="M2458.1 1858c-62.8 2.3-125.4-2.4-188.1-5.1-60.8-2.5-66.1-4.8-75.1-64.7-17.4-116.8-77.6-208.7-164.5-284.4-83.5-72.8-173.1-136-283.5-162.8-20.7-5-24.2-23.1-25.2-42.3-3.8-77.8-6.4-155.5-14.5-233.2-12.5-120.4-46.6-235.6-79.8-351.2-26.4-91.9-58.9-181.1-105.9-264.6-12.6-22.3-10.6-47.1-13.2-70.5-1.5-50.7 3.2-99 18.9-147.4 31.4-97.3 67.1-96.9 147.2-81.8 121 22.8 271.8 66.3 380.9 121.4 155 78.2 273.8 129.5 427.7 259.2 191.9 177.4 300.7 363.7 363.3 604.3 43.5 166.9 64.4 335.5 47.9 507.6-3.8 39.6-13.8 78.6-26.4 116.7-7.1 21.6-23.8 32.1-42.9 41.3-82.9 39.7-171.5 53.8-262.2 57.4-34.6 1.2-69.6.1-104.6.1zM1725.4 2623.7c.8-.2 2-.4 3.3-.6 12.5-2.1 19.4 13.9 9.4 21.6-64.9 49.6-223 152.3-460.2 191.8-107.2 16.6-215 25.4-324 18.6-149.4-9.2-284.6-57.4-408.7-139.4-222.3-146.9-366.5-348.9-422.7-609.9-31.4-146-27.5-293.6-3.5-440.8 1.4-8.6 5.6-14.8 10.8-20.9C250.9 1505.2 399 1415.4 584 1388.9c83.7-12 167.3-18.6 251.5-14.9 88.2 3.7 171.9 25.3 250.8 65.7 23.4 12 11.1 21.1-1 30.1-61.5 45.8-114.5 99.8-160.8 160.9-77.5 102.8-120.5 220.5-126.4 346.9C783.6 2289.2 974 2552 1291 2616.3c29.7 6.1 59.6 12 89.6 15.4 50.8 5.9 281.6 5.4 344.8-8z"
      className="campoPersonalizadofill"
    />
    <path
      d="M1625.8 1302.1c.5 12.9-4.4 16.3-17.7 15.1-134.8-11.5-264.6 8.5-387.9 65.1-14.2 6.5-24.9 4.8-37.6-1.7-81.6-42-165.2-78-257.1-92.2-76.6-11.8-153.5-12.6-230.2-7.2-105.2 7.4-208.8 24.7-308.2 62.2-46.3 17.5-148.3 72.4-192 96.3-7 3.8-15-3.2-12.1-10.6 24.6-63.2 98.1-246.1 154.3-326.1 94.3-134.4 195.2-263.6 331.5-358.8 150.3-104.9 318.5-167 498.6-198.9 92.4-16.4 185-18 278.3-11 15.8 1.2 20.8 9.1 26.3 20.8 43.9 92.9 68.8 192 97.1 290 37.1 128.2 50.8 289.2 56.7 457zM1224.7 1504.5c-7.3-.9-9.5-10.5-3.2-14.5 46.2-28.7 91.7-48 140.9-58.9 140.2-30.8 290.3-43.7 425.6 9.7 116.6 46 171.1 95.1 243.2 197.4 28.9 41 66 129.7 72.1 178 6.6 52.2 11.4 123.6 11.4 192.7-12.3 178.9-64.1 294.2-170.4 431.6-8.9 11.4-20 19.2-34 24.5-65.9 24.8-130.3 53.2-200.5 66.3-79.8 14.8-156.6 23.6-240.4 14.5-61.2-6.7-164.9-34.8-236.8-57.5-18.1-5.8-15.3-32.1 3.6-34h.5c286.8-28.4 503.6-280.1 536.9-492.9 16.2-103.7-1.6-204.4-57.2-294.4-52-84.2-133.1-122.1-230.3-126.4-31.2-1.4-62.8-2.8-93.8 3.6-9.5 2-17.9-.9-26.2-3.7-45-14.9-90-29.8-141.4-36z"
      className="campoPersonalizadofill"
    />
    <circle
      cx={2394.9}
      cy={1160.7}
      r={280.1}
      style={{
        fill: "#fff",
      }}
    />
    <circle cx={2393.2} cy={1160.7} r={169.3} className="campoPersonalizadofill" />
    <path
      d="M1214.7 1636.1c-16.2 7.3-31.3 17.1-47.4 24.8-9.5 4.6-23.2 12-30.8 3.1-7.2-8.5 4.1-23 7.9-29.3 5.6-9.1 13-17.1 18.6-26.1 10.4-16.5 4.6-27.4-15-26.9-27.9.7-51.3 12.5-71.1 31.7-10.2 6-18.4 13.9-26.2 22.7l-1.8 2.1v288.2c10.5-9.1 21.7-17.9 33.3-27.5 22.6-14.3 47.3-26.2 67.5-43.4 58.4-49.5 105.1-107.2 110.3-188.1 2.6-35.5-12.9-46.1-45.3-31.3z"
      style={{
        fill: "#2db62d",
      }}
    />
    <path
      d="M904.4 1983.2c-1.4 29 5.9 105.1 15.4 133.3 3.8 11.2 10.4 20.5 22.5 18.9 12.1-1.7 15.2-13 16.7-24.3 14.1-107.6 47.2-147.8 90.2-184.6v-288.2c-98.1 111.3-137.8 199.7-144.8 344.9z"
      style={{
        fill: "#5ade5a",
      }}
    />
  </svg>
);
export default CampoPersonalizadoIcon;
