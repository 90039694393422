import Modal from 'react-modal';
import { BoxOptions } from '../box-config-email/styled';
import { BoxButtons, ButtonHelp, ButtonReset, ButtonSave, ItemSelect, SelectCustom } from '../bar-config-email-ajustes/styled';
import { useSelector } from 'react-redux';
import { getTranslation } from '../../../../translations';
import { Container } from '../bar-config-product-ajustes/styled';
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BoxInfor, Close, Title } from './styled';
import { GrFormClose } from 'react-icons/gr';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalConfigTimeEmail = (props) => {


    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const dispatch = useDispatch()



    const save = () => {
        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                request_email_date: configControl.request_email_date,
            }
        })
        props.setVisibleModal(false)
    }

    const closeModal = () => {
        dispatch({ type: "CONFIG_CONTROL_VISIBLE_MODAL_EMAIL_SET", payload: false })
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() =>
                closeModal()
                //props?.setVisibleModal(false)
            }
        >
            <Container style={{ padding: "40px", width: "350px", gap: "20px" }}>

                {/* <BoxInfor>
                    <label>
                        Defina o tempo para envio do e-mail solicitando uma avaliação de compra.
                    </label>
                </BoxInfor> */}

                <Close onClick={() => {
                    closeModal()
                    props.setVisibleModal(false)
                }}>
                    <GrFormClose size={25} color={"#5e6278"} />
                </Close>
                <Title>
                    <strong>
                        E-mail
                    </strong>
                </Title>
                <BoxOptions>
                    <ItemSelect>
                        <label>{translation.thema.boxConfigEmail.tempoEnvio}:</label>
                        <SelectCustom name="select_layout_form" onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { request_email_date: e.target.value } }) }}                        >
                            <option value="0" selected={configControl.request_email_date == 0 ? true : false}>Nunca</option>
                            <option value="1" selected={configControl.request_email_date == 1 ? true : false} >01 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="2" selected={configControl.request_email_date == 2 ? true : false} >02 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="3" selected={configControl.request_email_date == 3 ? true : false} >03 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="4" selected={configControl.request_email_date == 4 ? true : false} >04 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="5" selected={configControl.request_email_date == 5 ? true : false} >05 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="6" selected={configControl.request_email_date == 6 ? true : false} >06 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="7" selected={configControl.request_email_date == 7 ? true : false} >07 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="8" selected={configControl.request_email_date == 8 ? true : false} >08 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="9" selected={configControl.request_email_date == 9 ? true : false} >09 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="10" selected={configControl.request_email_date == 10 ? true : false} >10 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="11" selected={configControl.request_email_date == 11 ? true : false} > 11 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="12" selected={configControl.request_email_date == 12 ? true : false} >12 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="13" selected={configControl.request_email_date == 13 ? true : false} >13 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="14" selected={configControl.request_email_date == 14 ? true : false} >14 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="15" selected={configControl.request_email_date == 15 ? true : false} >15 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="16" selected={configControl.request_email_date == 16 ? true : false} >16 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="17" selected={configControl.request_email_date == 17 ? true : false} >17 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="18" selected={configControl.request_email_date == 18 ? true : false} >18 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="19" selected={configControl.request_email_date == 19 ? true : false} >19 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="20" selected={configControl.request_email_date == 20 ? true : false} >20 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="21" selected={configControl.request_email_date == 21 ? true : false} >21 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="28" selected={configControl.request_email_date == 28 ? true : false} >28 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="30" selected={configControl.request_email_date == 30 ? true : false} >30 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="35" selected={configControl.request_email_date == 35 ? true : false} >35 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="42" selected={configControl.request_email_date == 42 ? true : false} >42 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="49" selected={configControl.request_email_date == 49 ? true : false} >49 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="56" selected={configControl.request_email_date == 56 ? true : false} >56 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="63" selected={configControl.request_email_date == 63 ? true : false} >63 {translation.thema.boxConfigEmail.diasCompra}</option>
                            <option value="70" selected={configControl.request_email_date == 70 ? true : false} >70 {translation.thema.boxConfigEmail.diasCompra}</option> </SelectCustom>
                    </ItemSelect>

                </BoxOptions>

                {/* <ButtonHelp>
                    <img src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp> */}

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />



                <BoxButtons style={{ justifyContent: "center" }}>
                    {/* <ButtonReset style={{visible: "hidden !important"}} onClick={() =>
                        alert("teste")
                    }>
                        <label>{translation.thema.boxConfigEmail.restaurar}</label>
                    </ButtonReset> */}
                    <ButtonSave style={{ justifyContent: "center" }} onClick={() =>
                        //saveTheme()
                        save()
                    }>
                        {translation.thema.boxConfigEmail.salvar}
                    </ButtonSave>
                </BoxButtons>
            </Container>
        </Modal>
    )
}

export default ModalConfigTimeEmail
