import React, { useEffect } from 'react'
import LayoutNew from '../../../../components/tailwind/layout-new'
import { getTranslation } from '../../../../translations';
import PageIntegration from '../../../../components/page-clean';
import TableReviews from '../../../reviews/components/tailwind/table';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/tailwind/navbar';
import { PlusIcon } from '@heroicons/react/24/outline';
import LayoutUI from '../../../../components/tailwind/layout-ui';
// import TableReviews from '../../components/tailwind/table-review';

const Reviews = () => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const { id } = useParams();

    useEffect(() => {
        console.log("productID", id)
    }, [])


    return (
        <>
            <TableReviews productId={id} navbar={<Navbar />} />
        </>
    )
}

export default Reviews