import { ReactNode, useState, Children, isValidElement, cloneElement, useEffect } from 'react';
import { SubMenuProvider } from './SubMenuContext';
import TopBar from './TopBar';
import ButtonSuporte from './ui/ButtonSuporte';
import { motion } from 'framer-motion';
import Content from './Content';
import ContentApp from './ContentApp';
import PerfilMenu from './ui/PerfilMenu';
import Separator from './ui/Separator';
import ItemMenu from './ui/ItemMenu';
import { eplusHub } from './api/eplus';

type HeadersType = {
	[key: string]: string;
};
interface SideBarProps {
	children: ReactNode;
	app: string;
	classeAtiva?: string;
	isOpen?: boolean | false | true;
	appEndPoint?: string;
	headers?: HeadersType;
	chave_empreender?: string;
}

interface ChildProps {
    chave_empreender: string;
}

interface ItemMenuProps {
	setSidebarOpen: (toggle: boolean) => void;
	classeAtiva?: string;
}

interface SeparatorProps {
	setSidebarOpen: (toggle: boolean) => void;
}

interface EplusApps {
	[key: string]: {
		integrations?: Integration;
	};
}

declare global {
	interface Window {
		configsAppsPlus: EplusApps;
	}
}

interface Integration {
	[key: string]: {
		show: boolean;
		img: string;
	};
}

const SideBar = ({ children, app, classeAtiva, isOpen = false, appEndPoint, headers, chave_empreender = '' }: SideBarProps) => {
	const [dataUser, setDataUser] = useState<EplusApps | null>(null);

	const [isSidebarOpen, setSidebarOpen] = useState(isOpen || false);

	const sidebarElements = Children.map(children, (child) => {
		if (!isValidElement(child)) return null;

		if (child.type === ItemMenu || child.type === Separator) {
			return cloneElement(child as React.ReactElement<ItemMenuProps | SeparatorProps>, {
				setSidebarOpen,
				classeAtiva,
			});
		}

		return null;
	});

	useEffect(() => {
		const options = {
			method: 'POST',
			credentials: 'include' as RequestCredentials,
			headers,
			body: JSON.stringify({
				acao: 'buscarUsuarioComConfigs',
			}),
		};

		if (!dataUser) {
			eplusHub(options, appEndPoint).then((res) => {
				try {
					const { name, imagem_perfil } = res.data;
					setDataUser({
						name: name || '',
						imagem_perfil: imagem_perfil || '',
					});
				} catch (error) {
					console.log(error);
				}
			});
		}
	}, [dataUser]);

	const contents = Children.map(children, (child) => (isValidElement(child) && child.type === Content ? child : null));

	const contentApp = Children.map(children, (child) => (isValidElement(child) && child.type === ContentApp ? child : null));

	const perfilMenu = Children.map(children, (child) => {
		if (isValidElement(child) && child.type === PerfilMenu) {
			return cloneElement(child, { ...dataUser, chave_empreender } as ChildProps);
		}
		return null;
	});

	return (
		<SubMenuProvider isSidebarOpen={isSidebarOpen} setSideBarOpen={setSidebarOpen} appEndPoint={appEndPoint} headers={headers}>
			<div className="h-screen w-screen overflow-hidden z-[1000]">
				<TopBar app={app} />

				<div className="flex flex-row flex-nowrap w-full h-full">
					<div className="flex flex-row bg-white">
						{/* Sidebar */}
						<motion.div exit={{ width: 0 }} initial={{ width: isSidebarOpen ? 290 : 68 }} animate={{ width: isSidebarOpen ? 290 : 68 }} className={`bg-white border-r !px-[15.5px] !py-5 border-gray-200 h-[calc(100%-60px)] justify-between flex flex-col gap-1 max-[460px]:z-[2] max-[460px]:absolute`}>
							<div className="overflow-x-hidden overflow-y-scroll custom-scroll zero-scroll rounded-md">
								<div className="flex flex-col gap-1 select-none">{sidebarElements}</div>
							</div>

							<div className="flex flex-col gap-2 select-none bg-white">
								<ButtonSuporte app={app} />
								{perfilMenu}
							</div>
						</motion.div>

						{/* Terceiro menu */}
						<div className="flex flex-row max-[460px]:relative max-[460px]:left-[68px] max-[460px]:z-[1]">{contents}</div>
					</div>

					{/* Aplicativo */}
					<div className="w-full h-full overflow-auto relative">{contentApp}</div>
				</div>
			</div>
		</SubMenuProvider>
	);
};

export default SideBar;
