import { useSelector } from "react-redux";
import LoadingLogoLilyNew from "../../../../components/Loading-logo-lily-new";
import Layout from "../../../../components/tailwind/layout";
import Pagination from "../../components/tailwind/pagination";
import TableReviews from "../../components/tailwind/table";
import { ContainerLoading } from "./styled";
import { useEffect } from "react";
// import ModalEdit from "../../components/tailwind/modal-edit";
import ModalEdit from "../../components/modal-edit-reviews";
import { useState } from "react";
import HelpClick from "../../../../components/HelpClick";
import ModalInforVideo from "../../../../components/modal-infor-video";
import { getTranslation } from "../../../../translations";
import LayoutNew from "../../../../components/tailwind/layout-new";
import PageIntegration from "../../../../components/page-clean";
import LayoutUI from "../../../../components/tailwind/layout-ui";


export default function HomeNew() {
  const reviews = useSelector(state => state.reducerReviews)
  const [visibleModalInforReviews, setVisibleModalInforReviews] = useState(false)
  const [visibleModalEdit, setvisibleModalEdit] = useState(false)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  return (
    <>
      <ModalInforVideo visible={visibleModalInforReviews} setVisibleModal={setVisibleModalInforReviews}
       video={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/xrqYpY3WL44" :"https://www.youtube.com/embed/9C4v5kaE_qs"}
       />
      {/* <ModalEdit visible={visibleModalEdit} setVisible={setvisibleModalEdit} /> */}

      {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">{translation.sidebar.avaliacoes}</h1>
              <p className="mt-2 text-sm text-gray-700">
                {translation.title.avaliacoes}
              </p>
            </div>
          </div>
          <PageIntegration />
        </div> :
        <>
          <TableReviews setVisibleModalEdit={setvisibleModalEdit} />
          {!reviews.loading &&
            <div style={{ display: "flex", padding: "20px 0px", justifyContent: "center" }} >
              <div onClick={() => { setVisibleModalInforReviews(true) }}>
                <HelpClick text={translation.alert.avaliacoes} />
              </div>
            </div>}
        </>
      }
    </>
  )
}
