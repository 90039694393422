import { ReactNode } from 'react';
import { useSubMenu } from './SubMenuContext';
import { AnimatePresence, motion } from 'framer-motion';

interface ContentProps {
	id: string;
	children: ReactNode;
}

const Content = ({ id, children }: ContentProps) => {
	const { state } = useSubMenu();
	const isOpen = state[id] || false;

	return (
		<AnimatePresence mode="popLayout">
			{isOpen && (
				<>
					<motion.div key={id} exit={{width: 0, opacity: 0}} initial={{ width: isOpen ? 0 : 290, opacity: 0 }} animate={{ width: 290, opacity: 1 }} className="overflow-hidden bg-white w-[290px] border-r border-gray-200 h-full max-[460px]:absolute max-[460px]:z-[1] max-[460px]:!opacity-100">
						<div className="w-[290px] h-[calc(100%-60px)] px-[15.5px] py-5">{children}</div>
					</motion.div>
				</>
			)}
		</AnimatePresence>
	);
};

export default Content;
