const ReplayIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Camada_3" x={0} y={0} width={22} height={22} viewBox="0 0 3000 3000" {...props}>
		<style>{'.replay1{fill:#080413}.replay4{fill:#fe8f00}'}</style>
		<path d="M2868.1 1475.1c-49.1-36.8-118.2-51.6-161.8-64-34.4-11.1-34-1.2-33.9 27.4.1 27.1 9.6 108 12.5 133.4 4.6 38-2.6 100.3-26.2 132.4-26.6 36.1-57.6 68.4-91.8 97.5-77 65.6-162.1 119.8-245.8 176.2-133.7 90-282.7 143.4-437.8 181.2-104 25.3-209.5 41.6-315.8 54.9-131.3 16.4-248.9 32.9-381.4 23-75.5-5.7-140.1-14.3-221.8-34.1-26-6.2-47.8-18.1-52.1-43-22.1-128.1-8.7-271.6 11.2-345.6 15.3-57.1 68.4-48.1 78.9-47.8 156.6 5 311.6-9.9 465.8-36.8 207.7-36.4 413.7-79 607.6-164.9 116-51.4 223.5-117.1 319-201.8 36.3-32 146.7-124.5 121.5-168.8-42.4-74.5-88.1-144.2-153.2-201.7-96.6-85.7-201.3-158-318.2-212.7-116.5-54.4-239.4-85.1-367.3-94.4-101.1-7.4-202.3 1.5-300.5 23.9-46.7 10.7-75.4-2.7-104.8-31.6-5-4.9-9.9-9.9-15.1-14.6-53.4-48.1-106.6-96.1-166.2-137.1-115-79.3-240.5-138.2-364.7-199.9-14.2-7-28.9-12.6-44.1-17.1-55-16.4-84-3.8-108.9 47.6-18.3 38-31.9 78.1-44.8 117.9-30.2 93.6-53.3 188.4-60.9 287.4-6.1 80.8-7 161.6-1.7 241.9 3 45.7-7.2 82.3-31.2 118.3-15.2 22.8-26.6 47.5-37.4 72.5-82.6 189.2-124.2 386.8-117.9 593.5.9 28-5.6 53.4-18.3 78.3-45.6 91-132.2 222.9-159.3 325.4-17.4 66.2 93.4 185.5 132.6 226.8 20.6 21.6 43.1 41.3 67.4 58.6 98.2 69.8 201.7 131 311.9 179.4 221.6 97.3 448.2 178.1 692.9 192.9 90.1 5.5 180.5 4.4 270.6-.3 26.3-1.3 257.9-16.1 338.1-6.8 51.8 5.9 277.9 12.1 278.1 11.4 90.6 3.7 167.8-25 226.8-96.7 62.4-75.9 97.7-170.6 104.7-267.3 2.6-34.9 2-62.8 10.5-95.9 8.5-33 20.9-65 39.3-95.9 38.6-64.8 74.2-131.3 109.4-197.9 50.1-94.7 98.2-238 139.8-336.6 24.9-59 46.2-119.5 63.9-181.1 20.5-71 16.1-76-19.6-107.4z" className="replay1" />
		<linearGradient id="SVGID_1_" x1={1021.423} x2={2576.923} y1={1968.232} y2={1968.232} gradientUnits="userSpaceOnUse">
			<stop
				offset={0}
				style={{
					stopColor: '#d501e8',
				}}
			/>
			<stop
				offset={0.547}
				style={{
					stopColor: '#f52761',
				}}
			/>
			<stop
				offset={1}
				style={{
					stopColor: '#ff9000',
				}}
			/>
		</linearGradient>
		<path
			d="m1516.6 1889.9 162.2 156.7c.1 0 .3-.1.4-.1l-162.1-156.7c-.1 0-.3 0-.5.1z"
			style={{
				fill: 'url(#SVGID_1_)',
			}}
		/>
		<path
			d="M1055 1915.7c-13.9-1-30.2 16.5-30.1 30.5l-3.4 110.3c.1 15.6 5.6 27.1 21.1 28.9 124.8 14.3 253.9 13.5 436.3-2 4.7-.4 43-6.2 47.6-7 57-8.5 105.9-18.3 152.4-29.7L1516.6 1890c-144.6 21.8-305.6 35.3-461.6 25.7z"
			style={{
				fill: '#d603dd',
			}}
		/>
		<path
			d="M2259.7 1830.9 2144 1719.2c-92.9 43.4-190.4 75.4-290.5 101.2-88.4 22.8-205.8 49.5-336.4 69.4l162.2 156.7c78.6-19.3 150.6-43.1 243.5-72.8 117.5-37.5 231.4-82.6 336.9-142.8z"
			style={{
				fill: '#f42364',
			}}
		/>
		<path d="M2521 1626.8c23.7-25.3 48.6-48.2 55.3-77.6 4.1-18-13.6-88.5-19.9-112-1.1-3.7-5.4-5.5-8.6-3.4-11.5 7-28.9 27.6-40.7 37.8-72.8 63-145.7 120.9-227.8 173.5-43.7 28-88.9 52.5-135.2 74.1l115.7 111.6c95-54 183.2-120.2 261.2-204z" className="replay4" />
		<path d="M2259.7 1830.9c.1 0 .1-.1.2-.1l-115.7-111.6c-.1 0-.1.1-.2.1l115.7 111.6z" className="replay4" />
		<path d="M2465.8 913.3c4.6 72.3-48.4 26.9-60.6 16.3-38.6-33.2-203.5-181-436.1-255.7-31.7-10.2-63-21.1-94.4-32.4-35.1-12.6-43.2-30.9-27.6-64.9 41.5-90 91.7-175.4 142.5-260.5 27.4-46 54.2-92.3 87.8-134.1 38.3-47.6 63.9-48.3 120.6-.6 64.8 69.6 209.8 206 240.1 484.2 13.1 59.4 26.2 225.7 27.7 247.7z" className="replay1" />
		<path
			d="M2393.3 2393.4c-3.6 38.3-10.3 80.4-25.7 120.7-7.1 18.5-19.1 26.8-38.8 16.7-42.4-21.4-82.2-46.7-115.4-81-18.7-19.5-17.7-31.2 4.4-47.2 39.3-28.3 83-48.5 129.6-61.2 31.7-8.6 46.8 7.9 45.9 52z"
			style={{
				fill: '#fefefe',
			}}
		/>
	</svg>
);
export default ReplayIcon;
