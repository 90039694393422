const OvniIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" id="Camada_1" x={0} y={0} width={22} height={22} viewBox="0 0 3000 3000" {...props}>
		<style>{'.ovni2{fill:#02000b}.ovni3{fill:#fefdfe}'}</style>
		<path
			d="M1301.8 1287.2c-.6-.1-1.2-.3-1.7-.5-38.5-20.4-68.8-51.3-101.3-79.3-48.1-41.3-99.7-77.8-162.7-90.4-117.8-23.5-213.3 17.8-289.6 108-33.2 39.4-68.4 76.8-113 103.8-58.2 35.2-120 28.1-182.2 13.2-67.7-16.2-133.5-16.6-192.7 26.8-54.6 40-82.1 95.6-89.9 161.8-.4 3.7-.8 7.6-1 11.2-.4 5.2-7.5 6.3-9.5 1.6v-.1c-28.8-67.7-53.9-136.7-57.6-210.9-7.1-140.9 48.9-254.7 154.7-345.2 96.8-82.9 211.2-131.3 332.8-164.1 218.4-59 440.3-64.3 664.1-44.3 231.4 20.7 455.1 73.8 673.2 152.7 2.4.9 4.7 2 7.1 3.2 1.7.8 2.8 2.6 2.7 4.5-.3 11.1-10 15.3-18.2 20-88.6 51.4-179 99.9-269.4 148.3-98.9 52.9-197.6 106.4-296.1 160.2-15.6 8.6-29.7 22.1-49.7 19.5z"
			style={{
				fill: '#e10066',
			}}
		/>
		<path
			d="M1300.9 1287.1c205.6-111.8 411.2-223.5 616.8-335.2 7.2-3.9 13.2-8.7 17.3-15.9 250.4 92.4 482.2 217 681.5 396.9 102.3 92.4 189.4 197.1 242.6 325.5 62.3 150 57.8 295-43.5 429.7-13.1 17.5-21.6 17-37.3 4.5-48.5-38.4-70.8-90.3-83.2-149-9.6-45.6-2.8-91.8-9.3-137.5-14.4-102.3-98.1-189.3-200.7-206.2-38.3-6.4-75.2-2.4-113 3.5-106.5 16.7-191.8-24.3-261.4-102.7-31.3-35.3-53.6-76.4-75.4-118-33.4-63.9-74.9-120.8-147.3-145-60.6-20.3-120.1-10.9-179.2 8.4-52.3 17.1-98.5 47.5-148.8 68.9-91.8 39.1-178.5 34-259.1-27.9z"
			style={{
				fill: '#ff3bab',
			}}
		/>
		<path d="M1081.5 697.8c63.3-45.7 128.5-71.4 197.8-86.7 218.2-48.4 431.1-21.6 639.8 49.6 136.6 46.6 262.8 113.3 371 210.6 70.6 63.6 129.2 135.8 157.2 229 2.8 9.4 5.2 18.6 5.1 32.2-13.3-9.7-24.8-18.1-36.3-26.3-227.9-162.9-477.4-277.1-752.1-335.1-189.8-40-380.8-69-582.5-73.3zM2740.3 2151.9c-79.3-60.5-113-144.1-119.2-240.9-2.2-35.4-.5-71.4-7.2-105.9-17.3-88.4-104.5-147.8-194.8-137.3-35.9 4.1-71.1 14.8-107.8 11.2-103.4-10.1-187.7-55.8-255.8-134-41.6-47.9-66.6-105.6-99.2-158.8-51.1-83.4-110.2-104.7-204.9-79.1-49.1 13.2-92.3 39-137.2 61-122.5 60-240.1 57.6-355.1-21-39.8-27.2-74.8-59.2-111.2-90.1-46.9-39.9-97.8-74.4-161.8-76.5-78.8-2.5-139.8 33.9-189.6 93.5-51.9 62.1-108.5 119.1-190.2 142-64.7 18.1-127.8 5.4-190.9-9.3-81.9-19-153.5 25.1-172.4 106.6-12.4 53.8-3.4 107.2 1.4 160.9 2.3 25.3 14.6 43.7 30.2 62.1 65.5 77.5 139.5 145.8 220.4 206.8 193 145.7 407.3 250.1 635.4 328.2 263.1 90.1 533.3 139.1 811.7 142.1 108.2-1 215.9-7.2 322.7-26.4 139.2-25.1 273.1-65.2 393.6-142.1 31.1-19.9 62.9-39.5 84.9-70.8 7.3-10.1 7.3-14.3-3-22.2zm-834.8-153c-8.6 12-24 11-38.6 6.6-52.3-16-105-30.7-157.2-47-16.8-5.3-30-5.7-47 3.4-41.4 22.3-87.7 18.9-132.7 15.7-86.6-6.2-169-29.3-244.7-71.9-35.2-19.8-72-40.4-78.3-87.2-1.2-8.7-9-7.7-14.2-9.2-51.6-15.2-103.4-29.8-155.1-44.6-5.1-1.4-10.4-2.5-15.2-4.7-11.1-5.2-25-9.1-23.8-24.7 1.2-16.5 14.6-20.2 28.4-22.6 65.5-11.8 131.6-18.1 198.2-18 246.9 1.3 465.4 76.8 648.1 246.7 7.2 6.7 14.3 13.2 20.7 20.5 9.4 10.5 21.2 23.1 11.4 37z" className="ovni2" />
		<path d="M349.6 1184c-19.1-7.7-28.5-29.6-20.8-48.7l28.7-71.4c7.7-19.1 29.6-28.5 48.7-20.8 19.1 7.7 28.5 29.6 20.8 48.7l-28.7 71.4c-7.6 19.1-29.5 28.5-48.7 20.8zM764.7 1043.2c-11.7 13.6-32.5 15.3-46.3 3.4l-51.1-44c-13.6-11.7-15.3-32.5-3.4-46.3 11.7-13.6 32.5-15.3 46.3-3.4l51.1 44c13.6 11.9 15.1 32.7 3.4 46.3zM950.1 958.5c-13.6-11.7-15.3-32.5-3.4-46.3l44-51.1c11.7-13.6 32.5-15.3 46.3-3.4 13.6 11.7 15.3 32.5 3.4 46.3l-44 51.1c-11.8 13.6-32.6 15.2-46.3 3.4zM1317.2 1116.1c-15.7 9-35.8 3.6-44.8-12l-33.8-58.4c-9-15.7-3.6-35.8 12-44.8 15.7-9 35.8-3.6 44.8 12l33.8 58.4c9.1 15.7 3.7 35.8-12 44.8zM1488.3 1031.7c-9-15.7-3.6-35.8 12-44.8l58.4-33.8c15.7-9 35.8-3.6 44.8 12 9 15.7 3.6 35.8-12 44.8l-58.4 33.8c-15.6 9-35.8 3.7-44.8-12zM2030.3 1203.7c-15.7 9-35.8 3.6-44.8-12l-33.8-58.4c-9-15.7-3.6-35.8 12-44.8 15.7-9 35.8-3.6 44.8 12l33.8 58.4c9.1 15.6 3.7 35.8-12 44.8zM2117.5 1405.5c-9-15.7-3.6-35.8 12-44.8l58.4-33.8c15.7-9 35.8-3.6 44.8 12 9 15.7 3.6 35.8-12 44.8l-58.4 33.8c-15.6 9-35.8 3.7-44.8-12zM2455.7 1388c-15.7 9-35.8 3.6-44.8-12l-33.8-58.4c-9-15.7-3.6-35.8 12-44.8 15.7-9 35.8-3.6 44.8 12l33.8 58.4c9 15.6 3.6 35.7-12 44.8zM2490.3 1547.5c-9-15.7-3.6-35.8 12-44.8l58.4-33.8c15.7-9 35.8-3.6 44.8 12 9 15.7 3.6 35.8-12 44.8l-58.4 33.8c-15.6 9-35.8 3.6-44.8-12zM2811.2 1805.1c-15.7 9-35.8 3.6-44.8-12l-33.8-58.4c-9-15.7-3.6-35.8 12-44.8 15.7-9 35.8-3.6 44.8 12l33.8 58.4c9.1 15.7 3.6 35.9-12 44.8z" className="ovni3" />
	</svg>
);
export default OvniIcon;
